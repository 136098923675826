import React, { useEffect } from 'react';
import { IonPage, IonText, IonContent,  IonRow, IonCol, IonGrid } from '@ionic/react';
import { useAuth0 } from '@auth0/auth0-react';
import { Browser } from '@capacitor/browser';
import { UserPreferences, defaultUserPreferences } from '../../services/user';
import { callbackUri } from '../../auth.config';

import './Login.css';



const Logout: React.FC = () => {

    const { buildLogoutUrl, logout } = useAuth0();

    useEffect(() => {

        const delay = 3000; //Wait 3 seconds before redirecting to login screen

        const doLogout = async () => {
            await new Promise(resolve => setTimeout(resolve, delay));
            await Browser.open({
            url: buildLogoutUrl({ returnTo: callbackUri }),
            windowName: "_self",
            });

            logout({ localOnly: true });
        };

        doLogout(); 

        return () => {
            UserPreferences.update(s => s = defaultUserPreferences); //Reset UserPreferefence values stored in memory 
        }

    },[logout, buildLogoutUrl])

    return (

        <IonPage>
            <IonContent fullscreen className="ion-padding">

                <IonGrid>

                    <IonRow className="header">
                        <IonCol className="header-logo-img">
                            <IonCol className="header-logo">
                                <IonCol className="header-logo-text"></IonCol>
                            </IonCol>
                        </IonCol>
                        <IonCol className="header-img"></IonCol>
                    </IonRow>

                    <IonRow className="login">
                        <IonText className="login-title"><strong>Logout</strong></IonText>
                        <IonText className="login-subtitle-black"><h5><strong>Logging you out, please wait ...</strong></h5></IonText>
                    </IonRow>

                </IonGrid>

            </IonContent>
        </IonPage>
    )
}

export default Logout;
