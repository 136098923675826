import { useEffect, useReducer } from 'react';
import { IonItem, IonCol, IonIcon, IonLabel, IonInput, isPlatform } from '@ionic/react';
import { construct } from 'ionicons/icons';
import { BodyWidth } from '../../../../../../models/Body';
import { Calculator, CalcValues, wReducer } from '../../../../../../services/calculations';

interface WidthProps {
    title: string;
    description: string;
    values: CalcValues;
    setValues: (v: CalcValues) => void;
}

const WidthInternal: React.FC<WidthProps> = ({title, description, values, setValues}) => {

    const ios = isPlatform('ios');
    const { ...truck } = Calculator(values);
    const [state, dispatcher] = useReducer(wReducer, new BodyWidth(values));

    useEffect(() => {
        const v = { ...values};
        v.body!.width = state;
        setValues(v);

    }, [state]);

    return (

        <IonItem>
            <IonCol size="8">
                { ios &&
                    <IonIcon slot="start" icon={construct} color="medium" />
                }
                <IonLabel>
                    <h3>{title}</h3>
                    <p>{description}</p>
                </IonLabel>
            </IonCol>
            <IonCol size="8">
                <IonInput color="primary" placeholder={`${truck.dimensions.ibw} mm`} onIonChange={e => {
                    const w = e.detail.value as string;
                    dispatcher({type: 'WIDTH_INTERNAL', payload: { ...state, loadCompartmentWidth: w} });
                }}>
                </IonInput>
            </IonCol>
        </IonItem>
    );
}

export default WidthInternal;