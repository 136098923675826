import React, { useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useStoreState } from 'pullstate';
import { User } from '@auth0/auth0-react';
import * as Sentry from "@sentry/react";

import { useFetchDataFromDB } from './services/DataApi';
import { UserPreferencesModel } from './models/User';
import { UserPreferences, getUser, setUser } from './services/user';

/** Main Pages */
import Builder from './pages/Builder';
import Message from './pages/Message';
import Messages from './pages/Messages';
import Dealership from './pages/Dealership';
import Guidelines from './pages/Guidelines';
//import OnBoarding from './pages/OnBoarding';
import Settings from './pages/Settings';
import Support from './pages/Support';
import Tabs from './pages/Tabs';

/** Calculation Pages */
import Calculations from './pages/Calculations';
import CalcSave from './pages/calculations/CalcSave';
import CalcTruck from './pages/calculations/CalcTruck';
import CalcTruckList from './pages/calculations/CalcModelList';
import NewCalc from './pages/calculations/NewCalc';

/** Auth Pages */
import Logout from './pages/auth/Logout';
import LoginCode from './pages/auth/LoginCode';
import LoginEmail from './pages/auth/LoginEmail';
import Profile from './pages/auth/Profile';
import LoginAccountDeleted from './pages/auth/LoginAccountDeleted';
import SplashScreen from './pages/auth/SplashScreen';

/** Account Pages */
import Legal from './pages/settings/Legal';
import Preferences from './pages/settings/Preferences';
import Privacy from './pages/settings/Privacy';
import TOS from './pages/settings/Tos';
import TOSPDF from './pages/settings/TosPdf';
import DocViewer from './components/documents/DocViewer';

/** Guidelines Pages */
import Precaution from './pages/guidelines/Precaution';
import Precautions from './pages/guidelines/Precautions';
import Regulations from './pages/guidelines/Regulations';
import ElectricalSystem from './pages/guidelines/ElectricalSystem';
import ElectricalSystems from './pages/guidelines/ElectricalSystems';

/** Route Constants */
import * as ROUTES from './config';


interface PrivateRoutesProps {
    auth0User?: User | undefined;
}


const messagesApiUrl = ROUTES.MESSAGES_API_ENDPOINT;
const userApiUrl = ROUTES.USERS_API_ENDPOINT;


const PrivateRoutes: React.FC<PrivateRoutesProps> = ({auth0User}) => {

    //const { user: auth0User , isLoading: isLoadingAuth0User } = useAuth0();
    const { data: messages, isLoading: isLoadingMessages } = useFetchDataFromDB('messages', messagesApiUrl);
    const { data: awsUser, isLoading: isLoadingUser} = useFetchDataFromDB('user', userApiUrl);
    const [showBadge, setShowBadge] = useState(false);
    const [messageCount, setMessageCount] = useState(0);

    useEffect(() => {

        if (auth0User !== undefined && awsUser !== undefined && !isLoadingUser) {

            const user = awsUser.length > 0 ? awsUser[0] : auth0User;
    
            const nickname = awsUser.length > 0 ? user.name : auth0User.name!.charAt(0).toUpperCase();
            const username = auth0User.name!.includes('@') ? nickname : auth0User.name!;
            const email = auth0User.email!;
            const role = awsUser.length > 0 ? [`${user.role}`] : auth0User["https://app.hino.co.za/roles"];
            const org = awsUser.length > 0 ? user.org : null;

            // Set user name, email and role on the state
            setUser(email, username, role, org, auth0User._raw, auth0User.sub);
            Sentry.setUser({ email: auth0User.sub, username: username, id: auth0User.sub });

            // Set message count and showBadge
            if (!isLoadingMessages && messages) {
                setMessageCount(messages.length);
                messages.length > 0 ? setShowBadge(true) : setShowBadge(false);
            } 

            // Update the values of showBadge and messageCount on the state
            UserPreferences.update((s: UserPreferencesModel) => {
                s.messageCount = messageCount;
                s.showBadge = showBadge;
            })
        };
    
    }, [auth0User, messages, isLoadingMessages, messageCount, showBadge, isLoadingUser, awsUser]);

 
    const user = useStoreState(UserPreferences, getUser);


    return (
        <Switch>

            {  //If Loading user, return Loading Screen
                (isLoadingUser) && <SplashScreen progress={75} text={`Loading your personal information`}/>
            }

            { //If user role is not defined, redirect to get more user in
                (auth0User !== undefined && awsUser !== undefined && !(user.role.length > 0)) && 
                
                <Profile />
            }
            
            {/** Main Routes */}
            <Route path={ROUTES.HPS_PROFILE_PAGE_ROUTE} component={Builder} exact={true} />
            <Route path={ROUTES.DEALERSHIPS_SINGLE_PAGE_ROUTE} component={Dealership} exact={true} />
            <Route path={ROUTES.GUIDELINES_PAGE_ROUTE} component={Guidelines} exact={true} />
            <Route path={ROUTES.LEADS_SINGLE_PAGE_ROUTE} component={Messages} exact={true} />
            <Route path={ROUTES.LEADS_CHAT_PAGE_ROUTE} component={Message} exact={true} />
            {/*<Route path={ROUTES.ONBOARDING_PAGE_ROUTE} component={OnBoarding} exact={true} />*/}
            
            <Route path={ROUTES.SETTINGS_LEGAL_PAGE_ROUTE} component={Legal} exact={true} />
            <Route path={ROUTES.SETTINGS_LEGAL_PRIVACY_PAGE_ROUTE} component={Privacy} exact={true} />
            <Route path={ROUTES.SETTINGS_LEGAL_TOS_PAGE_ROUTE} component={TOS} exact={true} />
            <Route path={ROUTES.SETTINGS_LEGAL_TOS_DOCUMENT_PAGE_ROUTE} component={TOSPDF} exact={true} />
            <Route path={ROUTES.SETTINGS_PAGE_ROUTE} component={Settings} exact={true} />
            <Route path={ROUTES.SETTINGS_PREFERENCES_PAGE_ROUTE} component={Preferences} exact={true} />
            <Route path={ROUTES.SETTINGS_PROFILE_PAGE_ROUTE} component={Profile} exact={true} />
            
            <Route path={ROUTES.SUPPORT_PAGE_ROUTE} component={Support} exact={true} />

            <Route path={ROUTES.PRECAUTIONS_DETAILS_PAGE_ROUTE} component={Precaution} exact={true} />
            <Route path={ROUTES.PRECAUTIONS_PAGE_ROUTE} component={Precautions} exact={true} />
            <Route path={ROUTES.ELECTRICAL_SYSTEMS_DETAILS_PAGE_ROUTE} component={ElectricalSystem} exact={true} />
            <Route path={ROUTES.ELECTRICAL_SYSTEMS_PAGE_ROUTE} component={ElectricalSystems} exact={true} />
            {/*<Route path={ROUTES.AARTO_REGULATIONS_DETAILS_PAGE_ROUTE} component={Regulation} exact={true} />*/}
            <Route path={ROUTES.AARTO_REGULATIONS_PAGE_ROUTE} component={Regulations} exact={true} />

            {/** Tabs Routes */}
            <Route path={ROUTES.MAIN_TABS_ROUTE} render={() => <Tabs user={user} />} />
            
            {/** Calculations Routes */}
            <Route path={ROUTES.CALCULATIONS_PAGE_ROUTE} component={Calculations} exact={true} />
            <Route path={ROUTES.CALCULATIONS_SAVE_PAGE_ROUTE} component={CalcSave} exact={true} />
            <Route path={ROUTES.CALCULATIONS_PDF_PAGE_ROUTE} component={DocViewer} exact={true} />
            <Route path={ROUTES.TRUCK_SINGLE_PAGE_ROUTE} component={CalcTruck} exact={true} />
            <Route path={ROUTES.TRUCK_LIST_PAGE_ROUTE} component={CalcTruckList} exact={true} />
            <Route path={ROUTES.CALCULATIONS_DETAIL_PAGE_ROUTE} component={NewCalc} exact={true} />

            {/** Auth Routes */}
            <Route path={ROUTES.LOGOUT_ROUTE} component={Logout} exact={true} />
            <Route path={ROUTES.LOGIN_DIGITS_ROUTE} component={LoginEmail} exact={true} /> 
            <Route path={ROUTES.LOGIN_CODE_ROUTE} component={LoginCode} exact={true} /> 
            <Route path={ROUTES.LOGIN_ACCOUNT_DELETED_ROUTE} component={LoginAccountDeleted} exact={true} /> 

            {/** Default Routes */}
            <Route exact path="/" render={() => <Redirect to={ROUTES.TABS_HOME_PAGE_ROUTE} />} />

            {/** Non-Existent Routes */}
            <Route path='*' render={() => <Redirect to={ROUTES.TABS_HOME_PAGE_ROUTE} /> } /> 

        </Switch> 

    );
}

export default PrivateRoutes;
