import { IonButton, IonCol, IonContent, IonHeader, IonRouterOutlet, IonRow, IonSplitPane, IonToolbar } from "@ionic/react";
import { User } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { useStoreState } from "pullstate";
import * as Sentry from "@sentry/react";
import { Redirect, Route, Switch } from "react-router";


import { useFetchDataFromDB } from "../services/DataApi";
import { UserPreferences, getUser, setUser } from "../services/user";
import { UserPreferencesModel } from "../models/User";

import LoginAccountDeleted from "../pages/auth/LoginAccountDeleted";
import LoginCode from "../pages/auth/LoginCode";
import LoginEmail from "../pages/auth/LoginEmail";
import Logout from "../pages/auth/Logout";
import Support from "../pages/Support";
import Menu from "../components/Menu";
import Home from "../pages/TabHome";

import * as ROUTES from "../config";

import './desktop.css';

import Privacy from "../pages/settings/Privacy";
import TOS from "../pages/settings/Tos";
import ElectricalSystem from "../pages/guidelines/ElectricalSystem";
import ElectricalSystems from "../pages/guidelines/ElectricalSystems";
import Guidelines from "../pages/Guidelines";
import Precaution from "../pages/guidelines/Precaution";
import Precautions from "../pages/guidelines/Precautions";
import Regulations from "../pages/guidelines/Regulations";


interface PrivateRoutesProps {
  auth0User?: User | undefined;
}

const messagesApiUrl = ROUTES.MESSAGES_API_ENDPOINT;
const userApiUrl = ROUTES.USERS_API_ENDPOINT;


const PrivateDesktop: React.FC<PrivateRoutesProps> = ({auth0User}) => {

      //const { user: auth0User , isLoading: isLoadingAuth0User } = useAuth0();
      const { data: messages, isLoading: isLoadingMessages } = useFetchDataFromDB('messages', messagesApiUrl);
      const { data: awsUser, isLoading: isLoadingUser} = useFetchDataFromDB('user', userApiUrl);
      const [showBadge, setShowBadge] = useState(false);
      const [messageCount, setMessageCount] = useState(0);
  
      useEffect(() => {
  
          if (auth0User !== undefined && awsUser !== undefined && !isLoadingUser) {
  
              const user = awsUser.length > 0 ? awsUser[0] : auth0User;
      
              const nickname = awsUser.length > 0 ? user.name : auth0User.name!.charAt(0).toUpperCase();
              const username = auth0User.name!.includes('@') ? nickname : auth0User.name!;
              const email = auth0User.email!;
              const role = awsUser.length > 0 ? [`${user.role}`] : auth0User["https://app.hino.co.za/roles"];
              const org = awsUser.length > 0 ? user.org : null;
  
              // Set user name, email and role on the state
              setUser(email, username, role, org, auth0User._raw, auth0User.sub);
              Sentry.setUser({ email: auth0User.sub, username: username, id: auth0User.sub });
  
              // Set message count and showBadge
              if (!isLoadingMessages && messages) {
                  setMessageCount(messages.length);
                  messages.length > 0 ? setShowBadge(true) : setShowBadge(false);
              } 
  
              // Update the values of showBadge and messageCount on the state
              UserPreferences.update((s: UserPreferencesModel) => {
                  s.messageCount = messageCount;
                  s.showBadge = showBadge;
              })
          };
      
      }, [auth0User, messages, isLoadingMessages, messageCount, showBadge, isLoadingUser, awsUser]);
  
   
      const user = useStoreState(UserPreferences, getUser);


  return (
    <>
    <div className="desktop">
      <IonHeader mode="ios">
        <IonToolbar>
          <IonRow className="ion-align-items-center">
                       
            <IonCol size="10">
                
              <div className="navbar ion-text-right">
                <IonButton fill="clear" routerLink={user.role.includes('dealership') ? '/preferred-suppliers' : 'dealerships'} routerDirection="root" 
                  className="link">
                  {user.role.includes('dealership') ? 'Hino Preferred Suppliers' : 'Hino Dealerships'}
                </IonButton>
                <IonButton fill="clear" routerLink={ROUTES.GUIDELINES_PAGE_ROUTE} routerDirection="root" 
                  className="link">
                  Hino Body Mounting Guidelines
                </IonButton>
                <IonButton fill="clear" routerLink={ROUTES.SUPPORT_PAGE_ROUTE} routerDirection="root"
                  className="link">
                  Support
                </IonButton>
              </div>
            </IonCol>

          </IonRow>
        </IonToolbar>
      
      </IonHeader>

      <IonRouterOutlet className="desktop-wrapper">
        
          <IonContent className="desktop-content">
            <IonSplitPane contentId="main" when="md">

              <Menu />

              <IonRouterOutlet id="main">
                <Switch>
                  <Route path={ROUTES.TABS_HOME_PAGE_ROUTE} render={() => <Home user={user} />} exact={true} />
                  <Route path="/" render={() => <Home user={user} />} exact={true} />

                  {/** Legal Routes */}
                  <Route path={ROUTES.SUPPORT_PAGE_ROUTE} component={Support} exact={true} />
                  <Route path={ROUTES.SETTINGS_LEGAL_PRIVACY_PAGE_ROUTE} component={Privacy} exact={true} />
                  <Route path={ROUTES.SETTINGS_LEGAL_TOS_PAGE_ROUTE} component={TOS} exact={true} />

                  {/** Guidelines Routes*/}
                  <Route path={ROUTES.GUIDELINES_PAGE_ROUTE} component={Guidelines} exact={true} />
                  <Route path={ROUTES.PRECAUTIONS_DETAILS_PAGE_ROUTE} component={Precaution} exact={true} />
                  <Route path={ROUTES.PRECAUTIONS_PAGE_ROUTE} component={Precautions} exact={true} />
                  <Route path={ROUTES.ELECTRICAL_SYSTEMS_DETAILS_PAGE_ROUTE} component={ElectricalSystem} exact={true} />
                  <Route path={ROUTES.ELECTRICAL_SYSTEMS_PAGE_ROUTE} component={ElectricalSystems} exact={true} />
                  <Route path={ROUTES.AARTO_REGULATIONS_PAGE_ROUTE} component={Regulations} exact={true} />
                  
                  {/** Auth Routes */}
                  <Route path={ROUTES.LOGOUT_ROUTE} component={Logout} exact={true} />
                  <Route path={ROUTES.LOGIN_DIGITS_ROUTE} component={LoginEmail} exact={true} /> 
                  <Route path={ROUTES.LOGIN_CODE_ROUTE} component={LoginCode} exact={true} /> 
                  <Route path={ROUTES.LOGIN_ACCOUNT_DELETED_ROUTE} component={LoginAccountDeleted} exact={true} /> 

                  {/** Default Routes */}
                  <Route exact path="/" render={() => <Redirect to={ROUTES.TABS_HOME_PAGE_ROUTE} />} />

                  {/** Non-Existent Routes */}
                  <Route path='*' render={() => <Redirect to={ROUTES.TABS_HOME_PAGE_ROUTE} /> } /> 
                </Switch>

              </IonRouterOutlet>

            </IonSplitPane>
          </IonContent>
        
      </IonRouterOutlet>

      <div className="desktop-footer">
        <IonRow className="ion-align-items-right ion-text-right">
        <IonCol size="6">
            <span>Hino Motors South Africa &copy; {new Date().getFullYear()}</span>
          </IonCol>
          <IonCol size="6" className="ion-text-center">
            <span>Privacy&nbsp;</span>
            <span>|&nbsp;</span>
            <span>Terms</span>
          </IonCol>
        </IonRow>
      </div>

    </div>
    </>
  );
}

export default PrivateDesktop;