import { JSXElementConstructor, ReactElement, useEffect, useRef } from "react";
import { IonPage, IonContent, IonHeader, IonToolbar, IonFooter, IonButtons, IonIcon, IonButton, IonText, IonRow, IonCol, IonTitle } from "@ionic/react";
import { close } from 'ionicons/icons';
import html2canvas from "html2canvas";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { DocumentProps } from "react-pdf";
import { Redirect } from "react-router";


import initCalculator, { CalculationStore } from "../../services/calculations";
import Doc from "./Doc";

import * as ROUTES from '../../config';


interface ViewerProps {
  onDismissModal?: () => void;
  docTemplate?: ReactElement<DocumentProps, string | JSXElementConstructor<any>>
}

const DocViewer: React.FC<ViewerProps> = () => {

  const filename = `Hino-Mobile-App-${new Date().getTime()}-${window.crypto.getRandomValues(new Uint32Array(1))[0].toString(16)}.pdf`;

  const canvas = useRef<HTMLElement>(null);
  const spec = CalculationStore.useState(s => s);
  const truckSMC = spec.truckid;
  const title = spec.uniquename;

  const captureElement = async (element: HTMLElement) => {
    const canvas = await html2canvas(element);
    const image = canvas.toDataURL("image/png", 1.0);
    //document.body.appendChild(canvas);
    console.log(image)
    return image
  }

  useEffect (() => {

   if (canvas.current !== null) {
    const image = captureElement(canvas.current);
    console.log(image)
   }

  }, []);

  if (spec.body === null) return <Redirect to={ROUTES.CALCULATIONS_PAGE_ROUTE} />

  initCalculator(truckSMC, spec);

  return (
    <IonPage>

      <IonHeader>
        <IonToolbar color="primary">

        <IonButtons slot="start">
            <IonButton routerLink={ROUTES.CALCULATIONS_PAGE_ROUTE}><IonIcon icon={close}/></IonButton>
        </IonButtons>
        <IonTitle><strong>{title}</strong></IonTitle>


        </IonToolbar>
      </IonHeader>

      <IonContent>

        <IonRow className="ion-justify-content-center ion-text-center ion-padding ion-margin">

          <PDFDownloadLink document={<Doc />} fileName={filename}>
          
            {({ loading }) => {

              return loading ? 

              <IonCol size="12">
                  <lottie-player src={ROUTES.DOWNLOAD_FILE}  background="transparent"  speed="1"  style={{width: '100%', height: '100%'}}  loop  autoplay></lottie-player>
                  <IonText>Loading Document...</IonText> 
              </IonCol>
              
              
              : 

              <IonCol size="12">
                  <lottie-player src={ROUTES.DOWNLOAD_FILE}  background="transparent"  speed="1"  style={{width: '100%', height: '100%'}}  loop  autoplay></lottie-player>
                  <IonButton mode="ios" expand="block">Download</IonButton>
              </IonCol>
              
              }}
          </PDFDownloadLink>
          
        </IonRow>
      </IonContent>

        <IonFooter>
          <IonToolbar>
            
          </IonToolbar>
        </IonFooter>
    </IonPage>
  );
}

export default DocViewer;


