import { useEffect, useRef, useState } from 'react';
import { IonContent,  IonLabel, IonPage, IonItem, IonHeader, IonToolbar, IonButtons, IonButton, IonIcon, isPlatform, IonItemDivider, IonChip, IonText, IonList, IonGrid, IonCol, IonRow, IonItemSliding, IonItemOptions, IonItemOption, IonTitle, IonModal, IonAvatar, IonCard, IonCardContent, IonCardTitle, IonListHeader } from '@ionic/react';
import { options, close, arrowDown, arrowUp, filter } from 'ionicons/icons';
import Skeleton from 'react-loading-skeleton';
import { useFetchDataFromDB } from '../services/DataApi';
import LeadStore from '../services/leads';
import { HinoDealership, dealerlist } from '../models/Dealership';
import { formatTel } from '../services/dealerships';
import { genericFilter, genericSearch, genericSort, IFilter, ISorter } from '../utils';
import SearchInput from '../components/SearchInput';
import SubmitToDealer from '../components/Leads/SubmitToDealer';

import * as ROUTES from '../config';

import './Dealerships.css';
import 'react-loading-skeleton/dist/skeleton.css';
import { useStoreState } from 'pullstate';
import { getUser, UserPreferences } from '../services/user';
import DealerItem from '../components/Leads/DealerItem';
import TruckListFilter, { applicationArray, listOfModels } from '../components/Trucks/TruckListFilter';
import { useHistory } from 'react-router';
import { useDefaultTrucks } from '../services/trucks';
import { TruckSpecifications } from '../models/Truck';
import { min, max } from 'mathjs';


const Trucks: React.FC = () => {
  
  const pageRef = useRef<HTMLElement>(null);
  const ios = isPlatform('ios');
  const user = useStoreState(UserPreferences, getUser);
  const isBodyBuilder = user.role.includes('Hino Preferred Supplier');

  const { data, isLoading } = useFetchDataFromDB('dealerships', ROUTES.DEALERSHIPS_API_ENDPOINT);
  //const { data: dealers, error } = useFetchDealerships();

  const history = useHistory();

  const {defaultTrucks, isLoading: isLoadingTrucks } = useDefaultTrucks(null);

  
  const gvmArray = (series: string) => {
      const c = listOfModels(defaultTrucks, series).flat(1);
      const g = Array.from(new Set(c.map((o: TruckSpecifications) => +o.maxGVMRating)));

      return g;
  }

  const seriesList = defaultTrucks.series.flat(1).map(series => series.name);

  const [query, setQuery] = useState<string>("");
  const [showFilter, setShowFilter] = useState(false);
  const [showCities, setShowCities] = useState(false);
  const [showProvinces, setShowProvinces] = useState(false);
  const [showSort, setShowSort] = useState(false);

  const [showSubmitToDealerModal, setShowSubmitToDealerModal] = useState(false);
  const [dealership, setDealership] = useState<HinoDealership | null>(null);

  const [sortProperty, setSortProperty] = useState<keyof HinoDealership>("SalesPerson");
  const [activeSorter, setActiveSorter] = useState<ISorter<HinoDealership>>({
    property: sortProperty,
    isDescending: false,
  });
  const [activeFilters, setActiveFilters] = useState<Array<IFilter<HinoDealership>>>(
    []
  );

  const [showAZ, setShowAZ] = useState(activeSorter.isDescending);
  const [dealerships, setDealerships] = useState<Array<HinoDealership>>([]);
  const [cities, setCities] = useState<Array<string>>([]);
  const [provinces, setProvinces] = useState<Array<string>>([]);

  useEffect(() => {

    if (!isLoading) {

      const d: HinoDealership[] = isBodyBuilder ? (data as unknown as HinoDealership[]) : (dealerlist as unknown as HinoDealership[]); //If HPS, fetch only dealerships registered on the App, else fetch all dealerships
      const q = d.filter((dealership: HinoDealership) =>
          genericSearch<HinoDealership>(dealership, ['Name','Region', 'Town', 'Address', 'SalesPerson'], query))
        .filter((dealership: HinoDealership) => 
          genericFilter<HinoDealership>(dealership, activeFilters))
        .sort((dealershipA: HinoDealership, dealershipB: HinoDealership) =>
          genericSort<HinoDealership>(dealershipA, dealershipB, activeSorter) 
        );

      const c = Array.from(new Set(q.map((o: HinoDealership) => o.Town.charAt(0))));
      const p = Array.from(new Set(q.map((o: HinoDealership) => o.Region.charAt(0))));

      setDealerships(q);
      setCities(c);
      setProvinces(p);
    }
  }, [query, data, activeFilters, activeSorter, isLoading, isBodyBuilder]);

  useEffect(() => {
    setActiveSorter({
      property: sortProperty,
      isDescending: showAZ
    })
  }, [showAZ, sortProperty]);

  let ionItems: any = [];

  for (let i: number = 0; i < 30; i++) {

    ionItems.push(
      <IonCol size="12" sizeMd="6" sizeLg="4" sizeXl="4" key={`dealership-${i}`}>
        <IonItem className="dealerships-item" key={i} >
          <IonLabel>
            <h4>{<Skeleton/>}</h4>
            <p>{<Skeleton count={3}/>}</p>
          </IonLabel>
        </IonItem>
      </IonCol>
    )
  }

  return (
    <IonPage ref={pageRef} id="Trucks-page">
            <IonHeader>
                <IonToolbar color="toolbar">
                    <IonTitle><strong>Hino Trucks</strong></IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen>
              <IonHeader collapse="condense">
                <IonToolbar>
                  <IonTitle size="large">Hino Trucks</IonTitle>
                </IonToolbar>
              </IonHeader>

            {/*<IonToolbar mode="ios" className="ion-padding-top">
                <SearchInput onChangeSearchQuery={(query) => setQuery(query)}/> 
                <IonButtons slot="end">
                    {
                    !showFilter &&
                    <IonButton color="dark" onClick={() => setShowFilter(true)}>
                    {ios ? 'Filter' : <IonIcon icon={options} slot="icon-only" />}
                    </IonButton>}
                    {
                    showFilter &&
                    <IonButton color="dark" onClick={() => setShowFilter(false)}>
                        {ios ? 'Close' : <IonIcon icon={close} slot="icon-only" />}
                    </IonButton>
                    
                    }
                </IonButtons>
                  </IonToolbar> */}

                <IonList lines={ ios ? 'inset' : 'full'}>


                    {isLoadingTrucks && <IonListHeader>Please wait...</IonListHeader>}
              
                    <IonRow className="ion-justify-content-center"> 
                        
                    {
                        seriesList && seriesList.sort() && seriesList.map((s: string) => {

                            return (
                            <IonCol className="ion-padding" size="12" /*sizeMd="6" sizeLg="6" sizeXl="6"*/ key={s}>

                                <IonCard mode="ios" className="ion-text-center">

                                {
                                isLoadingTrucks && defaultTrucks.series.length === 0 ? 
                                <IonLabel slot="start" className="ion-padding ion-margin-end">
                                    <IonCardTitle mode="ios">Please wait...</IonCardTitle>
                                </IonLabel> 
                                
                                :

                                <IonCardContent>

                                    <IonRow>
                                    <IonCol size="12">
                                        <IonLabel color="primary">
                                        <h2>
                                            <strong>
                                            {s === 'dyna' ? `Toyota ${s.charAt(0).toUpperCase() + s.slice(1)} ` : `Hino ${s} Series `}
                                            </strong>
                                        </h2>
                                        </IonLabel>
                                    </IonCol>
                                    </IonRow>

                                    <IonRow>

                                    <IonCol size="6">

                                        <br/>

                                        {
                                        min(gvmArray(s)) === max(gvmArray(s)) ?
                                        
                                        <p><strong>GVM:</strong> {`${min(gvmArray(s))} kg`}</p>

                                        :

                                        <>
                                            <p><strong>Min GVM: </strong>{`${min(gvmArray(s))} kg`}</p>
                                            <p><strong>Max GVM:</strong> {`${max(gvmArray(s))} kg`}</p>   
                                        </>
                                        }

                                        <p><strong><u>Application (s)</u></strong></p>
                                        <p>{applicationArray(defaultTrucks, s)}</p>

                                    </IonCol>

                                    <IonCol size="6">
                                        <img src={`/assets/images/hino${s}.png`} alt={s}/>
                                    </IonCol>
                                    
                                    </IonRow>

                                    <IonRow className="ion-justify-content-center">
                                    <IonButton 
                                        routerLink={`${ROUTES.TRUCK_LIST_PAGE_ROUTE}?series=${s}&from=${ROUTES.TABS_TRUCK_LIST_PAGE_ROUTE}`}
                                        color="primary">
                                        View {`${listOfModels(defaultTrucks, s).length < 2 ? `model` : `all ${listOfModels(defaultTrucks, s).length} models`}`} &rarr;
                                        </IonButton>
                                    </IonRow>

                                </IonCardContent>
                                }
                                </IonCard>

                            </IonCol>
                            );
                        })
                        }

                    </IonRow>

                </IonList>

            </IonContent>

        </IonPage>
  );

};

export default Trucks;
