import { useStoreState } from "pullstate";
import React, { useRef } from "react";
import { MessageList } from 'react-chat-elements';
import { Link } from "react-router-dom";
import { MessageDetails } from "../../../models/Lead";
import { getUser, UserPreferences } from "../../../services/user";

import 'react-chat-elements/dist/main.css';
import './chats.css';

interface ChatProps {
    message: MessageDetails;
  }

const Chat: React.FC<ChatProps> = ({ message }) => {
  
  const messageListRef = useRef(null);
  const user = useStoreState(UserPreferences, getUser);
  const userid = user.userid;

  const position = userid === message.sender_id ? "right" : "left";
  const status = userid === message.sender_id ? "sent" : "received";
  const bgColor = userid === message.sender_id ? "rgba(157,240,201,.75)" : "white";//"#42d77d" : "#50c8ff" //"#ffca22";
  const notch = userid === message.sender_id ? false : true;

  const titleColor = userid === message.sender_id ? "var(--ion-color-secondary)" : "var(--ion-color-primary)";
  const title = userid === message.sender_id ? "Me" : message.sender_name;
  
  const timestampArr = message.timestamp_sent.split(',').map(a => a.split('/'));
  const date = `${timestampArr[0][0]}/${timestampArr[0][1]}/${timestampArr[0][2]}`;
  const timeArr = `${timestampArr[1].map(t => t.split(':'))}`.split(',', 2);
  const time = `${timeArr[0]}:${timeArr[1]}:00`;

  const timestamp = new Date(`${date} ${time}`);

  return (

    <>
        {/*<div
      className={`chat-bubble ${userid === message.sender_id ? "right" : ""}`}>
      <img
        className="chat-bubble__left"
        src={message.avatar}
        alt="user avatar"
  />
      <div className="chat-bubble__right">
        <p className="user-name">{message.sender_name}</p>
        <p className="user-message">{message.text}</p>
      </div>
    </div>*/}

    <MessageList
      referance={messageListRef}
      className='message-list'
      lockable={false}
      toBottomHeight={'100%'}
      onMeetingLinkClick={() => <Link to={`/calculations`}/>}
      onReplyClick={(e) => console.log(e)}
      onRemoveMessageClick={e => console.log(e)}
      messageBoxStyles={ { background: bgColor, color: "var(--ion-color-dark)" }}
      dataSource={[
          {
            position: position,
            //avatar: '/assets/icon/icon_1024.png',
            type: 'text',
            text: message.text,
            date: timestamp,
            id: message.id!,
            title: title,
            focus: true,
            titleColor: titleColor,
            forwarded: false,
            replyButton: false,
            removeButton: false,
            status: status,
            notch: notch,
            retracted: false,
          },
      ]} />

    </>
 
  );
};

export default Chat;