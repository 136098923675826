import { useEffect, useReducer } from "react";
import { IonCol, IonIcon, IonInput, IonItem, IonLabel, IonList, IonListHeader, isPlatform } from "@ionic/react";
import { CalcValues, Calculator, hReducer, lReducer, mReducer, wReducer } from "../../../services/calculations";
import { construct } from "ionicons/icons";
import { ModelBody } from "../../../models";

interface PositionProps {
    values: CalcValues;
    setValues: (v: CalcValues) => void;
}

const ConfigurePosition: React.FC<PositionProps> = ({values, setValues}) => {

    const ios = isPlatform('ios');
    const { ...truck } = Calculator(values);

    const [hState, hDispatcher] = useReducer(hReducer, new ModelBody.BodyHeight(values)); //Height - Position
    const [lState, lDispatcher] = useReducer(lReducer, new ModelBody.BodyLength(values)); //Length - Cab Gap
    const [mState] = useReducer(mReducer, new ModelBody.BodyMass(values)); //Mass
    const [wState] = useReducer(wReducer, new ModelBody.BodyWidth(values)); //Width

    useEffect(() => {
        const v = { ...values};

        const body: ModelBody.Body = {
            type: v.body!.type,
            mass: mState,
            length: lState,
            height:  hState,
            width: wState
        }

        v.body = body;
        setValues(v);
    }, [hState, mState,lState, wState]);

    return (
        <IonList>

            <IonListHeader className="ion-padding-bottom">Configure Position</IonListHeader>

            <>
                <IonItem>
                    <IonCol size="8">
                        { ios &&
                            <IonIcon slot="start" icon={construct} color="medium" />
                        }
                        <IonLabel>
                            <h3>Cab Gap</h3>
                            <p>Back of cabin to body front</p>
                        </IonLabel>
                    </IonCol>
                    <IonCol size="4">
                        <IonInput color="primary" placeholder={`${truck.dimensions.gap} mm`} onIonChange={e => {
                            e.preventDefault();
                            const g = e.detail.value as string;
                            lDispatcher({type: 'LENGTH_GAP', payload: { ...lState, cabGapRecommendedMin: g} });
                        }}></IonInput>
                    </IonCol>
                </IonItem>

                <IonItem>
                    <IonCol size="8">
                        { ios &&
                            <IonIcon slot="start" icon={construct} color="medium" />
                        }
                        <IonLabel>
                            <h3>Above / Below Chassis</h3>
                            <p>Body position above / below chassis</p>
                        </IonLabel>
                    </IonCol>
                    <IonCol size="4">
                        <IonInput color="primary" placeholder={`${truck.dimensions.position} mm`} onIonChange={e => {
                            e.preventDefault();
                            const p = e.detail.value as string;
                            hDispatcher({type: 'HEIGHT_POSITION', payload: { ...hState, bodyPosition: p} });
                        }}></IonInput>
                    </IonCol>
                </IonItem>
            </>
            
        </IonList>
    );
}

export default ConfigurePosition;