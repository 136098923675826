
import { MassRegulations, DimensionsRegulations } from '../../models/Regulations';

const url = 'https://d1pn8kkxpb.execute-api.eu-west-1.amazonaws.com/alpha/rules';

/** Functions: Regulate mass, dimensions and circle values */

/** @@Params_start
 **
 ** ___input___
 ** property: regulation property to evaluate eg maxMass
 ** value: calculated value e.g gvm.twt
 **
 ** ___output___
 ** isWithin: value within regulation limit e.g. true / false 
 ** comment: user friendly messages e.g. Height is more than 4.3 metres
 **
 ** @@Params_end*/

export const RegulateGVM = (property: string, value: number, description: string) => {

  const mass = new MassRegulations();
  const isWithin: boolean = value <= (+(mass as any)[property]); 
  const comment: string = `${description} is ${isWithin ? "less": "greater"} than ${(mass as any)[property]} kg`;

  return { isWithin, comment };
}

export const RegulateDimensions = (property: string, value: number, description: string) => {

  const dimensions = new DimensionsRegulations();
  const isWithin: boolean = value <= (+(dimensions as any)[property]);  
  const comment: string = `${description} is ${isWithin ? "less": "greater"} than ${(dimensions as any)[property]} m`;

  return { isWithin, comment };
}

/** Function: Fetches rules from the DB */
export const getRules = async (payload = {}) => {

  const response = await fetch(url, {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, *same-origin, omit
    headers: {
      'Content-Type': 'application/json'
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    redirect: 'follow', // manual, *follow, error
    referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: JSON.stringify(payload) // body data type must match "Content-Type" header
  });
  return response.json(); // parses JSON response into native JavaScript objects
}











