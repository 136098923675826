import { useRef } from 'react';
import { Redirect } from 'react-router-dom';
import { IonContent, IonButton, IonToolbar, IonHeader, IonPage, IonButtons, IonIcon, IonTitle, IonFooter } from '@ionic/react';
import { chevronBack } from 'ionicons/icons';

//import { Root, Pages, Page, CanvasLayer, ZoomOut, CurrentZoom, ZoomIn, TextLayer, CurrentPage, TotalPages } from "@unriddle-ai/lector";

//import "pdfjs-dist/build/pdf.worker.min.mjs";
//import "pdfjs-dist/web/pdf_viewer.css";


import { Viewer } from '@react-pdf-viewer/core';



import  { PdfViewerComponent, Toolbar, Magnification, Navigation, LinkAnnotation, BookmarkView,
  ThumbnailView, Print, TextSelection, Annotation, TextSearch, FormFields, FormDesigner, Inject} from '@syncfusion/ej2-react-pdfviewer';

// Plugins
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { bookmarkPlugin } from '@react-pdf-viewer/bookmark';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import { searchPlugin } from '@react-pdf-viewer/search';
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';

// Import styles
import '@react-pdf-viewer/page-navigation/lib/styles/index.css';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';


import * as ROUTES from '../../config';

import '../Guidelines.css'

//Auto Load pdfjs service worker
// Set up the worker
/*GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.mjs",
  import.meta.url
).toString();*/


const TosPdf: React.FC = () => {
  
  const pageRef = useRef<HTMLElement>(null);

  const fileUrl = 'https://hino-documents.s3.af-south-1.amazonaws.com/142.pdf';
  const title = "SANS 10319 Standard";

  // Create new plugin instance
const defaultLayoutPluginInstance = defaultLayoutPlugin();
const bookmarkPluginInstance = bookmarkPlugin();
const zoomPluginInstance = zoomPlugin();
const searchPluginInstance = searchPlugin();
const pageNavigationPluginInstance = pageNavigationPlugin();


  if (title === null) {
		return (
			<Redirect to={ROUTES.SETTINGS_LEGAL_TOS_PAGE_ROUTE} />
		);
	}

  return (
    <IonPage ref={pageRef} id="tos-pdf-page">
      <IonHeader>
        <IonToolbar mode="ios" color="toolbar">
            <IonButtons slot="start">
            <IonButton routerLink={ROUTES.SETTINGS_LEGAL_TOS_PAGE_ROUTE} >
              <IonIcon icon={chevronBack} />
              
              </IonButton>
            </IonButtons>
            <IonTitle>{title}</IonTitle>
        </IonToolbar>

      </IonHeader>
      
      <IonContent className='control-section' fullscreen>
        {/*<Root source={fileUrl} className='container react-pdf__Document'>
          <div className="zoom-controls">
            <ZoomOut className='zoom-out'>-</ZoomOut>
            <CurrentZoom className='current-zoom'/>
            <ZoomIn className='zoom-in'>+</ZoomIn>
          </div>
          <Pages>
            <Page className='react-pdf__Page'>
              <CanvasLayer className='react-pdf__Canvas'/>
              <TextLayer />
            </Page>
          </Pages>
        </Root>*

        <Viewer
          fileUrl={fileUrl}
          plugins={[
            // Register plugins
            defaultLayoutPluginInstance,
            bookmarkPluginInstance,
            zoomPluginInstance,
            searchPluginInstance,
            pageNavigationPluginInstance
          ]}
        /> */}

        
            
                <PdfViewerComponent 
                    id="container" 
                    documentPath={fileUrl}
                    resourceUrl="https://cdn.syncfusion.com/ej2/26.2.11/dist/ej2-pdfviewer-lib"
                    style={{ 'height': '100%' }}>
                      {/* Inject the required services */}
                      <Inject services={[ Toolbar, Magnification,  Navigation, /*Annotation,*/ LinkAnnotation, BookmarkView, ThumbnailView,
                                          Print, TextSelection,  TextSearch,/* FormFields, FormDesigner */]} />
                </PdfViewerComponent>
            
      </IonContent>

      <IonFooter></IonFooter>

    </IonPage>
  );
};

export default TosPdf;






