export interface HinoPreferredSupplier {
    id: string;
    Name: string;
    BuilderCode: string;
    Address: string;
    Telephone: string;
    Telephone2: string;
    Fax: string;
    Latitude: string;
    Longitude: string;
    Town: string;
    Region: string;
    Email: string;
    PreferredSupplier: string;
  }

export const builderlist = [
    {"Name":"Alpha Truck Bodies (Pty) Ltd","BuilderCode":"001","Address":"206 Milan Place\u003cbr/\u003eApex\u003cbr/\u003eBenoni\u003cbr/\u003e1501\u003cbr/\u003eGauteng","Telephone":"(011)4200160","Telephone2":null,"Fax":null,"Latitude":-25.86197,"Longitude":28.19151,"Town":"Benoni","Region":"Gauteng","Email":null},
    {"Name":"Anchor Auto Body Builders","BuilderCode":"002","Address":"6 Argon Road\u003cbr/\u003eSprings\u003cbr/\u003e1559\u003cbr/\u003eGauteng","Telephone":"(011)8171700","Telephone2":null,"Fax":null,"Latitude":-25.86197,"Longitude":28.19151,"Town":"Springs","Region":"Gauteng","Email":null},
    {"Name":"600 SA Holdings (Pty) Ltd","BuilderCode":"003","Address":"63 Maple Street\u003cbr/\u003ePomona\u003cbr/\u003eKempton Park\u003cbr/\u003e1619\u003cbr/\u003eGauteng","Telephone":"(011)9662000","Telephone2":null,"Fax":null,"Latitude":-25.86197,"Longitude":28.19151,"Town":"Kempton Park","Region":"Gauteng","Email":null},
    {"Name":"BTC Engineering","BuilderCode":"004","Address":"Plot 65\u003cbr/\u003eBon Accord\u003cbr/\u003ePretoria\u003cbr/\u003e0002\u003cbr/\u003eGauteng","Telephone":"(012)5620004","Telephone2":null,"Fax":null,"Latitude":-25.86197,"Longitude":28.19151,"Town":"Pretoria","Region":"Gauteng","Email":null},
    {"Name":"BTS Benica Technical Services","BuilderCode":"005","Address":"22a 11th Road\u003cbr/\u003eKew\u003cbr/\u003eJohannesburg\u003cbr/\u003e2090\u003cbr/\u003eGauteng","Telephone":"(011)4401482","Telephone2":null,"Fax":null,"Latitude":-25.86197,"Longitude":28.19151,"Town":"Johannesburg","Region":"Gauteng","Email":null},
    {"Name":"Busco Marketing CC","BuilderCode":"006","Address":"249 Rembrandt Street\u003cbr/\u003eNestpark\u003cbr/\u003eBapsfontein\u003cbr/\u003e1510\u003cbr/\u003eGauteng","Telephone":"(011)9641182","Telephone2":null,"Fax":null,"Latitude":-25.86197,"Longitude":28.19151,"Town":"Bapsfontein","Region":"Gauteng","Email":null},
    {"Name":"Busmark","BuilderCode":"007","Address":"13 Foden Street\u003cbr/\u003eRandfontein\u003cbr/\u003e1760\u003cbr/\u003eGauteng","Telephone":"(011)4122927","Telephone2":null,"Fax":null,"Latitude":-25.86197,"Longitude":28.19151,"Town":"Randfontein","Region":"Gauteng","Email":null},
    {"Name":"Cabworld Pty Ltd","BuilderCode":"008","Address":"331 De La Rey Street\u003cbr/\u003ePretoria North\u003cbr/\u003e0182\u003cbr/\u003eGauteng","Telephone":"(012)5466510","Telephone2":null,"Fax":null,"Latitude":-25.86197,"Longitude":28.19151,"Town":"Pretoria North","Region":"Gauteng","Email":null},
    {"Name":"Calvary Tankers & Engineering Pty Ltd","BuilderCode":"009","Address":"Plot 26\u003cbr/\u003eNooitgedacht\u003cbr/\u003eKliprivier\u003cbr/\u003e1871\u003cbr/\u003eGauteng","Telephone":"(011)9036274","Telephone2":null,"Fax":null,"Latitude":-25.86197,"Longitude":28.19151,"Town":"Kliprivier","Region":"Gauteng","Email":null},
    {"Name":"CBC Body Construction CC","BuilderCode":"010","Address":"11 Telford Road\u003cbr/\u003eIndustria\u003cbr/\u003eJohannesburg\u003cbr/\u003e2093\u003cbr/\u003eGauteng","Telephone":"(011)4742185","Telephone2":null,"Fax":null,"Latitude":-25.86197,"Longitude":28.19151,"Town":"Johannesburg","Region":"Gauteng","Email":null},
    {"Name":"Ice Cold Bodies","BuilderCode":"011","Address":"6 Kruger Street\u003cbr/\u003eDenver\u003cbr/\u003eJohannesburg\u003cbr/\u003e2094\u003cbr/\u003eGauteng","Telephone":"(016)3491140","Telephone2":null,"Fax":null,"Latitude":-25.86197,"Longitude":28.19151,"Town":"Johannesburg","Region":"Gauteng","Email":null},
    {"Name":"M & P Bodies","BuilderCode":"012","Address":"141 Tarry Road\u003cbr/\u003eJohannesburg\u003cbr/\u003e2001\u003cbr/\u003eGauteng","Telephone":"(011)86811149","Telephone2":null,"Fax":null,"Latitude":-25.86197,"Longitude":28.19151,"Town":"Johannesburg","Region":"Gauteng","Email":null},
    {"Name":"Maximus Business Trust","BuilderCode":"013","Address":"18 Flamink Street\u003cbr/\u003eAlrode\u003cbr/\u003e1452\u003cbr/\u003eGauteng","Telephone":"(011)8641762","Telephone2":null,"Fax":null,"Latitude":-25.86197,"Longitude":28.19151,"Town":"Alrode","Region":"Gauteng","Email":null},
    {"Name":"MBC Motor Construction (Pty) Ltd","BuilderCode":"014","Address":"589 Lower Main Reef Road\u003cbr/\u003eCleveland\u003cbr/\u003eGermiston\u003cbr/\u003e2094\u003cbr/\u003eGauteng","Telephone":"(011)6222569","Telephone2":null,"Fax":null,"Latitude":-25.86197,"Longitude":28.19151,"Town":"Germiston","Region":"Gauteng","Email":null},
    {"Name":"OTT Technologies","BuilderCode":"015","Address":"Plot 113\u003cbr/\u003eMooiplaats\u003cbr/\u003ePretoria\u003cbr/\u003e0002\u003cbr/\u003eGauteng","Telephone":"(012)8028714","Telephone2":null,"Fax":null,"Latitude":-25.86197,"Longitude":28.19151,"Town":"Pretoria","Region":"Gauteng","Email":null},
    {"Name":"Pretoria Engineering","BuilderCode":"016","Address":"755 Rood Street\u003cbr/\u003eHermanstad\u003cbr/\u003ePretoria\u003cbr/\u003e0082\u003cbr/\u003eGauteng","Telephone":"(012)3794241","Telephone2":null,"Fax":null,"Latitude":-25.86197,"Longitude":28.19151,"Town":"Pretoria","Region":"Gauteng","Email":null},
    {"Name":"Royal Truck Bodies","BuilderCode":"017","Address":"10 - 12 Dekenah Street\u003cbr/\u003eAlrode\u003cbr/\u003e1451\u003cbr/\u003eGauteng","Telephone":"(011)9085625","Telephone2":null,"Fax":null,"Latitude":-25.86197,"Longitude":28.19151,"Town":"Alrode","Region":"Gauteng","Email":null},
    {"Name":"RVB Commercial Vehicle B/Builder","BuilderCode":"018","Address":"5 12 Tielman Roos Street\u003cbr/\u003eGermiston\u003cbr/\u003e1401\u003cbr/\u003eGauteng","Telephone":"(011)3457164","Telephone2":null,"Fax":null,"Latitude":-25.86197,"Longitude":28.19151,"Town":"Germiston","Region":"Gauteng","Email":null},
    {"Name":"TFM Olifantsfontein","BuilderCode":"019","Address":"30 Main Road\u003cbr/\u003eOlifantsfontein\u003cbr/\u003e1666\u003cbr/\u003eGauteng","Telephone":"(011)3162620","Telephone2":null,"Fax":null,"Latitude":-25.86197,"Longitude":28.19151,"Town":"Olifantsfontein","Region":"Gauteng","Email":null},
    {"Name":"Ticaserve (Pty) Ltd","BuilderCode":"020","Address":"8 Industrie Road\u003cbr/\u003eIsando\u003cbr/\u003e1609\u003cbr/\u003eGauteng","Telephone":"(076)2869884","Telephone2":null,"Fax":null,"Latitude":-25.86197,"Longitude":28.19151,"Town":"Isando","Region":"Gauteng","Email":null},
    {"Name":"UJ Truck Bodies (Pty) Ltd","BuilderCode":"021","Address":"32 Nagington Road\u003cbr/\u003eWadeville\u003cbr/\u003eGermiston\u003cbr/\u003e1428\u003cbr/\u003eGauteng","Telephone":"(011)8257260","Telephone2":null,"Fax":null,"Latitude":-25.86197,"Longitude":28.19151,"Town":"Germiston","Region":"Gauteng","Email":null},

    {"Name":"Global Elevation T/A Boss Truck & Trailer","BuilderCode":"022","Address":"154 Parow Street\u003cbr/\u003eParow\u003cbr/\u003eCape Town\u003cbr/\u003e7500\u003cbr/\u003eWestern Cape","Telephone":"(021)9110533","Telephone2":null,"Fax":null,"Latitude":-25.86197,"Longitude":28.19151,"Town":"Cape Town","Region":"Western Cape","Email":null},
    {"Name":"CTS Trailers (Pty) Ltd","BuilderCode":"023","Address":"Stikland\u003cbr/\u003eBellville\u003cbr/\u003eCape Town\u003cbr/\u003e7535\u003cbr/\u003eWestern Cape","Telephone":"(021)9486167","Telephone2":null,"Fax":null,"Latitude":-25.86197,"Longitude":28.19151,"Town":"Cape Town","Region":"Western Cape","Email":null},
    {"Name":"Elite Bodies","BuilderCode":"024","Address":"14 Jig Avenue\u003eCape Town\u003cbr/\u003e8001\u003cbr/\u003eWestern Cape","Telephone":"(021)5519390","Telephone2":null,"Fax":null,"Latitude":-25.86197,"Longitude":28.19151,"Town":"Cape Town","Region":"Western Cape","Email":null},
    {"Name":"Freight Body Engineering cc","BuilderCode":"025","Address":"Triangle Farm\u003cbr/\u003eStrand\u003cbr/\u003e7149\u003cbr/\u003eWestern Cape","Telephone":"(021)9487278","Telephone2":null,"Fax":null,"Latitude":-25.86197,"Longitude":28.19151,"Town":"Strand","Region":"Western Cape","Email":null},
    {"Name":"GRW Engineering","BuilderCode":"026","Address":"20 Abattoir Rd\u003cbr/\u003eWorcester\u003cbr/\u003e6850\u003cbr/\u003eWestern Cape","Telephone":"(023)3486300","Telephone2":null,"Fax":null,"Latitude":-25.86197,"Longitude":28.19151,"Town":"Worcester","Region":"Western Cape","Email":null},
    {"Name":"IKapa Truck Bodies & Trailers","BuilderCode":"027","Address":"Unit 8\u003cbr/\u003eBrights Hardware Industrial Park\u003cbr/\u003evan Riebeeck Road\u003cbr/\u003eBlackheath\u003cbr/\u003e7581\u003cbr/\u003eWestern Cape","Telephone":"(021)9050266","Telephone2":null,"Fax":null,"Latitude":-25.86197,"Longitude":28.19151,"Town":"Cape Town","Region":"Western Cape","Email":null},
    {"Name":"Roamer Rand Cape Town cc","BuilderCode":"028","Address":"Circle Park\u003cbr/\u003eBellville\u003cbr/\u003e7530\u003cbr/\u003eWestern Cape","Telephone":"(021)9518179","Telephone2":null,"Fax":null,"Latitude":-25.86197,"Longitude":28.19151,"Town":"Cape Town","Region":"Western Cape","Email":null},
    {"Name":"Unicape","BuilderCode":"029","Address":"24-26 Junction Road\u003cbr/\u003eTygerberg Business Park\u003cbr/\u003eTygerberg\u003cbr/\u003e7493\u003cbr/\u003eWestern Cape","Telephone":"(021)9516262","Telephone2":null,"Fax":null,"Latitude":-25.86197,"Longitude":28.19151,"Town":"Cape Town","Region":"Western Cape","Email":null},
    {"Name":"Vebody (Pty) Ltd","BuilderCode":"030","Address":"Reactor Street\u003cbr/\u003eStikland\u003cbr/\u003eBellville\u003cbr/\u003e7530\u003cbr/\u003eWestern Cape","Telephone":"(021)9514344","Telephone2":null,"Fax":null,"Latitude":-25.86197,"Longitude":28.19151,"Town":"Cape Town","Region":"Western Cape","Email":null},

    {"Name":"Blue Star Body Builders","BuilderCode":"031","Address":"2 Downie Street\u003cbr/\u003eQueensburgh\u003cbr/\u003e4093\u003cbr/\u003eKwaZulu-Natal","Telephone":"(031)4646402","Telephone2":null,"Fax":null,"Latitude":-25.86197,"Longitude":28.19151,"Town":"Queensburgh","Region":"KwaZulu-Natal","Email":null},
    {"Name":"Kholeka Engineering (Pty) Ltd","BuilderCode":"032","Address":"2 Leeukop Road\u003cbr/\u003ePinetown\u003cbr/\u003e3610\u003cbr/\u003eKwaZulu-Natal","Telephone":"(031)7001221","Telephone2":null,"Fax":null,"Latitude":-25.86197,"Longitude":28.19151,"Town":"Pinetown","Region":"KwaZulu-Natal","Email":null},
    {"Name":"L&S Body Manufacturers","BuilderCode":"033","Address":"6 Darby Crescent\u003cbr/\u003eNorthdane\u003cbr/\u003e3165\u003cbr/\u003eKwaZulu-Natal","Telephone":"(031)8367583","Telephone2":null,"Fax":null,"Latitude":-25.86197,"Longitude":28.19151,"Town":"Durban","Region":"KwaZulu-Natal","Email":null},
    {"Name":"Pinetown Truck Bodies","BuilderCode":"034","Address":"149 Shepstone Road\u003cbr/\u003eNew Germany\u003cbr/\u003e3610\u003cbr/\u003eKwaZulu-Natal","Telephone":"(031)7051011","Telephone2":null,"Fax":null,"Latitude":-25.86197,"Longitude":28.19151,"Town":"Pinetown","Region":"KwaZulu-Natal","Email":null},
    {"Name":"S.M Bodies","BuilderCode":"035","Address":"7 Bally Road\u003cbr/\u003eIsipingo Rail\u003cbr/\u003e4133\u003cbr/\u003eKwaZulu-Natal","Telephone":"(031)9022051","Telephone2":null,"Fax":null,"Latitude":-25.86197,"Longitude":28.19151,"Town":"Durban","Region":"KwaZulu-Natal","Email":null},

    {"Name":"C & G Truck Bodies","BuilderCode":"036","Address":"19130 Phillip Robinson St\u003cbr/\u003eDeal Party\u003cbr/\u003cbr/\u003ePort Elizabeth\u003cbr/\u003e6209\u003cbr/\u003eEastern Cape","Telephone":"(041)4861060","Telephone2":null,"Fax":null,"Latitude":-25.86197,"Longitude":28.19151,"Town":"Port Elizabeth","Region":"Eastern Cape","Email":null},
    {"Name":"Ramcom Trucks and Load Bodies cc","BuilderCode":"037","Address":"Extension Road\u003cbr/\u003eGeorge\u003cbr/\u003cbr/\u003e6209\u003cbr/\u003eWestern Cape","Telephone":"(044)8780511","Telephone2":null,"Fax":null,"Latitude":-25.86197,"Longitude":28.19151,"Town":"George","Region":"Western Cape","Email":null},
    {"Name":"Rapid Truck Bodies","BuilderCode":"038","Address":"137-14 Shortts Retreats Rd\u003cbr/\u003ePietermaritzburg\u003cbr/\u003e3201\u003cbr/\u003eKwaZulu-Natal","Telephone":"(033)3460238","Telephone2":null,"Fax":null,"Latitude":-25.86197,"Longitude":28.19151,"Town":"Pietermaritzburg","Region":"KwaZulu-Natal","Email":null}, 
    {"Name":"Serco Durban","BuilderCode":"039","Address":"193 Aberdare Drive\u003cbr/\u003ePhoenix Industrial Park\u003cbr/\u003e4068\u003cbr/\u003eKwaZulu-Natal","Telephone":"(031)5081013","Telephone2":null,"Fax":null,"Latitude":-25.86197,"Longitude":28.19151,"Town":"Durban","Region":"KwaZulu-Natal","Email":null},
    {"Name":"Smarty Bodies","BuilderCode":"040","Address":"32 Downie Crescent\u003cbr/\u003eQueensburgh\u003cbr/\u003e4093\u003cbr/\u003eKwaZulu-Natal","Telephone":"(031)4640903","Telephone2":null,"Fax":null,"Latitude":-25.86197,"Longitude":28.19151,"Town":"Queensburgh","Region":"KwaZulu-Natal","Email":null},
    {"Name":"TFM Customizing Centre (Pty) Ltd","BuilderCode":"041","Address":"36 Prospection Road\u003cbr/\u003eProspection\u003cbr/\u003e4133\u003cbr/\u003eKwaZulu-Natal","Telephone":"(031)9026443","Telephone2":null,"Fax":null,"Latitude":-25.86197,"Longitude":28.19151,"Town":"Durban","Region":"KwaZulu-Natal","Email":null},
    {"Name":"Transpec","BuilderCode":"042","Address":"1 Alexandra Road\u003cbr/\u003eWestmead\u003cbr/\u003e3610\u003cbr/\u003eKwaZulu-Natal","Telephone":"(031)7001542","Telephone2":null,"Fax":null,"Latitude":-25.86197,"Longitude":28.19151,"Town":"Durban","Region":"KwaZulu-Natal","Email":null},
    {"Name":"Truckbodies (Pty) Ltd","BuilderCode":"043","Address":"11 Kwaford Road\u003cbr/\u003cbr/\u003ePort Elizabeth\u003cbr/\u003e6001\u003cbr/\u003eEastern Cape","Telephone":"(041)4531976","Telephone2":null,"Fax":null,"Latitude":-25.86197,"Longitude":28.19151,"Town":"Port Elizabeth","Region":"Eastern Cape","Email":null},
    {"Name":"Tipper Clinic cc","BuilderCode":"044","Address":"23 Voorlooper Crescent\u003cbr/\u003eMossdustria\u003cbr/\u003eMossel Bay\u003cbr/\u003cbr/\u003e6500\u003cbr/\u003eWestern Cape","Telephone":"(082)5625140","Telephone2":null,"Fax":null,"Latitude":-25.86197,"Longitude":28.19151,"Town":"Mossel Bay","Region":"Western Cape","Email":null},

    {"Name":"Toss Bodies","BuilderCode":"045","Address":"18 Ross Street\u003cbr/\u003ePotchefstroom\u003cbr/\u003e2520\u003cbr/\u003eNorth West","Telephone":"(018)2930038","Telephone2":null,"Fax":null,"Latitude":-25.86197,"Longitude":28.19151,"Town":"Potchefstroom","Region":"North West","Email":null},
    {"Name":"Hippo Truck & Trailer","BuilderCode":"046","Address":"88 Piet Human Street\u003cbr/\u003eHamilton\u003cbr/\u003eBloemfontein\u003cbr/\u003cbr/\u003e9300\u003cbr/\u003eFree State","Telephone":"(051)4350055","Telephone2":null,"Fax":null,"Latitude":-25.86197,"Longitude":28.19151,"Town":"Bloemfontein","Region":"Free State","Email":null},
    {"Name":"MK Sweiswerke","BuilderCode":"047","Address":"38a Tannery Road\u003cbr/\u003eHamilton\u003cbr/\u003eBloemfontein\u003cbr/\u003cbr/\u003e9301\u003cbr/\u003eFree State","Telephone":"(051)4358000","Telephone2":null,"Fax":null,"Latitude":-25.86197,"Longitude":28.19151,"Town":"Bloemfontein","Region":"Free State","Email":null},
    {"Name":"Serfontein & Seuns (Edms) Bpk","BuilderCode":"048","Address":"10 Van Zyl Street\u003cbr/\u003eHennenman\u003cbr/\u003cbr/\u003e9445\u003cbr/\u003eFree State","Telephone":"(057)5731140","Telephone2":null,"Fax":null,"Latitude":-25.86197,"Longitude":28.19151,"Town":"Hennenman","Region":"Free State","Email":null},

    {"Name":"Kelran cc","BuilderCode":"049","Address":"6 Langerman Street\u003cbr/\u003eWitbank\u003cbr/\u003e1034\u003cbr/\u003eMpumalanga","Telephone":"(013)6562697","Telephone2":null,"Fax":null,"Latitude":-25.86197,"Longitude":28.19151,"Town":"Witbank","Region":"Mpumalanga","Email":null},
    {"Name":"Transerve JHT","BuilderCode":"050","Address":"14/15 Celcius Street\u003cbr/\u003eMiddelburg\u003cbr/\u003e1055\u003cbr/\u003eMpumalanga","Telephone":"(013)2461057","Telephone2":null,"Fax":null,"Latitude":-25.86197,"Longitude":28.19151,"Town":"Middelburg","Region":"Mpumalanga","Email":null},
    {"Name":"Truckline Services","BuilderCode":"051","Address":"15 Sonate Street\u003cbr/\u003eKlarinet\u003cbr/\u003eWitbank\u003cbr/\u003e1035\u003cbr/\u003eMpumalanga","Telephone":"(013)6903440","Telephone2":null,"Fax":null,"Latitude":-25.86197,"Longitude":28.19151,"Town":"Witbank","Region":"Mpumalanga","Email":null},
  ]