import { useRef } from 'react';
import { Redirect } from 'react-router-dom';
import { IonContent, IonButton, IonToolbar, IonHeader, IonPage, IonButtons, IonIcon, IonTitle } from '@ionic/react';
import { chevronBack } from 'ionicons/icons';
import  { PdfViewerComponent, Toolbar, Magnification, Navigation, BookmarkView, ThumbnailView, Print, TextSelection, TextSearch, Inject} from '@syncfusion/ej2-react-pdfviewer';

import GuidelineStore from '../../services/guidelines';

import * as ROUTES from '../../config';

import '../Guidelines.css'



const ElectricalSystem: React.FC = () => {
  
  const pageRef = useRef<HTMLElement>(null);
  const precaution = GuidelineStore.useState(s => s);

  const fileUrl = precaution.url;
  const title = precaution.title;

  if (precaution.title === null) {
		return (
			<Redirect to={ROUTES.ELECTRICAL_SYSTEMS_PAGE_ROUTE} />
		);
	}

  return (
    <IonPage ref={pageRef} id="electrical-system-page">
      <IonHeader>
        <IonToolbar mode="ios" color="toolbar">
            <IonButtons slot="start">
            <IonButton routerLink={ROUTES.ELECTRICAL_SYSTEMS_PAGE_ROUTE}>
              <IonIcon icon={chevronBack} />
              
              </IonButton>
            </IonButtons>
            <IonTitle>{title}</IonTitle>
        </IonToolbar>
      </IonHeader>
      
      <IonContent className="control-section" fullscreen>

        <PdfViewerComponent 
          id="container" 
          documentPath={fileUrl!}
          resourceUrl="https://cdn.syncfusion.com/ej2/26.2.11/dist/ej2-pdfviewer-lib"
          style={{ 'height': '100%' }}>
            <Inject services={[ Toolbar, Magnification,  Navigation, BookmarkView, ThumbnailView, Print, TextSelection,  TextSearch ]} />
        </PdfViewerComponent>
        
      </IonContent>
 
    </IonPage>
  );
};

export default ElectricalSystem;
