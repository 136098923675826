import { useEffect, useState } from 'react';
import { IonSearchbar } from '@ionic/react'
import useDebounce from '../utils/useDebounce';

export interface ISearchProps {
  onChangeSearchQuery: (searchQuery: string) => void;
  color?: "light" | "primary" | "dark" | "medium"
}

const SearchInput = (props: ISearchProps) => {
  const [searchQuery, setSearchQuery] = useState<string | undefined>();
  const { onChangeSearchQuery, color } = props;
  const debouncedSearchQuery = useDebounce(searchQuery, 250);

  useEffect(() => {
    if (debouncedSearchQuery !== undefined) {
      onChangeSearchQuery(debouncedSearchQuery);
    }
  }, [debouncedSearchQuery, onChangeSearchQuery]);

  return (
    <IonSearchbar placeholder="Search" onIonChange={(e: CustomEvent) => setSearchQuery(e.detail.value)} color={color}/>
  );
}

export default SearchInput;
