import { useEffect, useReducer, useState } from "react";
import { round } from "mathjs";
import { IonButton, IonButtons, IonCol, IonGrid, IonIcon, IonInput, IonItem, IonLabel, 
    IonList, IonListHeader, IonRadio, IonRadioGroup, IonText, isPlatform } from "@ionic/react";
import { CalcValues, Calculator, hReducer, lReducer, mReducer, wReducer } from "../../../services/calculations";
import { chevronDown, chevronUp, construct } from "ionicons/icons";
import { BodyHeight, BodyLength, BodyMass, BodyWidth, DefaultBodySpec } from "../../../models/Body";

interface MassProps {
    values: CalcValues;
    setValues: (v: CalcValues) => void;
}

interface Material {
    material: string;
    value: string;
    udl: string;
    kg: string;
}

const dbs = new DefaultBodySpec();

const ConfigureMass: React.FC<MassProps> = ({values, setValues}) => {

    const ios = isPlatform('ios');
    const { ...truck } = Calculator(values);

    const [mState, mDispatcher] = useReducer(mReducer, new BodyMass(values));
    const [hState, hDispatcher] = useReducer(hReducer, new BodyHeight(values));
    const [wState, wDispatcher] = useReducer(wReducer, new BodyWidth(values));
    const [lState, lDispatcher] = useReducer(lReducer, new BodyLength(values));

    const bodyType = truck.bodyType.toLowerCase();

    const materialList: Array<Material> = ((dbs as any)[bodyType]['mass']);
    const materialValues = materialList.map(m => {
        const v = { ...m};
        v.kg = round(+m.value*truck.dimensions.bl/1000).toString(); 
        v.udl = m.value;
        return v;
        }).sort((a, b) => {
            if (a.material > b.material) {
                return 1;
            } else if (a.material < b.material) {
                return -1;
            } else {
                return 0;
            }
        });

    const lcog = (dbs as any)[bodyType]['lcog']; 
    const vcog = (dbs as any)[bodyType]['vcog'];
    const hcog = (dbs as any)[bodyType]['hcog']; 

    /** UI */
    const [showKg, setShowKg] = useState(false);
    const [showKgEdit, setShowKgEdit] = useState(false);
    const [showMaterial, setShowMaterial] = useState(false);
    const [showMaterialEdit, setShowMaterialEdit] = useState(false);
    const [showCOG, setShowCOG] = useState(false);
    const [material, setMaterial] = useState<string | null | undefined>(truck.bodyMaterial);
    const [mode, setMode] = useState<string | null | undefined>('udl');

    useEffect(() => {
        const v = { ...values};
        v.body!.mass = mState;
        v.body!.height = hState;
        v.body!.length = lState;
        v.body!.width = wState;
        setValues(v);
    }, [mState, hState, lState, wState]);

    return (
        <IonList>

            <IonListHeader className="ion-padding-bottom">Configure Mass</IonListHeader>

            <IonItem color="light">
                <IonText className="ion-padding">Mass expressed as</IonText>
                <IonButtons slot="end">
                {
                    !showKg &&
                    <IonButton onClick={() => {
                        setShowKg(true);
                        setShowMaterial(false);
                        setShowCOG(false);
                    }}>
                    <IonIcon icon={chevronDown} slot="icon-only" />
                    </IonButton>}
                {
                    showKg &&
                    <IonButton onClick={() => setShowKg(false)}>
                    <IonIcon icon={chevronUp} slot="icon-only" />
                    </IonButton>
                }
                </IonButtons>
            </IonItem>

            {
                showKg &&
                
                <IonRadioGroup value={mode} onIonChange={(e) => {setMode(e.detail.value); mDispatcher({type: 'MASS_MODE', payload: { ...mState, mode: e.detail.value}})}}>

                    <IonItem className="ion-padding-start ion-padding-end">
                        <IonLabel>
                            <h3>kg/m</h3>
                            <p>Uniformly Distributed Load</p>
                        </IonLabel>
                        <IonRadio value="udl"></IonRadio>
                    </IonItem>

                    <IonItem className="ion-padding-start ion-padding-end">
                        <IonLabel>
                            <h3>Kilograms (kg)</h3>
                            <p>Total Mass</p>
                        </IonLabel>
                        <IonRadio value="mass"></IonRadio>
                    </IonItem>

                </IonRadioGroup>  
            }

            <IonItem color="light">
                <IonText className="ion-padding">Material</IonText>
                <IonButtons slot="end">
                {
                    !showMaterial &&
                    <IonButton onClick={() => {
                        setShowMaterial(true);
                        setShowCOG(false);
                        setShowKg(false);
                    }}>
                    <IonIcon icon={chevronDown} slot="icon-only" />
                    </IonButton>}
                {
                    showMaterial &&
                    <IonButton onClick={() => setShowMaterial(false)}>
                    <IonIcon icon={chevronUp} slot="icon-only" />
                    </IonButton>
                }
                </IonButtons>
            </IonItem>

            {
                showMaterial && 

                <>
                    {
                        mode === 'udl' &&

                        <IonRadioGroup value={material} onIonChange={(e) => {
                                setMaterial(e.detail.value)
                                const m = e.detail.value as string;
                                mDispatcher({type: 'MASS_MATERIAL', payload: { ...mState, material: m} })
                            }}>
                            {
                                materialValues.map((m, i) => 

                                    <IonGrid key={i}>

                                        <IonItem
                                            lines={showMaterialEdit && material === m.material ? "none": "full"} 
                                            className="ion-padding-start ion-padding-end">
                                            <IonLabel><h3>{m.material}</h3><p>{`${m.udl} kg/m`}</p></IonLabel>
                                            <IonRadio 
                                                value={m.material} 
                                                onClick={() => {
                                                    mDispatcher({type: 'MASS_UDL', payload: { ...mState, udl: m.udl} })
                                                    setShowMaterialEdit(true); 
                                                }} />
                                        </IonItem>

                                        {
                                            (showMaterialEdit && material === m.material) &&
                                            
                                            <IonItem className="ion-padding-start ion-padding-end">
                                                <IonCol size="7">
                                                    { ios &&
                                                        <IonIcon slot="start" icon={construct} color="medium" />
                                                    }
                                                    <IonLabel>
                                                        <p>Custom UDL</p>
                                                        {<p>{`for ${m.material}`}</p>}
                                                    </IonLabel>
                                                </IonCol>
                                                <IonCol size="5">
                                                    <IonInput placeholder={`${m.value} kg/m`} onIonChange={e => {
                                                        e.preventDefault();
                                                        const udl = (+(e.detail.value || m.value)).toString();
                                                        mDispatcher({type: 'MASS_UDL', payload: { ...mState, udl: udl} });
                                                    }}>
                                                    </IonInput>
                                                </IonCol>
                                            </IonItem>
                                        }
                                    </IonGrid>
                                )
                            }
                        </IonRadioGroup>
                    }

                    {
                        mode === 'mass' &&

                        <IonRadioGroup value={material} onIonChange={(e) => {
                                setMaterial(e.detail.value)
                            }}>
                            {
                                materialValues.map((m, i) => 

                                <IonGrid key={i}>

                                    <IonItem className="ion-padding-start ion-padding-end">
                                        <IonLabel><h3>{m.material}</h3><p>{`${m.kg} kg`}</p></IonLabel>
                                        <IonRadio 
                                            value={m.material}
                                            onClick={() => {
                                                mDispatcher({type: 'MASS_TOTAL', payload: { ...mState, totalMass: m.kg} })
                                                setShowKgEdit(true); 
                                                }} /> 
                                    </IonItem>

                                    {
                                        (showKgEdit && material === m.material) &&
                                        
                                        <IonItem className="ion-padding-start ion-padding-end">
                                            <IonCol size="7">
                                                { ios &&
                                                    <IonIcon slot="start" icon={construct} color="medium" />
                                                }
                                                <IonLabel>
                                                    <p>Custom Mass</p>
                                                    {<p>{`for ${m.material}`}</p>}
                                                </IonLabel>
                                            </IonCol>
                                            <IonCol size="5">
                                                <IonInput placeholder={`${m.kg} kg`} onIonChange={e => {
                                                    e.preventDefault();
                                                    const t = e.detail.value as string;
                                                    mDispatcher({type: 'MASS_TOTAL', payload: { ...mState, totalMass: t} });
                                                }}>
                                                </IonInput>
                                            </IonCol>
                                        </IonItem>
                                    }
                                    
                                </IonGrid>

                                    
                                )
                        }
                        </IonRadioGroup>
                    }
                </>
            }
        
            <IonItem color="light">
                <IonText className="ion-padding">Centre of Gravity</IonText>
                <IonButtons slot="end">
                {
                    !showCOG &&
                    <IonButton onClick={() => {
                        setShowCOG(true);
                        setShowKg(false);
                        setShowMaterial(false);
                    }}>
                    <IonIcon icon={chevronDown} slot="icon-only" />
                    </IonButton>}
                {
                    showCOG &&
                    <IonButton onClick={() => setShowCOG(false)}>
                    <IonIcon icon={chevronUp} slot="icon-only" />
                    </IonButton>
                }
                </IonButtons>
            </IonItem>

            {
                showCOG &&

                <>
                    <IonItem className="ion-padding-start ion-padding-end">
                        <IonCol size="8">
                            { ios &&
                                <IonIcon slot="start" icon={construct} color="medium" />
                            }
                            <IonLabel>
                                <h3>Horizontal CoG of Body (%)</h3>
                                <p>{`Default = ${hcog} %`}</p>
                            </IonLabel>
                        </IonCol>
                        <IonCol size="4">
                            <IonInput color="primary" placeholder={`${truck.dimensions.hcog} %`} onIonChange={e => {
                                e.preventDefault();
                                const l = e.detail.value as string;
                                lDispatcher({type: 'LENGTH_COG', payload: { ...lState, centreOfGravity: l} });
                            }}>
                            </IonInput>
                        </IonCol>
                    </IonItem>
                    <IonItem className="ion-padding-start ion-padding-end">
                        <IonCol size="8">
                            { ios &&
                                <IonIcon slot="start" icon={construct} color="medium" />
                            }
                            <IonLabel>
                                <h3>Vertical CoG of Body (%)</h3>
                                <p>{`Default = ${vcog} %`}</p>
                            </IonLabel>
                        </IonCol>
                        <IonCol size="4">
                            <IonInput color="primary" placeholder={`${truck.dimensions.vcog} %`} onIonChange={e => {
                                e.preventDefault();
                                const h = e.detail.value as string;
                                hDispatcher({type: 'HEIGHT_COG', payload: { ...hState, centreOfGravity: h} });
                            }}>
                            </IonInput>
                        </IonCol>
                    </IonItem>
                    <IonItem className="ion-padding-start ion-padding-end">
                        <IonCol size="8">
                            { ios &&
                                <IonIcon slot="start" icon={construct} color="medium" />
                            }
                            <IonLabel>
                                <h3>Lateral CoG of Body (%)</h3>
                                <p>{`Default = ${lcog} %`}</p>
                            </IonLabel>
                        </IonCol>
                        <IonCol size="4">
                            <IonInput color="primary" placeholder={`${truck.dimensions.lcog} %`} onIonChange={e => {
                                e.preventDefault();
                                const w = e.detail.value as string;
                                wDispatcher({type: 'WIDTH_COG', payload: { ...wState, centreOfGravity: w} });
                            }}>
                            </IonInput>
                        </IonCol>
                    </IonItem>
                </>
            }

            </IonList>
    );
}

export default ConfigureMass;