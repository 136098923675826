import { useRef, useEffect, useState } from 'react';
import { isPlatform, IonContent, IonButton, IonToolbar, IonHeader, IonPage, IonButtons, IonItem, IonLabel, IonIcon, IonTitle, IonItemDivider, IonRadioGroup, IonRadio, IonList } from '@ionic/react';
import { close} from 'ionicons/icons';
import { useStoreState } from 'pullstate';
import { UserPreferences, getUser } from '../../services/user';

import * as ROUTES from '../../config';

 
const Preferences: React.FC = () => {
  
  const pageRef = useRef<HTMLElement>(null);
  const ios = isPlatform('ios');

  console.log(ios)

  const user = useStoreState(UserPreferences, getUser);
  const [massUnit, setMassUnit] = useState(user.preferences.units.mass);
  const [dimensionUnit, setDimensionUnit] = useState(user.preferences.units.dimensions);

  useEffect(() => {

    UserPreferences.update(s => {
      s.preferences.units.mass = massUnit;
      s.preferences.units.dimensions = dimensionUnit;
    })

  }, [massUnit, dimensionUnit])

  return (
    <IonPage ref={pageRef} id="preferences-page">
      <IonHeader>
        <IonToolbar mode="ios" color="toolbar">
            <IonButtons slot="start">
              <IonButton routerLink={ROUTES.TABS_ACCOUNT_PAGE_ROUTE} routerDirection="back">
                <IonIcon icon={close} />
              </IonButton>
            </IonButtons>
            <IonTitle>Settings</IonTitle>
        </IonToolbar>
      </IonHeader>
      
      <IonContent fullscreen>

        <IonList>

          <IonItem disabled>
            <IonLabel>
              <h4>Preferred Dealership(s)</h4>
              <p>To submit calculations to</p>
            </IonLabel>
          </IonItem>
          <IonItem disabled>
            <IonLabel>
              <h4>Preferred Trucks</h4>
              <p>If not set, Hino 500 trucks will be loaded by default</p>
            </IonLabel>
          </IonItem>

          <IonItemDivider sticky>
            <IonLabel>Units</IonLabel>
          </IonItemDivider>

          <IonRadioGroup value={massUnit} onIonChange={e => setMassUnit(e.detail.value)}>
            <IonItem lines="none">
              <IonLabel color="primary">Mass</IonLabel>
            </IonItem>
            <IonItem lines="none">
              <IonLabel><p>Kilogram (kg)</p></IonLabel>
              <IonRadio value="kg" />
            </IonItem>
            <IonItem lines="none">
              <IonLabel><p>Ton</p></IonLabel>
              <IonRadio value="ton" />
            </IonItem>
          </IonRadioGroup>
          <IonRadioGroup value={dimensionUnit} onIonChange={e => setDimensionUnit(e.detail.value)}>
            <IonItem lines="none">
              <IonLabel color="primary">Dimensions</IonLabel>
            </IonItem>
            <IonItem lines="none">
              <IonLabel><p>Millimetres (mm)</p></IonLabel>
              <IonRadio value="mm" />
            </IonItem>
            <IonItem lines="none">
              <IonLabel><p>Centimetres (cm)</p></IonLabel>
              <IonRadio value="cm" />
            </IonItem>
            <IonItem lines="none">
              <IonLabel><p>Metres (m)</p></IonLabel>
              <IonRadio value="m" />
            </IonItem>
          </IonRadioGroup>

          <IonItemDivider sticky>
            Increments
          </IonItemDivider>

          <IonRadioGroup value={0} onIonChange={e => console.log(e.detail.value)}>
            <IonItem lines="none">
              <IonLabel color="primary">Dimensions</IonLabel>
            </IonItem>
            <IonItem lines="none">
              <IonLabel><p>Units (+/- 1)</p></IonLabel>
              <IonRadio value="d" />
            </IonItem>
            <IonItem lines="none">
              <IonLabel><p>Tens (+/- 10)</p></IonLabel>
              <IonRadio value="t" />
            </IonItem>
            <IonItem lines="none">
              <IonLabel><p>Hundreds (+/- 100)</p></IonLabel>
              <IonRadio value="h" />
            </IonItem>
            <IonItem lines="none">
              <IonLabel><p>Percentages (+/- %)</p></IonLabel>
              <IonRadio value="p" />
            </IonItem>
          </IonRadioGroup>

          <IonRadioGroup value={0} onIonChange={e => console.log(e.detail.value)}>
            <IonItem lines="none">
              <IonLabel color="primary">Specify Cab as</IonLabel>
            </IonItem>
            <IonItem lines="none">
              <IonLabel><p>Bumper to Back of Cabin</p></IonLabel>
              <IonRadio value="d" />
            </IonItem>
            <IonItem lines="none">
              <IonLabel><p>Front Axle to Back of Cab</p></IonLabel>
              <IonRadio value="t" />
            </IonItem>
          </IonRadioGroup>

          <IonRadioGroup value={0} onIonChange={e => console.log(e.detail.value)}>
            <IonItem lines="none">
              <IonLabel color="primary">Specify Chassis dimensions as</IonLabel>
            </IonItem>
            <IonItem lines="none">
              <IonLabel><p>Wheelbase</p></IonLabel>
              <IonRadio value="d" />
            </IonItem>
            <IonItem lines="none">
              <IonLabel><p>Cab to Rear Axle</p></IonLabel>
              <IonRadio value="t" />
            </IonItem>
          </IonRadioGroup>

          <IonRadioGroup value={0} onIonChange={e => console.log(e.detail.value)}>
            <IonItem lines="none">
              <IonLabel color="primary">Specify Wheelbase as</IonLabel>
            </IonItem>
            <IonItem lines="none">
              <IonLabel><p>Centre of Front Axle to Center of Rear Axle(s)</p></IonLabel>
              <IonRadio value="d" />
            </IonItem>
            <IonItem lines="none">
              <IonLabel><p>Frontmost Steering Axle to Center of Rear Axle(s)</p></IonLabel>
              <IonRadio value="t" />
            </IonItem>
            <IonItem lines="none">
              <IonLabel><p>Frontmost Steering Axle to Center of Rear-driven Axle(s)</p></IonLabel>
              <IonRadio value="g" />
            </IonItem>
          </IonRadioGroup>

          <IonItem disabled>
            <IonLabel>
              <h4>Reports</h4>
              <p>Upload company logo and details to appear on pdfs</p>
            </IonLabel>
          </IonItem>


        </IonList>

      </IonContent>
    </IonPage>
  );
};

export default Preferences;
