  
import { IonButton, IonButtons, IonContent, IonGrid, IonHeader, IonIcon, IonPage, 
	IonTitle, IonToolbar, isPlatform, IonSegment, IonSegmentButton, IonLabel, IonItem, IonRow, IonCol, IonBadge} from '@ionic/react';
import { chevronBack, close, options, arrowDownOutline, arrowUpOutline } from 'ionicons/icons';
import { useRef, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { GroupedLeadsStore } from '../services/leads';

import * as ROUTES from '../config';
import SearchInput from '../components/SearchInput';
import { useStoreState } from 'pullstate';
import { decryptAES, getUser, UserPreferences } from '../services/user';
import { getTruckFromStorage } from '../services/calculations';
import { TruckSpecifications } from '../models/Truck';

const Messages = () => {

	const pageRef = useRef();
	const ios = isPlatform('ios');
	const lead = GroupedLeadsStore.useState(s => s);

	const user = useStoreState(UserPreferences, getUser);
  	const isDealership = user.role.includes('Hino Dealership');
	const isBodyBuilder = user.role.includes('Hino Preferred Supplier');

	const [query, setQuery] = useState<string>("");
	const [segment, setSegment] = useState<'all' | 'pending' | 'completed'| 'failed'>('all');
	const [showFilter, setShowFilter] = useState(false);
	//const [showCities, setShowCities] = useState(false);
	//const [showProvinces, setShowProvinces] = useState(false);
	//const [showSort, setShowSort] = useState(false);

	console.log(query)


	if (lead.leads.length === 0) {
		return (
			<Redirect to={ROUTES.TABS_LEADS_PAGE_ROUTE} />
		);
	}

	return (
		<IonPage ref={pageRef}>
			<IonHeader>
				<IonToolbar mode="ios" color="toolbar">
					<IonTitle><strong>{isDealership ? lead.leads[0].builder_name : lead.leads[0].salesperson}</strong></IonTitle>
					<IonButtons slot="start">
            			<IonButton routerDirection="back" routerLink={ROUTES.TABS_LEADS_PAGE_ROUTE}>
              				<IonIcon icon={chevronBack} />
						</IonButton>
          			</IonButtons>
				</IonToolbar>
				<IonToolbar mode="ios" className="ion-padding-top">
					<SearchInput onChangeSearchQuery={(query) => setQuery(query)} />
					<IonButtons slot="end">
						{
						!showFilter &&
						<IonButton color="dark" onClick={() => setShowFilter(true)}>
						{ios ? 'Filter' : <IonIcon icon={options} slot="icon-only" />}
						</IonButton>}
						{
						showFilter &&
						<IonButton color="dark" onClick={() => setShowFilter(false)}>
							{ios ? 'Close' : <IonIcon icon={close} slot="icon-only" />}
						</IonButton>
						}
					</IonButtons>
				</IonToolbar>
			</IonHeader>
			<IonContent fullscreen>
				<IonToolbar>
					<IonSegment value={segment} onIonChange={(e) => setSegment(e.detail.value as any)} mode="md">

						<IonSegmentButton value="all">
							<IonLabel color="dark">All</IonLabel>
							{/* segment === "all" ? <IonIcon color="success" icon={checkmark}/> : <IonIcon color="danger" icon={close} />*/}
						</IonSegmentButton>

						<IonSegmentButton value="pending">
							<IonLabel color="dark">In Progress</IonLabel>
							{/* segment === "pending" ? <IonIcon color="success" icon={checkmark}/> : <IonIcon color="danger" icon={close} />*/} 
						</IonSegmentButton>

						<IonSegmentButton value="completed">
							<IonLabel color="dark">Closed</IonLabel>
							{/* segment === "completed" ? <IonIcon color="success" icon={checkmark}/> : <IonIcon color="danger" icon={close} />*/}
						</IonSegmentButton> 

						{/*<IonSegmentButton value="failed">
							<IonLabel>Rejected</IonLabel>
							{ segment === "failed" ? <IonIcon color="success" icon={checkmark}/> : <IonIcon color="danger" icon={close} />}
					</IonSegmentButton>*/}

					</IonSegment>
				</IonToolbar>
				<IonGrid>

					{
						segment === 'all' &&

						lead.leads.map((l) => 
						
							<IonItem key={l.id} 
								routerLink={`${ROUTES.LEADS_LIST_PAGE_ROUTE}/${encodeURIComponent(isDealership ? lead.leads[0].preferred_supplier : lead.leads[0].dealership).toLowerCase()}/chats?q=${encodeURI(l.id!).toLowerCase()}`}>
								<IonLabel>
									<h2>
										<strong>{l.truck_id === null ? `Hino Truck - ${l.body_type}` : `${(JSON.parse(getTruckFromStorage(l.truck_id as string) as string) as TruckSpecifications).description} - ${l.body_type}`}&nbsp;</strong>
									</h2>
									<h4>{decryptAES(l.message_notes!, l.userid)}</h4>
									<p>{l.date_last_updated}</p>
									

								</IonLabel>

								{
								//Arrow Up, if sent by HPS
								isBodyBuilder &&

								<>

									<IonBadge color={l.message_status as unknown as string === 'Pending' ? (l.specification_id !== null ? "warning" : "danger") : 'success'} slot="end">
										{l.message_status as unknown as string === 'Pending' ? "pending" : "completed"}&nbsp;
										<IonIcon icon={l.specification_id !== null ? arrowUpOutline : arrowDownOutline} />
									</IonBadge>
									
								</>
							}

							{
								//Arrow Up, if sent by Salesperson
								isDealership &&

								<>
									<IonBadge color={l.message_status as unknown as string === 'Pending' ? (l.specification_id === null ? "warning" : "danger") : 'success'} slot="end">
										{l.message_status as unknown as string === 'Pending' ? "pending" : "completed"}&nbsp;
										<IonIcon icon={l.specification_id === null ? arrowUpOutline : arrowDownOutline} />
									</IonBadge>
								
								</>
							}	
							</IonItem>
							
						)
					}

					{
						segment === 'pending' && lead.leads.filter(l => (l.message_status as unknown as string) === 'Pending').length > 0 &&

						lead.leads.filter(l => (l.message_status as unknown as string) === 'Pending').map((l) => 

							<IonItem
								key={l.id} 
								routerLink={`${ROUTES.LEADS_LIST_PAGE_ROUTE}/${encodeURIComponent(isDealership ? lead.leads[0].preferred_supplier : lead.leads[0].dealership).toLowerCase()}/chats?q=${encodeURI(l.id!).toLowerCase()}`}>
								
								<IonLabel>
									<h2>
										<strong>{l.truck_id === null ? `Hino Truck - ${l.body_type}` : `${(JSON.parse(getTruckFromStorage(l.truck_id as string) as string) as TruckSpecifications).description} - ${l.body_type}`}&nbsp;</strong>
									</h2>
									<h4>{decryptAES(l.message_notes!, l.userid)}</h4>
									<p>{l.date_last_updated}</p>
									
								</IonLabel>

								{
								//Arrow Up, if sent by HPS
								isBodyBuilder &&

								<IonBadge color={l.message_status as unknown as string === 'Pending' ? (l.specification_id !== null ? "warning" : "danger") : 'success'} slot="end">
									{l.message_status as unknown as string === 'Pending' ? "pending" : "completed"}&nbsp;
									<IonIcon icon={l.specification_id !== null ? arrowUpOutline : arrowDownOutline} />
								</IonBadge>
							}

							{
								//Arrow Up, if sent by Salesperson
								isDealership &&

								<IonBadge color={l.message_status as unknown as string === 'Pending' ? (l.specification_id === null ? "warning" : "danger") : 'success'} slot="end">
									{l.message_status as unknown as string === 'Pending' ? "pending" : "completed"}&nbsp;
									<IonIcon icon={l.specification_id === null ? arrowUpOutline : arrowDownOutline} />
								</IonBadge>
							}
							
							</IonItem>
							
						)
					}

					{
						segment === 'pending' &&

						lead.leads.filter(l => (l.message_status as unknown as string) === 'Pending').length < 1 &&

						<IonRow className="ion-text-center ion-justify-content-center ion-margin-top">
							<IonCol size="10">
								<lottie-player src={ROUTES.CONTENT_NOT_FOUND} mode="bounce" background="transparent" speed="0.8" loop autoplay></lottie-player>
							</IonCol>
							<IonCol size="10">
								<IonLabel>
									<h2><strong>No Messages Found</strong></h2>
									<p>{`'Please send new messages`}</p>
								</IonLabel>
							</IonCol>
						</IonRow>
					}

					{
						segment === 'completed' &&

						
						lead.leads.filter(l => (l.message_status as unknown as string) === 'Completed').length > 0 &&
						

							lead.leads.filter(l => (l.message_status as unknown as string) === 'Completed').map((l) => 

							<IonItem key={l.id}
								routerLink={`${ROUTES.LEADS_LIST_PAGE_ROUTE}/${encodeURIComponent(isDealership ? lead.leads[0].preferred_supplier : lead.leads[0].dealership).toLowerCase()}/chats?q=${encodeURI(l.id!).toLowerCase()}`}>
								{l.truck_id === null ? `Hino Truck - ${l.body_type}` : `${(JSON.parse(getTruckFromStorage(l.truck_id as string) as string) as TruckSpecifications).description} - ${l.body_type}`}
							
								{
									//Arrow Up, if sent by HPS
									isBodyBuilder &&

									<IonIcon icon={l.specification_id !== null ? arrowUpOutline : arrowDownOutline} color={l.specification_id !== null ? "success" : "danger"} slot="end"/>
								}

								{
									//Arrow Up, if sent by Salesperson
									isDealership &&

									<IonIcon icon={l.specification_id === null ? arrowUpOutline : arrowDownOutline} color={l.specification_id === null ? "success" : "danger"} slot="end"/>
								}
							
							</IonItem>
							
						)
	
					}

					{
						segment === 'completed' &&

						lead.leads.filter(l => (l.message_status as unknown as string) === 'Completed').length < 1 &&

						<IonRow className="ion-text-center ion-justify-content-center ion-margin-top">
							<IonCol size="10">
								<lottie-player src={ROUTES.CONTENT_NOT_FOUND} mode="bounce" background="transparent" speed="0.8" loop autoplay></lottie-player>
							</IonCol>
							<IonCol size="10">
								<IonLabel>
									<h2><strong>No Messages Found</strong></h2>
									<p>{`'Please reply to your pending messages`}</p>
								</IonLabel>
							</IonCol>
						</IonRow>
					}

				</IonGrid>
			</IonContent>
		</IonPage>
	);
};

export default Messages;