import React, { useEffect, useState } from 'react';
import { IonToolbar, IonButtons, IonItemDivider, IonItem, IonButton, IonContent, IonList, 
    IonListHeader,  IonIcon, IonHeader,  IonLabel, IonTextarea, IonRow, IonText, IonLoading, IonSpinner, IonCard, IonCardContent, IonCol, IonFooter, IonTitle } from '@ionic/react';
import { chevronBack, checkmark, close, folderOpenOutline } from 'ionicons/icons';
import { useHistory } from 'react-router';
import { ReactSVG } from 'react-svg';
import { useStoreState } from 'pullstate';
import { useMutation, useQueryClient } from 'react-query';

import { CalcStore, CalcValues } from '../../services/calculations';
import { HinoDealership } from '../../models/Dealership';
import { postMessagesToDB } from '../../services/DataApi';
import { useFetchDataFromDB } from '../../services/DataApi';
import { Calculation } from '../../models/Calculation';
import { Message } from '../../models/Lead';

import { encryptAES, getUser, UserPreferences } from '../../services/user';
import * as ROUTES from '../../config';
import { LeadStatus } from '../../services/leads';
import { genericFilter, genericSearch, genericSort, IFilter, ISorter } from '../../utils';
import SearchInput from '../SearchInput';

interface SubmitToDealerProps {
    onDismissModal: () => void;
    route: string;
    values?: HinoDealership | Calculation | {id: null | string} | { id: string, name: string } | null;
  }

const SubmitToDealer: React.FC<SubmitToDealerProps> = ({onDismissModal, route, values}) => {

    
    const history = useHistory();
    const user = useStoreState(UserPreferences, getUser);
    const userid = user.userid;

    const { data: calculations, isLoading: isLoadingCalculations } = useFetchDataFromDB("specifications", ROUTES.SPECIFICATIONS_API_ENDPOINT);
    const { data: dealerships, isLoading: isLoadingDealerships } = useFetchDataFromDB("dealerships", ROUTES.DEALERSHIPS_API_ENDPOINT);

    const [showDealers, setShowDealers] = useState(false);
    const [showCalculations, setShowCalculations] = useState(false);

    const [message, setMessage] = useState("");
    const [notes, setNotes] = useState<string | null | undefined>("");

    //Determine if values is typeof  HinoDealership
    const isHinoDealership = (values as HinoDealership)?.SalesPerson !== undefined;
    const isSalesperson = values !== null && !isHinoDealership && (values as unknown as {id: string, name: string}).name !== undefined;
    //const dfg = values !== null && values !== undefined ? values.hasOwnProperty('name') : values.SalesPerson;

    console.log(isHinoDealership, isSalesperson)

    const {_id, _name} = isHinoDealership ? { _id: (values as HinoDealership).id, _name: (values as HinoDealership).SalesPerson } : isSalesperson ? { _id: (values as {id: string, name: string}).id, _name: (values as {id: string, name: string}).name } : { _id: null, _name: null };
    const [name, setName] = useState<string | null | undefined>(_name);
    const [salesPersonID, setSalesPersonID] = useState<string | null | undefined>(_id);
 
    //Post Lead to Dealership
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation(postMessagesToDB, {
        onSuccess: data => {
            console.log(data);
            setCalculation("");
            setCalculationID("");
            setMessage("success")
          },
          onError: () => {
            setMessage("error")
          },
          onSettled: () => {
            queryClient.invalidateQueries('messages');
          }
    });

    const [calculation, setCalculation] = useState<string | Calculation | null | undefined>(route.includes('calculations') ? (values as Calculation) : null);
    const [calculationID, setCalculationID] = useState(CalcStore.useState((s: CalcValues) => s.calcID));

    //Use calcID to find the calculation from a list of calculations
    useEffect(() => {

        if ((calculation === undefined || calculation === null) && calculationID !== "" && calculations) {
            const c = (calculations as Array<Calculation>).filter(c => c.calculationID === calculationID);
            setCalculation(c[0]);
        }
    }, [calculationID, calculation, calculations]);


    /** Search, Sort and Filter: Calculations*/
    const [queryC, setQueryC] = useState<string>("");
    const [sortPropertyC, setSortPropertyC] = useState<keyof Calculation>("uniquename");
    const [activeSorterC, setActiveSorterC] = useState<ISorter<Calculation>>({
        property: sortPropertyC,
        isDescending: false,
    });

    const [activeFiltersC, setActiveFiltersC] = useState<Array<IFilter<Calculation>>>(
        []
    );
    const [filteredCalculations, setFilteredCalculations] = useState<Array<Calculation>>([]);
    useEffect(() => {

        if (!isLoadingCalculations && calculations !== undefined) {
    
          const c: Calculation[] = calculations
            .filter((calc: Calculation) =>
              genericSearch<Calculation>(calc, ['truck','series', 'body', 'savedDate','uniquename', 'truckid'], queryC))
            .filter((calc: Calculation) => 
              genericFilter<Calculation>(calc, activeFiltersC))
            .sort((calcA: Calculation, calcB: Calculation) =>
              genericSort<Calculation>(calcA, calcB, activeSorterC) 
            );

          setFilteredCalculations(c);

        }
      }, [queryC, calculations, activeFiltersC, activeSorterC, isLoadingCalculations]);
    
      useEffect(() => {
        setActiveSorterC({
          property: sortPropertyC,
          isDescending: false //showAZ
        })
      }, [/*showAZ, */ sortPropertyC]);

    /** Search, Sort and Filter: Dealerships*/
    const [query, setQuery] = useState<string>("");
    const [sortProperty, setSortProperty] = useState<keyof HinoDealership>("SalesPerson");
    const [activeSorter, setActiveSorter] = useState<ISorter<HinoDealership>>({
        property: sortProperty,
        isDescending: false,
      });
    const [activeFilters, setActiveFilters] = useState<Array<IFilter<HinoDealership>>>(
        []
      );
    const [filteredDealerships, setFilteredDealerships] = useState<Array<HinoDealership>>([]);

    useEffect(() => {

        if (!isLoadingDealerships) {
    
          const d: HinoDealership[] = (dealerships as unknown as HinoDealership[]); //If HPS, fetch only dealerships registered on the App, else fetch all dealerships
          const q = d.filter((dealership: HinoDealership) =>
              genericSearch<HinoDealership>(dealership, ['Name','Region', 'Town', 'Address', 'SalesPerson'], query))
            .filter((dealership: HinoDealership) => 
              genericFilter<HinoDealership>(dealership, activeFilters))
            .sort((dealershipA: HinoDealership, dealershipB: HinoDealership) =>
              genericSort<HinoDealership>(dealershipA, dealershipB, activeSorter) 
            );
    
          setFilteredDealerships(q);

        }
    }, [query, dealerships, activeFilters, activeSorter]);
    
    useEffect(() => {
        setActiveSorter({
          property: sortProperty,
          isDescending: false //showAZ
        })
    }, [/*showAZ, */ sortProperty]);

    return (
        <>
            <IonHeader>
                <IonToolbar color="primary">
                    <IonButtons slot="start">
                        <IonButton 
                            //disabled={message === "success"}
                            onClick={() => {
                                onDismissModal();
                                history.push(route);
                                }}>
                            <IonIcon icon={chevronBack} />
                            &nbsp;
                        </IonButton>
                    </IonButtons>
                    <IonTitle>{`Submit Specification`}</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                
                <IonList>

                    <IonListHeader className="ion-padding">

                    {message === "success" ? "" : 

                        `Submit ${calculation !== null && calculation !== undefined ?
                            `${(calculation as Calculation).body !== null && (calculation as Calculation).body !== undefined ? `${(calculation as Calculation).truck} with a ${(calculation as Calculation).body!.charAt(0) + (calculation as Calculation).body!.slice(1)} Body` : (calculation as Calculation).truck}` 
                            
                            :

                            ""
                        }
                        
                        Specification To ${name === null ? "a Hino Dealership" : name}`
                    }
                    </IonListHeader>

                    {
                        message === "" &&

                        <>

                            {
                                !showCalculations && !showDealers &&

                                <IonItem color={name === null ? "danger" : ""} onClick={() => setShowDealers(true)}>
                                    <IonLabel>
                                        <h2><strong>{name === null ? 'Dealership' : name}</strong></h2>
                                        <p>{name === null ? 'Select a dealership' : `Your Specification will be sent to ${name}`}</p>
                                    </IonLabel>
                                    <IonIcon slot="end" size="large" icon={folderOpenOutline}/>
                                </IonItem>
                            }

                            {
                                isLoadingCalculations &&

                                <IonItem color="warning">
                                    <IonLabel>
                                        <h2><strong>Loading Specifications</strong></h2>
                                        <p>Please wait...</p>
                                    </IonLabel>
                                    <IonIcon slot="end" size="large" icon={folderOpenOutline}/>
                                </IonItem>

                            }

                            {
                                !showCalculations && !showDealers && !isLoadingCalculations && 

                                <>
                                    
                                    <IonItem color={calculation === null || calculation === undefined ? calculationID !== "" ? "warning" : "danger" : ""} onClick={() => calculations === undefined ? history.replace(ROUTES.CALCULATIONS_PAGE_ROUTE) : setShowCalculations(true)}>
                                        <IonLabel>
                                            <h2><strong>{calculation === null || calculation === undefined ? 'Specification' : (calculation as Calculation)?.uniquename}</strong></h2>
                                            <p>{calculation === null || calculation === undefined ? (calculations === undefined ? 'Please save Specifications first' : 'Select Specifications') : `Specification Selected: ${(calculation as Calculation).uniquename}`}</p>
                                        </IonLabel>
                                        <IonIcon slot="end" size="large" icon={folderOpenOutline}/>
                                    </IonItem>

                                    {
                                        calculation !== "" && calculation !== null && calculation !== undefined && !showCalculations &&
                                        
                                        <>
                                            <IonCard mode="ios">
                                                <IonCardContent>
                                                    <IonItem lines="none">
                                                        <IonLabel>
                                                            <h2><strong>Summary of Selected Specifications</strong></h2>
                                                            <h5>
                                                                {`Description: ${(calculation as Calculation).body !== null ? 
                                                                    `${(calculation as Calculation).truck} with a ${(calculation as Calculation).body!.charAt(0) + (calculation as Calculation).body!.slice(1)} Body` 
                                                                    : (calculation as Calculation).truck}`}
                                                            </h5>
                                                            <h5>{`GVM: ${(calculation as Calculation).gvm} kg, Payload: ${(calculation as Calculation).payload === null ? '0' : (calculation as Calculation).payload?.payload} kg`}</h5>
                                                            <h6>
                                                                <IonText>Mass&nbsp;
                                                                <IonIcon icon={(calculation as Calculation).rules.mass ? checkmark : close} color={(calculation as Calculation).rules.mass ? "success" : "danger"}/>
                                                                </IonText>
                                                                <IonText>&nbsp;Dimensions&nbsp;
                                                                <IonIcon icon={(calculation as Calculation).rules.dimensions ? checkmark : close} color={(calculation as Calculation).rules.dimensions ? "success" : "danger"}/>
                                                                </IonText>
                                                                <IonText>&nbsp;Bridge&nbsp;
                                                                <IonIcon icon={(calculation as Calculation).rules.bridge ? checkmark : close} color={(calculation as Calculation).rules.bridge ? "success" : "danger"}/>
                                                                </IonText>
                                                                <IonText>&nbsp;Circle&nbsp;
                                                                <IonIcon icon={(calculation as Calculation).rules.circle ? checkmark : close} color={(calculation as Calculation).rules.circle ? "success" : "danger"}/>
                                                                </IonText>
                                                            </h6>
                                                        </IonLabel>
                                                    </IonItem>
                                                    <ReactSVG 
                                                        fallback={() => <IonLabel>Not Found</IonLabel> }
                                                        loading={() => <IonSpinner color="primary"/>} 
                                                        src={`assets/trucks/${(calculation as Calculation).series}/${(calculation as Calculation).truckid}/bridge/${(calculation as Calculation).body === null ? 'index' : (calculation as Calculation).body}.svg`}/> 
                                                </IonCardContent>
                                            </IonCard>
                                            
                                        </>
                                    }
                                </>
                            }

                        </>
                    }

                    {
                        message === "" && !isLoading && showDealers && !isLoadingDealerships &&

                        <IonList>

                            <IonItemDivider mode="ios" color="primary" sticky>
                                <SearchInput onChangeSearchQuery={(query) => setQuery(query)} color="light"/>
                                <IonIcon onClick={() => setShowDealers(false)} slot="end" size="large" icon={close} />
                            </IonItemDivider>

                            {(filteredDealerships as unknown as Array<HinoDealership>).map((d: any, index: number) => (

                                <IonItem key={`${d.id}-${index}`} onClick={() => {
                                    setName(d.SalesPerson);
                                    setSalesPersonID(d.id);
                                    setShowDealers(false);
                                    }}> {`${d.SalesPerson} (${d.Name})`}
                                </IonItem> 
                            ))} 

                        </IonList>
                    }

                    {
                        message === "" && !isLoading && showCalculations && 

                        <>
                            
                            { isLoadingCalculations && <IonItem><strong>Loading specifications...</strong></IonItem> }
                            
                            {
                                !isLoadingCalculations && calculations.length > 0 &&

                                <IonList>

                                    <IonItemDivider mode="ios" color="primary" sticky>
                                        <SearchInput onChangeSearchQuery={(queryC) => setQueryC(queryC)} color="light"/>
                                        <IonIcon onClick={() => setShowCalculations(false)} slot="end" size="large" icon={close} />
                                    </IonItemDivider>

                                    {(filteredCalculations as unknown as Array<Calculation>).map((c: Calculation, index: number) => (

                                        <IonItem key={index} onClick={() => {
                                            setCalculation(c);
                                            setShowCalculations(false);
                                            }}> {c.uniquename}
                                        </IonItem> 
                                    ))} 
                            
                                </IonList>
                            }

                            { 
                                !isLoadingCalculations && filteredCalculations.length === 0 &&

                                <IonList className="ion-padding">
                                    <IonItem>You haven't saved any Specifications yet</IonItem>
                                    
                                    <IonRow className="ion-padding ion-justify-content-center">
                                        <IonCol size="12">
                                            <lottie-player src={ROUTES.CONTENT_NOT_FOUND} mode="bounce" background="transparent" speed="0.8" loop autoplay></lottie-player>
                                        </IonCol>
                                        <IonCol size="6" className="ion-justify-content-center ion-text-center">
                                            <IonButton fill="solid" routerLink={ROUTES.CALCULATIONS_PAGE_ROUTE} onClick={()=> onDismissModal()}>Add Specifications</IonButton>
                                        </IonCol>
                                        <IonCol size="6" className="ion-justify-content-center ion-text-center">
                                            <IonButton fill="outline" onClick={() => {
                                                setShowCalculations(false);
                                            }}>Back</IonButton>
                                        </IonCol>
                               
                                    </IonRow>
                                </IonList> 
                            }
                        </>  
                    }

                    {
                        !showCalculations && !showDealers && !isLoading && message === "" &&

                        <IonList>
                            <IonItem>
                                <IonLabel>
                                    <h2><strong>Notes</strong></h2>
                                </IonLabel>
                            <IonTextarea 
                                rows={6}
                                spellcheck={true}
                                autoCapitalize='sentences'
                                autoGrow={true}
                                style={ { borderLeft: '2px solid var(--ion-color-primary)', paddingLeft: '10px' }}
                                onIonChange={e => setNotes(e.detail.value)}
                                placeholder={`Brief Notes to ${name === null ? "a Dealership" : name}`}/>
                            </IonItem>
                        </IonList>
                    }

                </IonList>

                <IonLoading
                    //cssClass='my-custom-class'
                    isOpen={isLoading}
                    message={`Submitting your specifications to ${name}. Please wait...`}
                />

            </IonContent>

            <IonFooter>
                {
                    !showCalculations && !showDealers && !isLoading && message !== "success" &&

                    
                        <IonRow className="ion-padding ion-margin ion-justify-content-center" style={ {marginBottom: "60px"} }>
                            <IonCol size="12">
                                <IonButton
                                    expand="block"
                                    mode="ios" 
                                    disabled={isLoading || message === "success" || name === null || name === undefined || calculation === null || calculation === undefined}
                                    onClick={() => {

                                        const l: Message = {
                                            id: null,
                                            dealership: salesPersonID!,
                                            preferred_supplier: `${userid}`,
                                            builder_name: user.fullname,
                                            salesperson: name!,
                                            specification_id: (calculation as Calculation).id,
                                            specification: encryptAES(JSON.stringify(calculation), userid),
                                            body_type: (calculation as Calculation).body,
                                            message_notes: encryptAES(notes || `Please find specifications for ${(calculation as Calculation).truckid} ${(calculation as Calculation).body !== null ? `with ${(calculation as Calculation).body}` : ""}`, userid),
                                            truck_id: (calculation as Calculation).truckid || null,
                                            date_submitted: new Date().toLocaleString( 'en-ZA', { timeZoneName: 'short' } ),
                                            date_last_updated: new Date().toLocaleString( 'en-ZA', { timeZoneName: 'short' } ),
                                            message_status: ("Pending" as unknown as LeadStatus),
                                            userid: userid
                                            //dealership: dealerlist.filter(d => d.Name === name).map(d => d)
                                        }

                                        mutate(l);
                                    }
                                    }>Submit
                                </IonButton>
                            </IonCol>

                        </IonRow>  
                    }
                    {
                        message === "success" &&

                        <IonList className="ion-padding">
                            <IonListHeader className="ion-text-center ion-justify-content-center">Your specification was submitted successfully to {name}</IonListHeader>
                            
                            <IonRow className="ion-padding ion-justify-content-center">
                            
                                <IonCol size="12">
                                    <lottie-player src={ROUTES.MESSAGE_SUBMITTED_SUCCESSFULLY} mode="bounce" background="transparent" speed="0.8" loop autoplay></lottie-player>
                                </IonCol>
                                
                                <IonCol size="12" className="ion-justify-content-center ion-text-center">
                                    <IonButton fill="outline" expand='block' onClick={() => {
                                        setCalculation(null);
                                        setName(null);
                                        setMessage("");
                                    }}>Submit Again</IonButton>
                                </IonCol>
                               <IonCol size="12" className="ion-justify-content-center ion-text-center" style={ {marginBottom: "100px"} }>
                                <IonButton fill="solid" expand='block' onClick={()=> {
                                        onDismissModal();
                                        history.replace(route);
                                    }}>No, I'm Done</IonButton>
                               </IonCol>
                                {/*<IonButton fill="solid" onClick={()=> onDismissModal()}>Close</IonButton>*/}
                            </IonRow>
                        </IonList>
                    }
            </IonFooter>

        </>    
    )
}

export default SubmitToDealer;