import { useState, useRef, useEffect } from 'react';
import { isPlatform, IonContent,  IonPage, IonToolbar, IonFab, IonFabButton, IonIcon, IonLabel, IonItem,  IonItemSliding, IonItemOptions, IonItemOption, IonText,  IonChip, IonItemDivider, IonModal, IonHeader, IonButton, IonButtons, IonTitle, IonGrid, IonRow, IonCol, IonList, IonFabList } from '@ionic/react';
import { add, arrowUp, arrowDown, checkmark, close, options, filter, hammerOutline, calculatorOutline } from 'ionicons/icons';
import Skeleton from 'react-loading-skeleton';
import { useStoreState } from 'pullstate';

import { getUser, UserPreferences } from '../services/user';
import { useFetchDataFromDB } from '../services/DataApi';
import initCalculator, { CalcStore, CalculationStore, CalcValues } from '../services/calculations';
import { SubmitToDealer, BuildTruck } from '../components/Leads';
import { genericFilter, genericSearch, genericSort, IFilter, ISorter } from '../utils';
import { Calculation } from '../models/Calculation';
import SearchInput from '../components/SearchInput';
import TruckListFilter from '../components/Trucks/TruckListFilter';

import DocViewer from '../components/documents/DocViewer';

import * as ROUTES from '../config';

import './Calculations.css';
import 'react-loading-skeleton/dist/skeleton.css';


const Calculations: React.FC = () => {
  
  const pageRef = useRef<HTMLElement>(null);
  const ios = isPlatform('ios');

  CalculationStore.useState(s => s);

  const { data, isLoading } = useFetchDataFromDB("specifications", ROUTES.SPECIFICATIONS_API_ENDPOINT);

  /** User Details */
  const user = useStoreState(UserPreferences, getUser);
  const initials = user.initials;
  const isDealership = user.role.includes('Hino Dealership');
  const isBodyBuilder = user.role.includes('Hino Preferred Supplier');

  /** Search, Sort and Filter */
  const [query, setQuery] = useState<string>("");
  const [sortProperty, setSortProperty] = useState<keyof Calculation>("uniquename");
  const [activeSorter, setActiveSorter] = useState<ISorter<Calculation>>({
    property: sortProperty,
    isDescending: false,
  });

  const [activeFilters, setActiveFilters] = useState<Array<IFilter<Calculation>>>(
    []
  );

  /** Data */
  const [showAZ, setShowAZ] = useState(activeSorter.isDescending);
  const [calculations, setCalculations] = useState<Array<Calculation>>([]);
  const [series, setSeries] = useState<Array<string>>([]);
  const [bodies, setBodies] = useState<Array<string>>([]);
  const [calculation, setCalculation] = useState<Calculation | null>(null);

  /** UI */
  const [showFilter, setShowFilter] = useState(false);
  const [showSeries, setShowSeries] = useState(false);
  const [showBodies, setShowBodies] = useState(false);
  const [showPayload, setShowPayload] = useState(false);
  const [showTruckListFilter, setShowTruckListFilter] = useState(false);
  const [showSort, setShowSort] = useState(false);
  const [showSubmitToDealerModal, setShowSubmitToDealerModal] = useState(false);
  const [showBuildTruckModal, setShowBuildTruckModal] = useState(false);
  const [showPDFViewer, setShowPDFViewer] = useState(false);


  useEffect(() => {

    if (!isLoading && data !== undefined) {

      const c: Calculation[] = data
        .filter((calc: Calculation) =>
          genericSearch<Calculation>(calc, ['truck','series', 'body', 'savedDate','uniquename'], query))
        .filter((calc: Calculation) => 
          genericFilter<Calculation>(calc, activeFilters))
        .sort((calcA: Calculation, calcB: Calculation) =>
          genericSort<Calculation>(calcA, calcB, activeSorter) 
        );

      const b = Array.from(new Set(c.map((o: Calculation) => o.body !== null ? o.body.charAt(0).toUpperCase() + o.body.slice(1): "None")));
      const s = Array.from(new Set(c.map((o: Calculation) => o.series !== null ? o.series : "None")));

      setCalculations(c);
      setBodies(b);
      setSeries(s)
    }
  }, [query, data, activeFilters, isLoading, activeSorter]);

  useEffect(() => {
    setActiveSorter({
      property: sortProperty,
      isDescending: showAZ
    })
  }, [showAZ, sortProperty]);

  const title = isDealership ? 'My Payload Estimations': `${initials}'s Configurations`;
  const values = CalcStore.useState((s: any) => s);

  let ionLoadingItems: any = [];

  for (let i: number = 0; i < 10; i++) {

    ionLoadingItems.push(
      <IonCol size="12" sizeSm="6" sizeMd="6" sizeLg="4" sizeXl="3" key={i}>
        <IonItemSliding key={i} className="calculations-item">
          <IonItem>
            <IonLabel>
              <h3><strong><Skeleton/></strong></h3>
              <h5><Skeleton/></h5>
              <h6>
                <Skeleton/>
              </h6>
              <p><Skeleton/></p>
            </IonLabel>
          </IonItem>
        </IonItemSliding>
      </IonCol>
    )
  }

  return (
    <IonPage ref={pageRef} id="calculations-page">

      <IonHeader>
        <IonToolbar mode="ios" color="toolbar">

          <IonButtons slot="start">
            <IonButton
              routerDirection="back"
              routerLink={ROUTES.TABS_HOME_PAGE_ROUTE}
              >
              <IonIcon icon={close} /> 
            </IonButton>
          </IonButtons>

          <IonTitle><strong>{title}</strong></IonTitle>
        </IonToolbar>

        <IonToolbar mode="ios" className="ion-padding-top">
          <SearchInput onChangeSearchQuery={(query) => setQuery(query)} />
          <IonButtons slot="end">
            {
              !showFilter &&
              <IonButton color="dark" onClick={() => setShowFilter(true)}>
              {ios ? 'Filter' : <IonIcon icon={options} slot="icon-only" />}
            </IonButton>}
            {
              showFilter &&
              <IonButton color="dark" onClick={() => setShowFilter(false)}>
                {ios ? 'Close' : <IonIcon icon={close} slot="icon-only" />}
              </IonButton>
            }
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      {
        showFilter &&

        <IonToolbar mode="ios">

          <IonItemDivider color="light">

            <IonChip 
              disabled
              slot="start"
              color={showSeries ? "medium" : "primary"}
              //disabled={showSeries}
              onClick={() => {
                setShowSeries(true);
                setShowBodies(false);
                setShowPayload(false);
                setShowSort(false);
              }}>Series
            </IonChip>

            <IonChip 
              disabled
              slot="start"
              color={showBodies ? "medium" : "primary"}
              //disabled={showBodies}
              onClick={() => {
                setShowSeries(false);
                setShowBodies(true);
                setShowPayload(false);
                setShowSort(false);
              }}>Bodies
            </IonChip>

            <IonChip
              disabled 
              slot="start"
              color={showPayload ? "medium" : "primary"}
              //disabled={showPayload}
              onClick={() => {
                setShowSeries(false);
                setShowBodies(false);
                setShowPayload(true);
                setShowSort(false);
              }}>Payload
            </IonChip>

            <IonButton 
              color={showSort ? "medium" : "dark"}
              disabled={showSort}
              slot="end" 
              onClick={() => {
                setShowSeries(false);
                setShowBodies(false);
                setShowPayload(false);
                setShowSort(true);
              }}>{ios ? 'Sort' : <IonIcon icon={filter} slot="icon-only" />}
              </IonButton>


          </IonItemDivider>

          {
            showSeries &&

            <IonItemDivider color="light">
              {
                series.map(s => 

                  <IonChip 
                    disabled
                    color="success" 
                    key={s}
                    onClick={() => {
                      setActiveFilters([
                        ...activeFilters.filter(
                          (filter) => filter.property !== 'series'
                        ),
                        { property: 'series', isTruthyPicked: true },
                      ]);
                    }}
                    >{s}
                  </IonChip>
                )
              }
            </IonItemDivider>
          }

          {
            showBodies && 

            <IonItemDivider color="light">

              {
                bodies.map(b => 

                  <IonChip 
                    disabled
                    color="success" 
                    key={b}
                    onClick={() => {
                      setActiveFilters([
                        ...activeFilters.filter(
                          (filter) => filter.property !== 'body'
                        ),
                        { property: 'body', isTruthyPicked: true },
                      ]);
                    }}
                    >{b}
                  </IonChip>
                )
              }

              </IonItemDivider>
            }

          {
            showPayload &&

            <IonItemDivider color='light'>
              <IonChip color="success" slot="start">Less than 12 Ton</IonChip>
              <IonChip color="success" slot="start">More than 12 Ton</IonChip>
            </IonItemDivider>
          } 

{
            showSort &&

            <IonItemDivider color='light'>
              <IonChip
                onClick={() => setSortProperty('uniquename')} 
                color={sortProperty === 'uniquename' ? "medium" : "success"}
                slot="start">Name
              </IonChip>

              <IonChip
                onClick={() => setSortProperty('savedDate')} 
                color={sortProperty === 'savedDate' ? "medium" : "success"}
                slot="start">Date
              </IonChip>

              {/*<IonChip
                onClick={() => setSortProperty('wheelBase')} 
                color={sortProperty === 'wheelBase' ? "medium" : "success"}
                slot="start">Wheelbase
              </IonChip>

              <IonChip
                onClick={() => setSortProperty('maxGVMRating')} 
                color={sortProperty === 'maxGVMRating' ? "medium" : "success"}
                slot="start">GVM
              </IonChip> */}

              <IonButtons slot="end">
                {
                  showAZ &&
                  <IonButton color="dark" onClick={() => setShowAZ(false)}>
                  {ios ? 'A-Z' : <IonIcon icon={arrowDown} slot="icon-only" />}
                  </IonButton>
                }

                {
                  !showAZ &&
                  <IonButton color="dark" onClick={() => setShowAZ(true)}>
                  {ios ? 'Z-A' : <IonIcon icon={arrowUp} slot="icon-only" />}
                  </IonButton>
                }
              
              </IonButtons>
            </IonItemDivider>
          }
        </IonToolbar>
      }

      <IonContent fullscreen>

        {isLoading && 
          <IonList>
            <IonGrid className="c-wrapper">
              <IonRow className="ion-justify-content-center">
                {ionLoadingItems}
              </IonRow>
            </IonGrid>
          </IonList>}

        {
          !isLoading && calculations.length === 0 &&
          
          <IonList className="ion-padding">
            <IonRow className="ion-text-center ion-justify-content-center ion-margin-top">
              {
                data !== undefined ?

                <>
                  <IonCol size="10">
                    <lottie-player src={ROUTES.CONTENT_NOT_FOUND} mode="bounce" background="transparent" speed="0.8" loop autoplay></lottie-player>
                  </IonCol>
                  <IonCol size="10">
                      <IonLabel> 
                        {
                          query === "" ?
                          <>
                            <h2><strong>Not Found</strong></h2>
                            <p>{`${isDealership ? 'Tab the button below to estimate payload of Hino Trucks based on body application': 'Add new Body Configuration'}`}</p>
                          </>
                          :
                          <>
                            <h2><strong>Not Found</strong></h2>
                            <p>{`Please search for different ${isDealership ? 'payload estimations or tab the button below to estimate payload of Hino Trucks based on body application': 'configurations or add new Configurations'} `}</p>
                          </>
                        }  
                        
                      </IonLabel>
                  </IonCol>
                </>

                :

                <>
                    <IonCol size="10">
                      <lottie-player src={ROUTES.FORBIDDEN} mode="bounce" background="transparent" speed="0.8" loop autoplay></lottie-player>
                    </IonCol>

                    <IonCol size="10" className='ion-margin ion-padding'>
                      <IonLabel>
                        <h2><strong>You are forbidden to access the resource</strong></h2>
                        <p>{`Contact App Support to upgrade your account`}</p>
                      </IonLabel>
                    </IonCol>

                    <IonButton routerLink={ROUTES.SUPPORT_PAGE_ROUTE}>Contact Support</IonButton>
                </>


              }
              
            </IonRow>
          </IonList>
        }

        {
          calculations.length > 0 && (

            <IonGrid className="c-wrapper">
              <IonRow>
              {
                calculations.map((c: Calculation) => (

                  <IonCol size="12" sizeSm="6" sizeMd="6" sizeLg="4" sizeXl="3" key={`calculation-${c.id}`}>
                    <IonItemSliding  className="calculations-item" key={c.id}>
                      <IonItem
                        mode="ios"
                        routerLink={`${ROUTES.CALCULATIONS_PAGE_ROUTE}/${c.id}/view`}
                        onClick={() => {
                          setCalculation(c);
                          CalculationStore.update(s => {
                            s.body = c.body;
                            s.bodySpecification = c.bodySpecification;
                            s.gvm = c.gvm;
                            s.id = c.id;
                            s.payload = c.payload;
                            s.rules = c.rules;
                            s.savedDate = c.savedDate;
                            s.series = c.series;
                            s.truck = c.truck;
                            s.truckid = c.truckid;
                            s.uniquename = c.uniquename;
                            s.userid = c.userid;
                          });
                          //history.replace(`${ROUTES.CALCULATIONS_PAGE_ROUTE}/${c.id}/view`, {"calculation": c});
                          //setShowPDFViewer(true);
                        }}

                        >
                        <IonLabel>
                          <h2><strong>{c.uniquename}</strong></h2>
                          <h5>
                            {`${c.body !== null ? `${c.truck} - ${c.body.charAt(0).toUpperCase() + c.body.slice(1)} Body` : c.truck}`}
                          </h5>
                          <h6>
                            <IonText>Mass&nbsp;
                              <IonIcon icon={c.rules.mass ? checkmark : close} color={c.rules.mass ? "success" : "danger"}/>
                            </IonText>
                            <IonText>&nbsp;Dimensions&nbsp;
                              <IonIcon icon={c.rules.dimensions ? checkmark : close} color={c.rules.dimensions ? "success" : "danger"}/>
                            </IonText>
                            <IonText>&nbsp;Bridge&nbsp;
                              <IonIcon icon={c.rules.bridge ? checkmark : close} color={c.rules.bridge ? "success" : "danger"}/>
                            </IonText>
                            <IonText>&nbsp;Circle&nbsp;
                              <IonIcon icon={c.rules.circle ? checkmark : close} color={c.rules.circle ? "success" : "danger"}/>
                            </IonText>
                          </h6>
                          <p>Saved: {(c.savedDate).toString().substring(0, 10)}</p>
                        </IonLabel>
                        {/*<IonAvatar color="primary">
                          <IonImg src={hino500} alt={initials}/>
                        </IonAvatar>*/}
                      </IonItem>
                      <IonItemOptions>
                          <IonItemOption color="danger" onClick={() => alert("Calculation Deleted")} disabled>
                            Delete
                          </IonItemOption> 
                          <IonItemOption 
                            color="success"
                            onClick={() => {
                              initCalculator(c.truckid);
                              const v = { ...values };
                              v.calcID = c.id;
                              CalcStore.update((s: CalcValues) => {
                                s.calcID = c.id;
                              });
                              setCalculation(c);
                              setShowSubmitToDealerModal(true);
                            }}>
                            Submit to Dealer
                          </IonItemOption>
                        </IonItemOptions>
                    </IonItemSliding>
                  </IonCol>
                ))
              }
              </IonRow>
            </IonGrid>
          )
        }

        <IonModal
          mode="ios"
          isOpen={showSubmitToDealerModal}
          onDidDismiss={() => setShowSubmitToDealerModal(false)}
          swipeToClose={true}> 
            <SubmitToDealer 
              onDismissModal={() => setShowSubmitToDealerModal(false)} 
              route={ROUTES.CALCULATIONS_PAGE_ROUTE}
              values={calculation}/>
        </IonModal>

        <IonModal
          mode="ios"
          isOpen={showBuildTruckModal}
          onDidDismiss={() => setShowBuildTruckModal(false)}
          swipeToClose={true}> 
            <BuildTruck 
              onDismissModal={() => setShowBuildTruckModal(false)} 
              route={ROUTES.CALCULATIONS_PAGE_ROUTE}
              values={calculation}/>
        </IonModal>

        <IonModal
          mode="ios"
          isOpen={showTruckListFilter}
          onDidDismiss={() => setShowTruckListFilter(false)}
          swipeToClose={true}> 
            <TruckListFilter onDismissModal={() => setShowTruckListFilter(false)} route={ROUTES.CALCULATIONS_PAGE_ROUTE}/>
        </IonModal>

        <IonModal mode="ios" isOpen={showPDFViewer} onDidDismiss={() => setShowPDFViewer(false)} swipeToClose={true}>
          {/*<Viewer onDismissModal={() => setShowPDFViewer(false)} docTemplate={<SpecDoc specification={calculation as Calculation}/>}/>*/}
          <DocViewer onDismissModal={() => setShowPDFViewer(false)} />
        </IonModal>

        {
          isDealership === true &&

          <IonFab vertical="bottom" horizontal="end" slot="fixed">
            <IonFabButton onClick={() =>  setShowBuildTruckModal(true)}>
              <IonIcon icon={add}></IonIcon>
            </IonFabButton>
          </IonFab>

        }

        {
          isBodyBuilder === true && 

          <IonFab vertical="bottom" horizontal="end" slot="fixed">
            <IonFabButton>
              <IonIcon icon={add}/>
            </IonFabButton>
            <IonFabList side="top">
              <IonFabButton data-desc={'Configure a Hino Truck'} onClick={() =>  {
                setShowTruckListFilter(true)
                }}>
                <IonIcon icon={hammerOutline}/>
              </IonFabButton>
              <IonFabButton data-desc="Estimate Payload" onClick={() =>  {
                setShowBuildTruckModal(true)
                }}>
                <IonIcon icon={calculatorOutline}/>
              </IonFabButton>
            </IonFabList>
          </IonFab>
        }
      </IonContent>
    </IonPage>
  );
};

export default Calculations;
