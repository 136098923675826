import { IonCard, IonCardContent, IonChip, IonCol, IonLabel, IonRow, IonSpinner } from "@ionic/react";
import { ReactSVG } from "react-svg";
import { CalcValues, Calculator} from "../../services/calculations";

import circle from '../../assets/images/tc.svg';
import { TruckSpecifications } from "../../models/Truck";

interface CircleProps {
    values: CalcValues;
}

const CnvsCircle: React.FC<CircleProps> = ({values}) => {

    const { dimensions, regulations, bodyType } = Calculator(values);
    const truck = JSON.parse(values.truck as string) as TruckSpecifications;
    const body = bodyType.toLowerCase() !== 'unknown' ? `with a ${bodyType.charAt(0).toUpperCase() + bodyType.slice(1)}` : '';
    const title = `${truck.name} (${truck.chassisModel}) ${body} Turning Circle`;

    return (
        <>
            <IonRow className="ion-justify-content-center ion-padding">
                <IonCol size="12" className="ion-text-center">
                    <IonRow className="ion-justify-content-center">
                        <strong>{title}</strong>
                    </IonRow>
                </IonCol>
            </IonRow>

            <IonRow className="ion-justify-content-center">
                <IonCol size="4" className="ion-padding">
                    <IonRow className="ion-justify-content-center ion-text-center"><strong>Inner Radius (Path of Rearmost Axle)</strong></IonRow>
                    <IonRow className="ion-justify-content-center">
                        <IonChip color={regulations.innerRadius < regulations.maxRadius ? "success" : "danger"}>
                            {`${regulations.innerRadius} mm`}
                        </IonChip>
                    </IonRow>
                </IonCol>
                <IonCol size="4" className="ion-padding">
                    <IonRow className="ion-justify-content-center ion-text-center"><strong>Outer Min Radius (Kerb-to-kerb)</strong></IonRow>
                    <IonRow className="ion-justify-content-center">
                        <IonChip color={dimensions.tck < regulations.maxRadius ? "success" : "danger"}>
                            {`${dimensions.tck} mm`}
                        </IonChip>
                    </IonRow>
                </IonCol>
                <IonCol size="4" className="ion-padding">
                    <IonRow className="ion-justify-content-center ion-text-center"><strong>Outer Max Radius (Wall-to-Wall)</strong></IonRow>
                    <IonRow className="ion-justify-content-center">
                        <IonChip color={dimensions.tck < regulations.maxRadius ? "success" : "danger"}>
                            {`${dimensions.tcw} mm`}
                        </IonChip>
                    </IonRow>
                </IonCol>
                
            </IonRow>

            <IonCard className="ion-justify-content-center" style={{textAlign: "center"}}>

                <IonCardContent>

                    <ReactSVG 
                        fallback={() => <h3>Not Found</h3> }
                        loading={() => <IonSpinner color="primary"/>} 
                        src={circle} /> 

                    {/*<IonImg src={tc} alt="circle"/>*/}

                    <IonLabel>
                        <h4>Values</h4>
                        <p>{`Swept Path: ${regulations.sweptPath} m`}</p>
                        <p>{`Steering Angle: ${regulations.steeringAngle}`}</p>
                    </IonLabel>

                </IonCardContent>

            </IonCard>
        </>
    );
}

export default CnvsCircle;