import { Page, Text, View, Image, Document, StyleSheet} from '@react-pdf/renderer';
import packageInfo from '../../../package.json';
import { HinoPreferredSupplier } from '../../models/Builder';
import { TruckSpecifications } from '../../models/Truck';


interface TruckDocProps {
    builder?: HinoPreferredSupplier;
    specification?: TruckSpecifications;
  }
  
// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#FFFFFF'
  },
  section: {
    marginLeft: 10,
    marginRight: 10,
    marginBottom: 10,
    marginTop: 5,
    padding: 10,
    flexGrow: 1
  },
  heading: {
    fontSize: 30,
    fontWeight: 'bold'
  },
  spec_wrapper: {
    //backgroundColor: "#f6f6f5",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    padding: 5
},
  spec_container: {
      backgroundColor: "#f6f6f5",
      display: "flex",
      flexDirection: "column",
      padding: 5,
      justifyContent: "center"
  },
  spec_details: {
      fontSize: 15,
      //display: "flex",
      marginLeft: 5
  },
  spec_title: {
      fontSize: 15,
      marginBottom: 10,
      marginTop: 10,
      fontWeight: 900,
      marginLeft: 5
  },
  spec_overview: {
      fontSize: 10
  },

  image: {
      height: 240,
      width: 320
  },
  subtitle: {
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "row",
      width: 150,
      alignItems: "center",
      marginBottom: 12
  },
  vote: {
      display: "flex",
      flexDirection: "row"
  },
  rating: {
      height: 10,
      width: 10
  },
  vote_text: {
      fontSize: 10
  },
  vote_pop: {
      fontSize: 10,
      padding: 2,
      backgroundColor: "#61C74F",
      color: "#fff"
  },
  vote_pop_text: {
      fontSize: 10,
      marginLeft: 4
  },
  overviewContainer: {
      minHeight: 110
  },
  detailsFooter: {
      display: "flex",
      flexDirection: "row"
  },
  lang: {
      fontSize: 8,
      fontWeight: 700
  },
  vote_average: {
      fontSize: 8,
      marginLeft: 4,
      fontWeight: "bold"
  }
});

// Create Document Component
const TruckDoc: React.FC<TruckDocProps> = ({specification}) => {

  //const compilerName = specification?.userid;
  //const compilerContact = builder?.Email;

  const truckSMC = specification?.chassisModel;
  //initCalculator(truckSMC, specification); //Likely not needed
  //const bodySpec = specification?.bodySpecification;
  //const payload = specification?.payload;

  /*const  v = { ...new EmptyCalcValues()};
  v.truck = _truck;
  v.body = (bodySpec as Body || null);
  v.payload = (payload as Payload || null);*/

  const truck = specification;

  //const body = specification?.body === null || specification?.body === undefined ? "" : `with ${specification?.body} Body`;

  const title = `Specification for ${truck?.description} (${truckSMC})`;
  const series = specification?.series;
  const subtitle = `Application(s) - ${specification?.application}`
  const overview = `GVM: ${specification?.GVM} kg, Body and Payload Allowance:  ${specification?.bodyPayloadAllownce} kg`;

  //const rules = `Mass: ${specification?.rules.mass}, Dimensions: ${specification?.rules.dimensions}, Bridge: ${specification?.rules.bridge} Circle: ${specification?.rules.circle}`;


  
  return (
    <Document
      title={title}
      keywords={`${specification?.description}, ${specification?.series}, ${specification?.model}`}
      author={`Hino Truck Body Builders App v${packageInfo.version}`}
      subject={title}
      creator={`Hino Truck Body Builders App v${packageInfo.version}`}
      producer={`Hino MobileTruck Body Builders App v${packageInfo.version}`}
      language="en-za"
    >
      <Page size="A4" style={styles.page}>

        {/** Header */}
        <View style={styles.section}>

          <View style={styles.spec_wrapper}>
            <View style={[{display: "flex", justifyContent: "center"}]}>
              <Image src={`/assets/icon/icon_1024.png`} style={{ height: 50, width: 50}}/>
            </View>
          
            <View>
              {/*<Text style={styles.heading}>{builder?.Name}</Text>*/}
              <Text style={[styles.spec_title, {fontWeight: "bold"}]}>{title}</Text>
              <Text style={styles.spec_details}>{subtitle}</Text>
              {/*<Text style={styles.spec_details}> {`Date: ${specification?.savedDate}`}</Text>*/}
            </View>
          </View>

        </View>

        {/** Specification Overview */}
        <View style={styles.section}>
          <Text style={styles.heading}>Overview</Text>
          <Text style={[styles.spec_details, {fontWeight: "bold"}]}>{overview}</Text>
          {/*<Text style={[styles.spec_details, {fontWeight: "bold"}]}>{rules}</Text>*/}
          <Image src={`/assets/images/hino${series}.png`} style={styles.image}/>

          {/** Specification Details */}
          <View style={styles.spec_wrapper}>
            <View style={styles.spec_container}>
              <Text style={styles.spec_title}>Mass</Text>
              <Text style={styles.spec_details}>{`A - Front: ${truck?.frontTyreRating} kg`}</Text>
              <Text style={styles.spec_details}>{`A - Rear:  ${truck?.rearTyreRating} kg`}</Text>
              <Text style={styles.spec_details}>{`D/T - ${truck?.GCM} kg`}</Text>
              <Text style={styles.spec_details}>{`GA - Front: ${truck?.frontGAMRating} kg`}</Text>
              <Text style={styles.spec_details}>{`GA - Rear: ${truck?.rearGAMRating} kg`}</Text>
              <Text style={styles.spec_details}>{`GCM: ${truck?.GCM} kg`}</Text>
              <Text style={styles.spec_details}>{`GVM: ${truck?.GVM} kg`}</Text>
              <Text style={styles.spec_details}>{`Tare - Front: ${truck?.chassisWeightF} kg`}</Text>
              <Text style={styles.spec_details}>{`Tare - Rear: ${truck?.chassisWeightR} kg`}</Text>
              <Text style={styles.spec_details}>{`Tare - Total: ${+(truck!.chassisWeightF) + +(truck!.chassisWeightR)} kg`}</Text>
              <Text style={styles.spec_details}>{`V - Rating: ${truck?.maxGVMRating} kg`}</Text>
            </View>
            <View style={{padding: 5, justifyContent: "center"}}>
              <Text style={styles.spec_title}>Dimensions</Text>
              <Text style={styles.spec_details}>{`CA: ${truck?.cabToRearAxleCentre} mm`}</Text>
              <Text style={styles.spec_details}>{`WB: ${truck?.wheelBase} mm`}</Text>
              <Text style={styles.spec_details}>{`WBMin: ${truck?.wheelBaseMin} mm`}</Text>
              <Text style={styles.spec_details}>{`WBMax: ${truck?.wheelBaseMax} mm`}</Text>
              <Text style={styles.spec_details}>{`OH: ${truck?.overallHeight} mm`}</Text>
              <Text style={styles.spec_details}>{`OL: ${truck?.overAllLength} mm`}</Text>
              <Text style={styles.spec_details}>{`OW: ${truck?.overallWidth} mm`}</Text>
              <Text style={styles.spec_details}>{`ROH: ${truck?.rearOverhang} mm`}</Text>
              <Text style={styles.spec_details}>{`Kerb Radius: ${truck?.turningRadiusKerb} mm`}</Text>
              <Text style={styles.spec_details}>{`Wall Radius: ${truck?.turningRadiusWall} mm`}</Text>
            </View>
            <View style={styles.spec_container}>
              {/*<Text style={styles.spec_title}>Bridge</Text>
              <Text style={styles.spec_details}>{`Distance: ${truck.regulations.mass.distance} mm`}</Text>
              <Text style={styles.spec_details}>{`Gross: ${truck.regulations.mass.gross} kg`}</Text>
              <Text style={styles.spec_details}>{`Permissible: ${truck.regulations.mass.permissible} kg`}</Text>
              <Text style={styles.spec_details}>{`Utilisation: ${truck.regulations.mass.utilisation} %`}</Text>  
              <Text style={styles.spec_title}>Circle</Text>
              <Text style={styles.spec_details}>{`Kerb Radius: ${truck.regulations.kerbRadius} mm`}</Text>
              <Text style={styles.spec_details}>{`Wall Radius: ${truck.regulations.wallRadius} mm`}</Text>
              <Text style={styles.spec_details}>{`Swept Path: ${truck.regulations.sweptPath} mm`}</Text>
              <Text style={styles.spec_details}>{`Inner Radius: ${truck.regulations.innerRadius} mm`}</Text>
            <Text style={styles.spec_details}>{`Steering Angle: ${truck.regulations.steeringAngle} degrees`}</Text>*/}
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default TruckDoc;