import { ModelTruck } from "../../../models";
import { CalcValues } from '../';
import { round } from "mathjs";

let 
//Chassis
foh: number, wb: number, wbmin: number, wbmax: number, roh: number, fas: number, ras: number,
cw: number, ch: number, hogfg: number, ca: number, bbc: number, mgc: number, gcm: number,
cwr: number, cwf: number, wbDiff: number, rating: number, tck: number, tcw: number, 
tkf: number, tkr: number, fwf: number, fwr: number, f5wh: number, twb: number,

//Tyres, Suspensions and Axles
trf: number, trr: number, gaf: number, gar: number,

//Fuel and Crew 
nc: number, mc: number, ft: number, fd: number;

export const TruckAdapter = (values: CalcValues) => {

    const { ...mass } = massAdapter(values);
    const { ...dimensions } = dimensionsAdapter(values);

    return { mass, dimensions };
}

const dimensionsAdapter = (values: CalcValues) => {

    const truck = JSON.parse(values.truck as string) as ModelTruck.TruckSpecifications;
    const differences = values.differences;

    const title = `${truck.description} (${truck.chassisModel})`;
    const application = truck.application || "General Use";

    bbc = +(truck.bbc || 0);
    mgc = +(truck.mgc || 0);
    foh = +(truck.frontOverhang || 0);
    fas = +(truck.wbfas || 0);
    ras = +(truck.wbRas || 0);
    roh = +(truck.rearOverhang || 0);
    hogfg = +(truck.heightOfGravityFromGround || 0);
    tck = +(truck.turningRadiusKerb || 0);
    tcw = +(truck.turningRadiusWall || 0);

    wbDiff = +(differences?.wbDiff || 0);

    ca = +(truck.cabToRearAxleCentre || 0) + (+(wbDiff || 0));
    wb = +(truck.wheelBase || 1);
    twb = wb + (+(wbDiff || 0)) + (+(fas/2 || 0)) + (+(ras/2 || 0)) + (+(wbDiff || 0));
    wbmin = +(truck.wheelBaseMin || wb);
    wbmax = +(truck.wheelBaseMax || wb);

    ch = +(truck.cabHeight || 0);
    cw = +(truck.cabWidth || 0);

    tkf = +(truck.trackF || 0);
    tkr = +(truck.trackR || 0);
    fwf = +(truck.frameWidth || 0);
    fwr = +(truck.frameWidthR || 0);
    f5wh = +(truck.fifthWheelHeight || 0);

    return {
        title, application,
        bbc, mgc, foh, fas, ras, roh,
        hogfg, wbDiff, ca, wb, twb, wbmin, wbmax, ch, cw,
        fwf, fwr, f5wh, tkf, tkr,
        tck, tcw 
    }
}

const massAdapter = (values: CalcValues) => {

    const truck = JSON.parse(values.truck as string) as ModelTruck.TruckSpecifications;
    const fuelAndCrew = values.fuelAndCrew;

    mc = round(+(fuelAndCrew?.crewMass || 80));
    nc = round(+(fuelAndCrew?.crewNumber || 1));

    ft = round(+truck.fuelTankCapacity);
    fd = round(+(fuelAndCrew?.fuelDensity || 0));

    cwf = round(+(truck.chassisWeightF || 0)/10)*10;
    cwr = round(+(truck.chassisWeightR || 0)/5)*5;
    
    rating = round(+(truck.maxGVMRating || 0));

    gaf = round(+(truck.frontGAMRating || 0));
    gar = round(+(truck.rearGAMRating || 0));

    trf = round(+(truck.frontTyreRating || 0));
    trr = round(+(truck.rearTyreRating || 0));
    gcm = round(+(truck.GCM || 0));

    return {
        mc, nc, 
        fd, ft,
        cwf, cwr, rating, 
        gaf, gar, trf, trr,
        gcm
    }
}

