import { useRef } from 'react';
import { useHistory } from 'react-router';
import { IonContent, IonButton, IonToolbar, IonHeader, IonPage, IonButtons, IonIcon, IonTitle, IonBadge, IonCard, IonCardContent, IonCol, IonGrid } from '@ionic/react';
import { chevronBack } from 'ionicons/icons';

//import SearchInput from '../../components/SearchInput';

import GuidelineStore from '../../services/guidelines';
import { Guidelines } from '../../models/Guidelines';

import * as ROUTES from '../../config';

import '../Guidelines.css'


const ElectricalSystems: React.FC = () => {
  
  const pageRef = useRef<HTMLElement>(null);
  const history = useHistory();
  //const ios = isPlatform('ios');

  //const [query, setQuery] = useState<string>("");
  //const [showFilter, setShowFilter] = useState(false);

  return (
    <IonPage ref={pageRef} id="electrical-systems-page">
      <IonHeader>
        <IonToolbar mode="ios" color="toolbar">
            <IonButtons slot="start">
            <IonButton routerLink={ROUTES.GUIDELINES_PAGE_ROUTE}>
              <IonIcon icon={chevronBack} />
              </IonButton>
            </IonButtons>
            <IonTitle>Electrical Systems</IonTitle>
        </IonToolbar>
        
          {/*!ios &&
          <IonToolbar mode="ios" className="ion-padding-top">
            <SearchInput onChangeSearchQuery={(query) => setQuery(query)} />
            <IonButtons slot="end">
              {
                !showFilter &&
                <IonButton color="dark" onClick={() => setShowFilter(true)}>
                  <IonIcon icon={options} slot="icon-only" />
              </IonButton>}
              {
                showFilter &&
                <IonButton color="dark" onClick={() => setShowFilter(false)}>
                  <IonIcon icon={close} slot="icon-only" />
                </IonButton>
              }
            </IonButtons>
            </IonToolbar> */}
            
      </IonHeader>
      
      <IonContent fullscreen>
        {/*
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Body Mounting Guidelines</IonTitle>
          </IonToolbar>

          <IonToolbar className="ion-padding-top">
            <SearchInput onChangeSearchQuery={(query) => setQuery(query)} />
            <IonButtons slot="end">
              {
                !showFilter &&
                <IonButton color="dark" onClick={() => setShowFilter(true)}>
                  Filter
              </IonButton>}
              {
                showFilter &&
                <IonButton color="dark" onClick={() => setShowFilter(false)}>
                  Close
                </IonButton>
              }
            </IonButtons>
          </IonToolbar>
          </IonHeader> */}

        <IonGrid>

          {
            Guidelines.filter(g => g.topic === 'electrical-systems').sort((a, b) => {
              if (a.tag > b.tag) {
                  return 1;
              } else if (a.tag < b.tag) {
                  return -1;
              } else {
                  return 0;
              }
            }).map((g, index) =>
            
              <IonCol key={`${g.title}-${index}`} sizeSm="6" sizeMd="4" sizeLg='3' sizeXl='3'>

                <IonCard mode="ios" onClick={() => {
                  GuidelineStore.update(s => {
                      s.url = g.url;
                      s.title = g.title;
                    });

                    history.replace(`${ROUTES.ELECTRICAL_SYSTEMS_PAGE_ROUTE}/${encodeURIComponent(btoa(g.title))}`);
              
                  }}>
                  <IonCardContent>
    
                    <div className={ "ion-padding-top"}>
                      <IonBadge color={g.topic.toLowerCase().includes('precaution') ? "warning" : "danger"}>{g.topic.toLowerCase()}</IonBadge>
                      <IonBadge color={g.topic.toLowerCase().includes('precaution') ? "warning" : "warning"}>{g.tag.toLowerCase()}</IonBadge>
                      <br />

                      <p key={`regulation-${g.url}_${ index }`} className={ index > 0 ? "ion-padding-top" : "" }>
                          {g.title}&nbsp;&rarr;   
                      </p>
                    </div> 
    
                  </IonCardContent>
                </IonCard>  
              </IonCol>
            
            )
          }

        </IonGrid>

      </IonContent>
    </IonPage>
  );
};

export default ElectricalSystems;
