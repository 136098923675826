import { SvgLoader, SvgProxy } from "react-svgmt";

import { CalcValues,  Calculator } from "../../services/calculations";
import { TruckSpecifications } from "../../models/Truck";

interface DimensionsProps {
    values: CalcValues;
    view: 'front' | 'rear' | 'side'| 'top';
}

//Conditional Colours
const pass = '#2dd36f';
const fail = '#eb445a';

//List of Bodies
//const bodies = ['van', 'dropside', 'flatdeck', 'tautliner', 'timber', 'tipper', 'tanker', 'livestock', 'rollback', 'beavertail'];


const updateElement = (el: any, value: number) => el['innerText' in el ? "innerText" : "textContent"] = value

const CnvsDimensions: React.FC<DimensionsProps> = ({values, view }) => {

    const { regulations, dimensions, bodyType } = Calculator(values);
    const truck = JSON.parse(values.truck as string) as TruckSpecifications;

    const file = bodyType === 'unknown' ? `index` : `${bodyType.toLowerCase()}`;
    const filepath = `assets/trucks/${truck.series}/${truck.chassisModel}/${view}/${file}.svg`;

    const busfilepath = `assets/trucks/${truck.series}/${truck.chassisModel}/${view}/${file}.png`;

    const isBus = truck.series.toLowerCase().includes('bus');

    return (

        isBus ? 

            <img src={busfilepath} alt={truck.series} /> 

            :

            <SvgLoader path={filepath}>
                <SvgProxy selector="#front-axle" stroke={regulations.mass.twfW ? pass : fail} />
                <SvgProxy selector="#rear-axle" stroke={regulations.mass.twrW ? pass : fail} />
                <SvgProxy selector="#txt-roh" stroke={regulations.dimensions.rohw ? pass : fail} />
                {/*<SvgProxy selector="rect" stroke={bodyType === 'unknown' ? '#fff' : '#000'} style={bodyType === 'unknown' ? "fill-opacity:0" : "fill:#fff"}/>
                <SvgProxy selector="#bl-arrow" stroke={bodyType === 'unknown' ? '#fff' : '#000'} /> */}
                <SvgProxy selector="#subframe-to-cab-height" stroke={bodyType === 'unknown' ? '#fff' : '#000'} />

                <SvgProxy 
                    selector="#front-track" 
                    onElementSelected={(el: any) => updateElement(el, dimensions.tkf)} 
                />
                <SvgProxy 
                    selector="#bbc" 
                    onElementSelected={(el: any) => updateElement(el, dimensions.foh + dimensions.wb - dimensions.ca)} 
                />
                <SvgProxy 
                    selector="#ca" 
                    onElementSelected={(el: any) => updateElement(el,  dimensions.ca)} 
                />
                <SvgProxy 
                    selector="#roh" 
                    onElementSelected={(el: any) => updateElement(el, dimensions.roh)} 
                />
                <SvgProxy 
                    selector="#ras" 
                    onElementSelected={(el: any) => updateElement(el, dimensions.ras)} 
                />
                <SvgProxy 
                    selector="#hras" 
                    onElementSelected={(el: any) => updateElement(el, 0.5*dimensions.ras)} 
                />
                <SvgProxy 
                    selector="#foh" 
                    onElementSelected={(el: any) => updateElement(el, dimensions.foh)} 
                />
                <SvgProxy 
                    selector="#wb" 
                    onElementSelected={(el: any) => updateElement(el, dimensions.wb)} 
                />
                <SvgProxy 
                    selector="#wt" 
                    onElementSelected={(el: any) => updateElement(el, dimensions.twb)} 
                />
                <SvgProxy 
                    selector="#bl" 
                    onElementSelected={(el: any) => updateElement(el, dimensions.bl)} 
                />
                <SvgProxy 
                    selector="#subframe-to-cab-height" 
                    style={regulations.mass.twrW ? "fill-opacity:0" : "fill-opacity:1"}
                    fill="#eb445a" />
                {/*<SvgProxy 
                    selector="rect" 
                    style={bodyType === 'unknown' ? "fill-opacity:0" : "fill-opacity:1"}
                    fill="#fff" />
                <SvgProxy 
                    selector="#bl-arrow" 
                    style={bodyType === 'unknown' ? "fill-opacity:0" : "fill-opacity:1"}
                    fill="#fff" />
                <SvgProxy 
                    selector="#bl" 
                    style={bodyType === 'unknown' ? "fill-opacity:1" : "fill-opacity:0"}
                    stroke="#000"
                fill="#fff" />*/}
            </SvgLoader>

    );
}

export default CnvsDimensions;