import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { IonPage, IonText, IonButton, IonContent,  IonGrid, IonCol, IonRow, IonCardSubtitle, isPlatform } from '@ionic/react';
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from 'react-router-dom';
import packageInfo from '../../../package.json';

import * as ROUTES from '../../config';

import './Login.css';


const Login: React.FC<RouteComponentProps> = () => {

    const ios = isPlatform('ios');
    
    const { loginWithRedirect } = useAuth0();
    const [buttonText, setButtonText] = useState('Get Started');

    useEffect(() => {

        const doLogIn = async () => {

            const delay = 2000; //Wait 2 seconds before redirecting to login screen
            await new Promise(() => setTimeout(() => {
                loginWithRedirect(); //Redirect to Auth0
                setTimeout(() => setButtonText('Get Started'), 5000) //Wait 5 seconds before resetting button text to continue
                }, delay));
        };

        if(buttonText !== 'Get Started') doLogIn();


    },[buttonText, loginWithRedirect])

    return (

        <IonPage>

            <IonContent fullscreen className="ion-padding">

                <IonGrid>
                    <IonRow className="header">
                        <IonCol className="header-logo-img">
                            <IonCol className="header-logo">
                                <IonCol className="header-logo-text" />
                                <IonCol className="offline-img" style={ {visibility: 'hidden'} } />
                            </IonCol>
                        </IonCol>
                        <IonCol className="header-img"></IonCol>
                    </IonRow>

                    <IonRow className="ion-justify-content-center login">
                        <IonText className="ion-text-center login-title"><strong>Welcome</strong></IonText>
                        <IonText className="login-subtitle-black"><h5>Welcome to the <strong>Hino Truck Body Builders App</strong> | seeing <strong>Great Hino Trucking</strong> through till the end</h5></IonText>
                        
                        
                        <IonButton mode="ios" className="login-button" onClick={() => setButtonText('Please wait...')} disabled={buttonText !== 'Get Started'} >{buttonText}</IonButton>  
                        
                        
                        
                    </IonRow>

                    <IonRow className="ion-text-center ion-justify-content-center footer">
                        <IonCol size='12'>
                            <IonText>
                                <p>By using this app, you agree to <Link to={ROUTES.SETTINGS_LEGAL_TOS_PAGE_ROUTE}>Terms</Link> and <Link to={ROUTES.SETTINGS_LEGAL_PRIVACY_PAGE_ROUTE}>Privacy Policy</Link></p>
                            </IonText>
                        </IonCol>
                        <IonCol size='12'>
                            <IonCardSubtitle className="ion-no-padding ion-no-margin">Version {ios ? packageInfo.ios : packageInfo.android} ({ROUTES.BUILD_NUMBER})</IonCardSubtitle>
                        </IonCol>
                    </IonRow>

                </IonGrid>
                
            </IonContent>
        </IonPage>
    )
}

export default Login;
