import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

/** Auth Pages */
import Login from './pages/auth/Login';
import LoginCode from './pages/auth/LoginCode';
import LoginEmail from './pages/auth/LoginEmail';
import LoginAccountDeleted from './pages/auth/LoginAccountDeleted';

/** Legal Pages */
import TOS from './pages/settings/Tos';
import Privacy from './pages/settings/Privacy';
import TOSPDF from './pages/settings/TosPdf';


/** Route Constants */
import * as ROUTES from './config';


const PublicRoutes: React.FC = () => {

    return (
        <Switch>
            {/** Auth Routes */}
            <Route path={ROUTES.LOGIN_EMAIL_ROUTE} component={Login} exact={true} /> 
            <Route path={ROUTES.LOGIN_DIGITS_ROUTE} component={LoginEmail} exact={true} /> 
            <Route path={ROUTES.LOGIN_CODE_ROUTE} component={LoginCode} exact={true} /> 
            <Route path={ROUTES.LOGIN_ACCOUNT_DELETED_ROUTE} component={LoginAccountDeleted} exact={true} /> 

             {/** Legal Routes */}
            <Route path={ROUTES.SETTINGS_LEGAL_PRIVACY_PAGE_ROUTE} component={Privacy} exact={true} />
            <Route path={ROUTES.SETTINGS_LEGAL_TOS_PAGE_ROUTE} component={TOS} exact={true} />
            <Route path={ROUTES.SETTINGS_LEGAL_TOS_DOCUMENT_PAGE_ROUTE} component={TOSPDF} exact={true} />

            {/** Default Route */}
            <Route exact path="/" render={() => <Redirect to={ROUTES.LOGIN_EMAIL_ROUTE} />} />

            {/** Non-Existent Routes */}
                <Route path='*' render={() => <Redirect to={ROUTES.LOGIN_EMAIL_ROUTE} />} />
        </Switch> 

    );
}

export default PublicRoutes;