import React from 'react';
import { useLocation } from 'react-router';
import { IonContent, IonIcon, IonItem, IonLabel, IonList, IonCol,IonMenu, IonMenuToggle, IonText, isPlatform } from '@ionic/react';
import { homeOutline, logInOutline, logOutOutline, settingsOutline, chatboxOutline } from 'ionicons/icons';
import { useStoreState } from 'pullstate';
import { UserPreferences } from '../services/user';
import packageInfo from '../../package.json';
import hinoIcon from '../assets/images/hino-logo.svg';

import * as ROUTES from '../config';

import './Menu.css';


const routes = {
  appPages: [
    { title: 'Home', path: '/tabs/home', icon: homeOutline },
    /*{ title: 'Hino Models', path: '/calculations/trucks', icon: busOutline },
    { title: 'Hino Preferred Suppliers', path: '/tabs/preferred-suppliers', icon: constructOutline },
    { title: 'Hino Dealerships', path: '/dealerships', icon: cubeOutline },
    { title: 'Hino Design Guidelines', path: '/design-guidelines', icon: bookOutline }*/
  ],
  loggedInPages: [
    { title: 'Messages', path: '/tabs/notifications', icon: chatboxOutline },
    { title: 'Settings', path: '/settings', icon: settingsOutline },
    //{ title: 'Support', path: '/support', icon: helpCircleOutline },
    { title: 'Logout', path: '/logout', icon: logOutOutline }
  ],
  loggedOutPages: [
    { title: 'Login', path: '/login', icon: logInOutline },
    //{ title: 'Support', path: '/support', icon: helpCircleOutline }
  ]
};

interface Pages {
  title: string,
  path: string,
  icon: string,
  routerDirection?: string
}

const Menu: React.FC = () => {

  const ios = isPlatform('ios');
  const location = useLocation();

  const user = useStoreState(UserPreferences)
  const email = user.email;
  const fullname= user.fullname;

  const version = `${ios ? packageInfo.ios : packageInfo.android} Build ${ROUTES.BUILD_NUMBER}`

  
  function renderlistItems(list: Pages[]) {
    return list
      .filter(route => !!route.path)
      .map(p => (
        <IonMenuToggle key={p.title} auto-hide="false">
          <IonItem detail={false} routerLink={p.path} routerDirection="none" className={location.pathname.startsWith(p.path) ? 'selected' : undefined}>
            <IonIcon slot="start" icon={p.icon} />
            <IonLabel>{p.title}</IonLabel>
          </IonItem>
        </IonMenuToggle>
      ));
  }

  return (
    <IonMenu type="overlay" disabled={false} contentId="main"> {/* Todo: menu-disabled => true/false */}
      <IonContent forceOverscroll={false}>

        <IonList lines="none">
          <IonItem>
            <img src={hinoIcon} height='30px' style={ {aspectRatio: 'auto'} } alt="logo" />
          </IonItem>
          {
            email !== null && fullname !== null &&

            <IonItem lines="full">
              <IonCol className="ion-padding-start">
                <IonText color="light">{fullname}</IonText>
                <br/>
                <IonText className="small-text">{email}</IonText>
              </IonCol>
            </IonItem>
          }
        </IonList>

        <IonList lines="none">

          {renderlistItems(routes.appPages)}

          { email !== null && fullname !== null &&
            renderlistItems(routes.loggedInPages) }

          {  (email === null || fullname === null) &&
            renderlistItems(routes.loggedOutPages)}

          <IonItem className="ion-no-padding" lines="full"/>

        </IonList>

        {/*<IonList lines="none">
          { getCookie('accessToken') != null &&
            renderlistItems(routes.loggedInPages) }
          { getCookie('accessToken') == null &&
            renderlistItems(routes.loggedOutPages)}
        </IonList> */}

        <div className="ion-text-center ion-justify-content-center ion-margin-top ion-padding-top">
          <IonText className="ion-no-padding ion-no-margin small-text">
            <h5>Hino Truck Body Builders App</h5>
            <p>v{version}</p>
          </IonText>
        </div>

      </IonContent>
    </IonMenu>
  );
};

export default Menu;
