import { useState } from "react";
import { IonButton, IonButtons, IonIcon, IonItem, IonText } from "@ionic/react";
import { chevronDown, chevronUp } from "ionicons/icons";
import { HeightExternal, HeightSubframe, HeightSubstructure, LenghtExternal, LenghtHeadboard, 
    LenghtInternal, LenghtTailboard, WidthExternal } from "./Dimensions";
import { CalcValues } from "../../../../services/calculations";

interface FlatDeckProps {
    values: CalcValues;
    setValues: (v: CalcValues) => void;
}

const FlatDeck: React.FC<FlatDeckProps> = ({values, setValues}) => {

    const [showHeight, setShowHeight] = useState(false);
    const [showLength, setShowLength] = useState(false);
    const [showWidth, setShowWidth] = useState(false);

    return (

        <>
            <IonItem color="light">
                <IonText className="ion-padding">Length</IonText>
                <IonButtons slot="end">
                {
                    !showLength &&
                    <IonButton onClick={() => {
                        setShowWidth(false);
                        setShowHeight(false);
                        setShowLength(true);
                    }}>
                    <IonIcon icon={chevronDown} slot="icon-only" />
                    </IonButton>}
                {
                    showLength &&
                    <IonButton onClick={() => setShowLength(false)}>
                    <IonIcon icon={chevronUp} slot="icon-only" />
                    </IonButton>
                }
                </IonButtons>
            </IonItem>

            {
                showLength &&

                <>
                    <LenghtExternal title="Length (External)" description="Overall Body Length" values={values} setValues={setValues} />
                    <LenghtInternal title="Length (Internal)" description="Load compartment length" values={values} setValues={setValues} />
                    <LenghtHeadboard title="Headboard" description="Headboard length" values={values} setValues={setValues} />
                    <LenghtTailboard title="Tailboard" description="Tailboard length" values={values} setValues={setValues} />
                    
                </> 
            }

            <IonItem color="light">
                <IonText className="ion-padding">Height</IonText>
                <IonButtons slot="end">
                {
                    !showHeight &&
                    <IonButton onClick={() => {
                        setShowWidth(false);
                        setShowHeight(true);
                        setShowLength(false);
                    }}>
                    <IonIcon icon={chevronDown} slot="icon-only" />
                    </IonButton>}
                {
                    showHeight &&
                    <IonButton onClick={() => setShowHeight(false)}>
                    <IonIcon icon={chevronUp} slot="icon-only" />
                    </IonButton>
                }
                </IonButtons>
            </IonItem>

            {
                showHeight &&

                <>
                    <HeightSubframe title="Subframe Height" description="Subframe height" values={values} setValues={setValues} />
                    <HeightSubstructure title="Substructure Height" description="substructure height" values={values} setValues={setValues} />
                    <HeightExternal title="Headboard Height" description="Headboard height" values={values} setValues={setValues} />
                </>  
            }

            <IonItem color="light">
                <IonText className="ion-padding">Width</IonText>
                <IonButtons slot="end">
                {
                    !showWidth &&
                    <IonButton onClick={() => {
                        setShowWidth(true);
                        setShowHeight(false);
                        setShowLength(false);
                    }}>
                    <IonIcon icon={chevronDown} slot="icon-only" />
                    </IonButton>}
                {
                    showWidth &&
                    <IonButton onClick={() => setShowWidth(false)}>
                    <IonIcon icon={chevronUp} slot="icon-only" />
                    </IonButton>
                }
                </IonButtons>
            </IonItem>

            {
                showWidth &&

                <WidthExternal title="Width (External)" description="Overall body width" values={values} setValues={setValues}/>
            }

        </>
    );
}

export default FlatDeck;