import { Calculator, CalcValues } from "../services/calculations";

export interface Body {
  type: string | null;
  //material: string | null;
  length: BodyLength | null;
  height: BodyHeight | null;
  width: BodyWidth | null;
  mass: BodyMass | null;
}

export interface BodySpecifications {
  type: string;
  mass_floorConstructMass: string;
  mass_roofConstructMass: string;
  mass_guardframeMass: string;
  mass_sidegateMass: string;
  mass_tailgateMass: string;
  length_bodyLengthExternalInclRearDoors: string;
  length_bodyLengthInternal: string;
  length_tailgateLength: string;
  length_sidegateLength: string;
  length_breakpointForRearOverhangFromRearMostAxle: string;
  length_breakpointForWheelbaseFromRearMostAxleFront: string;
  length_bumperToStartOfBodyInternal: string;
  length_cabGapRecommendedMin: string;
  length_completeRearOverhang: string;
  length_guardframeLength: string;
  length_loadCompartmentLength: string;
  length_rearMostProtrudingItemBehindCabToRearAxles: string;
  length_rearOverhang: string;
  length_rearProtrusion: string;
  length_centreOfGravity: string;
  width_bodyWidthExternal: string;
  width_completeOverallBodyAndEquipmentWidth: string;
  width_leftBodyThickness: string;
  width_rightBodyThickness: string;
  width_loadCompartmentWidth: string;
  width_centreOfGravity: string;
  height_bodyHeightExternal: string;
  height_completeOverallBodyAndEquipmentHeight: string;
  height_floorHeight: string;
  height_roofHeight: string;
  height_loadCompartmentHeight: string;
  height_centreOfGravity: string;
  height_bodyPosition: string;
}

/*export interface BodySpecifications {
  bodyHeightExternal: string;
  bodyLengthExternalInclRearDoors: string;
  bodyLengthInternal: string;
  bodySpecificationID: string;
  bodySpecification: string;
  bodyType: string;
  breakpointForRearOverhangFromRearMostAxle: string;
  breakpointForWheelbaseFromRearMostAxleFront: string;
  bumperToStartOfBodyInternal: string;
  cabGapRecommendedMin: string;
  completeOverallBodyAndEquipmentHeight: string;
  completeRearOverhang: string;
  floorConstructMass: string;
  floorHeight: string;
  groundClearanceRear: string;
  guardframeLength: string;
  guardframeMass: string;
  loadCompartmentHeight: string;
  loadCompartmentLength: string;
  rearMostProtrudingItemBehindCabToRearAxles: string;
  rearOverhang: string;
  rearProtrusion: string;
  sidegateLength: string;
  sidegateMass: string;
  tailgateLength: string;
}*/

export class BodyLength {
    
  constructor (values: CalcValues) {

    const { dimensions: d } = Calculator(values);

    this.bodyLengthExternalInclRearDoors = d.bl.toString();
    this.bodyLengthInternal = d.ibl.toString();
    this.breakpointForRearOverhangFromRearMostAxle = "0";
    this.breakpointForWheelbaseFromRearMostAxleFront = "0";
    this.bumperToStartOfBodyInternal = "0";
    this.cabGapRecommendedMin = d.gap.toString();
    this.completeRearOverhang = d.croh.toString();
    this.loadCompartmentLength = d.ibl.toString();
    this.rearOverhang = d.roh.toString();
    this.centreOfGravity = d.hcog.toString();
    this.guardframeLength = d.hb.toString();
    this.tailgateLength = d.tb.toString();
  };

  bodyLengthExternalInclRearDoors: string = "0";
  bodyLengthInternal: string = "0";
  tailgateLength: string = "50";
  sidegateLength: string = "0";
  breakpointForRearOverhangFromRearMostAxle: string = "0";
  breakpointForWheelbaseFromRearMostAxleFront: string = "0";
  bumperToStartOfBodyInternal: string = "0";
  cabGapRecommendedMin: string = "0";
  completeRearOverhang: string = "0";
  guardframeLength: string = "50";
  loadCompartmentLength: string = "0";
  rearMostProtrudingItemBehindCabToRearAxles: string = "0";
  rearOverhang: string = "0";
  rearProtrusion: string = "0";
  centreOfGravity: string = "0";
 }

export class BodyMass {

  constructor (values: CalcValues) {

    const { ...b } = Calculator(values);

    this.totalMass = b.mass.bwt.toString();
    this.material = b.bodyMaterial.toString();

  };
  floorConstructMass: string = "0";
  roofConstructMass: string = "0";
  guardframeMass: string = "0";
  sidegateMass: string = "0";
  tailgateMass: string = "0";
  material: string = "Steel";
  totalMass: string = "0";
  udl: string = "0";
  mode: 'mass' | 'udl' = 'udl';
}


export class BodyWidth {

  constructor (values: CalcValues) {

    const { dimensions: d } = Calculator(values);

    this.bodyWidthExternal = d.bw.toString();
    this.loadCompartmentWidth = d.ibw.toString();
    this.completeOverallBodyAndEquipmentWidth = d.bw.toString();
    this.centreOfGravity = d.lcog.toString();
  };

  bodyWidthExternal: string = "0";
  completeOverallBodyAndEquipmentWidth: string = "0";
  leftBodyThickness: string = "50";
  rightBodyThickness: string = "50";
  loadCompartmentWidth: string = "0";
  centreOfGravity: string = "0";
 }

 export class BodyHeight {

  constructor (values: CalcValues) {

    const { dimensions: d } = Calculator(values);

    this.bodyHeightExternal = d.bh.toString();
    this.loadCompartmentHeight = d.ibh.toString();
    this.completeOverallBodyAndEquipmentHeight = d.bh.toString();
    this.centreOfGravity = d.vcog.toString();
    this.floorHeight = d.ssh.toString();
    this.subFrameHeight = d.sfh.toString();
  };
  bodyHeightExternal: string = "0";
  completeOverallBodyAndEquipmentHeight: string = "0";
  floorHeight: string = "100";
  roofHeight: string = "50";
  subFrameHeight: string = "150";
  loadCompartmentHeight: string = "0";
  centreOfGravity: string = "0";
  bodyPosition: string = "0";
 }

 /** Default */
 export class DefaultBodySpec {

  /** Van */
  van = {  mass:  [ {material: "Aluminium" , value: "200"},
                    {material: "Steel" , value: "280"},
                    {material: "Fibreglass (Chiller)" , value: "320"},
                    {material: "Fibreglass (Dry Freight)" , value: "300"},
                    {material: "Fibreglass (Freezer)" , value: "340"},
                    {material: "Fibreglass (Meat Hanger)" , value: "450"},
                    {material: "Steel Floor & Aluminium Body" , value: "240"},
                    {material: "Aluminium (Heavy Duty)" , value: "240"},
                    {material: "Steel (Heavy Duty)" , value: "320"},
                    {material: "Fibreglass (Chiller, Heavy Duty)" , value: "360"},
                    {material: "Fibreglass (Dry Freight, Heavy Duty)" , value: "340"},
                    {material: "Fibreglass (Freezer, Heavy Duty)" , value: "380"},
                    {material: "Fibreglass (Meat Hanger, Heavy Duty)" , value: "490"},
                    {material: "Steel Floor & Aluminium Body (Heavy Duty)" , value: "280"}],
            lcog: "50", hcog: "50", vcog: "33", defaultHeight: "2100"};

  /** Tautliner */
  tautliner = { mass: [{material: "Steel", floorType: "Standard", value: "300"},
                       {material: "Aluminium", value: "260"},
                       {material: "Aluminium with Heavy Duty Floor", value: "300"},
                       {material: "Steel with Heavy Duty Floor", value: "340"}], 
                lcog: "50", hcog: "50", vcog: "30", defaultHeight: "2100"};
  
  /** Tanker */
  tanker = {  mass: [{material: "Steel" , value: "350"}, 
                     {material: "Aluminium", value: "260"}], 
              lcog: "50", hcog: "50", vcog: "45", headboardLenght: "200", tailboardLenght: "200"};

  /** Unknown */
  unknown = { mass: [{material: "Steel" , value: "0"}], lcog: "50", hcog: "0", vcog: "0", defaultHeight: "0"};

  /**Compactor */
  compactor = { mass: [{material: "Steel" , value: "950"}], lcog: "50", hcog: "47", vcog: "30"};
  /** Tipper */
  tipper = {  mass: [{material: "Steel" , value: "600"},
                     {material: "Steel with Heavy Duty Floor" , value: "640"},
                     {material: "Aluminium" , value: "380"},
                     {material: "Aluminium with Heavy Duty Floor" , value: "420"}],
              lcog: "50", hcog: "52", vcog: "35"};

  /** Dropside */
  dropside = {  mass: [{material: "Steel" , value: "220"},
                       {material: "Steel with Heavy Duty Floor" , value: "260"},
                       {material: "Aluminium" , value: "140"},
                       {material: "Aluminium with Heavy Duty Floor" , value: "180"},], 
                lcog: "50", hcog: "50", vcog: "25", defaultHeight: "480", headboardHeight: "1200"};

  /** Flatdeck */
  flatdeck = {  mass: [{material: "Steel" , value: "200"},
                       {material: "Steel with Heavy Duty Floor" , value: "240"},
                       {material: "Aluminium" , value: "120"},
                       {material: "Aluminium with Heavy Duty Floor" , value: "160"}], 
                lcog: "50", hcog: "50", vcog: "15", defaultHeight: "1200", tailboardHeight: "0"};

  /** Rollback */
  rollback = {  mass:  [{material: "Steel" , value: "425"}], lcog: "50", hcog: "50", vcog: "10"};

  /** Timber */
  timber = {  mass: [{material: "Steel" , value: "220"}], lcog: "50", hcog: "50", vcog: "20"}; //mass[0]value: 330?

  /** Livestock Carrier */
  livestock = {  mass: [{material: "Steel" , value: "450"},
                        {material: "Aluminium" , value: "320"}], 
                 lcog: "50", hcog: "50", vcog: "33"};
  
  /** Hooklift */
  hooklift = {  mass: [{material: "Steel" , value: "625"}], lcog: "50", hcog: "34", vcog: "10"};

  /** Beavertail */
  beavertail = {  mass: [{material: "Steel" , value: "600"}], lcog: "50", hcog: "50", vcog: "35"};

 }


