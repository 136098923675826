import React from 'react';
import { IonList } from '@ionic/react';
 
import CalcGVM from './CalcGVM';
import CalcDimensions from './CalcDimensions';
import { CalcValues } from '../../../services/calculations';
import { CnvsBridge, CnvsCircle, CnvsDimensions } from '../../canvas';

interface CalcTableProps {
    listType: 'gvm' | 'dimensions' | 'turn-circle'| 'bridge';
    show: boolean;
    view: 'front' | 'rear' | 'side'| 'top';
    values: CalcValues;
    slider: {cog: 'side' | 'top' | 'front' | 'rear' | 'payload', show: boolean, setValues : (values: CalcValues) => void};
}

const CalcTable: React.FC<CalcTableProps> = ({listType, show, view, values, slider }) => {

    if(listType === "gvm") {

        return (
            <IonList className="c-wrapper" style={show ? {} : {display: 'none'}}>
                <CalcGVM values={values}/> 
            </IonList>
        );
    }

    if(listType === "dimensions") {

        return (
            <IonList className="c-wrapper" style={show ? {} : {display: 'none'}}>
                <CalcDimensions slider={slider} values={values} view={view}/> 
            </IonList>
        );
    }

    if(listType === "bridge") {

        return (
            <IonList className="c-wrapper" style={show ? {} : {display: 'none'}}>
               <CnvsBridge values={values} view={view}/>
            </IonList>
        );
    }

    if(listType === "turn-circle") {

        return (
            <IonList className="c-wrapper" style={show ? {} : {display: 'none'}}>
                <CnvsCircle values={values}/>
            </IonList>
        );
    }

    return (

        <IonList style={ {display: 'none'}}>
            <CalcGVM values={values}/>
        </IonList> 
    );
}

export default CalcTable;

