import { useRef } from 'react';
import { IonContent,  IonCol, IonPage, IonHeader, IonToolbar, IonTitle, IonRow, IonCard, IonCardContent, IonIcon, IonGrid, IonCardTitle, IonButton, IonButtons, IonItem, IonList } from '@ionic/react';

import * as ROUTES from '../config';

import './Guidelines.css';
import { bookOutline, close, /* alertCircleOutline, batteryChargingOutline */ } from 'ionicons/icons';

const Guidelines: React.FC = () => {
  
  const pageRef = useRef<HTMLElement>(null);
  
  return (
    <IonPage ref={pageRef} id="guidelines-page">

      <IonHeader>
        <IonToolbar mode="ios" color="toolbar">
          <IonToolbar color="toolbar">
            <IonButtons slot="start">
              <IonButton routerLink={ROUTES.TABS_HOME_PAGE_ROUTE}>
              <IonIcon icon={close} /></IonButton>
            </IonButtons>
            <IonTitle><strong>Hino Body Mounting Guidelines</strong></IonTitle>
          </IonToolbar>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonGrid>
          <IonRow className={ `animate__animated animate__faster animate__fadeIn` }>
            <IonCol size="12">
              <IonCard mode="ios">
                <IonCardContent>
                  <IonIcon icon={ bookOutline } color="primary" style={{ fontSize: "2rem" }} />
                  <IonCardTitle>Hino Body Mounting Guidelines</IonCardTitle>
                  <p>General Precautions, Electrical Systems and AARTO Regulations</p>

                  <IonList lines="full">

                    <IonCol sizeXs='12' sizeSm='12' sizeMd='12' sizeLg='6' sizeXl='6' className="ion-no-padding ion-no-margin">
                      <IonItem detail={false} className='ion-padding ion-margin' routerLink={ROUTES.PRECAUTIONS_PAGE_ROUTE}>
                        <h2 className="ion-justify-content-center"><strong>General Precautions &rarr;</strong></h2>
                      </IonItem>
                    </IonCol>

                    <IonCol sizeXs='12' sizeSm='12' sizeMd='12' sizeLg='6' sizeXl='6' className="ion-no-padding ion-no-margin">
                      <IonItem detail={false} className='ion-padding ion-margin' routerLink={ROUTES.ELECTRICAL_SYSTEMS_PAGE_ROUTE}>
                        <h2 className="ion-justify-content-center"><strong>Electrical Systems &rarr;</strong></h2>
                      </IonItem>
                    </IonCol>
                    <IonCol sizeXs='12' sizeSm='12' sizeMd='12' sizeLg='6' sizeXl='6' className="ion-no-padding ion-no-margin">
                      <IonItem detail={false} className='ion-padding ion-margin' routerLink={ROUTES.AARTO_REGULATIONS_PAGE_ROUTE}>
                        <h2 className="ion-justify-content-center"><strong>AARTO Regulations &rarr;</strong></h2>
                      </IonItem>
                    </IonCol>

                  </IonList>
                </IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>
        
        </IonGrid>


       
      </IonContent>
    </IonPage>
  );
};

export default Guidelines;
