import { useEffect, useRef, useState } from 'react';
import { IonContent,  IonLabel, IonPage, IonItem, IonHeader, IonToolbar, IonButtons, IonButton, IonIcon, isPlatform, IonItemDivider, IonChip, IonText, IonList, IonGrid, IonCol, IonRow, IonItemSliding, IonTitle, IonModal, IonCard, IonCardHeader, IonCardTitle, IonSegment, IonSegmentButton } from '@ionic/react';
import { options, close, arrowDown, arrowUp, filter } from 'ionicons/icons';
import Skeleton from 'react-loading-skeleton';
import { useStoreState } from 'pullstate';
import { getUser, UserPreferences } from '../services/user';
import DealerItem from '../components/Leads/DealerItem';
import { useFetchDataFromDB } from '../services/DataApi';
import LeadStore from '../services/leads';
import { HinoDealership, dealerlist } from '../models/Dealership';
import { formatTel, useFetchDealerships } from '../services/dealerships';
import { genericFilter, genericSearch, genericSort, IFilter, ISorter } from '../utils';
import SearchInput from '../components/SearchInput';
import SubmitToDealer from '../components/Leads/SubmitToDealer';

import * as ROUTES from '../config';

import './Dealerships.css';
import 'react-loading-skeleton/dist/skeleton.css';


const Dealerships: React.FC = () => {
  
  const pageRef = useRef<HTMLElement>(null);
  const ios = isPlatform('ios');
  const user = useStoreState(UserPreferences, getUser);
  const isBodyBuilder = user.role.includes('Hino Preferred Supplier');

  const { data, isLoading } = useFetchDataFromDB('dealerships', ROUTES.DEALERSHIPS_API_ENDPOINT);
  const { data: dealersFromWebsite, error, isLoading: isLoadingDealersFromWebsite } = useFetchDealerships();

  const [query, setQuery] = useState<string>("");
  const [showFilter, setShowFilter] = useState(false);
  const [showCities, setShowCities] = useState(false);
  const [showProvinces, setShowProvinces] = useState(false);
  const [showSort, setShowSort] = useState(false);
  const [segment, setSegment] = useState<'salespersons' | 'dealerships'>('salespersons');

  const [showSubmitToDealerModal, setShowSubmitToDealerModal] = useState(false);
  const [dealership, setDealership] = useState<HinoDealership | null>(null);

  const [sortProperty, setSortProperty] = useState<keyof HinoDealership>(segment === 'salespersons' ? "SalesPerson" : 'name');
  const [activeSorter, setActiveSorter] = useState<ISorter<HinoDealership>>({
    property: sortProperty,
    isDescending: false,
  });
  const [activeFilters, setActiveFilters] = useState<Array<IFilter<HinoDealership>>>(
    []
  );

  const [showAZ, setShowAZ] = useState(activeSorter.isDescending);
  const [dealerships, setDealerships] = useState<Array<HinoDealership>>([]);
  const [formattedDealershipsFromWebsite, setFormattedDealershipsFromWebsite] = useState<Array<HinoDealership>>([]);
  const [cities, setCities] = useState<Array<string>>([]);
  const [provinces, setProvinces] = useState<Array<string>>([]);

  useEffect(() => {

    if (!isLoading) {

      //Salespersons
      const d: HinoDealership[] = isBodyBuilder ? (data as unknown as HinoDealership[]) : (dealerlist as unknown as HinoDealership[]); //If HPS, fetch only dealerships registered on the App, else fetch all dealerships
      const q = d.filter((dealership: HinoDealership) =>
          genericSearch<HinoDealership>(dealership, ['Name','Region', 'Town', 'Address', 'SalesPerson'], query))
        .filter((dealership: HinoDealership) => 
          genericFilter<HinoDealership>(dealership, activeFilters))
        .sort((dealershipA: HinoDealership, dealershipB: HinoDealership) =>
          genericSort<HinoDealership>(dealershipA, dealershipB, activeSorter) 
        );
      
      //Dealerships from Website
      const dfw: HinoDealership[] = !error && !isLoadingDealersFromWebsite ? (dealersFromWebsite as unknown as HinoDealership[]) : (dealerlist as unknown as HinoDealership[]); 
      const s = dfw.filter((dealership: HinoDealership) =>
          genericSearch<HinoDealership>(dealership, ['name','province', 'city', 'streetAddress'], query))
        .filter((dealership: HinoDealership) => 
          genericFilter<HinoDealership>(dealership, activeFilters))
        .sort((dealershipA: HinoDealership, dealershipB: HinoDealership) =>
          genericSort<HinoDealership>(dealershipA, dealershipB, activeSorter) 
        );

      const c = Array.from(new Set(q.map((o: HinoDealership) => o.Town.charAt(0))));
      const p = Array.from(new Set(q.map((o: HinoDealership) => o.Region.charAt(0))));

      setDealerships(q);
      setCities(c);
      setProvinces(p);
      setFormattedDealershipsFromWebsite(s);

    }
  }, [query, data, activeFilters, activeSorter, isLoading, dealersFromWebsite, error, isLoadingDealersFromWebsite, isBodyBuilder]);

  useEffect(() => {
    setActiveSorter({
      property: sortProperty,
      isDescending: showAZ
    })
  }, [showAZ, sortProperty]);

  let ionLoadingCards: any = [], ionLoadingItems: any = [];

  for (let i: number = 0; i < 30; i++) {

    ionLoadingCards.push(


      <IonCol size="12" sizeSm="6" sizeMd="6" sizeLg="4" sizeXl="4" key={`dealership-${i}`}>
        
        <IonCard  mode="ios">

          <IonCardHeader>
            <IonCardTitle color="light">
              <h2><strong><Skeleton /></strong></h2>
              <p><strong><Skeleton /></strong></p>

            </IonCardTitle>

          </IonCardHeader>

          <IonRow className="ion-no-padding justify-content-center">
      
            <IonCol size="6" className='ion-justify-content-end'>
                
            </IonCol>
        
            <IonCol size="6">
                <IonButton fill="clear" size="small">
                  &nbsp;<strong><Skeleton /></strong>&nbsp;
                </IonButton>
            </IonCol>
          </IonRow>

        </IonCard>
      
      </IonCol>

    )

    ionLoadingItems.push(
      <IonCol size="12" sizeSm="6" sizeMd="6" sizeLg="4" sizeXl="3" key={i}>
        <IonItemSliding key={i} className="calculations-item">
          <IonItem>
            <IonLabel>
              <h3><strong><Skeleton/></strong></h3>
              <h5><Skeleton/></h5>
              <h6>
                <Skeleton/>
              </h6>
              <p><Skeleton/></p>
            </IonLabel>
          </IonItem>
        </IonItemSliding>
      </IonCol>
    )
  }

  return (
    <IonPage ref={pageRef} id="dealerships-page">

      <IonHeader>

          <IonToolbar color="toolbar">
            <IonTitle><strong>Hino Dealerships</strong></IonTitle>
          </IonToolbar>

        {
          !ios &&
          
          <IonToolbar mode="ios" className="ion-padding-top">
            <SearchInput onChangeSearchQuery={(query) => setQuery(query)} />
            <IonButtons slot="end">
              {
                !showFilter &&
                <IonButton color="dark" onClick={() => setShowFilter(true)}>
                  <IonIcon icon={options} slot="icon-only" />
              </IonButton>}
              {
                showFilter &&
                <IonButton color="dark" onClick={() => setShowFilter(false)}>
                  <IonIcon icon={close} slot="icon-only" />
                </IonButton>
              }
            </IonButtons>
          </IonToolbar>
        
        }

        {
          !ios && showFilter &&
    
          <IonToolbar mode="ios">
            <IonItemDivider color='light'>
              <IonChip 
                disabled
                color={showCities ? "medium" : "primary"}
                slot="start"
                onClick={() => {
                  setShowCities(true);
                  setShowProvinces(false);
                  setShowSort(false);
                }}>Cities
                </IonChip>
              <IonChip
                disabled 
                color={showProvinces ? "medium" : "primary"}
                slot="start"
                onClick={() => {
                  setShowProvinces(true);
                  setShowCities(false);
                  setShowSort(false);
                }}>Provinces
              </IonChip>
              
              <IonButton 
                slot="end"
                color={showSort ? "medium" : "dark"}
                disabled={showSort}
                onClick={() => {
                  setShowProvinces(false);
                  setShowCities(false);
                  setShowSort(true);
                }}><IonIcon icon={filter} slot="icon-only" />
              </IonButton>

            </IonItemDivider>

            {
              showCities && 

              <IonItemDivider color="light">

                {
                  cities.map(c => 

                    <IonChip 
                      disabled
                      color="success" 
                      key={c}
                      onClick={() => {
                        setActiveFilters([
                          ...activeFilters.filter(
                            (filter) => filter.property !== 'Town'
                          ),
                          { property: 'Town', isTruthyPicked: true },
                        ]);
                      }}
                      >{c}
                    </IonChip>
                  )
                }

              </IonItemDivider>
            }

            {
              showSort &&

              <IonItemDivider color='light'>
                <IonChip
                  disabled={sortProperty === 'Name'}
                  onClick={() => setSortProperty(segment === 'salespersons' ? 'SalesPerson' : 'name')} 
                  color={sortProperty === 'SalesPerson' || sortProperty === 'name'  ? "medium" : "success"}
                  slot="start">Name
                </IonChip>

                <IonChip
                  disabled={sortProperty === 'Region'}
                  onClick={() => setSortProperty(segment === 'salespersons' ? 'Region' : 'province')}
                  color={sortProperty === 'Region' || sortProperty === 'province' ? "medium" : "success"}
                  slot="start">Region
                </IonChip>

                <IonChip
                  disabled={sortProperty === 'Town'}
                  onClick={() => setSortProperty(segment === 'salespersons' ? 'Town' : 'city')}
                  color={sortProperty === 'Town' || sortProperty === 'city' ? "medium" : "success"}
                  slot="start">Town
                </IonChip>

                <IonButtons slot="end">
                  {
                    showAZ &&
                    <IonButton color="dark" onClick={() => setShowAZ(false)}>
                    {ios ? <p>A-Z</p> : <IonIcon icon={arrowDown} slot="icon-only" />}
                    </IonButton>
                  }

                  {
                    !showAZ &&
                    <IonButton color="dark" onClick={() => setShowAZ(true)}>
                    {ios ? <p>Z-A</p> : <IonIcon icon={arrowUp} slot="icon-only" />}
                    </IonButton>
                  }
                
                </IonButtons>
              </IonItemDivider>
            }
          </IonToolbar>
        }

        {
          !ios &&
          <IonToolbar className="ion-justify-content-center">
            <IonSegment value={segment} scrollable={true} onIonChange={(e) => setSegment(e.detail.value as any)}>

              <IonSegmentButton value="salespersons">
                <IonLabel>Salespersons</IonLabel> 
              </IonSegmentButton>

              <IonSegmentButton value="dealerships">
                <IonLabel>Dealerships</IonLabel>
              </IonSegmentButton>

            </IonSegment>
          </IonToolbar>
        }
 
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Dealerships</IonTitle>
          </IonToolbar>

          <IonToolbar className="ion-padding-top">
            <SearchInput onChangeSearchQuery={(query) => setQuery(query)} />
            <IonButtons slot="end">
              {
                !showFilter &&
                <IonButton color="dark" onClick={() => setShowFilter(true)}>
                  Filter
              </IonButton>}
              {
                showFilter &&
                <IonButton color="dark" onClick={() => setShowFilter(false)}>
                  Close
                </IonButton>
              }
            </IonButtons>
          </IonToolbar>
          
          {
            showFilter &&
      
            <IonToolbar mode="ios">
              <IonItemDivider color='light'>
                <IonChip 
                  disabled
                  color={showCities ? "medium" : "primary"}
                  slot="start"
                  onClick={() => {
                    setShowCities(true);
                    setShowProvinces(false);
                    setShowSort(false);
                  }}>Cities
                  </IonChip>
                <IonChip 
                  disabled
                  color={showProvinces ? "medium" : "primary"}
                  slot="start"
                  onClick={() => {
                    setShowProvinces(true);
                    setShowCities(false);
                    setShowSort(false);
                  }}>Provinces
                </IonChip>
                
                <IonButton 
                  slot="end"
                  color={showSort ? "medium" : "dark"}
                  disabled={showSort}
                  onClick={() => {
                    setShowProvinces(false);
                    setShowCities(false);
                    setShowSort(true);
                  }}>{ios ? 'Sort' : <IonIcon icon={filter} slot="icon-only" />}
                </IonButton>

              </IonItemDivider>

              {
                showCities && 

                <IonItemDivider color="light">

                  {
                    cities.map(c => 

                      <IonChip 
                        color="success" 
                        key={c}
                        onClick={() => {
                          setActiveFilters([
                            ...activeFilters.filter(
                              (filter) => filter.property !== 'Town'
                            ),
                            { property: 'Town', isTruthyPicked: true },
                          ]);
                        }}
                        >{c}
                      </IonChip>
                    )
                  }

                </IonItemDivider>
              }

              {
                showSort &&

                <IonItemDivider color='light'>
                  <IonChip
                    disabled={sortProperty === 'Name'}
                    onClick={() => setSortProperty(segment === 'salespersons' ? 'SalesPerson' : 'name')} 
                    color={sortProperty === 'SalesPerson' || sortProperty === 'name'  ? "medium" : "success"}
                    slot="start">Name
                  </IonChip>

                  <IonChip
                    disabled={sortProperty === 'Region'}
                    onClick={() => setSortProperty(segment === 'salespersons' ? 'Region' : 'province')}
                    color={sortProperty === 'Region' || sortProperty === 'province' ? "medium" : "success"}
                    slot="start">Region
                  </IonChip>

                  <IonChip
                    disabled={sortProperty === 'Town'}
                    onClick={() => setSortProperty(segment === 'salespersons' ? 'Town' : 'city')}
                    color={sortProperty === 'Town' || sortProperty === 'city' ? "medium" : "success"}
                    slot="start">Town
                  </IonChip>

                  <IonButtons slot="end">
                    {
                      showAZ &&
                      <IonButton color="dark" onClick={() => setShowAZ(false)}>
                        <p>A-Z</p>
                      </IonButton>
                    }

                    {
                      !showAZ &&
                      <IonButton color="dark" onClick={() => setShowAZ(true)}>
                        <p>Z-A</p>
                      </IonButton>
                    }
                  
                  </IonButtons>
                </IonItemDivider>
              }
            </IonToolbar>
          }
          <IonToolbar className="ion-justify-content-center">
            <IonSegment value={segment} scrollable={true} onIonChange={(e) => setSegment(e.detail.value as any)}>

              <IonSegmentButton value="salespersons">
                <IonLabel>Salespersons</IonLabel> 
              </IonSegmentButton>

              <IonSegmentButton value="dealerships">
                <IonLabel>Dealerships</IonLabel>
              </IonSegmentButton>

            </IonSegment>
          </IonToolbar>

        </IonHeader>

        {
          isLoading && segment === 'salespersons' &&
          
          <IonList>
            <IonGrid className="d-wrapper">
              <IonRow>{ionLoadingCards}</IonRow>
            </IonGrid>
          </IonList>
        }

        {
          isLoadingDealersFromWebsite && segment === 'dealerships' &&

          <IonList>
            <IonGrid className="d-wrapper">
              <IonRow>{ionLoadingItems}</IonRow>
            </IonGrid>
          </IonList>
        }
 
        {dealerships.length === 0 && !isLoading && segment === 'salespersons' &&
        
          <IonList className="ion-padding">
            <IonRow className="ion-text-center ion-justify-content-center ion-margin-top">
              <IonCol size="10">
                <lottie-player src={ROUTES.CONTENT_NOT_FOUND} mode="bounce" background="transparent" speed="0.8" loop autoplay></lottie-player>
              </IonCol>
              <IonCol size="10">
                  <IonLabel>
                    {
                      query === "" ?
                      <>
                        <h2><strong>Error: Failed to load Dealerships</strong></h2>
                        <p>{'Please reload the page and make sure you are connected to the internet. If the problem persists, please contact support'}</p>
                      </>
                      :
                      <>
                        <h2><strong>Dealership(s) not found</strong></h2>
                        <p>{'Please search for different dealership(s)'}</p>
                      </>
                    }    
                    
                  </IonLabel>
              </IonCol>
            </IonRow>
          </IonList>
        }

        {
          segment === 'salespersons' && !isLoading && dealerships.length > 0 && (

            <IonGrid className="d-wrapper">

              <IonRow>
              {
                dealerships.map((d: HinoDealership, index: number) => (

                  <IonCol size="12" sizeSm="6" sizeMd="6" sizeLg="4" sizeXl="4" key={`dealership-${isBodyBuilder ? d.id : d.DealerCode}-${index}`}>

                    <IonItemSliding>
                      {!isBodyBuilder &&
                      <IonItem
                        mode="ios" 
                        key={isBodyBuilder ? d.id : d.DealerCode} 
                        className="dealerships-item" 
                        detail={true}
                        routerLink={`/dealerships/${encodeURIComponent(btoa(isBodyBuilder ? d.id : d.DealerCode)).toLowerCase()}`}
                        onClick={() => LeadStore.update(s => {s.dealership = d})}>
                        <IonLabel>
                          <h2><strong>{isBodyBuilder ? d.SalesPerson : d.Name}</strong></h2>
                          <IonText><h3>{isBodyBuilder ? d.Name : d.IntroCopy}</h3></IonText>
                          <p>{`${d.Town}, ${d.Region}`}</p>
                          {isBodyBuilder ? "" : <IonText><h6>{`Tel: ${formatTel(d.Region, d.Telephone)}`}</h6></IonText>  }
                        </IonLabel>
                      </IonItem>
                      }

                      {
                        isBodyBuilder && 
        
                          <DealerItem
                            key={d.id}
                            dealer={d}
                            salesperson={d}
                          />
                      }

                    </IonItemSliding>
                  </IonCol>

                ))
              }
              </IonRow>

            </IonGrid>
          ) // Salespersons
        }

        {formattedDealershipsFromWebsite.length === 0 && !isLoadingDealersFromWebsite && segment === 'dealerships' &&
        
        <IonList className="ion-padding">
          <IonRow className="ion-text-center ion-justify-content-center ion-margin-top">
            <IonCol size="10">
              <lottie-player src={ROUTES.CONTENT_NOT_FOUND} mode="bounce" background="transparent" speed="0.8" loop autoplay></lottie-player>
            </IonCol>
            <IonCol size="10">
                <IonLabel>
                  {
                    query === "" ?
                    <>
                      <h2><strong>Error: Failed to load Dealerships</strong></h2>
                      <p>{'Please reload the page and make sure you are connected to the internet. If the problem persists, please contact support'}</p>
                    </>
                    :
                    <>
                      <h2><strong>Dealership(s) not found</strong></h2>
                      <p>{'Please search for different dealership(s)'}</p>
                    </>
                  }    
                  
                </IonLabel>
            </IonCol>
          </IonRow>
        </IonList>
      
      }

        {
          segment === 'dealerships' && !isLoadingDealersFromWebsite && formattedDealershipsFromWebsite.length > 0 && (

            <IonGrid className="d-wrapper">

              <IonRow>
              {
                formattedDealershipsFromWebsite.map((d: HinoDealership, index: number) => (


                  <IonCol size="12" sizeSm="6" sizeMd="6" sizeLg="4" sizeXl="4" key={`dealership-${d.supplierCode}-${index}`}>

                    <IonItem
                      mode="ios" 
                      key={d.DealerCode} 
                      className="dealerships-item" 
                      routerLink={`/dealerships/${encodeURIComponent(d.supplierCode).toLowerCase()}`}
                      onClick={() => LeadStore.update(s => {s.dealership = d})}
                      detail={true}>
                      <IonLabel>
                        <h2><strong>{d.name}</strong></h2>
                        <IonText><h3>{d.IntroCopy}</h3></IonText>
                        <p>{d.streetAddress}</p>
                        <p>{`${d.city}, ${d.province}`}</p>
                        <IonText><h6>{`Tel: ${d.telephone}`}</h6></IonText> 
                      </IonLabel>
                    </IonItem>
                    
                  </IonCol>

                ))
              }
              </IonRow>

            </IonGrid>
          ) // AllDealerships
        }

        <IonModal
          mode="ios"
          isOpen={showSubmitToDealerModal}
          onDidDismiss={() => setShowSubmitToDealerModal(false)}
          swipeToClose={true}> 
            <SubmitToDealer 
              onDismissModal={() => setShowSubmitToDealerModal(false)} 
              route={ROUTES.TABS_DEALERSHIPS_LIST_PAGE_ROUTE}
							values={dealership}/>
        </IonModal>

      </IonContent>

    </IonPage>
  );

};

export default Dealerships;
