import { createSelector } from 'reselect';
import { Buffer } from 'buffer';
import * as CryptoJS from 'crypto-js';

import { Preferences } from "../../models/Preferences";
import { UserPreferencesModel } from "../../models/User";
import UserPreferences from './store';


const getState = (state: UserPreferencesModel) => state;

/** Setters */
export const setUser = (email: string, username: string, role: Array<string>, org: string | null, accessToken: string | undefined, sub: string | undefined) => {

    UserPreferences.update((s: UserPreferencesModel) => {

        //email.substring(0, email.lastIndexOf("@"))

        if (username !== null) {
            s.initials = initialiseUserName(username);
            s.fullname = username.includes('@') ? s.initials : username; //If email used as username, initialise email address
            s.accessToken = accessToken;
        };

        if (email !== null) {
            s.email = email;
            s.userid = sub || Buffer.from(`${email}`).toString('base64');
        }

        if (org !== null) {
            s.org = org;
        }

        s.isDealership = true;

        const r = role ?? [];

        if (r.length > 0) {
            s.isDealership = r.includes('Hino Dealership');
            s.role = r;
        }
    })
}


export const setPreferences = (preferences: Preferences) => {
    UserPreferences.update((s: UserPreferencesModel) => { s.preferences = preferences })
}

export const setNotifications = (n: boolean) => {
    UserPreferences.update((s: UserPreferencesModel) => { s.enableNotifications = n })
}

export const setTheme = (t: boolean) => {
    UserPreferences.update((s: UserPreferencesModel) => { s.enableHinoBlackTheme = t })
}

export const setRoute = (r: string) => {
    UserPreferences.update((s: UserPreferencesModel) => { s.route = r })
}


/** Getters */
export const getPreferences = createSelector(getState, state => state.preferences);
export const getUser = createSelector(getState, state => state); 


/** Helper Function: Returns User Intials */
export const initialiseUserName = (fullName: string) => {
    const allNames = fullName.trim().split(' ');
    const initials = allNames.reduce((acc, curr, index) => {
      if(index === 0 || index === allNames.length - 1){
        acc = `${acc}${curr.charAt(0).toUpperCase()}`;
      }
      return acc;
    }, '');
    return initials;
}

/** Function: Encrypt Data */
export const encryptData = (userid: string, message: string) => {
    const secret = userid;
    const hmac = CryptoJS.algo.HMAC.create(CryptoJS.algo.SHA256, secret).update(message);
    console.log(CryptoJS.enc.Hex.stringify(hmac.finalize()))
    return CryptoJS.enc.Hex.stringify(hmac.finalize());
    
}

/** Function: Decrypt Data */
export const decryptData = (userid: string, message: string) => {
    const secret = userid;
    const hmac = CryptoJS.algo.HMAC.create(CryptoJS.algo.SHA256, secret).update(message);
    return CryptoJS.enc.Hex.stringify(hmac.finalize());
}

/** Encrypt a derived hd private key with a given pin and return it in Base64 form */
export const encryptAES = (text: string, key: string) => {
    return CryptoJS.AES.encrypt(text, key).toString();
  };

/** Function: Decrypt an encrypted message
   * 
   * @param encryptedBase64 encrypted data in base64 format
   * @param key The secret key
   * @return The decrypted content
   */
  export const decryptAES = (encryptedBase64: string, key: string) => {
    const decrypted = CryptoJS.AES.decrypt(encryptedBase64, key);
    if (decrypted) {
      try {
        const str = decrypted.toString(CryptoJS.enc.Utf8);
        if (str.length > 0) {
          return str;
        } else {
          return 'error 1';
        } 
      } catch (e) {
        return 'error 2';
      }
    }
    return 'error 3';
  };




