  
import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonLabel, IonPage, IonSegment, IonSegmentButton, IonTitle, IonToolbar } from '@ionic/react';
import { chevronBack, /*close, options*/ } from 'ionicons/icons';
import { useRef, useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { GroupedLeadsStore } from '../services/leads';

import * as ROUTES from '../config';
//import SearchInput from '../components/SearchInput';
import ChatBox from '../components/Leads/Chats/ChatBox';
import { useStoreState } from 'pullstate';
import { getUser, UserPreferences } from '../services/user';
import { useFetchDataFromDB } from '../services/DataApi';

const specsEndpoint =  ROUTES.SPECIFICATIONS_API_ENDPOINT;

const Lead = () => {

	const pageRef = useRef();
	//const ios = isPlatform('ios');
	const lead = GroupedLeadsStore.useState(s => s);
	const user = useStoreState(UserPreferences, getUser);
  	const isDealership = user.role.includes('Hino Dealership');
	const isBodyBuilder = user.role.includes('Hino Preferred Supplier');

	const{ data: specs, isLoading: isLoadingSpecs, error: loadingSpecsError } = useFetchDataFromDB(`specifications`, `${specsEndpoint}`);

	const urlParams = useLocation().search;
	const message_id = new URLSearchParams(urlParams).get('q');

	const [segment, setSegment] = useState<'chat' | 'spec' | 'history'>('chat');

	//const [query, setQuery] = useState<string>("");
	//const [showFilter, setShowFilter] = useState(false);
	//const [showCities, setShowCities] = useState(false);
	//const [showProvinces, setShowProvinces] = useState(false);
	//const [showSort, setShowSort] = useState(false);

	if (lead.salesperson === null || lead.builder_name === null || lead.leads[0] === undefined) {
		return (
			<Redirect to={ROUTES.LEADS_LIST_PAGE_ROUTE} />
		);
	}

	return (
		<IonPage ref={pageRef}>
			<IonHeader>
				<IonToolbar mode="ios" color="toolbar">
					<IonTitle><strong>{isDealership ? lead.leads[0].builder_name : lead.leads[0].salesperson}</strong></IonTitle>
					<IonButtons slot="start">
            			<IonButton routerDirection="back" routerLink={`${ROUTES.LEADS_LIST_PAGE_ROUTE}/${encodeURIComponent(isDealership ? lead.leads[0].preferred_supplier : lead.leads[0].dealership).toLowerCase()}`}>
              				<IonIcon icon={chevronBack} />
						</IonButton>
          			</IonButtons>
				</IonToolbar>
				{/*<IonToolbar mode="ios" className="ion-padding-top">
					<SearchInput onChangeSearchQuery={(query) => setQuery(query)} />
					<IonButtons slot="end">
						{
						!showFilter &&
						<IonButton color="dark" onClick={() => setShowFilter(true)}>
						{ios ? 'Filter' : <IonIcon icon={options} slot="icon-only" />}
						</IonButton>}
						{
						showFilter &&
						<IonButton color="dark" onClick={() => setShowFilter(false)}>
							{ios ? 'Close' : <IonIcon icon={close} slot="icon-only" />}
						</IonButton>
						}
					</IonButtons>
					</IonToolbar>*/}
			</IonHeader>

			<IonContent color="light-shade" fullscreen>
				<IonToolbar>
					<IonSegment value={segment} onIonChange={(e) => setSegment(e.detail.value as any)} mode="md">

						<IonSegmentButton value="chat">
							<IonLabel color="dark">Chat Messages</IonLabel>
							{/* segment === "all" ? <IonIcon color="success" icon={checkmark}/> : <IonIcon color="danger" icon={close} />*/}
						</IonSegmentButton>

						<IonSegmentButton value="spec">
							<IonLabel color="dark">Specification</IonLabel>
							{/* segment === "pending" ? <IonIcon color="success" icon={checkmark}/> : <IonIcon color="danger" icon={close} />*/} 
						</IonSegmentButton>

						{/*<IonSegmentButton value="failed">
							<IonLabel>Rejected</IonLabel>
							{ segment === "failed" ? <IonIcon color="success" icon={checkmark}/> : <IonIcon color="danger" icon={close} />}
					</IonSegmentButton>*/}

					</IonSegment>
				</IonToolbar>
				{ segment === 'chat' && <ChatBox /> }

				{
					segment === 'spec' &&
					<div className="ion-padding">
						{
							isDealership && lead.leads.filter(l => l.id === message_id).map(l => 
							
								<IonLabel key={l.id}>
									<p >{l.specification_id}</p>
								</IonLabel>
							)
						}

						{
							isBodyBuilder && lead.leads.filter(l => l.id === message_id).map(l => 
							
								<IonLabel key={l.id}>
									<p >List of my specifications</p>
								</IonLabel>
							)
						}
					</div>
				}
			</IonContent>
		</IonPage>
	);
};

export default Lead;