import { useEffect, useRef, useState } from 'react';
import { IonContent, IonHeader, IonPage, IonToolbar, IonTitle, IonButton,  isPlatform, IonIcon } from '@ionic/react';
import { alarmOutline, moonOutline,  sunnyOutline } from 'ionicons/icons';
import { Browser } from '@capacitor/browser';

import { HomeBuilders, HomeDealerships } from '../components/home';

import { useDefaultTrucks } from '../services/trucks';
import { UserPreferencesModel } from '../models/User';


import './Home.css';
import './PreferredSupplier.css';


interface HomeTabProps {
  user: UserPreferencesModel;
}


const Home: React.FC<HomeTabProps> = ({user}) => {

  const pageRef = useRef<HTMLElement>(null);
  const ios = isPlatform('ios');
  const android = isPlatform('android');

  const isBodyBuilder = user.role.includes('Hino Preferred Supplier');


  /** 1. Greeting */
  const [time, setTime] = useState(new Date().getHours());
  const [greeting, setGreeting] = useState({message: "Morning", icon: alarmOutline});
  const { isLoading } = useDefaultTrucks(null);  //Fetch all Trucks: Not necessary but called to ensure that trucks are loaded before they are used

 
  useEffect(() => {

    const updateTime = setInterval(() => setTime(new Date().getHours()), 1000*60); //Every Minute

    const updateGreeting = () => {
      const message = time < 12 ? "Morning" : time < 18 ? "Afternoon" : "Evening";
      const icon = time < 12 ? alarmOutline : time < 18 ? sunnyOutline : moonOutline;

      setGreeting({message: message, icon: icon})
    }

    if (!isLoading) updateGreeting();

    return () => clearInterval(updateTime);

  }, [time, isLoading]);


  /** 2. eToyota */

  const openEToyota = async (url: string) => {

    ios ? window.location.assign(url)

      : 

    android ? await Browser.open({ url, windowName: "_self", toolbarColor: "#C90B1B" }) 

      :
    
    window.open(url, "_blank");

}

  return (
    <IonPage ref={pageRef} id="home-page">
      {
        ios &&

        <IonHeader>
          <IonToolbar color="toolbar">
            <IonTitle color='light'>Hino Truck Body Builders App</IonTitle>
          </IonToolbar>
        </IonHeader>
      }
      

      <IonContent color="light" fullscreen>

        <IonHeader collapse="condense">

          <IonToolbar color="light">
            <IonTitle size="large">
              Hi {user.fullname.length < 2 ? "" : ` ${user.fullname.split(" ").slice(0, 1)}`},
            </IonTitle>
          </IonToolbar>
        </IonHeader>
        
        {
        !ios &&
        
          <IonToolbar color="light" className='ion-margin-top ion-padding-top'>
            <IonButton mode='ios' fill="clear" slot="start" color="dark" size="large">
              {/*<img src={`https://eu.ui-avatars.com/api/?name=${user.fullname.split(" ").join("+")}&size=256&background=random&color=000&rounde=true&bold=true`} alt={`${user.fullname.split(" ").slice(0, 1)}`}/> */} 
              <IonIcon style={ {fontSize: "2em"} } color="primary" icon={greeting.icon} />
              &nbsp;<h3><strong>Good {greeting.message}{user.fullname.length < 2 ? "" : `, ${user.fullname.split(" ").slice(0, 1)} `}</strong></h3>
            </IonButton>
          </IonToolbar>
        
      }

        {
          isBodyBuilder ? 

          <HomeBuilders user={user} greeting={greeting} openWindow={openEToyota}/>
          
          :

          <HomeDealerships user={user} greeting={greeting} openWindow={openEToyota}/>
        }

      </IonContent>

    </IonPage>
  );
};

export default Home;
