import { useRef } from 'react';
import { Redirect } from 'react-router-dom';
import { IonContent, IonButton, IonToolbar, IonHeader, IonPage, IonButtons, IonIcon, IonTitle,  } from '@ionic/react';
import { chevronBack } from 'ionicons/icons';

import  { PdfViewerComponent, Toolbar, Magnification, Navigation, LinkAnnotation, BookmarkView,
  ThumbnailView, Print, TextSelection, Annotation, TextSearch, FormFields, FormDesigner, Inject} from '@syncfusion/ej2-react-pdfviewer';
//import { Document, Page } from 'react-pdf';
//import { GlobalWorkerOptions } from 'pdfjs-dist';
import GuidelineStore from '../../services/guidelines';

import * as ROUTES from '../../config';

import '../Guidelines.css'
//import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
//import 'react-pdf/dist/esm/Page/TextLayer.css';

/*const options = {
  cMapUrl: 'cmaps/',
  cMapPacked: true,
  standardFontDataUrl: 'standard_fonts/',
};*/

//Auto Load pdfjs service worker
// Set up the worker
/*GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.mjs",
  import.meta.url
).toString();*/
 
const Precaution: React.FC = () => {
  
  const pageRef = useRef<HTMLElement>(null);
  const precaution = GuidelineStore.useState(s => s);

  const fileUrl = precaution.url;
  const title = precaution.title;

  //const [numPages, setNumPages] = useState(0);
  //const [documentLoaded, setDocumentLoaded] = useState(false);
  //const [errorLoadingDocument, setErrorLoadingDocument] = useState(false);
  //const [progress, setProgress] = useState<{loaded: number, total: number}>({loaded: 0, total: 0});
  //const [scale, setScale] = useState(3);

  /*const onDocumentLoadSuccess = ({numPages: nextNumPages} : any ) => {
    setDocumentLoaded(true);
    setNumPages(nextNumPages as unknown as number);
  }*/

  if (precaution.title === null) {
		return (
			<Redirect to={ROUTES.PRECAUTIONS_PAGE_ROUTE} />
		);
	}

  return (
    <IonPage ref={pageRef} id="precaution-page">
      <IonHeader>
        <IonToolbar mode="ios" color="toolbar">
            <IonButtons slot="start">
            <IonButton routerLink={ROUTES.PRECAUTIONS_PAGE_ROUTE} >
              <IonIcon icon={chevronBack} />
              
              </IonButton>
            </IonButtons>
            <IonTitle>{title}</IonTitle>
        </IonToolbar>
      </IonHeader>
      
      <IonContent className="control-section" fullscreen>

        <PdfViewerComponent 
          id="container" 
          documentPath={fileUrl!}
          resourceUrl="https://cdn.syncfusion.com/ej2/26.2.11/dist/ej2-pdfviewer-lib"
          style={{ 'height': '100%' }}>
            {/* Inject the required services */}
            <Inject services={[ Toolbar, Magnification,  Navigation, /*Annotation,*/ LinkAnnotation, BookmarkView, ThumbnailView,
                                Print, TextSelection,  TextSearch,/* FormFields, FormDesigner */]} />
        </PdfViewerComponent>
        
       

          {/*
            !documentLoaded  && 
            <IonCardTitle mode="ios" className='ion-margin ion-padding'>Loading Document ...</IonCardTitle>  
          */}

          {/*<IonProgressBar style={{fontWeight: "900px"}} value={progress.loaded}/>*/}

          {/*


            <Document 
              file={fileUrl} 
              onLoadProgress={(data) => setProgress(data)} 
              onLoadSuccess={onDocumentLoadSuccess} 
              //onLoadError={() => setErrorLoadingDocument(true)}
              options={options}>
              {
                Array.from(new Array(numPages), (el, index) => (
                  <Page 
                    key={`page_${index + 1}`} 
                    pageNumber={index + 1}
                  />
                ))
              }
            </Document>
          */}
         

      </IonContent>
      {/*<IonFooter>
        <IonToolbar mode="ios" color="toolbar">
          <IonButton slot="start" onClick={() => setScale(scale - 1)}><IonIcon icon={remove} /></IonButton>
          <IonButton slot="end" onClick={() => setScale(scale + 1)}><IonIcon icon={add} /></IonButton>
        </IonToolbar>
          </IonFooter>*/}
    </IonPage>
  );
};

export default Precaution;
