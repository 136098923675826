import { useReducer, useState } from "react";
import { IonButton, IonButtons, IonCol, IonIcon, IonInput, IonItem, IonLabel, IonText, isPlatform } from "@ionic/react";
import { chevronDown, chevronUp, construct } from "ionicons/icons";
import { BodyHeight,  DefaultBodySpec } from "../../../../models/Body";
import { HeightExternal, HeightInternal, HeightSubframe, HeightSubstructure, LenghtExternal, 
    LenghtHeadboard, LenghtInternal, LenghtTailboard, WidthExternal, WidthInternal, WidthLeftThickness, 
    WidthRightThickness } from "./Dimensions";
import { Calculator, CalcValues, hReducer } from "../../../../services/calculations";

interface DropsideProps {
    values: CalcValues;
    setValues: (v: CalcValues) => void;
}

const Dropside: React.FC<DropsideProps> = ({values, setValues}) => {

    const ios = isPlatform('ios');
    const { ...truck } = Calculator(values);

    const dbs = new DefaultBodySpec();
    const headboardHeight = (dbs as any)[truck.bodyType.toLowerCase()]['headboardHeight'] || "0";

    const [showHeight, setShowHeight] = useState(false);
    const [showLength, setShowLength] = useState(false);
    const [showWidth, setShowWidth] = useState(false);

    const [hState, hDispatcher] = useReducer(hReducer, new BodyHeight(values))

    return (

        <>
            <IonItem color="light">
                <IonText className="ion-padding">Length</IonText>
                <IonButtons slot="end">
                {
                    !showLength &&
                    <IonButton onClick={() => {
                        setShowWidth(false);
                        setShowHeight(false);
                        setShowLength(true);
                    }}>
                    <IonIcon icon={chevronDown} slot="icon-only" />
                    </IonButton>}
                {
                    showLength &&
                    <IonButton onClick={() => setShowLength(false)}>
                    <IonIcon icon={chevronUp} slot="icon-only" />
                    </IonButton>
                }
                </IonButtons>
            </IonItem>

            {
                showLength &&

                <>
                    <LenghtExternal title="Length (External)" description="Overall body length" values={values} setValues={setValues} />
                    <LenghtInternal title="Length (Internal)" description="Load compartment length" values={values} setValues={setValues} />
                    <LenghtHeadboard title="Headboard" description="Headboard" values={values} setValues={setValues} />
                    <LenghtTailboard title="Tailboard" description="Tailboard" values={values} setValues={setValues} />
                </> 
            }

            <IonItem color="light">
                <IonText className="ion-padding">Height</IonText>
                <IonButtons slot="end">
                {
                    !showHeight &&
                    <IonButton onClick={() => {
                        setShowWidth(false);
                        setShowHeight(true);
                        setShowLength(false);
                    }}>
                    <IonIcon icon={chevronDown} slot="icon-only" />
                    </IonButton>}
                {
                    showHeight &&
                    <IonButton onClick={() => setShowHeight(false)}>
                    <IonIcon icon={chevronUp} slot="icon-only" />
                    </IonButton>
                }
                </IonButtons>
            </IonItem>

            {
                showHeight &&

                <>
                    <HeightSubframe title="Subframe Height" description="Subframe height" values={values} setValues={setValues} />
                    <HeightExternal title="Dropside Height (External)" description="External height" values={values} setValues={setValues} />
                    <HeightInternal title="Dropside Height (Internal)" description="Load compartment height" values={values} setValues={setValues} />
                    <HeightSubstructure title="Substructure Height" description="Substructure Height" values={values} setValues={setValues} />
                    <IonItem>
                        <IonCol size="8">
                            { ios &&
                                <IonIcon slot="start" icon={construct} color="medium" />
                            }
                            <IonLabel>
                                <h3>Headboard Height</h3>
                                <p>Headboard Height</p>
                            </IonLabel>
                        </IonCol>
                        <IonCol size="8">
                            <IonInput color="primary" placeholder={`${headboardHeight} mm`} onIonChange={e => {
                                const h = e.detail.value as string;
                                hDispatcher({type: 'HEIGHT_INTERNAL', payload: { ...hState, loadCompartmentHeight: h} });
                            }}>
                            </IonInput>
                        </IonCol>
                    </IonItem>
                </>  
            }

            <IonItem color="light">
                <IonText className="ion-padding">Width</IonText>
                <IonButtons slot="end">
                {
                    !showWidth &&
                    <IonButton onClick={() => {
                        setShowWidth(true);
                        setShowHeight(false);
                        setShowLength(false);
                    }}>
                    <IonIcon icon={chevronDown} slot="icon-only" />
                    </IonButton>}
                {
                    showWidth &&
                    <IonButton onClick={() => setShowWidth(false)}>
                    <IonIcon icon={chevronUp} slot="icon-only" />
                    </IonButton>
                }
                </IonButtons>
            </IonItem>

            {
                showWidth &&

                <>
                    <WidthExternal title="Width (External)" description="Overall body width" values={values} setValues={setValues} />
                    <WidthInternal title="Width (Internal)" description="Load compartment width" values={values} setValues={setValues} />
                    <WidthLeftThickness title="Left Sidewall" description="Thickness of left sidewall" values={values} setValues={setValues} />
                    <WidthRightThickness title="Right Sidewall" description="Thickness of right sidewall" values={values} setValues={setValues} /> 
                </>   
            }
        </>
    );
}

export default Dropside;