import axios, { AxiosError, InternalAxiosRequestConfig, AxiosResponse } from 'axios';
import axiosRetry from 'axios-retry';
import { useAuth0 } from '@auth0/auth0-react';
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useHistory } from 'react-router';
import { Buffer } from 'buffer';

import { Message, MessageDetails } from '../models/Lead';
import * as URLS from '../config';


/** 1. Params: accessToken and userid*/
//export let userid: string | undefined = undefined;
export let accessToken: string | undefined = undefined;
export let userid: string | undefined = undefined;

/** 2. Axios Defaults */
axios.defaults.baseURL = URLS.BASEURL;
axios.defaults.timeout = 30000; //Connection timeout after 30 seconds of no response
axios.defaults.headers.get["Content-Type"] = "application/json";
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.put["Content-Type"] = "application/json";

/** 3. Axios Interceptors */

/** 3.1 Request Interceptor*/
const onRequest = (config: InternalAxiosRequestConfig) => {
    if (config.headers !== undefined) {
        config.headers.Authorization = `Bearer ${accessToken}`;
        config.headers.userid = `${userid}`;
        //config.headers.sub = `${sub}`;
    }

    return config;
};

/** 3.2 Request Error Interceptor*/
const onRequestError = (error: AxiosError): Promise<AxiosError> => {
    //console.error(`[interceptor Request error] [${JSON.stringify(error)}]`);
    return Promise.reject(error);
}

/** 3.3 Response Interceptor*/
const onResponse = (response: AxiosResponse): AxiosResponse => {
    if (response.data !== null) {
        if(response.data.status === 401) window.location.href = '/login';
    }
    return response;
}

/** 3.4 Response Error Interceptor*/
const onResponseError = (error: AxiosError): Promise<AxiosError> => {

    /** response === 401 ? redirect user to login */
    if (error.response?.status === 401) window.location.href = '/login';

    return Promise.reject(error);
}

/** 3.5 Intercept all Axios Requests and Responses */
axios.interceptors.request.use(onRequest, onRequestError);
axios.interceptors.response.use(onResponse, onResponseError);

/** 4. AxiosRetry: Retry Requests during Network Errors */
const retryDelay = (retryNumber = 0) => { 
    const seconds = Math.pow(2, retryNumber) * 1000; 
    const randomMs = 1000 * Math.random(); 
    return seconds + randomMs; 
  }; 
  
axiosRetry(axios, { 
    retries: 2, 
    retryDelay, 
    retryCondition: axiosRetry.isRetryableError, 
  }); 

/* 5. Generic Function to fetch data from the database

** @@Params_start 
** key: eg. 'bodies' //Cache Reference for ReactQuery
** apiUrl: eg. 'bodySpecifications'
** id: eg. 'bodySpecificationID'
** @@Params_end
*/ 
export const useFetchDataFromDB = (key: string, apiUrl: string, id?: string) => {

    const history = useHistory();
    const requestTimeout = 15000; //15 seconds

    //Auth0 hook
    const { isLoading: isLoadingUser, getAccessTokenSilently, user } = useAuth0();

    //React-Query hook
    const { status, data, error, isLoading } = useQuery(key, async () => {

        //AbortController
        const abortSignal = (timeoutMs: number) => {
            const abortController = new AbortController();
            setTimeout(() => abortController.abort(), timeoutMs || 0);
          
            return abortController.signal;
        }

        //Retrieve user-details and accessToken
        if (!isLoadingUser) {
            accessToken = await getAccessTokenSilently();
            userid = user?.sub || Buffer.from(`${user?.email}`).toString('base64');
        }

        //Deal with the data from the api
        const fetchDataWithTimeout = async () => {

            let response;

            if(id) response = await axios.request({method: "GET", timeout: 30000, url: `/${apiUrl}/${id}`, signal: abortSignal(30000)});
            else response = await axios.request({method: "GET", timeout: 30000, url: `/${apiUrl}`, signal: abortSignal(30000)});

            return response.data;
        }

        //Race fetchData and connectionTimeout
        return Promise.race([
                //fetchData
                fetchDataWithTimeout(),
                //connectionTimeout
                new Promise((_, reject) => setTimeout(() => reject(new Error('Timeout')), requestTimeout))
            ]
        ).catch((err) => {
            if(err.code === 'ECONNABORTED') {
                // handle timeout error
                console.log('Request timed out');

            } else if (err.response?.status === 401) {
                history.replace('/login');
            } 
            /** response === 403 ? send an email to support and redirect user */
            else if (err.response?.status === 403) {

                const request = axios.create({
                    baseURL: "https://postmail.invotes.com",
                    timeout: 5000,
                    headers: {"Content-type": "application/x-www-form-urlencoded"}
                })
        
                const data = {
                    "access_token": "pgs0idzhlddscnc37tcdg0b4",
                    "subject": "Hino Truck Body Builders App | Upgrade Account",
                    "text": `Please upgrade the user account with the following userid: ${userid!}`
                }

                if (window.location.pathname.includes(URLS.CALCULATIONS_PAGE_ROUTE)) {

                    request.post('/send', data).then(() => {
                        alert('Your Account is forbidden to access this resource. An email has been sent to support to verify your account and enable access. You will receive an email communication once the process is complete. Close the window to be redirected to the Home Page');
                        history.replace(URLS.TABS_HOME_PAGE_ROUTE)
                    }).catch(() => {
                        alert('Your Account is forbidden to access this resource. Please send an email to support to verify your account and enable access. Close the window to be redirected to the Support Page');
                        history.replace(URLS.SUPPORT_PAGE_ROUTE);
                    })
                }

            } else {
                //console.error(err.message);
                AbortSignal.abort('Something went wrong');
                // do something else, such as logging the error or sending a notification
              }

        }); // </Promise.race> */

    }); // </useQuery>

    return {status, data, error, isLoading};
};

/* 6. Generic Function to post data to the database 

** @@Params_start 
** apiUrl: eg. 'rules'
** payload: data payload
** @@Params_end
*/ 
export const postDataToDB = async (apiUrl: string, payload: any) => {

    let data = null;

    data = await axios.put(`/${apiUrl}`, payload)
                    .then(response => response)
                    .catch(error => error.response)

    return data
  

    /*return new Promise<any>( async (resolve) => {
        let response = await axios.put(`/${apiUrl}`, payload);
        let data = response.data;
        console.log(data.includes('403'))
        setTimeout(() => resolve(data), 1000);
    }).catch(error => error); // </Promise> */
}; 

/** 7. Function: Post Messages from Builders, Dealers and Users to the DB*/
export const postMessagesToDB = (payload: Message) => {

    const apiUrl = URLS.MESSAGES_API_ENDPOINT;

    return new Promise<any>( async (resolve) => {
        let response = await axios.put(`${apiUrl}`, payload);
        let data = response.data;
        setTimeout(() => resolve(data), 1000);
    } ); // </Promise>
}; 

export const postMessageToDB = (payload: MessageDetails) => {

    const apiUrl = URLS.MESSAGES_API_ENDPOINT;
    const { message_id } = payload;

    return new Promise<any>( async (resolve) => {
        let response = await axios.put(`${apiUrl}/${message_id}`, payload);
        let data = response.data;
        setTimeout(() => resolve(data), 1000);
    } ); // </Promise>
};


export const postUserToDB = (payload: any) => {

    const apiUrl = URLS.USERS_API_ENDPOINT;
    //const { message_id } = payload;

    return new Promise<any>( async (resolve) => {
        let response = await axios.put(`${apiUrl}`, payload);
        let data = response.data;
        setTimeout(() => resolve(data), 1000);
    } ); // </Promise>
};

/* 7. Generic Function to post / put data to the database 
** @@Params_start 
** key: eg. 'bodies' //Cache Reference for ReactQuery
** apiUrl: eg. 'bodySpecifications'
** id: eg. 'bodySpecificationID'
** @@Params_end
*/
export const usePostDataToDB = (key: string, apiUrl: string) => {

    const queryClient = useQueryClient();

    const postDataToDB = (payload: any) => {
        return new Promise<any>( async (resolve) => {
            let response = await axios.put(`/${apiUrl}`, payload);
            let data = response.data;
            setTimeout(() => resolve(data), 1000);
        } ); // </Promise>
    };

    const { mutate, isLoading, data, error, status } = useMutation(postDataToDB, {
        onSuccess: data => console.log(data),
        onError: error => console.log(error),
        onSettled: () => queryClient.invalidateQueries(key)
    });

    return { mutate, isLoading, data, error, status }
};




















