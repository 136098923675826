import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { IonButton, IonRow, IonGrid, IonCol, IonListHeader, IonCard, IonCardTitle, IonNote, IonCardContent,  IonIcon, IonItem, IonLabel, IonText, IonToolbar, IonModal, IonSpinner, IonChip, IonCardHeader, IonList, isPlatform } from '@ionic/react';
import { bookOutline, calculatorOutline, checkmark, documentOutline, documentsOutline, close, newspaperOutline } from 'ionicons/icons';
import SwiperCore, { Navigation, Pagination, EffectCoverflow, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import Skeleton from 'react-loading-skeleton';
import { useMedia } from 'react-use';
import { max, min } from 'mathjs';
import { ReactSVG } from 'react-svg';
import { TruckSpecifications } from '../../models/Truck';

import { useDefaultTrucks } from '../../services/trucks';
import TruckListFilter, { applicationArray, listOfModels} from '../Trucks/TruckListFilter';
import { UserPreferencesModel } from '../../models/User';
import { useFetchDataFromDB } from '../../services/DataApi';
import { CalculationStore } from '../../services/calculations';

import * as ROUTES from '../../config';

import 'react-loading-skeleton/dist/skeleton.css';

// Import Swiper styles
import './swiper/swiper.min.css';
import './swiper/swiper-bundle.css';
import './swiper/effect-coverflow.min.css';
import './swiper/pagination.min.css';
import './swiper/navigation.min.css';




interface HBProps {
    user?: UserPreferencesModel,
    openWindow: (url: string) => void,
    greeting?: {message: string, icon: string}
}


const HomeBuilders: React.FC<HBProps> = ({openWindow}) => {

  const history = useHistory();
  const isWide = useMedia('(min-width: 576px)');
  const ios = isPlatform('ios');

  /** Get Latest Calculation */
  CalculationStore.useState(s => s);

  /** Specifications */
  const { data: specifications, isLoading: isLoadingSpecifications } = useFetchDataFromDB("specifications", ROUTES.SPECIFICATIONS_API_ENDPOINT);


  /** Trucks */
  const { defaultTrucks, isLoading: isLoadingTrucks } = useDefaultTrucks(null);
	const seriesList = defaultTrucks.series.flat(1).map(series => series.name);

  const [showTruckListFilter, setShowTruckListFilter] = useState(false);

  /** eToyota Urls */
  const submitJobUrl = "https://toyapps3.e-toyota.co.za/Trucks/HQSECO.nsf/HPS_CAPSup?OpenForm";
  const viewJobsUrl = ios ? "https://portal.e-toyota.co.za" : "https://toyapps3.e-toyota.co.za/Trucks/HQSECO.nsf/HPS_JobView?OpenForm";

  const hinoNewsRoomUrl = "https://www.hino.co.za/discover/news";

  /** GVMs */
  const gvmArray = (series: string) => {
    const c = listOfModels(defaultTrucks, series).flat(1);
    const g = Array.from(new Set(c.map((o: TruckSpecifications) => +o.maxGVMRating)));

    return g;
  }

  //Reload Swiper Modules each time seriesList is updated
  useEffect(() => {
    SwiperCore.use([Navigation, Pagination, EffectCoverflow, Autoplay]);
  }, [seriesList]);


  return (

    <>

        {isLoadingTrucks &&
        
        <IonGrid>

          <IonRow className="ion-padding-start ion-padding-end">
              
            <IonCol sizeSm="12" sizeMd="10" sizeLg="10">

              <IonLabel className="ion-margin-top">
                <h2 className="ion-justify-content-center ion-text-center"><strong><Skeleton/></strong></h2>
                <h2 className="ion-justify-content-center ion-text-center"><strong><Skeleton/></strong></h2>
                <ul className='ion-justify-content-start'>
                  <h2><Skeleton/></h2>
                  <h2><Skeleton/></h2>
                  <h2><Skeleton/></h2>
                </ul>
              </IonLabel>

            </IonCol>
          </IonRow>

          <IonRow className="ion-justify-content-center">
            <IonCol sizeSm="12" sizeMd="8" sizeLg="8">
              <IonCard mode="ios">
                <IonCardContent>
                  <IonCardTitle>{<Skeleton/>}</IonCardTitle>
                  <p>{<Skeleton/>}</p>
                  <IonButton expand="block" className="ion-margin-top">{<Skeleton/>}</IonButton>
                </IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>

          <IonRow className="models ion-padding-top ion-justify-content-center">
            
            <IonListHeader className="ion-padding-bottom">{<Skeleton/>}</IonListHeader>
            
            <IonCol sizeSm="12" sizeMd="8" sizeLg="8">
              
                <IonCard className="models">
                  <IonCardContent className="model-card">
                    <div className="model-details">
                      <IonCardTitle>
                        {<Skeleton/>}
                      </IonCardTitle>
                      <IonNote>{<Skeleton/>}</IonNote>
                    </div>
                  </IonCardContent>
                </IonCard>
            
              </IonCol>
            </IonRow>  

          </IonGrid>

        }

        { !isLoadingTrucks &&

        <IonGrid className="h-wrapper">

          <IonRow className="ion-padding-start ion-padding-end">
              
            <IonCol sizeSm="12" sizeMd="10" sizeLg="10">

              <IonLabel className="ion-margin-top">
                <h2 className="ion-justify-content-center ion-text-center"><strong>Welcome to Hino Truck Body Builders App | Learn about Hino Body Mounting Guidelines; Configure, Validate and Save Body Specifications for Hino Models</strong></h2>
                {/*<IonToolbar
                  style={{
                    height: 60,
                    marginTop: 35,
                    paddingTop: 10,
                    paddingBottom: 10,
                    paddingLeft: 15,
                    backgroundColor: '#F5F5F7',
                    borderRadius: 30,
                    alignItems: 'center',
                    flexDirection: 'row',
                  }}>
                  <IonInput
                    disabled={true}
                    style={{fontSize: 18, marginLeft: 5}}
                    placeholder="Search to get started">
                      <IonIcon size="large" slot="start" icon={search} />
                  </IonInput>

                </IonToolbar> */}
              </IonLabel>

            </IonCol>
    
          </IonRow>

          <IonItem className='ion-no-margin ion-no-padding' lines="full" detail={false} color="light"/>

          <IonRow
            style={{
              paddingVertical: 25,
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}>
            <IonToolbar color="light">
            
              <IonButton mode="ios" fill="clear" slot="start">
                <IonText style={{color: 'var(--ion-color-dark)',fontSize: 20, fontWeight: 'bold'}}>Hino Models</IonText>
              </IonButton>
              <IonButton mode="ios" fill="clear" slot="end" onClick={() => setShowTruckListFilter(true)}>
                <IonText className="ion-justify-content-end" style={{fontSize: 18, fontWeight: 'bold', color: 'var(--ion-color-primary)'}}>
                  View All
                </IonText>
              </IonButton>
            
            </IonToolbar>
          </IonRow>


          {/** <Hino Models Slider> */}
          <IonRow>

            <Swiper
              //navigation={true}
              pagination={{ clickable: true, type: 'bullets' }}
              effect="coverflow"
              autoplay={ {delay: 2000} }
              loop={true}
              coverflowEffect={{
                rotate: 50,
                stretch: 0,
                depth: 100,
                modifier: 1,
                slideShadows: false
              }}
              slidesPerView={isWide ? 'auto' : 1.25}
              centeredSlides
            >

            {
              seriesList && seriesList.sort() && seriesList.map((s: string) => (
                
                <SwiperSlide key={s} style={ {background: "white"} } className='ion-margin-bottom'>
                  
                    <IonCardContent mode='ios'>

                      <IonCardTitle mode='ios'>{s === 'dyna' ? `Toyota ${s.charAt(0).toUpperCase() + s.slice(1)} ` : `Hino ${s.charAt(0).toUpperCase() + s.slice(1)}`}</IonCardTitle>
                      
                      <IonNote>
                        {
                          listOfModels(defaultTrucks, s).length < 2 ? 

                          `GVM: ${min(gvmArray(s))} kg` 

                          : 

                          `${listOfModels(defaultTrucks, s).length} Models (GVM: ${min(gvmArray(s)) === max(gvmArray(s)) ? `${min(gvmArray(s))}` : `${min(gvmArray(s))} - ${max(gvmArray(s))}`} kg)`
                        }
                      </IonNote>

                      <img style={ {objectFit: "contain", height: 100, width: "auto"} } src={`/assets/images/hino${s}.png`} alt={s}/>

                      <h2><strong>Applications</strong></h2>

                      <IonNote>{applicationArray(defaultTrucks, s)}</IonNote>

                      <IonRow className='ion-justify-content-center ion-padding'>
                        <IonButton mode='ios' routerLink={`${ROUTES.TRUCK_LIST_PAGE_ROUTE}?series=${s}&from=${ROUTES.TABS_HOME_PAGE_ROUTE}`}>
                          View {listOfModels(defaultTrucks, s).length < 2 ? 'Model ' :  'Models '}&rarr;
                        </IonButton>
                      </IonRow>

                    </IonCardContent>
                    
                  
                </SwiperSlide>
              ))
            }

            </Swiper>

          </IonRow>

          <IonItem detail={false} color="light"></IonItem>

          {/** </Hino Models Slider> */}


          {/** <Specifications> */}
          {/*specifications !== undefined &&

            <>

              <IonRow
                style={{
                  paddingVertical: 25,
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}>
                <IonToolbar color="light">
                
                  <IonButton mode="ios" fill="clear" slot="start">
                    <IonText style={{color: 'var(--ion-color-dark)',fontSize: 20, fontWeight: 'bold'}}>My Specifications</IonText>
                  </IonButton>

                  {
                    !isLoadingSpecifications && specifications.length > 0 &&

                    <IonButton mode="ios" fill="clear" slot="end" routerLink={ROUTES.CALCULATIONS_PAGE_ROUTE}>
                      <IonText className="ion-justify-content-end" style={{fontSize: 18, fontWeight: 'bold', color: 'var(--ion-color-primary)'}}>
                        View All
                      </IonText>
                    </IonButton>
                  }
                
                </IonToolbar>
              </IonRow>

                
                  
                      
                {
                  !isLoadingSpecifications && specifications.length > 0 ?

                  <IonItem detail={false} lines="none">

                    <div>

                    <IonChip color='success' className='ion-padding ion-margin'>Saved: {(specifications[0].savedDate).toString().substring(0, 10)}</IonChip>

                      <IonCardHeader>
                        
                        <IonCardTitle mode='ios' className='ion-no-margin ion-text-center'>
                          {specifications[0].uniquename}
                        </IonCardTitle>
                      </IonCardHeader>

                      <IonCardContent mode='ios' className='ion-text-center ion-justify-content-center'>

                        <ReactSVG 
                          src={`/assets/trucks/${specifications[0].series}/${specifications[0].truckid}/bridge/${specifications[0].body === null ? 'index' : specifications[0].body.toLowerCase()}.svg`}
                          fallback={() => <h3>Not Found</h3> }
                          loading={() => <IonSpinner color="primary"/>} 
                        />

                        <div className='ion-text-center'>
                          <h5>
                            <IonText>Mass&nbsp;
                              <IonIcon icon={specifications[0].rules.mass ? checkmark : close} color={specifications[0].rules.mass ? "success" : "danger"}/>
                            </IonText>
                            <IonText>&nbsp;Dimensions&nbsp;
                              <IonIcon icon={specifications[0].rules.dimensions ? checkmark : close} color={specifications[0].rules.dimensions ? "success" : "danger"}/>
                            </IonText>
                            <IonText>&nbsp;Bridge&nbsp;
                              <IonIcon icon={specifications[0].rules.bridge ? checkmark : close} color={specifications[0].rules.bridge ? "success" : "danger"}/>
                            </IonText>
                            <IonText>&nbsp;Circle&nbsp;
                              <IonIcon icon={specifications[0].rules.circle ? checkmark : close} color={specifications[0].rules.circle ? "success" : "danger"}/>
                            </IonText>  
                          </h5>
                        </div>

                        <IonButton 
                          mode='ios'
                          fill="clear"
                          routerLink={`${ROUTES.CALCULATIONS_PAGE_ROUTE}/${specifications[0].id}/view`}
                          onClick={() => {
                            CalculationStore.update(s => {
                              s.body = specifications[0].body;
                              s.bodySpecification = specifications[0].bodySpecification;
                              s.gvm = specifications[0].gvm;
                              s.id = specifications[0].id;
                              s.payload = specifications[0].payload;
                              s.rules = specifications[0].rules;
                              s.savedDate = specifications[0].savedDate;
                              s.series = specifications[0].series;
                              s.truck = specifications[0].truck;
                              s.truckid = specifications[0].truckid;
                              s.uniquename = specifications[0].uniquename;
                              s.userid = specifications[0].userid;
                          });
                        }}>Download & Share&nbsp;&rarr;</IonButton>
                      </IonCardContent>

                    </div>
                  </IonItem>

                  :

                  <IonCard mode="ios">
                    <IonCardContent className="ion-text-center model-card">
                      <IonIcon className="title-white" icon={calculatorOutline} style={{ fontSize: "2rem" }} />
                      <h2 className="ion-justify-content-center title-white"><strong>&nbsp;My Specifications</strong></h2>
                      <p className="ion-justify-content-center title-white">Configure and Validate Axle Weight Calculations against AARTO Regulations</p>
                      <IonButton routerLink={ROUTES.CALCULATIONS_PAGE_ROUTE}>Start Trucking&nbsp;&rarr;</IonButton>
                    </IonCardContent>
                  </IonCard>
                }

              <IonItem className='ion-no-margin ion-no-padding' lines="full" detail={false} color="light"/>

            </>
          */}

          {/** </Specifications> */}

          <IonRow className="ion-justify-content-center">
            <IonCol sizeSm="12" sizeMd="8" sizeLg="8">
              <IonCard mode="ios" className="ion-text-center saved-spec-card">

                <IonCardContent>

                  <IonCardTitle className="ion-justify-content-center" color="light">Ready to get started?</IonCardTitle>
                  <h2 className="title-white">Estimate payload of Hino Trucks based on body application</h2>
                  <IonButton
                    className="ion-margin-top" 
                    color="primary"
                    fill="solid"
                    routerLink={ROUTES.CALCULATIONS_PAGE_ROUTE}>
                    Start Trucking &rarr;
                  </IonButton>
            
                </IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow> 




          <IonRow
            style={{
              paddingVertical: 25,
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}>
            <IonToolbar color="light">
            
              <IonButton mode="ios" fill="clear" slot="start">
                <IonText style={{color: 'var(--ion-color-dark)',fontSize: 20, fontWeight: 'bold'}}>Resources</IonText>
              </IonButton>
            
            </IonToolbar>
          </IonRow>

          <IonList>

            <IonCol sizeXs="12" sizeSm="12" sizeMd="6" sizeLg="4" sizeXl='4'>
              <IonItem detail={true} className="ion-no-padding ion-no-margin" onClick={() => history.replace(ROUTES.GUIDELINES_PAGE_ROUTE)}>
                  <IonCard mode="ios" color="warning" style={{ height: '4rem', width: '4rem'}}>
                    <IonCardContent>
                      <IonIcon icon={bookOutline} color="dark" style={{ fontSize: "1.5rem" }} />
                    </IonCardContent>
                  </IonCard>
                  <IonLabel>
                    <h5 className="ion-justify-content-center"><strong>&nbsp;Body Mounting Guidelines</strong></h5>
                    <p className="ion-justify-content-center">&nbsp;General Precautions, Electrical Systems,</p>
                    <p className="ion-justify-content-center">&nbsp;AARTO Regulations</p>
                  </IonLabel>
                </IonItem>
              </IonCol>

              {
                !ios && 
                  <IonCol sizeXs="12" sizeSm="12" sizeMd="6" sizeLg="4" sizeXl='4'>
                    <IonItem detail={true} className="ion-no-padding ion-no-margin" onClick={() => openWindow(submitJobUrl)}>
                      <IonCard mode="ios" color="success" style={{ height: '4rem', width: '4rem'}}>
                        <IonCardContent>
                          <IonIcon icon={documentOutline} color='light' style={{ fontSize: "1.5rem" }} />
                        </IonCardContent>
                      </IonCard>
                      <IonLabel>
                        <h5 className="ion-justify-content-center"><strong>&nbsp;Capture Job</strong></h5>
                        <p className="ion-justify-content-center">&nbsp;Capture Job on eToyota Portal</p>
                      </IonLabel>
                    </IonItem>
                  </IonCol>
                }
            
                <IonCol sizeXs="12" sizeSm="12" sizeMd="4" sizeLg="4" sizeXl='4'>
                  <IonItem detail={true} className="ion-no-padding ion-no-margin" onClick={() => openWindow(viewJobsUrl)}>
                    <IonCard mode="ios" color={ios ? "success" : "tertiary"} style={{ height: '4rem', width: '4rem'}}>
                      <IonCardContent>
                        <IonIcon icon={documentsOutline} color='light' style={{ fontSize: "1.5rem" }} />
                      </IonCardContent>
                    </IonCard>
                    <IonLabel>
                      <h5 className="ion-justify-content-center"><strong>&nbsp;View Jobs</strong></h5>
                      <p className="ion-justify-content-center">&nbsp;View Jobs on eToyota Portal</p>
                    </IonLabel>
                  </IonItem>
                </IonCol>

            <IonCol sizeXs="12" sizeSm="12" sizeMd="4" sizeLg="4" sizeXl='4'>
              <IonItem detail={true} className="ion-no-padding ion-no-margin" onClick={() => openWindow(hinoNewsRoomUrl)}>
                <IonCard mode="ios" color="danger" style={{ height: '4rem', width: '4rem'}}>
                  <IonCardContent>
                    <IonIcon icon={newspaperOutline} color='light' style={{ fontSize: "1.5rem" }} />
                  </IonCardContent>
                </IonCard>
                <IonLabel>
                  <h5 className="ion-justify-content-center"><strong>&nbsp;News Room</strong></h5>
                  <p className="ion-justify-content-center">&nbsp;News and Updates about Hino</p>
                </IonLabel>
              </IonItem>
            </IonCol>

          </IonList>

          <IonModal
            mode="ios"
            isOpen={showTruckListFilter}
            onDidDismiss={() => setShowTruckListFilter(false)}
            swipeToClose={true}> 
              <TruckListFilter onDismissModal={() => setShowTruckListFilter(false)} route={ROUTES.TABS_HOME_PAGE_ROUTE}/>
          </IonModal>

		    </IonGrid> 
      }

    </>
  );
};

export default HomeBuilders;
