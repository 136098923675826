import React, { useState, useCallback, useRef } from 'react';
import  { Redirect, RouteComponentProps } from 'react-router';
import { IonButtons, IonButton, IonIcon,  IonFooter, IonLabel, IonHeader, IonLoading,
    IonToolbar, IonContent, IonPage,  IonSegment, IonSegmentButton, IonModal, IonActionSheet, IonAlert, IonFabButton, IonFab, IonChip, IonRow, IonGrid } from '@ionic/react';
import { chevronForward, closeCircleOutline, ellipsisVertical, checkmark, close, 
    arrowUndo, arrowRedo, chevronDownOutline, chevronUpOutline, arrowDownCircleOutline, chevronBackCircleOutline, arrowForwardCircleOutline, 
    arrowUpCircleOutline, locateOutline, chevronBack } from 'ionicons/icons';

import useUndo from 'use-undo';
import { useMutation, useQueryClient } from 'react-query';
import { useStoreState } from 'pullstate';

import { getUser, UserPreferences } from '../../services/user';

import { CalcTable, CalcEditBody, CalcEditTrailer, CalcEditTruck } from '../../components/calculations';
import { CalcStore, CalcValues, Calculator, useLocalStore, validateRules } from '../../services/calculations';
import { TruckSpecifications } from '../../models/Truck';

import * as ROUTES from '../../config';

//import 'swiper/swiper.min.css';
import '@ionic/react/css/ionic-swiper.css';

import './calc.css';



/*interface ViewMoreProps {
    onHide: () => void;
    saveCalculations: () => void;
    //share: () => void;
    isBodyBuilder: boolean;
    bodyType: string;
}

const ViewMore: React.FC<ViewMoreProps> = ({ onHide, saveCalculations, isBodyBuilder, bodyType }) => (
    <IonList>
        <IonItem button onClick={saveCalculations} disabled={!isBodyBuilder && bodyType !== 'unknown'}><IonIcon icon={checkmarkCircleOutline}/>&nbsp;Save</IonItem>
        <IonItem lines="none" detail={false} button onClick={onHide}>
            <IonIcon icon={closeCircleOutline}/>&nbsp;Discard Changes
        </IonItem>
    </IonList>
  ); */

/** New Calculations Page */
const NewCalc: React.FC<RouteComponentProps> = ({history}) => {

    const pageRef = useRef<HTMLElement>(null);
    const user = useStoreState(UserPreferences, getUser);
	const isBodyBuilder = user.role.includes('Hino Preferred Supplier');
    const isDealership = user.role.includes('Hino Dealership');

    
    const [segment, setSegment] = useState<'gvm' | 'dimensions' | 'turn-circle'| 'bridge'>('gvm');
    const [view, setView] = useState<'front' | 'rear' | 'side'| 'top'>('side');


    const [showActionSheet, setShowActionSheet] = useState(false);
    const [showCOGSlider, setShowCOGSlider] = useState(false);
    const [COGSliderProps, setCOGSliderProps] = useState<'side' | 'top' | 'front' | 'rear' | 'payload'>('side');
    const [showEditTruckModal, setShowEditTruckModal] = useState(false);
    const [showEditBodyModal, setShowEditBodyModal] = useState(false);
    const [showEditTrailerTrailer, setShowEditTrailerTrailer] = useState(false);
    const [showShareAlert, setShowShareAlert] = useState(false);
    const [showSaveCloseAlert, setShowSaveCloseAlert] = useState(false);
    const [showView, setShowView] = useState(false);

    const [showLoading, setShowLoading] = useState(false);

    const ActionSheetButtons = [{
        text:  `${COGSliderProps !== 'payload' && showCOGSlider ? 'Hide ' : 'Show '} ${view === 'side' ? 'Horizontal' : view === 'top' ? 'Lateral' : 'Vertical'} COG Slider`,
        icon: locateOutline,
        handler: () => {
            setCOGSliderProps(view);
            COGSliderProps !== 'payload' && showCOGSlider ? setShowCOGSlider(false) : setShowCOGSlider(true);
        }
        }, {
            text: `${COGSliderProps === 'payload' && showCOGSlider ? 'Hide' : 'Show'} Payload COG Slider`,
            icon: locateOutline,
            handler: () => {
                setCOGSliderProps('payload');
                COGSliderProps === 'payload' && showCOGSlider ? setShowCOGSlider(false) : setShowCOGSlider(true);
            }
        }, {
        text: 'Cancel',
        icon: closeCircleOutline,
        role: 'cancel',
        handler: () => setShowCOGSlider(false)
        }
    ];

    /** Something to do with allowing payload cog to be updated only when view === side  */
    const filteredActionSheetButtons = view === 'side'? 
        ActionSheetButtons : ActionSheetButtons.filter((btn) => !btn.text.includes('Payload') )

    /** Get values from the store and use the values to initialise Undo / Redo States */
    const initStoreValues: CalcValues = CalcStore.useState((s: any) => s);
    const [localStoreValues, setLocalStoreValues] = useLocalStore("calculations", initStoreValues);
    
    const [
        initialValues,
        {
            set: setValues,
            reset: resetValues,
            undo: undoValues,
            redo: redoValues,
            canUndo,
            canRedo,
        },
    ] = useUndo(localStoreValues as CalcValues);
    const { present: presentValues } = initialValues;

    /** Reset Calc Values */
    const emptyCalcValues = { 
        truck: null,
        body: null,
        payload: null,
        fuelAndCrew: null,
        differences: null,
        rules: null,
        calcID: '' } as CalcValues;

    /** Hook: Set and Update CalcValues in the Undo / Redo States */
    const useSetValues = useCallback((values: CalcValues) => {

        setValues(values);
        setLocalStoreValues(values)

    }, [setValues, setLocalStoreValues]);

    /** IonPopOver Menu 
    const [present, dismiss] = useIonPopover(ViewMore, {
        onHide: async () => {

            const v = { ...emptyCalcValues}
            v.truck = presentValues.truck

            resetValues(v);
            setLocalStoreValues(v);
            localStorage.removeItem("calculations");
            sessionStorage.removeItem("calculations");
            CalcStore.update(s => {s = v});

            dismiss();

            isBodyBuilder ? history.push(ROUTES.CALCULATIONS_PAGE_ROUTE) : history.push(ROUTES.TABS_HOME_PAGE_ROUTE);
        },
        
        saveCalculations: () => {

            dismiss();

            //Post truck data so that it can be validated against rules; should be a calculation 
            const _truck = JSON.parse(presentValues.truck as string) as TruckSpecifications;
            mutate(_truck);

            setShowLoading(true);
        },

        isBodyBuilder: isBodyBuilder,
        body: presentValues.body?.type
    }); */

    /** Detailed Summary of Rules */
    const queryClient = useQueryClient();
    const { mutate } = useMutation(validateRules, {
        onSuccess: (response: any) => {
            const _truck = JSON.parse(presentValues.truck as string) as TruckSpecifications
            const specID = _truck.id;

            setShowLoading(false);

            //Update Store and Redirect to SaveCalc Page
            const v = { ...presentValues};

            v.rules = response.data;
            CalcStore.update(s => {s = v});
            history.replace(`${ROUTES.CALCULATIONS_PAGE_ROUTE}/${specID}/save`, {"values": v});
          },
          onError: (error) => {
            console.log(error)
            setShowLoading(false);

            //history.replace(`${ROUTES.CALCULATIONS_PAGE_ROUTE}/${specID}/save`, {"values": v});
            
          },
          onSettled: () => {
            queryClient.invalidateQueries('rules');
          }
    });

    /** Present Value - Truck === null, Redirect to list of Trucks*/
    if (presentValues.truck === null) {
		return (
			<Redirect to={ROUTES.TRUCK_LIST_PAGE_ROUTE} />
		);
	}

    /** Brief Summary of Rules plus Truck Description */
    const { regulations, dimensions, mass, bodyType } = Calculator(presentValues);
    const { isBridgePass, isDimensionsPass, isCirclePass, isGVMPass } = regulations;

    const isNotBus = !(JSON.parse(presentValues.truck as string) as TruckSpecifications).series.toLowerCase().includes('bus');

    /** New Calculations Render */    
    return (

        <IonPage  ref={pageRef} id="new-calculation-page">
            
            <IonHeader>

                <IonToolbar mode="ios" color="toolbar">
                    <IonButtons slot="start">
                       <IonButton 
                            onClick={() => {

                                const v = { ...emptyCalcValues}
                                v.truck = presentValues.truck

                                resetValues(v);
                                setLocalStoreValues(v);
                                localStorage.removeItem("calculations");
                                sessionStorage.removeItem("calculations");
                                CalcStore.update(s => {s = v});

                                const truck = JSON.parse(presentValues.truck as string) as TruckSpecifications
                                history.replace(`${ROUTES.TRUCK_LIST_PAGE_ROUTE}?series=${truck.series}&from=${ROUTES.CALCULATIONS_PAGE_ROUTE}`);
                            }}
                            
                            routerDirection="back">
                            <IonIcon icon={chevronBack} /> Trucks
                        </IonButton>
                    </IonButtons>

                    <IonButtons slot="end">

                        <IonButton onClick={undoValues} disabled={!canUndo}>
                            <IonIcon icon={arrowUndo} />
                            &nbsp;{`Undo (${undoValues.length})`}
                        </IonButton>

                        <IonButton onClick={redoValues} disabled={!canRedo}>
                        {`Redo (${redoValues.length})`}&nbsp;
                            <IonIcon icon={arrowRedo} />
                        </IonButton>

                        
                            

                        <IonButton
                            disabled={(isNotBus && (isBodyBuilder || isDealership) && presentValues.body === null) /*|| (isNotBus && isDealership)*/}
                            onClick={() => setShowSaveCloseAlert(true)} 
                            /*onClick={(e) => {
                                present({
                                    event: e.nativeEvent,
                                })
                            }}*/>
                            <IonIcon icon={ellipsisVertical} />
                        </IonButton>
                        

                    </IonButtons>
                    
                </IonToolbar>

                <IonToolbar>
                    <IonSegment value={segment} scrollable={true} mode='md' onIonChange={(e) => setSegment(e.detail.value as any)}>

                        <IonSegmentButton value="gvm">
                            <IonLabel>Mass</IonLabel>
                            { isGVMPass ? <IonIcon color="success" icon={checkmark}/> : <IonIcon color="danger" icon={close} />}
                        </IonSegmentButton>

                        <IonSegmentButton value="dimensions">
                            <IonLabel>Dimensions</IonLabel>
                            { isDimensionsPass ? <IonIcon color="success" icon={checkmark}/> : <IonIcon color="danger" icon={close} />} 
                        </IonSegmentButton>

                        <IonSegmentButton value="bridge">
                            <IonLabel>Bridge</IonLabel>
                            { isBridgePass ? <IonIcon color="success" icon={checkmark}/> : <IonIcon color="danger" icon={close} />}
                        </IonSegmentButton>

                        <IonSegmentButton value="turn-circle">
                            <IonLabel>Circle</IonLabel>
                            { isCirclePass ? <IonIcon color="success" icon={checkmark}/> : <IonIcon color="danger" icon={close} />}
                        </IonSegmentButton>

                    </IonSegment>
                </IonToolbar>

            </IonHeader>

            <IonContent fullscreen>

                <IonGrid>

                    <IonRow className="ion-justify-content-center ion-padding">

                        <CalcTable
                            listType={segment}
                            show={segment === "gvm"}
                            values={presentValues}
                            view={view}
                            slider={{cog: COGSliderProps, show: showCOGSlider, setValues: useSetValues }}
                        />
                        
                        <CalcTable
                            listType={segment}
                            show={segment === "dimensions"}
                            values={presentValues}
                            view={view}
                            slider={{cog: COGSliderProps, show: showCOGSlider, setValues: useSetValues }}
                        />

                        <CalcTable
                            listType={segment}
                            show={segment === "turn-circle"}
                            values={presentValues}
                            view={view}
                            slider={{cog: COGSliderProps, show: showCOGSlider, setValues: useSetValues }}
                        />
                        
                        <CalcTable
                            listType={segment}
                            show={segment === "bridge"}
                            values={presentValues}
                            view={view}
                            slider={{cog: COGSliderProps, show: showCOGSlider, setValues: useSetValues }}
                        />
                    </IonRow>

                </IonGrid>

                <IonModal
                    mode="ios"
                    isOpen={showEditBodyModal}
                    onDidDismiss={() => setShowEditBodyModal(false)}
                    swipeToClose={true}
                    //presentingElement={pageRef.current!}
                    cssClass="truck-list-filter">
                    <CalcEditBody
                        onDismissModal={() => setShowEditBodyModal(false)}
                        values={presentValues}
                        setValues={useSetValues}
                    />
                </IonModal> 

                <IonModal
                    mode="ios"
                    isOpen={showEditTrailerTrailer}
                    onDidDismiss={() => setShowEditTrailerTrailer(false)}
                    swipeToClose={true}
                    //presentingElement={pageRef.current!}
                    cssClass="truck-list-filter">
                    <CalcEditTrailer
                        onDismissModal={() => setShowEditTrailerTrailer(false)}
                        values={presentValues}
                        setValues={useSetValues}
                    />
                </IonModal>

                <IonModal
                    mode="ios"
                    isOpen={showEditTruckModal}
                    onDidDismiss={() => setShowEditTruckModal(false)}
                    swipeToClose={true}
                    //presentingElement={pageRef.current!}
                    cssClass="truck-list-filter">
                    <CalcEditTruck
                        onDismissModal={() => setShowEditTruckModal(false)}
                        values={presentValues}
                        setValues={useSetValues}
                    />
                </IonModal>

                {
                    bodyType.toLowerCase() !== 'unknown' && segment === 'dimensions' &&

                    <IonFab
                        vertical="bottom"
                        horizontal="end"
                        edge slot="fixed">
                        <IonFabButton
                            color="primary"
                            onClick={() => setShowActionSheet(true)}>
                            <IonIcon icon={locateOutline} /> 
                        </IonFabButton>
                    </IonFab>
                }

                <IonAlert
                    isOpen={showShareAlert}
                    header="Alert"
                    subHeader="Cannot Save your Specification"
                    message="Saving specifications as a salesperson is not yet supported"
                    onDidDismiss={() => setShowShareAlert(false)}
                    //cssClass='my-custom-class'
                    buttons={[ /*{
                    text: 'Ok',
                    handler: () => {
                        console.log(`Share via 'email' selected`)
                    }
                    }, */ {
                    text: 'Ok',
                    role: 'cancel',
                    handler: () => {
                        //resetValues(initStoreValues);
                        //history.replace(ROUTES.CALCULATIONS_TABS_ROUTE)
                    }
                    }]}
                />

                <IonAlert
                    isOpen={showSaveCloseAlert}
                    header="Save Specifications"
                    subHeader="Would you like to save your specifications or exit?"
                    //message="Would you like to save your specifications or exit?"
                    onDidDismiss={() => setShowSaveCloseAlert(false)}
                    //cssClass='my-custom-class'
                    buttons={[
                    {
                    text: 'Save',
                    handler: () => {
                        //Post truck data so that it can be validated against rules; should be a calculation 
                        const _truck = JSON.parse(presentValues.truck as string) as TruckSpecifications;
                        if (isBodyBuilder) {
                            mutate(_truck);
                            setShowLoading(true);
                        }

                        if (isDealership) setShowShareAlert(true)
                            
                    }
                    }, {
                    text: 'Discard Changes and Exit',
                    role: 'cancel',
                    handler: async () => {
                        const v = { ...emptyCalcValues}
                        v.truck = presentValues.truck
            
                        resetValues(v);
                        setLocalStoreValues(v);
                        localStorage.removeItem("calculations");
                        sessionStorage.removeItem("calculations");
                        CalcStore.update(s => {s = v});
                        history.push(ROUTES.CALCULATIONS_PAGE_ROUTE);
                    }
                    }, {
                        text: 'Cancel',
                        
                        handler: async () => {
                            
                        }}]}
                />

                <IonActionSheet
                    isOpen={showActionSheet}
                    onDidDismiss={() => setShowActionSheet(false)}
                    buttons={filteredActionSheetButtons}
                    mode="ios"
                >
                </IonActionSheet>

                <IonLoading
                    //cssClass='my-custom-class'
                    isOpen={showLoading}
                    onDidDismiss={() => setShowLoading(false)}
                    message={'Validating your Specifications against AARTO Regulations. Please wait...'}
                />
                
            </IonContent>

            

            <IonFooter>

                {
                    segment === 'dimensions' &&

                    <>

                        <IonToolbar>
                            <IonButtons slot="start">
                                <IonButton 
                                    onClick={() => showView ? setShowView(false) : setShowView(true)}>
                                        View&nbsp;
                                    {showView ? <IonIcon icon={chevronDownOutline}/> : <IonIcon icon={chevronUpOutline}/>}
                                </IonButton>
                            </IonButtons>
                        </IonToolbar>

                        {
                            showView &&

                            <IonToolbar>
                                <IonButtons slot="start">
                                    <IonChip 
                                        color={view === 'front' ? "medium" : "primary"}
                                        //disabled={view === 'front'}
                                        disabled
                                        onClick={() => setView('front')}>
                                        <IonIcon icon={arrowForwardCircleOutline} />
                                        &nbsp;&nbsp;Front
                                    </IonChip>
                                    <IonChip
                                        color={view === 'rear' ? "medium" : "primary"}
                                        //disabled={view === 'rear'}
                                        disabled
                                        onClick={() => setView('rear')} >
                                        <IonIcon icon={chevronBackCircleOutline} />
                                        &nbsp;&nbsp;Rear
                                    </IonChip>
                                    <IonChip
                                        color={view === 'side' ? "medium" : "primary"}
                                        disabled={view === 'side'}
                                        onClick={() => setView('side')}>
                                        <IonIcon icon={arrowUpCircleOutline} />
                                        &nbsp;&nbsp;Side
                                    </IonChip>
                                    <IonChip 
                                        color={view === 'top' ? "medium" : "primary"}
                                        //disabled={view === 'top'}
                                        disabled
                                        onClick={() => setView('top')}>
                                        <IonIcon icon={arrowDownCircleOutline} />
                                        &nbsp;&nbsp;Top
                                    </IonChip>
                                </IonButtons>
                            </IonToolbar>
                        }
                    </>
                }

                <IonToolbar color="toolbar">
   
                    <IonButtons slot="start">
                        <IonButton 
                            onClick={() => setShowEditTruckModal(true)}>
                                {isNotBus ? 'Truck' : 'Bus'}
                            <IonIcon icon={chevronForward} /> 
                        </IonButton>

                        {
                            //Don't allow Add Body on Trucks with fifth Wheels
                            (dimensions.f5wh === 0 && isNotBus) &&

                            <IonButton
                                onClick={() => setShowEditBodyModal(true)}>
                                    Body
                                {/*<FontAwesomeIcon icon={faTruckMoving} size="lg"/>*/}
                                <IonIcon icon={chevronForward} />
                            </IonButton>
                        }

                        {
                            //Don't allow update of trailer if body is null and / or truck does not have GCM
                            bodyType.toLowerCase() !== 'unknown' && mass.gcm > 0 &&

                            <IonButton 
                                onClick={() => setShowEditTrailerTrailer(true)} disabled>
                                    Trailer
                                <IonIcon icon={chevronForward} />
                            </IonButton>
                        }

                        {
                            //Don't allow update of trailers if truck does not have a fifth wheel 
                            dimensions.f5wh > 0 &&

                            <IonButton 
                                onClick={() => setShowEditTrailerTrailer(true)} disabled>
                                    Trailer(s)
                                {/*<FontAwesomeIcon icon={faTruckLoading} size="lg"/>*/}
                                <IonIcon icon={chevronForward} />
                            </IonButton>
                        }
                    </IonButtons>

                </IonToolbar>
            </IonFooter>  
        </IonPage>
    );
}

export default NewCalc;
