import * as URLS from '../config';

export const Guidelines = [
    {url: URLS.GUIDELINES_ROH_URL, title: "How to Extend Rear Over Hang (R.O.H)", tag: "ROH", topic: "general-precautions"},
    {url: URLS.GUIDELINES_SUBFRAME_URL, title: "Recommended Structure of Body Subframe", tag: "Subframe", topic: "general-precautions"},

    {url: URLS.GUIDELINES_ELECTRIC_WELDING_WORK_URL, title: "Electric Welding Work", tag: "Electric Welding Work", topic: "general-precautions"},
    {url: URLS.GUIDELINES_CAB_TILTING_URL, title: "Precaution for Cab Tilting", tag: "Cab Tilting", topic: "general-precautions"},
    {url: URLS.GUIDELINES_EBSVSC_URL, title: "Precautions for EBS/VSC", tag: "EBS/VSC", topic: "general-precautions"},

    {url: URLS.GUIDELINES_BODY_ATTACHMENTS_URL, title: "Recommended Positions of Body Attachments", tag: "Body Attachments", topic: "general-precautions"},

    {url: URLS.GUIDELINES_FUSE_BLOCK_URL, title: "Fuse Block, Relay Panel and Fusible Link Block", tag: "Fuse Block, Relay Panel and Fusible Link Block", topic: "electrical-systems"},
    {url: URLS.GUIDELINES_TURN_LAMP_URL, title: " Countermeasure for LED Side Turn Lamp of Trailer", tag: "LED Side Turn Lamp of Trailer", topic: "electrical-systems"}
]

export const AARTORegulations = [
    {title: "Overall Length", value: "11.3 m", description: "Trailer with one axle or one axle unit, maximum overall length is 11.3 m", regulation: "221 (b) (i)"},
    {title: "Overall Length", value: "12.5 m", description: "Trailer with two axles or one or more axle units, maximum overall length is 12.5 m", regulation: "221 (c)"},
    {title: "Overall Length", value: "12.5 m", description: "Any other vehicle, maximum overall length is 12.5 m", regulation: "221 (f)"},
    {title: "Overall Length", value: "18.5 m", description: "An articulate motor vehicle, maximum overall length is 18.5 m", regulation: "221 (d)"},
    {title: "Overall Length", value: "22.0 m", description: "Combination of vehicles, maximum overall length is 22.0 m", regulation: "221 (g)"},

    {title: "Overall Width", value: "2.5 m", description: "Goods vehicle, the gross vehicle mass of which does not exceed 12 000 kg, maximum overall width is 2.5 m", regulation: "223 (b) & (c)"},
    {title: "Overall Width", value: "2.6 m", description: "Goods vehicle, the gross vehicle mass of which exceeds 12 000 kg, maximum overall width is 2.6 m", regulation: "223 (a)"},
 
    {title: "Overall Height", value: "4.3 m", description: "All goods vehicles, maximum overall height is 4.3 m", regulation: "224 (b)"},

    {title: "Wheelbase", value: "10.0 m", description: "Semi-trailer, maximum wheelbase is 10.0 m", regulation: ""},
    {title: "Wheelbase", value: "8.5 m", description: "Trailer, maximum wheelbase is 8.5 m", regulation: ""},
    {title: "Wheelbase", value: "8.5 m", description: "All other goods vehicles, maximum wheelbase is 8.5 m", regulation: ""},

    {title: "Rear Overhang", definition: "The rear overhang is measured from the rear most axle", value: "70 %", description: "Refuse collectors, road making & road construction vehicles and farming vehicles, maximum rear overhang is 70% of wheelbase", regulation: "226 (2) (a)"},
    {title: "Rear Overhang", definition: "The rear overhang is measured from the rear most axle", value: "50 %", description: "A trailer with one axle or one axle unit (excluding a semi-trailer), maximum rear overhang is 50% of body length", regulation: "226 (2) (b)"},
    {title: "Rear Overhang", definition: "The rear overhang is measured from the rear most axle", value: "60 %", description: "Any goods vehicles, maximum rear overhang is 60% of wheelbase", regulation: "226 (2) (c)"},

    {title: "Front Overhang", definition: "In the case of a front axle unit, the front overhang is measured from the foremost axle and not the centre of the axle unit", value: "", description: "Goods vehicle, 60% of wheelbase, or if the driver's seat is not more than 1.7 m from the front end, then maximum front overhang is 6.2 m minus half the wheelbase", regulation: ""},
    {title: "Front Overhang", definition: "In the case of a front axle unit, the front overhang is measured from the foremost axle and not the centre of the axle unit", value: "", description: "Goods vehicle, 60% of wheelbase, or any other goods vehicle (including a trailer), maximum front overhang is 5.8 m minus half the wheelbase", regulation: ""},
    {title: "Front Overhang", definition: "In the case of a front axle unit, the front overhang is measured from the foremost axle and not the centre of the axle unit", value: "1.8 m", description: "Semi-trailer, maximum front overhang is 1.8 m", regulation: "226 (a) (i) or (ii)"},




    {title: "Permissible Maximum Axle Unit Massloads", value: "7 700 kg", description: "Single Axle (steering) - Singles, maximum axle unit massloads is 7 700 kg", regulation: ""},
    {title: "Permissible Maximum Axle Unit Massloads", value: "8 000 kg", description: "Single Axle - Singles, maximum axle unit massloads is 8 000 kg", regulation: ""},
    {title: "Permissible Maximum Axle Unit Massloads", value: "9 000 kg", description: "Single Axle - Dual tyres (4 Tyres), maximum axle unit massloads is 9 000 kg", regulation: ""},
    {title: "Permissible Maximum Axle Unit Massloads", value: "16 000 kg", description: "Tandem Axle unit - Single Tyres, maximum axle unit massloads is 16 000 kg", regulation: ""},
    {title: "Permissible Maximum Axle Unit Massloads", value: "18 000 kg", description: "Tandem Axle unit - Dual Tyres, maximum axle unit massloads is 18 000 kg", regulation: ""},
    {title: "Permissible Maximum Axle Unit Massloads", value: "24 000 kg", description: "Tridem Axle unit - Singles or Duals, maximum axle unit massloads is 24 000 kg", regulation: ""},
    
    {title: "Bridge Formula", value: "", description: "Bridge Formula: (2100 X [measured in metres and tenths of a metre from the centre of the first axle of any group of axles to the centre of the last axle of such group]) + 18000 kg", regulation: ""},
    
    {title: "General Restrictions", value: "", description: "A combination of motor vehicles may consist of a truck drawing two drawbar trailers.", regulation: "222 (1) (i)"},
    {title: "General Restrictions", value: "", description: "A combination of motor vehicles may consist of a truck-tractor drawing a semi-trailer and a drawbar trailer.", regulation: "222 (1) (ii)"},
    {title: "General Restrictions", value: "", description: "A combination of motor vehicles may consist of a truck-tractor and two semi-trailers (an interlink).", regulation: "222 (1) (iii)"},
    {title: "General Restrictions", value: "", description: "A combination is limited to a drawing vehicle and one or two trailers (a converter dolly shall, when used in combination with a semi-trailer, be deemed not to be a trailer).", regulation: ""},
    {title: "General Restrictions", value: "56 000 kg", description: "Maximum Permissible Combination Mass is 56 000 kg", regulation: ""},
    {title: "General Restrictions", value: "30 %", description: "The load on the steering axles may not be less than 30 % of the gross mass if the vehicle is fitted with a steering axle unit.", regulation: ""},
    {title: "General Restrictions", value: "20 %", description: "The load on the steering axles may not be less than 20 % of the gross mass if the vehicle is not an articulated vehicle.", regulation: ""},
    {title: "General Restrictions", value: "11 %", description: "The load on the steering axles may not be less than 11 % of the gross mass in the case of articulated vehicle.", regulation: ""},
    {title: "General Restrictions", value: "20 %", description: "Minimum 20 % of GCM on driving axle/s (laden or unladen condition).", regulation: ""},
    {title: "General Restrictions", value: "", description: "Vehicle or combination mass in kg not to exceed nett engine power in kilowatts x 240.", regulation: ""},
    {title: "General Restrictions", value: "", description: "All vehicles to be plated showing maximum design and maximum legal (permitted) loads.", regulation: ""},
]