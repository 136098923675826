export interface Regulations {
  totalMass: number;
  truckPower: number;
  hasTrailer: boolean;
  numberOfAxels: number;
  isArticulatedVehicle: boolean;
  isCombinationOfVehicles: boolean;
  totalVehicleLength: number;
}

export class MassRegulations {
  steeringAxle: string = "7500"; //kg
  nonSteeringAxle: string = "9000"; //kg
  maxMass: string = "56000"; //kg
  minLoadOnSteeringAxleArticulatedVehicle: string = "11"; //%
  minLoadOnSteeringAxleNonArticulatedVehicle: string = "20"; //%
  minLoadOnSteeringAxleGoodsVehicle: string = "30"; //%
 }

export class DimensionsRegulations {
  maxLength: string = "22.5"; //metres
  maxHeight: string = "4.3"; //metres
  maxWidthGVMgt12: string = "2.6"; //metres
  maxWidthGVMlt12: string = "2.5"; //metres
  maxWB: string = "12.5"; //metres
  maxROH: string = "60"; //percent
}

export class CircleRegulations {
  maxRadius: string = "13.1"; //metres
}


