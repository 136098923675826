import { Truck, TruckSpecifications } from '../../models/Truck';
import { useFetchDataFromDB } from '../DataApi';
import { TRUCKS_API_ENDPOINT, HINO_SERIES_FROM_WEBSITE_URL, HINO_MODEL_FROM_WEBSITE_URL, PRICES_API_ENDPOINT } from '../../config';
import { useQuery } from 'react-query';

type Trucks = Truck;

/**Query Cache Instance: Caches trucks returned from the DB */
/*const queryCache = new QueryCache({
    onError: error => {
      console.log(error)
    },
  }); */

/** Hook: Returns Default Trucks from the DB */
export const useDefaultTrucks = (series: string | null) => {

  const { data, isLoading, error, status } = useFetchDataFromDB('trucks', TRUCKS_API_ENDPOINT);
  const { data: prices, isLoading: isLoadingPrices, error: isErrorLoadingPrices } = useFetchDataFromDB('prices', PRICES_API_ENDPOINT);

  const filteredData = isLoading ? [] :
                          series !== null ? (data as unknown as Array<TruckSpecifications>).filter(d => d.series === series)
                                        :
                                        data
  const defaultTrucks = transformTruckSpecs("truck", filteredData, prices, isLoadingPrices);

  return { defaultTrucks, data, isLoading, error, status, prices, isErrorLoadingPrices, isLoadingPrices };
};

/** Hook: Returns Trucks from the Hino Website */
export const useTrucksFromWebsite = (SMC?: string) => {
    const { data, isLoading, isError, status } = useQuery(['trucks', 'series'], () => fetch(HINO_SERIES_FROM_WEBSITE_URL).then(res => res.json()), { useErrorBoundary: true });
    const { data: model, isLoading: isLoadingModel, isError: isErrorModel, status: statusModel } = useQuery(['trucks', `model-${SMC}`], () => fetch(`${HINO_MODEL_FROM_WEBSITE_URL}=${SMC}`).then(res => res.json()), { useErrorBoundary: true });

    return { data, model, isLoading, isLoadingModel, isError, isErrorModel, status, statusModel }
};

/** Function: Transforms TruckSpecifications into Trucks */
export const transformTruckSpecs = (key: string, data: any, prices: any, isLoadingPrices: boolean) => {

    /** Hack: Transforming TruckSpecifications Object into a Truck models Array*/
    let models: TruckSpecifications[] = [];

    /** data not empty, push models into models Array */
    if (data && !isLoadingPrices) data.forEach((d: TruckSpecifications) => {
      const price = prices.filter((p: any) => p.SMC === d.chassisModel);
      d.price = price[0] === undefined ? "" : price[0].price;
      return models.push(d)
    })

    /** Save Models on Local Storage / Cache / State to read them later on */
    JSON.parse(JSON.stringify(models)).map((truck: any) => {
        localStorage.setItem(`${key}:${truck['chassisModel']}`, JSON.stringify(truck))
      return true;
    });

    let grouped: [] = []

    models.forEach(item => getSeries(grouped, item.series).models.push(item))

    /** Convert TruckSpecifications to a Truck */
    let s: string = `{"series":${JSON.stringify(grouped)}}`;

    return JSON.parse(s) as Trucks;
};

/** Helper Function: Groups trucks by series */
const getSeries = (groups: any, series: string) => {
  let group = groups.find((g: any) => g.series === series);

  if (!group) {
    group = ({series, "name": series, models:[]});
    groups.push(group);
  }

  return group;
};







