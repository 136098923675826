import { ModelTruck, ModelBody } from "../../../models";
import { CalcValues } from '../';
import { TruckAdapter } from "./TruckAdapter";
import { DefaultBodySpec } from "../../../models/Body";

interface Material {
    material: string;
    value: string;
}

const dbs = new ModelBody.DefaultBodySpec();

let 
bodyType: string, position: number, defaultHeight: number,

//Body Height
bh: number, ibh: number, sfh: number, ssh: number, rth: number, croh: number, vcog: number,

//Body Length
bl: number, hbl: number, ibl: number, hb: number, tb: number, gap: number, hcog: number,

//Body Width
bw: number, rbw: number, lbw: number, ibw: number, lcog: number,

//Body Mass
fcm: number, gfm: number, material: string, mode: string, rcm: number, sgm: number, tgm: number, tm: number, udl: number,

//Payload
pcog: number, pwt: number;


/** Function: returns body mass and dimension values */
export const BodyAdapter = (values: CalcValues) => {

    const { ...mass} = massAdapter(values);
    const { ...dimensions} = dimensionsAdapter(values);
    const { ...truck} = TruckAdapter(values)

    return {dimensions, mass, truck};
} 

/** Helper Function: returns body dimensions */
const dimensionsAdapter = (values: CalcValues) => {

    const body = values.body;
    const payload = values.payload;
    const truck = JSON.parse(values.truck as string) as ModelTruck.TruckSpecifications;
    const { ...cog} = massAdapter(values);

    //Default Body Specification
    const dbs = new DefaultBodySpec();
    const headboard: string = body !== null && body.type !== null && body.type !== undefined && (dbs as any)[body.type.toLowerCase()]['headboardLenght'] !== undefined ? (dbs as any)[body.type.toLowerCase()]['headboardLenght'] : "50";
    const tailboard: string = body !== null && body.type !== null && body.type !== undefined && (dbs as any)[body.type.toLowerCase()]['tailboardLenght'] !== undefined? (dbs as any)[body.type.toLowerCase()]['tailboardLenght'] : "50";

    //const fas = +(truck.wbfas || 0);
    const ras = +(truck.wbRas || 0);

    const ca = +(truck.cabToRearAxleCentre || 0) + (+(ras/2 || 0));

    const roh = +(truck.rearOverhang || 0) + (+(ras/2 || 0));
    const trbw = +(truck.recommendedMinBodyWidth || 0) > 0 ? 
                            +truck.recommendedMinBodyWidth : +truck.cabWidth;
    const tcrg = +(truck.cabRecommendedGap || 0);

    //Body Length
    gap = +(body?.length?.cabGapRecommendedMin || tcrg);
    bl = +(body?.length?.bodyLengthExternalInclRearDoors || ca + roh - gap);

    hb = +(body?.length?.guardframeLength || headboard);
    tb = +(body?.length?.tailgateLength || tailboard);

    ibl = +(body?.length?.bodyLengthInternal || bl - hb - tb);
    hcog = +(body?.length?.centreOfGravity || cog.hcog);

    //Body Width
    bw = +(body?.width?.bodyWidthExternal || trbw);
    ibw = +(body?.width?.loadCompartmentWidth || bw - lbw - rbw);
    lbw = +(body?.width?.leftBodyThickness || 50);
    rbw = +(body?.width?.rightBodyThickness || 50);
    lcog = +(body?.width?.centreOfGravity || cog.lcog);

    //Body Height
    bh = +(body?.height?.bodyHeightExternal || cog.defaultHeight);
    ibh = +(body?.height?.loadCompartmentHeight || bh - ssh - rth);
    sfh = +(body?.height?.subFrameHeight || 150);
    position = +(body?.height?.bodyPosition || 0);
    ssh = +(body?.height?.floorHeight || 100);
    rth = +(body?.height?.roofHeight || 0);
    vcog = +(body?.height?.centreOfGravity || cog.vcog);
    
    //Payload
    //console.log(`${pcog}-${hcog}-${bl}-${ibl}`)
    //pcog = +(payload?.cog || (ca - (bl*hcog/100 + gap)));
    //pcog = +(payload?.cog || ibl*hcog/100)
    pcog = +(payload?.cog || 50);
    pwt = +(payload?.payload || 0);

    return {
        length: { gap, bl, ibl, hb, tb, hcog },
        width: { bw, ibw, lbw, rbw, lcog },
        height: { bh, ibh, ssh,sfh,  rth, vcog, position },
        payload: { pcog, pwt }
    }
}

/** Helper Function: returns body mass values */
export const massAdapter = (values: CalcValues) => {

    const body = values.body;

    bodyType = body?.type !== null && body?.type !== undefined ? body!.type.charAt(0).toUpperCase() + body!.type.slice(1) : 'unknown';

    //Body Mass
    fcm = +(body?.mass?.floorConstructMass || 0);
    gfm = +(body?.mass?.guardframeMass || 0);
    material = body?.mass?.material || 'steel';
    mode = body?.mass?.mode || 'udl';
    rcm = +(body?.mass?.roofConstructMass || 0); 
    sgm = +(body?.mass?.sidegateMass || 0); 
    tgm = +(body?.mass?.tailgateMass || 0);
    tm = +(body?.mass?.totalMass || 0);
    udl = +(body?.mass?.udl || 0);

    lcog = body?.width?.centreOfGravity || (dbs as any)[bodyType.toLowerCase()]['lcog'] || "50"; 
    vcog = body?.height?.centreOfGravity || (dbs as any)[bodyType.toLowerCase()]['vcog'] || "0"; 
    hcog = body?.length?.centreOfGravity || (dbs as any)[bodyType.toLowerCase()]['hcog'] || "50"; 
    
    defaultHeight = (dbs as any)[bodyType.toLowerCase()]['defaultHeight'] || "0";
    
    return { 
        bodyType, material, mode,
        hcog, lcog, vcog, defaultHeight,
        fcm, gfm, rcm, sgm, tgm, 
        tm, udl
    };

}