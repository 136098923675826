import { useState } from "react";
import { IonButton, IonButtons, IonCol, IonIcon, IonInput, IonItem, IonLabel, IonText, isPlatform } from "@ionic/react";
import { chevronDown, chevronUp, construct } from "ionicons/icons";
import { Calculator, CalcValues } from "../../../../services/calculations";
import {  } from "../../../../services/calculations/utils";
import { HeightExternal, HeightSubframe, HeightSubstructure, LenghtExternal, LenghtHeadboard, 
    LenghtInternal, LenghtTailboard, WidthExternal, WidthInternal, WidthLeftThickness, 
     WidthRightThickness } from "./Dimensions";

interface TimberProps {
    values: CalcValues;
    setValues: (v: CalcValues) => void;
}

const Timber: React.FC<TimberProps> = ({values, setValues}) => {

    const ios = isPlatform('ios');
    const { ...truck } = Calculator(values);

    const [showHeight, setShowHeight] = useState(false);
    const [showLength, setShowLength] = useState(false);
    const [showWidth, setShowWidth] = useState(false);
    const [showUprights, setShowUprights] = useState(false);

    return (

        <>
            <IonItem color="light">
                <IonText className="ion-padding">Length</IonText>
                <IonButtons slot="end">
                {
                    !showLength &&
                    <IonButton onClick={() => {
                        setShowWidth(false);
                        setShowHeight(false);
                        setShowLength(true);
                        setShowUprights(false);
                    }}>
                    <IonIcon icon={chevronDown} slot="icon-only" />
                    </IonButton>}
                {
                    showLength &&
                    <IonButton onClick={() => setShowLength(false)}>
                    <IonIcon icon={chevronUp} slot="icon-only" />
                    </IonButton>
                }
                </IonButtons>
            </IonItem>

            {
                showLength &&

                <>
                    <LenghtExternal title="Length (External)" description="Overall body length" values={values} setValues={setValues} />
                    <LenghtInternal title="Length (Internal)" description="Load compartment length" values={values} setValues={setValues} />
                    <LenghtHeadboard title="Headboard" description="Headboard" values={values} setValues={setValues} />
                    <LenghtTailboard title="Tailboard" description="Tailboard" values={values} setValues={setValues} />
                </> 
            }

            <IonItem color="light">
                <IonText className="ion-padding">Height</IonText>
                <IonButtons slot="end">
                {
                    !showHeight &&
                    <IonButton onClick={() => {
                        setShowWidth(false);
                        setShowHeight(true);
                        setShowLength(false);
                        setShowUprights(false);
                    }}>
                    <IonIcon icon={chevronDown} slot="icon-only" />
                    </IonButton>}
                {
                    showHeight &&
                    <IonButton onClick={() => setShowHeight(false)}>
                    <IonIcon icon={chevronUp} slot="icon-only" />
                    </IonButton>
                }
                </IonButtons>
            </IonItem>

            {
                showHeight &&

                <>
                    <HeightSubframe title="Subframe Height" description="Subframe height" values={values} setValues={setValues} />
                    <HeightExternal title="Headboard Height" description="Headboard height" values={values} setValues={setValues} />
                    <HeightSubstructure title="Substructure Height" description="Substructure Height" values={values} setValues={setValues} />
                </>  
            }

            <IonItem color="light">
                <IonText className="ion-padding">Width</IonText>
                <IonButtons slot="end">
                {
                    !showWidth &&
                    <IonButton onClick={() => {
                        setShowWidth(true);
                        setShowHeight(false);
                        setShowLength(false);
                        setShowUprights(false);
                    }}>
                    <IonIcon icon={chevronDown} slot="icon-only" />
                    </IonButton>}
                {
                    showWidth &&
                    <IonButton onClick={() => setShowWidth(false)}>
                    <IonIcon icon={chevronUp} slot="icon-only" />
                    </IonButton>
                }
                </IonButtons>
            </IonItem>

            {
                showWidth &&

                <>
                    <WidthExternal title="Width (External)" description="Overall body width" values={values} setValues={setValues} />
                    <WidthInternal title="Width (Internal)" description="Load compartment width" values={values} setValues={setValues} />
                    <WidthLeftThickness title="Left Sidewall" description="Thickness of left sidewall" values={values} setValues={setValues} />
                    <WidthRightThickness title="Right Sidewall" description="Thickness of right sidewall" values={values} setValues={setValues} />
                </>   
            }

            <IonItem color="light">
                <IonText className="ion-padding">Uprights</IonText>
                <IonButtons slot="end">
                {
                    !showUprights &&
                    <IonButton onClick={() => {
                        setShowWidth(false);
                        setShowHeight(false);
                        setShowLength(false);
                        setShowUprights(true);
                    }}>
                    <IonIcon icon={chevronDown} slot="icon-only" />
                    </IonButton>}
                {
                    showUprights &&
                    <IonButton onClick={() => setShowUprights(false)}>
                    <IonIcon icon={chevronUp} slot="icon-only" />
                    </IonButton>
                }
                </IonButtons>
            </IonItem>

            {
                showUprights &&

                <>
                    <IonItem lines="none">
                        <IonCol size="8">
                            { ios &&
                                <IonIcon slot="start" icon={construct} color="medium" />
                            }
                            <IonLabel>
                                <h3>Uprights</h3>
                                <p>Something cool is cooking</p>
                            </IonLabel>
                        </IonCol>
                        <IonCol size="4">
                                <IonInput color="primary" placeholder={`${truck.dimensions.bw} mm`} onIonChange={e => {
                                   // bW = { ...bodyWidth};
                                   // bW.bodyWidthExternal = e.detail.value || "0";
                                   // setBodyWidth(bW);
                                }}></IonInput>
                        </IonCol>
                    </IonItem>
                </>   
            }
        </>
    );

}

export default Timber;