import { useEffect, useRef, useState } from 'react';
import { IonContent, IonHeader, IonPage, IonButton, IonToolbar, IonRow, IonGrid, IonCol, IonCard, IonCardTitle, IonCardContent, IonTitle, IonLabel, IonInput, IonRadioGroup, IonRadio, IonItem, isPlatform } from '@ionic/react';

import { useStoreState } from 'pullstate';
import { UserPreferences, getUser, setUser } from '../../services/user';
import { usePostDataToDB } from '../../services/DataApi';

import * as ROUTES from '../../config';

import '../Home.css';
import '../PreferredSupplier.css';



const Profile: React.FC = () => {

  const pageRef = useRef<HTMLElement>(null);
  const ios = isPlatform('ios');

  const user = useStoreState(UserPreferences, getUser);
  const route = user.route; //From account or not

  const _role = route === null ? null : user.role[0];
  const _org = route === null ? null : user.org;

  const { isLoading, data, mutate } = usePostDataToDB('user', ROUTES.USERS_API_ENDPOINT);

  const [showEditProfile, setShowEditProfile] = useState(route !== null);

  const [name, setName] = useState<string | null | undefined>(null);
  const [role, setRole] = useState<string | null | undefined>(_role);
  const [org, setOrg] = useState<string | null | undefined>(_org);

  useEffect(() => {

    const _email = user.email!;
    const _accessToken = user.accessToken;
    const _role = [`${role}`];
    const _name = name!;
    const _org = org!;
    const _sub = user.userid;
    
    if (data === 'success') {
      setUser(_email, _name, _role, _org, _accessToken, _sub)
    }
  
  }, [data, user, role, name, org])


  //const error = errors && errors.filter(e => e.id === field.id)[0];
  //const errorMessage = error && errors.filter(e => e.id === field.id)[0].message;

  //console.log(user)


  return (
    <IonPage ref={pageRef} id="Profile-page">
      {
        ios &&

        <IonHeader>
          <IonToolbar color="toolbar">
            <IonTitle color='light'>Hino Truck Body Builders App</IonTitle>
          </IonToolbar>
        </IonHeader>
      }
      
      <IonContent color="light" fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar color="light">
              <IonButton fill="clear" slot="start" routerLink={ROUTES.TABS_ACCOUNT_PAGE_ROUTE}>
                <img src="/assets/images/hino-logo.svg" height={'30px'} width={'auto'} alt="hino" />
              </IonButton>
          </IonToolbar>
        </IonHeader>

        {
          !ios &&
        
          <IonToolbar color="light" className='ion-margin-top ion-padding-top ion-margin-bottom ion-padding-bottom'>
            <IonButton fill="clear" slot="start" routerLink={ROUTES.TABS_ACCOUNT_PAGE_ROUTE}>
              <img src="/assets/images/hino-logo.svg" height={'30px'} width={'auto'} alt="hino" />
            </IonButton>
          </IonToolbar>
        
        }

        <IonGrid>
          <IonRow className="ion-justify-content-center ion-text-center">
    
            <IonCol sizeSm="12" sizeMd="10" sizeLg="10">

              <IonRow>
                
                {
                  route === null &&

                  <IonCol size="12" sizeMd="10" sizeLg="10">
                  {
                    !showEditProfile &&
                    <IonCard mode="ios" color="dark" className="ion-text-center">
                      <IonCardContent className="ion-justify-content-center">
                        {/*<IonIcon icon={personAddOutline} color="primary" style={{ fontSize: "2rem" }} />*/}
                        <IonCardTitle mode="ios">Welcome to the Hino Truck Body Builders App</IonCardTitle>
                        <h2 className="ion-margin ion-padding"><strong>Let's Personalise your experience</strong></h2>
                        <IonRow className="ion-justify-content-center"> 
                          <img src={`/assets/images/welcome.svg`} height="150" alt="welcome"/>
                        </IonRow>
                        <IonButton
                          fill="solid"
                          className="ion-margin-top" 
                          onClick={() => setShowEditProfile(true)}
                          >Get Started &rarr;
                        </IonButton>
                          
                      </IonCardContent>
                    </IonCard>
                  }

                  {
                    showEditProfile && data !== 'success' &&

                    <IonCard mode="ios" color="dark" className="ion-text-center">
                      <IonCardContent className="ion-justify-content-center">

                        <IonCardTitle mode="ios" className="ion-padding">Update your Profile</IonCardTitle>
                        
                        <IonRow className="ion-justify-content-center">
                          <h2 className="ion-margin ion-padding"><strong>Who would you like to be called?</strong></h2>
                          <div>
                            <IonInput style={{backgroundColor: "var(--ion-color-dark-tint)", color: "white", borderRadius: "10px"}} onIonChange={(e) => setName(e.detail.value)}/>
                          </div> 
                        </IonRow>

                        <hr/>

                      {
                        name !== null && name !== undefined && name.length > 2 &&

                        <IonRow className="ion-justify-content-center">

                          <h2 className="ion-margin ion-padding"><strong>Hi, {name}. You are a part of ... </strong></h2>

                          <div>

                            <IonRadioGroup onIonChange={e => setRole(e.detail.value)}>

                              <IonItem color="dark">
                                  <IonLabel>
                                    <h3>A Hino Dealership</h3>
                                  </IonLabel>
                                  <IonRadio value="Hino Dealership"/> 
                              </IonItem>
                              <IonItem color="dark">
                                  <IonLabel>
                                    <h3>A Hino Preferred Supplier</h3>
                                  </IonLabel>
                                  <IonRadio value="Hino Preferred Supplier" /> 
                              </IonItem>
                                
                            </IonRadioGroup>
                          </div>
                        </IonRow>
                  
                      }

                      <hr/>

                      {
                        name !== null && name !== undefined && name.length > 2 && role !== null &&

                        <IonRow className="ion-justify-content-center">

                          <h2 className="ion-margin ion-padding"><strong>Which {role}? </strong></h2>

                          <div>
                            <IonInput 
                              style={{backgroundColor: "var(--ion-color-dark-tint)", color: "white", borderRadius: "10px"}} 
                              onIonChange={e => setOrg(e.detail.value)}/>
                          </div>
                        </IonRow>
                      }

                      
                        <IonButton
                          fill="solid"
                          className="ion-margin-top" 
                          onClick={ () => {
                            mutate({'name': name, 'role': role, 'org': org}) 
                          }}
                          disabled={
                                      !(name !== null && name !== undefined && name.length > 2) || 
                                      !(org !== null && org !== undefined && org.length > 2) ||
                                      role === null || isLoading 

                                    }
                          >{isLoading ? "Please wait" : "Update"} &rarr;
                        </IonButton>
                      
                        
                      </IonCardContent>
                    </IonCard>
                  }

                  {
                    
                    data === 'success' &&

                    <IonCard mode="ios" color="dark" className="ion-padding">

                      <IonCardTitle mode="ios" className="ion-padding">Update your Profile</IonCardTitle>

                      <h2 className="ion-margin ion-padding"><strong>Your profile was updated successfully</strong></h2>
                      
                      <IonRow className="ion-padding ion-justify-content-center">
                        <IonCol size="12">
                            <lottie-player src={ROUTES.MESSAGE_SUBMITTED_SUCCESSFULLY} mode="bounce" background="transparent" speed="0.8" loop autoplay></lottie-player>
                        </IonCol>
                        
                        <IonCol size="12" className="ion-justify-content-center ion-text-center">
                          <IonButton fill="solid" routerLink={ROUTES.TABS_HOME_PAGE_ROUTE}>
                            Go to Home Page
                          </IonButton>
                        </IonCol>
                      </IonRow>
                     </IonCard> 
                  }
                </IonCol>
                }

                {
                  route !== null &&

                  <IonCol size="12" sizeMd="10" sizeLg="10">

                    { data !== 'success' &&

                      <IonCard mode="ios" color="dark" className="ion-text-center">
                        <IonCardContent className="ion-justify-content-center">

                          <IonCardTitle mode="ios" className="ion-padding">Update your Profile</IonCardTitle>
                          
                          <IonRow className="ion-justify-content-center">
                            <h2 className="ion-margin ion-padding"><strong>Who would you like to be called?</strong></h2>
                            <div>
                              <IonInput 
                                style={{backgroundColor: "var(--ion-color-dark-tint)", color: "white", borderRadius: "10px"}}
                                placeholder={user.fullname} 
                                onIonChange={(e) => setName(e.detail.value)}/>
                            </div> 
                          </IonRow>

                          <hr/>

                          <IonButton
                            fill="solid"
                            className="ion-margin-top" 
                            onClick={ () => {
                              mutate({'name': name, 'org': _org, 'role': _role}) 
                            }}
                            disabled={!(name !== null && name !== undefined && name.length > 2) || isLoading }
                            >{isLoading ? "Please wait" : "Update"} &rarr;
                          </IonButton>

                        </IonCardContent>
                        
                      </IonCard>
                    }

                    {
                      data === 'success' &&

                      <IonCard mode="ios" color="dark" className="ion-padding">
                      
                        <IonCardTitle mode="ios" className="ion-padding">Update your Profile</IonCardTitle>
                      
                        <h2 className="ion-margin ion-padding"><strong>Your profile was updated successfully</strong></h2>
                        
                        <IonRow className="ion-padding ion-justify-content-center">
                          <IonCol size="12">
                              <lottie-player src={ROUTES.MESSAGE_SUBMITTED_SUCCESSFULLY} mode="bounce" background="transparent" speed="0.8" loop autoplay></lottie-player>
                          </IonCol>
                          
                          <IonCol size="12" className="ion-justify-content-center ion-text-center">
                            <IonButton fill="solid" routerLink={ROUTES.TABS_ACCOUNT_PAGE_ROUTE}>
                              Go to Account Page
                            </IonButton>
                          </IonCol>
                        </IonRow>
                       </IonCard> 
                    }
                  </IonCol>

                }

              </IonRow>

            </IonCol>
          </IonRow> 

            <IonRow className="ion-margin-top ion-padding-top">
                <IonCol size="12">

                

                {/*
                  name !== null &&

                  <div>
                    <IonLabel> 
                        Hi, {name}. You are a part of ... 
    
                    </IonLabel>
                    <IonRadioGroup onIonChange={e => setRole(e.detail.value)}>

                    <IonItem color="light">
                        <IonLabel>
                          <h3>A Hino Dealership</h3>
                        </IonLabel>
                        <IonRadio 
                            value="Dealership"
                              /> 
                    </IonItem>
                    <IonItem color="light">
                        <IonLabel>
                          <h3>A Hino Preferred Supplier</h3>
                        </IonLabel>
                        <IonRadio 
                            value="Preferred Supplier" /> 
                    </IonItem>
                      
                  </IonRadioGroup>
                </div>
                  
                */}
                
                

                  {/*
                    data !== 'success' &&
                    <IonButton className="custom-button" expand="block" onClick={ () => mutate({'name': name, 'role': role, 'org': org}) } disabled={isLoading}>Update</IonButton>
              */}
                  {/*
                    data === 'success' &&
                    <Redirect to={ROUTES.TABS_HOME_PAGE_ROUTE} />
            */}
                </IonCol>
            </IonRow>
        </IonGrid>


      

      </IonContent>

    </IonPage>
  );
};

export default Profile;
