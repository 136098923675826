import { useRef } from 'react';
import { IonContent, IonButton, IonToolbar, IonHeader, IonPage, IonButtons, IonIcon, IonTitle, IonCardContent, IonCard, IonItem, IonText, IonCol, IonNote } from '@ionic/react';
import { chevronBack, cameraOutline, folderOutline, mailOpenOutline, notificationsOutline, peopleOutline, personOutline } from 'ionicons/icons';
//import { useAuth0 } from '@auth0/auth0-react';
//import { Browser } from '@capacitor/browser';
//import { callbackUri } from '../../auth.config';

import * as ROUTES from '../../config';

 
const Privacy: React.FC = () => {
  
  const pageRef = useRef<HTMLElement>(null);
      
  return (
    <IonPage ref={pageRef} id="legal-page">
      <IonHeader>
        <IonToolbar mode="ios" color="toolbar">
            <IonButtons slot="start">
            <IonButton routerLink={ROUTES.TABS_ACCOUNT_PAGE_ROUTE} routerDirection="back">
              <IonIcon icon={chevronBack} />
              
              </IonButton>
            </IonButtons>
            <IonTitle>Privacy</IonTitle>
        </IonToolbar>
      </IonHeader>
      
      <IonContent fullscreen>
        <IonCard mode="ios">
          <IonCardContent>
            <h3 className='ion-text-center'><strong>Hino Truck Body Builders App</strong></h3>
            <h1 className='ion-text-center'><strong>PRIVACY NOTICE</strong></h1>
            <IonNote className='ion-text-center ion-justify-content-center'><p>Last Updated: 2024/07/23</p></IonNote>


            <p className="ion-text-center ion-padding ion-margin">Toyota South Africa Motors (Pty) Ltd respects your right to privacy and your right to control your personal data. We are committed to processing personal data fairly and lawfully.</p>
            <p className="ion-text-center ion-padding ion-margin">We take appropriate measures to safeguard personal data against access or amendment by unauthorized persons and to prevent its unlawful loss, damage, or destruction.</p> 
            <p className="ion-text-center ion-padding ion-margin">We encourage you to read our&nbsp;<a href="https://www.hino.co.za/protection-policy" target="_blank" rel="noreferrer">Data Protection Policy</a>.&nbsp;The Data Protection Policy is supported by procedures and standards based on best practices. These are reviewed regularly and, where necessary, are improved to combat ever-changing cyber risks.</p>
            <p className="ion-text-center ion-padding ion-margin">You may contact our information officer at&nbsp;<a href="mailto:informationofficer.popia@toyota.co.za">informationofficer.popia@toyota.co.za</a>&nbsp;with any queries relating to your personal data, and we will respond promptly.</p>
            
            <hr color="tertiary"/>
            
            <h1 className='ion-text-center ion-justify-content ion-padding-top ion-margin-top'><strong>PERMISSIONS</strong></h1>
            <IonItem className='ion-justify-content' detail={false} lines="none">
              <h2 className='ion-text-center'><strong>Optional permissions</strong></h2>
            </IonItem>

            <IonItem detail={false} lines="none">
              <IonCol size="2">
                <IonIcon className="ion-padding-end ion-margin-end" icon={folderOutline}/>
              </IonCol>
              <IonCol size="10">
                <IonText>
                  <h3><strong>Files</strong></h3>
                  <p>Used to share and save images and documents</p>
                </IonText>
              </IonCol>
            </IonItem>

            <IonItem detail={false} lines="none">
              <IonCol size="2">
                <IonIcon className="ion-padding-end ion-margin-end" icon={cameraOutline}/>
              </IonCol>
              <IonCol size="10">
                <IonText>
                  <h3><strong>Camera</strong></h3>
                  <p>Used to take pictures of the results when using the app</p>
                </IonText>
              </IonCol>
            </IonItem>
            <IonItem detail={false} lines="none">
              <IonCol size="2">
                <IonIcon className="ion-padding-end ion-margin-end" icon={notificationsOutline}/>
              </IonCol>
              <IonCol size="10">
                <IonText>
                  <h3><strong>Notifications</strong></h3>
                  <p>Used to update you of the results when using the app</p>
                </IonText>
              </IonCol>
            </IonItem>

            <IonItem detail={false} lines="none">
              <IonText>
                <p className="ion-padding-bottom ion-padding-top ion-margin-bottom ion-margin-top">
                  You can still use the app's basic functions without allowing the optional permissions.
                </p>
              </IonText>
            </IonItem>

            <hr color="tertiary"/>
            
            <h1 className='ion-text-center ion-justify-content ion-padding-top ion-margin-top'><strong>PERSONAL DATA</strong></h1>
            <IonItem detail={false} lines="none">
              <h2 className='ion-text-center'><strong>Personal data processed and stored by the app</strong></h2>
            </IonItem>

            <IonItem detail={false} lines="none">
              <IonCol size="2">
                <IonIcon className="ion-padding-end ion-margin-end" icon={personOutline}/>
              </IonCol>
              <IonCol size="10">
                <IonText>
                  <h3><strong>Fullname</strong></h3>
                  <p>Used so that other users can easily identify and communicate with you within the app</p>
                </IonText>
              </IonCol>
            </IonItem>

            <IonItem detail={false} lines="none">
              <IonCol size="2">
                <IonIcon className="ion-padding-end ion-margin-end" icon={mailOpenOutline}/>
              </IonCol>
              <IonCol size="10">
                <IonText>
                  <h3><strong>Email</strong></h3>
                  <p>Used to login and to uniquely identify you</p>
                </IonText>
              </IonCol>
            </IonItem>

            <IonItem detail={false} lines="none">
              <IonCol size="2">
                <IonIcon className="ion-padding-end ion-margin-end" icon={peopleOutline}/>
              </IonCol>
              <IonCol size="10">
                <IonText>
                  <h3><strong>Role and Organisation</strong></h3>
                  <p>Used for Role Based Access and Control</p>
                </IonText>
              </IonCol>
            </IonItem>

            <hr color="tertiary"/>
            
            
            <div className='ion-text-center ion-padding ion-margin'>
              <h1><strong>ERASE PERSONAL DATA</strong></h1>
              <p className='ion-padding-bottom ion-margin-bottom ion-padding-top ion-margin-top'>
                Deactivate account on Hino Truck Body Builders App and Delete all your personal data related to the App
              </p>
              <IonButton routerLink={ROUTES.LOGIN_DIGITS_ROUTE}>Erase&nbsp;&rarr;</IonButton>
            </div>
          </IonCardContent>
        </IonCard>

      </IonContent>
    </IonPage>
  );
};

export default Privacy;
