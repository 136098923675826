import { IonAvatar, IonButton, IonButtons, IonContent, IonGrid, 
	IonHeader, IonIcon, IonItem, IonLabel, IonList, IonListHeader, IonModal, IonPage, IonTitle, IonToolbar, isPlatform } from '@ionic/react';
import { chevronBack, callOutline, mapOutline } from 'ionicons/icons';
import { useRef, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { HinoDealership } from '../models/Dealership';
import { formatTel } from '../services/dealerships';
import LeadStore from '../services/leads';
import SubmitToDealer from '../components/Leads/SubmitToDealer';
//import { useStoreState } from 'pullstate';
//import { getUser, UserPreferences } from '../services/user';
import DealershipsMap from '../components/Leads/DealershipsMap';

import * as ROUTES from '../config';

import './Dealerships.css'

const Dealer = () => {

	const pageRef = useRef();
	const ios = isPlatform('ios');
	//const user = useStoreState(UserPreferences, getUser);
	//const isBodyBuilder = user.role.includes('Hino Preferred Supplier');

	const dealership: HinoDealership = LeadStore.useState((s: any) => s.dealership);
	const [showSubmitToDealerModal, setShowSubmitToDealerModal] = useState(false);

	if (dealership === null) {
		return (
			<Redirect to={ROUTES.TABS_DEALERSHIPS_LIST_PAGE_ROUTE} />
		);
	}


	//Google Maps Url
	const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${dealership.lat},${dealership.longitude}`

	return (
		<IonPage ref={pageRef}>
			<IonHeader>
				<IonToolbar mode="ios" color="toolbar">
					<IonTitle><strong>{dealership.name}</strong></IonTitle>
					<IonButtons slot="start">
            			<IonButton routerDirection="back" routerLink={ROUTES.TABS_DEALERSHIPS_LIST_PAGE_ROUTE}>
              				<IonIcon icon={chevronBack} />
						</IonButton>
          			</IonButtons>

					{/*<IonButtons slot="end">
						<IonButton onClick={() => alert('Set as preferred Dealership?')}>
							<IonIcon icon={bookmarkOutline} size="large" />
						</IonButton>
					</IonButtons>*/}
				</IonToolbar>
			</IonHeader>
			<IonContent fullscreen>

				<div className="header-map">
					<DealershipsMap dealership={dealership} />
				</div>
				

				<IonGrid>

					<IonList>

					<IonListHeader className={`ion-no-margin ion-padding`}>
						<h3><strong>{dealership.name}</strong></h3>
					</IonListHeader>

						{/*<IonItem className="top-border-line">
							<IonAvatar slot="start"> <IonIcon icon={personOutline} color="dark" size="large"></IonIcon> </IonAvatar>
							<IonLabel>
								<h2><strong>{dealership.name}</strong></h2>
								<p>{dealership.name}</p>
							</IonLabel>
							<IonAvatar slot="end"> 
								<IonIcon 
									icon={chatbox} 
									size="large"
									onClick={() => setShowSubmitToDealerModal(true) }
								/>
							</IonAvatar>
				</IonItem>*/}
						<IonItem detail={true} lines='full' onClick={() => setTimeout(()  => window.open(`tel:${dealership.telephone !== undefined ? formatTel(dealership.province, dealership.telephone).replaceAll('-','') : ""}`))}>
							<IonAvatar slot="start"> <IonIcon icon={callOutline} color="dark" size="large"></IonIcon> </IonAvatar>
							<IonLabel>
								<h3><strong>Phone</strong></h3>
								<p>{dealership.telephone}</p>
							</IonLabel>
							{/*<IonAvatar slot="end"> 
								<IonIcon 
									icon={call} 
									size="large"
									onClick={() => setTimeout(()  => window.open(`tel:${dealership.telephone !== undefined ? formatTel(dealership.province, dealership.telephone).replaceAll('-','') : ""}`))}
								/>
			</IonAvatar>*/}
						</IonItem>
						<IonItem lines="full" detail={true} disabled={ios}
							onClick={() => ios ? 

								window.location.assign(googleMapsUrl)
								
								:

								window.open(googleMapsUrl)//window.open(`https://www.google.com/maps/dir//${dealership.lat},${dealership.longitude}`)
							}
						
						
						>
							<IonAvatar slot="start"> <IonIcon icon={mapOutline} color="dark" size="large"></IonIcon> </IonAvatar>
							<IonLabel>
								<h2><strong>Address</strong></h2>
								<p>{dealership.streetAddress}</p>
								<p>{`${dealership.city}, ${dealership.province}`}</p>
							</IonLabel>
							{/*<IonAvatar slot="end"> 
								<IonIcon 
									color='dark'
									icon={navigate} 
									size="large"
									
								/>
						</IonAvatar>*/}
						</IonItem>

					</IonList>
				</IonGrid>
				<IonModal
					mode="ios"
					isOpen={showSubmitToDealerModal}
					onDidDismiss={() => setShowSubmitToDealerModal(false)}
					swipeToClose={true}> 
						<SubmitToDealer 
							onDismissModal={() => setShowSubmitToDealerModal(false)} 
							route={`/dealerships/${dealership.DealerCode}`}
							values={dealership}/>
				</IonModal>
			</IonContent>
		</IonPage>
	);
};

export default Dealer;