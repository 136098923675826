import { useRef } from 'react';
import { 
  IonContent, IonButton, IonToolbar, IonHeader, IonPage, IonButtons, IonItem, IonIcon, 
  IonList, IonToggle, IonCardSubtitle, IonTitle, IonLabel } from '@ionic/react';
import { notificationsOutline, documentOutline, colorPaletteOutline,
  logOutOutline, helpCircleOutline, close, settingsOutline, chevronBack } from 'ionicons/icons';
  import { useStoreState } from 'pullstate';
import { getUser, UserPreferences } from '../services/user';

import * as ROUTES from '../config';

import './Account.css';

const Settings: React.FC = () => {

  const pageRef = useRef<HTMLElement>(null);
  const user = useStoreState(UserPreferences, getUser);
  const isHinoBlackThemeEnabled = user.enableHinoBlackTheme;
  const isPushNoticationsEnabled = user.enableNotifications;

  return (
    <IonPage ref={pageRef} id="settings-page">
      <IonHeader>
        <IonToolbar mode="ios" color="primary">
            <IonButtons slot="start">
              <IonButton routerLink={ROUTES.TABS_ACCOUNT_PAGE_ROUTE} routerDirection="back">
              <IonIcon icon={chevronBack} /></IonButton>
            </IonButtons>
            <IonTitle><strong>Settings</strong></IonTitle>
        </IonToolbar>
      </IonHeader>
      
      <IonContent className="settings-page" fullscreen>

        <IonList className="settings-list"> 
          <IonItem detail={true} routerLink={ROUTES.SETTINGS_PREFERENCES_PAGE_ROUTE} lines="none">
            <IonIcon icon={settingsOutline}/>
            <p> Preferences</p>
          </IonItem>
          <IonItem lines="none">
            <IonIcon icon={notificationsOutline}/>
            <p> Enable Push Notifications</p>
            <IonToggle checked={isPushNoticationsEnabled} slot="end"/>
          </IonItem>
          <IonItem lines="none">
            <IonIcon icon={colorPaletteOutline}/>
            <p> Hino Black Theme</p>
            <IonToggle checked={isHinoBlackThemeEnabled} slot="end" onIonChange={(e) => console.log(e.detail.value)}/>
          </IonItem>
          <IonItem detail={true} routerLink={ROUTES.SUPPORT_PAGE_ROUTE}lines="none">
            <IonIcon icon={helpCircleOutline}/>
            <p> Help &amp; Feedback</p>
          </IonItem>
          <IonItem lines="none">
            <IonIcon icon={documentOutline}/>
            <p> Legal (Terms &amp; Privacy)</p>
          </IonItem>
          <IonItem detail={true} routerLink={ROUTES.LOGOUT_ROUTE} routerDirection="back" lines="none">
            <IonIcon icon={logOutOutline}/>
            <p> Logout</p>
          </IonItem>
          
        </IonList>
        <div className="ion-text-center ion-justify-content-center ion-margin-top ion-padding-top">

          <IonLabel ion-no-padding ion-no-margin>
            <h5>Hino Truck Body Builders App </h5>
            <p>Version</p>
          </IonLabel>
          
          <IonCardSubtitle className="ion-no-padding ion-no-margin">0.1.0</IonCardSubtitle>
       
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Settings;
