import React, { useCallback, useEffect, useState } from 'react';
import { Redirect, RouteComponentProps } from 'react-router';
import { IonPage, IonText, IonButton, IonContent, IonGrid, IonRow, IonCol } from '@ionic/react';
import { Buffer } from 'buffer';
import { Link } from 'react-router-dom';

import KeypadInputs from '../../components/auth/KeypadInputs';
import useAxios from '../../services/auth/useAxios';
import * as URL from '../../config';

import './Login.css';


const url = URL.AUTHENTICATE_URL;

const LoginCode: React.FC<RouteComponentProps> = ({ history }) => {

    const [code, setCode] = useState("");
    const [online, postData, response, loading] = useAxios(url);

    const emailb64: string | null = sessionStorage.getItem(Buffer.from("email").toString('base64'));
    const email: string = Buffer.from(emailb64 === null ? "" : emailb64, 'base64').toString();

    const message: string = "Please enter the verfication code sent to your email address to intiate deletion of your Hino Truck Body Builders App account";

    const loginCode = useCallback(async () => {

        const timeIn: number = new Date().getTime();
        setCode(code.trim());

        await postData({"stage": "verify", "code": code, "emailAddress": email, "timeIn": timeIn}) ;

    }, [postData, code, email]);

    useEffect(() => {

        let isMounted = true; //Needed because postData is async

        new Promise((resolve) => {

            const verify = response?.verify;

            if (response !== null && verify !== "failed" && isMounted) {

                //Code valid: Save token and Redirect
                setTimeout(() => {
                    return resolve(true);
                    }, 1000); 
                    
                    history.replace(URL.LOGIN_ACCOUNT_DELETED_ROUTE);
            }
        });

        return () => {
            isMounted = false;
        }

    }, [response, code, history, email]);

    if (email === null) {
        return (
			<Redirect to={URL.LOGIN_DIGITS_ROUTE} />
		);
    }

    return (

        <IonPage>
            <IonContent fullscreen className="ion-padding">

                <IonGrid>
                    <IonRow className="header">
                        <IonCol className="header-logo-img">
                            <IonCol className="header-logo">
                                <IonCol className="header-logo-text"></IonCol>
                            </IonCol>
                        </IonCol>
                        <IonCol className="header-img"></IonCol>
                    </IonRow>

                    <IonRow className="login">
                        <IonText className="login-title"><strong>Verification</strong></IonText>
                        {
                            online && 
                            <IonText className="login-subtitle-black ion-padding-right">{message}</IonText>
                        }
                        
                        {
                            !online && 
                            <IonText className="login-subtitle-black ion-padding-right"><h5>Please connect to a network or wifi to login</h5></IonText>
                        }

                        {
                            response == null &&
                            <>
                                <IonText className="login-email-title">Verification Code</IonText>
                                <KeypadInputs
                                    autoFocus
                                    isNumberInput
                                    length={5}
                                    className="otpContainer"
                                    inputClassName="otpInput-5"
                                    onChangeOTP={(otp) => setCode(otp)}
                                />
                            </>     
                        }
                            
                        {
                            response !== null && response?.verify === 'failed' &&
                            <>
                                <IonText className="login-email-title-error">The code you entered is incorrect</IonText>
                                <KeypadInputs
                                    autoFocus
                                    isNumberInput
                                    length={5}
                                    className="otpContainer"
                                    inputClassName="otpInput-5-error"
                                    onChangeOTP={(otp) => setCode(otp)}
                                />
                            </>
                        }

                    </IonRow>

                    <IonRow className="ion-text-center ion-justify-content-center ion-no-margin ion-no-padding footer-button">

                        {
                            !loading && code.length > 2 && online &&

                            <IonRow className="ion-text-center ion-justify-content-center footer">
                                <IonButton mode="ios" className="login-button" expand="block" onClick={() => loginCode()}>Delete Account</IonButton>
                                <IonText className="ion-margin-top ion-padding-top">
                                    <p><Link to={URL.SETTINGS_LEGAL_PRIVACY_PAGE_ROUTE}>Cancel</Link></p>
                                </IonText>
                            </IonRow>
                        
                        }

                        { 
                            loading && 
                            <IonButton mode="ios" className="login-button" disabled>Please Wait...</IonButton>
                        }

                        {
                            !online &&
                            <IonButton mode="ios" className="login-button" disabled>No Internet Connection</IonButton>
                        }

                    </IonRow>

                </IonGrid>

            </IonContent>
        </IonPage>
    )
}

export default LoginCode;