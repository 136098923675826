import { useState } from 'react';
import { IonCard, IonContent, IonGrid, IonHeader, IonIcon, IonPage,  IonText, IonToolbar, IonLabel, IonList, IonItem, IonToggle, IonRow, IonTitle, isPlatform, IonButton, IonCol, IonAlert, IonCardSubtitle } from '@ionic/react';
import { settingsOutline, personCircleOutline, colorPaletteOutline, logoPwa, logoAppleAppstore, logoGooglePlaystore, helpCircleOutline, logOutOutline, notificationsOutline, openOutline } from "ionicons/icons";
import { Share } from '@capacitor/share';
import platform from 'platform';
import packageInfo from '../../package.json';
import { UserPreferencesModel } from '../models/User';
import { setRoute } from '../services/user';

import * as ROUTES from '../config';

import './Account.css';


interface AccountTabProps {
	user: UserPreferencesModel;
  }

const shareToUsersNotOnTheApp = async (user?: string) => {

    try {
        await Share.share({
            title: `Hino Truck Body Builders App`,
            text: `Check out the Hino Truck Body Builders App`,
            url: 'https://app.hino.co.za',
            dialogTitle: 'Share with buddies',
            });
        } catch(err) {
            console.log(err);
    }
}


const playStoreUrl = ROUTES.GOOGLE_PLAY_STORE_URL;
const appStoreUrl = ROUTES.APPLE_APP_STORE_URL;


const Account: React.FC<AccountTabProps> = ({user}) => {

	const ios = isPlatform('ios');
	const android = isPlatform('android');

	const os = platform.os;

	const fullname = user.fullname;
	const email = user.email;
	const role = user.role.length > 0 ? user.role[0] : "Hino Mobile App User";
	const org = user.org;
	const isBodyBuilder = user.role.includes('Hino Preferred Supplier');
	const isHinoBlackThemeEnabled = user.enableHinoBlackTheme;
	const isPushNoticationsEnabled = user.enableNotifications;

	const [showToast, setShowToast] = useState(false);

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar color="toolbar">
					<IonTitle><strong>Account</strong></IonTitle>	
				</IonToolbar>
			</IonHeader>
			<IonContent color="light" fullscreen>
				<IonHeader collapse="condense">
					<IonToolbar color="light">
						<IonTitle size="large">Account</IonTitle>
					</IonToolbar>
				</IonHeader>

				{!ios && <div className="top-header"></div>}

				<IonGrid>
					<div>
						<IonRow className="ion-justify-content-center">
						
							<IonCard mode="ios" className="ion-justify-content-center ion-align-items-center ion-text-center">
								<IonIcon style={{ fontSize: "120px", color: "var(--ion-color-primary)" }} icon={personCircleOutline}/>
							</IonCard>
						
						</IonRow>

						<IonRow className="ion-justify-content-center">
						
							<IonLabel className="ion-justify-content-center ion-align-items-center ion-text-center">
								<IonButton mode='ios' color="primary" onClick={() => setRoute('account') } routerLink={ROUTES.SETTINGS_PROFILE_PAGE_ROUTE}>
									<h2>
										<strong>
											<u>{fullname}&nbsp;<IonIcon icon={openOutline} /></u>
										</strong>
									</h2>
								</IonButton>
								<IonText color="primary"><p>{email}</p></IonText> 
								{org !== null && <IonText color="dark"><p>{org}</p></IonText>}
								<IonText color="dark"><p>{role}</p></IonText>
							</IonLabel>
						
						</IonRow>
					</div>

					<IonRow className="ion-justify-content-center ion-padding-top">
						<IonList className="settings-list" lines="none">
							
							<IonItem lines="none" disabled>
								<IonIcon icon={notificationsOutline}/>
								<p>&nbsp;Enable Push Notifications</p>
								<IonToggle checked={isPushNoticationsEnabled} slot="end" onIonChange={(e) => console.log(e.detail.checked)}/>
							</IonItem>
							<IonItem lines="none" disabled>
								<IonIcon icon={colorPaletteOutline}/>
								<p>&nbsp;Hino Black Theme</p>
								<IonToggle checked={isHinoBlackThemeEnabled} slot="end" onIonChange={(e) => console.log(e.detail.checked)}/>
							</IonItem>

							{/*
								isBodyBuilder &&

								<IonItem detail={true} routerLink={ROUTES.SETTINGS_PREFERENCES_PAGE_ROUTE} lines="none" disabled>
									<IonIcon icon={settingsOutline}/>
									<p>&nbsp;Settings</p>
								</IonItem>
							*/} 

							<IonItem detail={true} routerLink={ROUTES.SUPPORT_PAGE_ROUTE} lines="none">
								<IonIcon icon={helpCircleOutline}/>
								<p>&nbsp;Support</p>
							</IonItem>
							{/*<IonItem detail={true} routerLink={ROUTES.SETTINGS_LEGAL_PAGE_ROUTE} lines="none" disabled>
								<IonIcon icon={trashBinOutline}/>
								<p> Delete Account</p>
							</IonItem>*/}
							<IonItem detail={true} routerLink={ROUTES.LOGOUT_ROUTE} routerDirection="back" lines="none">
								<IonIcon icon={logOutOutline}/>
								<p>&nbsp;Logout</p>
							</IonItem>
						</IonList>	
					</IonRow>

					<IonRow className="ion-text-center ion-justify-content-center ion-margin-top ion-padding-top">
						
						<IonItem 
							color="light"
							detail={false} 
							onClick={() => {
								ios ? window.location.assign(appStoreUrl)
								:
								android ? window.open(playStoreUrl, '_blank')
								:
								shareToUsersNotOnTheApp()
							} 
							} 
							lines="none">
							<IonIcon icon={ios ? logoAppleAppstore : android ? logoGooglePlaystore : logoPwa}/>
							<p><strong>&nbsp;{ios ? "Rate on Apple App Store" : android ? "Rate on Google Play Store" : ""}</strong></p>
						</IonItem>
						
					</IonRow>

					<IonItem className="ion-text-center ion-justify-content-center ion-margin-top ion-padding-top" color="light" lines="none">
						<IonLabel ion-no-padding ion-no-margin >
							<h3><strong>Hino Truck Body Builders App</strong></h3>
							<p>Version {ios ? packageInfo.ios : packageInfo.android} Build {ROUTES.BUILD_NUMBER}</p>
							<p>{`${os?.family} ${os?.version}`}</p>
						</IonLabel>
						{/*<IonCardSubtitle className="ion-no-padding ion-no-margin">Build-{packageInfo.version}</IonCardSubtitle>*/}
						<IonCardSubtitle className="ion-no-padding ion-no-margin"></IonCardSubtitle>
					</IonItem>

					<IonItem className="ion-text-center ion-justify-content-center" color="light" lines="none">
						<IonCol size="4">
							<IonButton mode='ios' ion-no-padding ion-no-margin fill="clear" routerLink={ROUTES.SETTINGS_LEGAL_PRIVACY_PAGE_ROUTE}>
								<p><u>Privacy&nbsp;<IonIcon icon={openOutline}/></u></p>
							</IonButton>
						</IonCol>
						<IonCol size="4">
							<IonButton mode='ios' ion-no-padding ion-no-margin fill="clear" routerLink={ROUTES.SETTINGS_LEGAL_TOS_PAGE_ROUTE}>
								<p><u>Terms&nbsp;<IonIcon icon={openOutline}/></u></p>
							</IonButton>
						</IonCol>
						<IonCol size="4">
							<IonButton mode='ios' disabled ion-no-padding ion-no-margin fill="clear" routerLink={ROUTES.SETTINGS_LEGAL_TOS_DOCUMENT_PAGE_ROUTE}>
								<p><u>Credits&nbsp;<IonIcon icon={openOutline}/></u></p>
							</IonButton>
						</IonCol>
					</IonItem>

					<IonRow className="ion-text-center ion-justify-content-center ion-margin-top ion-padding-top">
						
						{/*<IonItem detail={false} onClick={() => shareToUsersNotOnTheApp() } lines="none">
							<IonIcon icon={shareSocialOutline}/>
							<p><strong>Share App with Friends</strong></p>
						</IonItem>*/}
						
					</IonRow>
				</IonGrid>

				<IonAlert 
					isOpen={showToast}
					onDidDismiss={() => setShowToast(false)}
					header='Account Details'
					message="Update or delete your account details"
					inputs={[
						  {
							name: 'Name',
							placeholder: "Full Name"
						  }/*,{
							name: 'Role',
							placeholder: "User Role"
						  },*/
						]}
					buttons={[{
						text: 'Update',
						handler: () => {
							setShowToast(false);
							//history.replace(ROUTES.TABS_ACCOUNT_PAGE_ROUTE);
						}
						}, {
							text: 'Delete Account',
							//icon: trashBinOutline,
							handler: async () => {
	
							}
						}, {
						text: 'Cancel',
						role: 'cancel',
						handler: async () => {

						}
						}
					]}
					
					/>

			</IonContent>
		</IonPage>
	);
};

export default Account;