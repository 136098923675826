  
import { IonButton, IonButtons, IonCard, IonCardContent, IonCardSubtitle, IonCardTitle, IonCol, IonContent, IonGrid, 
    IonHeader, IonIcon,  IonList, IonModal, IonNote, IonPage, IonRow, IonTitle, IonToolbar } from '@ionic/react';
import {  documentsOutline, documentTextOutline, layersOutline, calculatorOutline, ellipsisVerticalOutline, chevronBack, bookmarksOutline } from 'ionicons/icons';
import { useRef, useState } from 'react';
import { Redirect } from 'react-router';
import { useMedia } from 'react-use';
import { useStoreState } from 'pullstate';
import { getUser, UserPreferences } from '../../services/user';
import { CalcStore, CalcValues } from '../../services/calculations';
import { SubmitToBuilder } from '../../components/Leads';
import TruckDoc from '../../components/documents/TruckDoc';
import { CnvsDimensions } from '../../components/canvas';
import Viewer from '../../components/pdf';
import { ModelTruck } from '../../models';
import { useTrucksFromWebsite } from '../../services/trucks';
import * as ROUTES from '../../config';

import "./calc.css";


const CalcTruck = () => {

	const pageRef = useRef();
	const user = useStoreState(UserPreferences, getUser);
	const isDealerShip = user.role.includes('Hino Dealership');
	const isWide = useMedia('(min-width: 768px)');

	const [showMass, setShowMass] = useState(true);
	const [showDimensions, setShowDimensions] = useState(false);
	const [showGuidelines, setShowGuidelines] = useState(false);
	const [showDiagrams, setShowDiagrams] = useState(false);

	const [showSubmitToBuilderModal, setShowSubmitToBuilderModal] = useState(false);
	const [showPDFViewer, setShowPDFViewer] = useState(false);

	const _truck = CalcStore.useState((s: CalcValues) => s.truck);
	const truck = JSON.parse(_truck as string) as ModelTruck.TruckSpecifications;
	const values = CalcStore.useState((s: CalcValues) => s);

	
	const isBusOrIsDyna = truck !== null ? (truck.series === 'busco' || truck.series === 'dyna') : true;
	const SMC = isBusOrIsDyna ? undefined : truck.chassisModel;
	
	const price = truck.price === "" ? 
										null 
	
									: 
										
										new Intl.NumberFormat('en-US', {
											style: 'currency',
											currency: 'ZAR',
										
											// These options are needed to round to whole numbers if that's what you want.
											//minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
											//maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
	  									}).format(+truck.price).replace('ZA', '')

	const { data } = useTrucksFromWebsite(SMC);


	if (truck === null) {
		return (
			<Redirect to={ROUTES.TRUCK_LIST_PAGE_ROUTE} />
		);
	}

	return (
		<IonPage ref={pageRef}>
			<IonHeader>
				<IonToolbar mode="ios" color="toolbar">
					<IonTitle><strong>{`${truck.description} (${truck.chassisModel})`}</strong></IonTitle>
					<IonButtons slot="start">
            			<IonButton routerLink={`${ROUTES.TRUCK_LIST_PAGE_ROUTE}?series=${truck.series}`} routerDirection="back">
              				<IonIcon icon={chevronBack} />&nbsp;
						</IonButton>
          			</IonButtons>

					{/*<IonButtons slot="end">
						<IonButton onClick={() => setShowPDFViewer(true)}>
							<IonIcon icon={ shareSocialOutline } size="large"/>
						</IonButton>
					</IonButtons>*/}
				</IonToolbar>
			</IonHeader>
			<IonContent fullscreen>
				
				<div className="header-image">

					{truck.series === "dyna" && <img src="assets/images/hinodyna.png" alt="main cover" style={ {objectFit: "contain", height: 300, width: "auto"} } />}
					{truck.series === "200" && <img src="assets/images/hino200.png" alt="main cover" style={ {objectFit: "contain", height: 300, width: "auto"} } />}
					{truck.series === "300" && <img src="assets/images/hino300.png" alt="main cover" style={ {objectFit: "contain", height: 300, width: "auto"} } />}
					{truck.series === "500" && <img src="assets/images/hino500.png" alt="main cover" style={ {objectFit: "contain", height: 300, width: "auto"} } />}
					{truck.series === "700" && <img src="assets/images/hino700.png" alt="main cover" style={ {objectFit: "contain", height: 300, width: "auto"} } />}
					{truck.series === "busco" && <img src="assets/images/hinobusco.png" alt="main cover" style={ {objectFit: "contain", height: 300, width: "auto"} } />}

					<div className={`header-info animate__animated animate__slideInRight`}>
						
						<p>{`${truck.description} (${truck.chassisModel})`}</p>
						{
							truck.application === 'General Use' ?

							<h1>{`Application - Dropside, Flatdeck, Tautliner and Van`}</h1>

							:

							<h1>{`Application - ${truck.application}`}</h1>
						}
						
					</div>
				</div>

				<IonGrid className="tl-wrapper">

					<IonRow className="ion-text-center">
						<IonCol 
							size="3" 
							onClick={() => {
								setShowDiagrams(false);
									setShowMass(true);
									setShowDimensions(false);
									setShowGuidelines(false);
								}}>
							<IonCardTitle>
								<IonIcon icon={layersOutline} />
							</IonCardTitle>
							<IonCardSubtitle>Mass</IonCardSubtitle>
						</IonCol>
						<IonCol 
							size="3"
							onClick={() =>{
								setShowDiagrams(false);
								setShowMass(false);
								setShowDimensions(true);
								setShowGuidelines(false);
							}}>
							<IonCardTitle>
								<IonIcon icon={bookmarksOutline} />
							</IonCardTitle>
							<IonCardSubtitle>Dimensions</IonCardSubtitle>
						</IonCol>
						<IonCol size="3" 
								onClick={() => {
									setShowDiagrams(true);
									setShowMass(false);
									setShowDimensions(false);
									setShowGuidelines(false);
							}}>
							<IonCardTitle>
								<IonIcon icon={documentsOutline} />
							</IonCardTitle>
							<IonCardSubtitle>Diagrams</IonCardSubtitle>
						</IonCol>
						<IonCol size="3" 
								onClick={() => {
									setShowDiagrams(false);
									setShowMass(false);
									setShowDimensions(false);
									setShowGuidelines(true);
							}}>
							<IonCardTitle>
								<IonIcon icon={ellipsisVerticalOutline} />
							</IonCardTitle>
							<IonCardSubtitle>More</IonCardSubtitle>
						</IonCol>
					</IonRow>

					<IonList>
						<IonGrid>
						
							<IonRow>
								{
									showMass &&

									<IonCol size="12">
										<IonCardSubtitle className="ion-text-center" color="primary">
											Mass
										</IonCardSubtitle>

										<IonRow style={{ borderBottom: "1px solid #242424", padding: "0.5rem" }}>
											<IonCol size="9">
												<IonCardTitle style={{ fontSize: "0.9rem", marginLeft: true ? "1.5rem" : "" }}>
													A - Front
												</IonCardTitle>
											</IonCol>

											<IonCol size="3">
												<IonCardTitle style={{ fontSize: "0.9rem" }}>
													{`${truck.frontTyreRating} kg`}
												</IonCardTitle>
											</IonCol>
										</IonRow>

										<IonRow style={{ borderBottom: "1px solid #242424", padding: "0.5rem" }}>
											<IonCol size="9">
												<IonCardTitle style={{ fontSize: "0.9rem", marginLeft: true ? "1.5rem" : "" }}>
													A - Rear
												</IonCardTitle>
											</IonCol>

											<IonCol size="3">
												<IonCardTitle style={{ fontSize: "0.9rem" }}>
													{`${truck.rearTyreRating} kg`}
												</IonCardTitle>
											</IonCol>
										</IonRow>

										<IonRow style={{ borderBottom: "1px solid #242424", padding: "0.5rem" }}>
											<IonCol size="9">
												<IonCardTitle style={{ fontSize: "0.9rem", marginLeft: true ? "1.5rem" : "" }}>
													D/T
												</IonCardTitle>
											</IonCol>

											<IonCol size="3">
												<IonCardTitle style={{ fontSize: "0.9rem" }}>
													{`${truck.dT === "" ? (truck.GCM === "" ? truck.GVM : truck.GCM) : truck.dT} kg`}
												</IonCardTitle>
											</IonCol>
										</IonRow>

										<IonRow style={{ borderBottom: "1px solid #242424", padding: "0.5rem" }}>
											<IonCol size="9">
												<IonCardTitle style={{ fontSize: "0.9rem", marginLeft: true ? "1.5rem" : "" }}>
													GA - Front
												</IonCardTitle>
											</IonCol>

											<IonCol size="3">
												<IonCardTitle style={{ fontSize: "0.9rem" }}>
													{`${truck.frontGAMRating} kg`}
												</IonCardTitle>
											</IonCol>
										</IonRow>

										<IonRow style={{ borderBottom: "1px solid #242424", padding: "0.5rem" }}>
											<IonCol size="9">
												<IonCardTitle style={{ fontSize: "0.9rem", marginLeft: true ? "1.5rem" : "" }}>
													GA - Rear
												</IonCardTitle>
											</IonCol>

											<IonCol size="3">
												<IonCardTitle style={{ fontSize: "0.9rem" }}>
													{`${truck.rearGAMRating} kg`}
												</IonCardTitle>
											</IonCol>
										</IonRow>

										<IonRow style={{ borderBottom: "1px solid #242424", padding: "0.5rem" }}>
											<IonCol size="9">
												<IonCardTitle style={{ fontSize: "0.9rem", marginLeft: true ? "1.5rem" : "" }}>
													GCM
												</IonCardTitle>
											</IonCol>

											<IonCol size="3">
												<IonCardTitle style={{ fontSize: "0.9rem" }}>
													{`${truck.GCM === "" ? (truck.dT === "" ? truck.GVM : truck.dT) : truck.GCM} kg`}
												</IonCardTitle>
											</IonCol>
										</IonRow>

										<IonRow style={{ borderBottom: "1px solid #242424", padding: "0.5rem" }}>
											<IonCol size="9">
												<IonCardTitle style={{ fontSize: "0.9rem", marginLeft: true ? "1.5rem" : "" }}>
													GVM
												</IonCardTitle>
											</IonCol>

											<IonCol size="3">
												<IonCardTitle style={{ fontSize: "0.9rem" }}>
													{`${truck.GVM} kg`}
												</IonCardTitle>
											</IonCol>
										</IonRow>

										<IonRow style={{ borderBottom: "1px solid #242424", padding: "0.5rem" }}>
											<IonCol size="9">
												<IonCardTitle style={{ fontSize: "0.9rem", marginLeft: true ? "1.5rem" : "" }}>
													Tare - Front
												</IonCardTitle>
											</IonCol>

											<IonCol size="3">
												<IonCardTitle style={{ fontSize: "0.9rem" }}>
													{`${truck.chassisWeightF} kg`}
												</IonCardTitle>
											</IonCol>
										</IonRow>

										<IonRow style={{ borderBottom: "1px solid #242424", padding: "0.5rem" }}>
											<IonCol size="9">
												<IonCardTitle style={{ fontSize: "0.9rem", marginLeft: true ? "1.5rem" : "" }}>
													Tare - Rear
												</IonCardTitle>
											</IonCol>

											<IonCol size="3">
												<IonCardTitle style={{ fontSize: "0.9rem" }}>
													{`${truck.chassisWeightR} kg`}
												</IonCardTitle>
											</IonCol>
										</IonRow>

										<IonRow style={{ borderBottom: "1px solid #242424", padding: "0.5rem" }}>
											<IonCol size="9">
												<IonCardTitle style={{ fontSize: "0.9rem", marginLeft: true ? "1.5rem" : "" }}>
													Tare - Total (incl. spare wheel)
												</IonCardTitle>
											</IonCol>

											<IonCol size="3">
												<IonCardTitle style={{ fontSize: "0.9rem" }}>
													{`${+(truck.chassisWeightF || 0) + +(truck.chassisWeightR || 0)} kg`}
												</IonCardTitle>
											</IonCol>
										</IonRow>

										<IonRow style={{ borderBottom: "1px solid #242424", padding: "0.5rem" }}>
											<IonCol size="9">
												<IonCardTitle style={{ fontSize: "0.9rem", marginLeft: true ? "1.5rem" : "" }}>
													V- Rating
												</IonCardTitle>
											</IonCol>

											<IonCol size="3">
												<IonCardTitle style={{ fontSize: "0.9rem" }}>
													{`${truck.maxGVMRating} kg`}
												</IonCardTitle>
											</IonCol>
										</IonRow>
										
									</IonCol>

									/** <!-- Mass --> */
								}


								{
									showDimensions &&

									<IonCol size="12">
										<IonCardSubtitle className="ion-text-center" color="primary">
											Dimensions
										</IonCardSubtitle>

										<IonRow style={{ borderBottom: "1px solid #242424", padding: "0.5rem" }}>
											<IonCol size="9">
												<IonCardTitle style={{ fontSize: "0.9rem", marginLeft: true ? "1.5rem" : "" }}>
													OL - Overall Lenght
												</IonCardTitle>
											</IonCol>

											<IonCol size="3">
												<IonCardTitle style={{ fontSize: "0.9rem" }}>
													{`${truck.overAllLength} mm`}
												</IonCardTitle>
											</IonCol>
										</IonRow>

										<IonRow style={{ borderBottom: "1px solid #242424", padding: "0.5rem" }}>
											<IonCol size="9">
												<IonCardTitle style={{ fontSize: "0.9rem", marginLeft: true ? "1.5rem" : "" }}>
													CA - Cab to Axle
												</IonCardTitle>
											</IonCol>

											<IonCol size="3">
												<IonCardTitle style={{ fontSize: "0.9rem" }}>
													{`${truck.cabToRearAxleCentre} mm`}
												</IonCardTitle>
											</IonCol>
										</IonRow>

										<IonRow style={{ borderBottom: "1px solid #242424", padding: "0.5rem" }}>
											<IonCol size="9">
												<IonCardTitle style={{ fontSize: "0.9rem", marginLeft: true ? "1.5rem" : "" }}>
													CH - Cab Height
												</IonCardTitle>
											</IonCol>

											<IonCol size="3">
												<IonCardTitle style={{ fontSize: "0.9rem" }}>
													{`${truck.cabHeight} mm`}
												</IonCardTitle>
											</IonCol>
										</IonRow>

										<IonRow style={{ borderBottom: "1px solid #242424", padding: "0.5rem" }}>
											<IonCol size="9">
												<IonCardTitle style={{ fontSize: "0.9rem", marginLeft: true ? "1.5rem" : "" }}>
													CW - Cab Width (excl. mirrors)
												</IonCardTitle>
											</IonCol>

											<IonCol size="3">
												<IonCardTitle style={{ fontSize: "0.9rem" }}>
													{`${truck.cabWidth} mm`}
												</IonCardTitle>
											</IonCol>
										</IonRow>

										<IonRow style={{ borderBottom: "1px solid #242424", padding: "0.5rem" }}>
											<IonCol size="9">
												<IonCardTitle style={{ fontSize: "0.9rem", marginLeft: true ? "1.5rem" : "" }}>
													CH - Chassis Height
												</IonCardTitle>
											</IonCol>

											<IonCol size="3">
												<IonCardTitle style={{ fontSize: "0.9rem" }}>
													{`${truck.overallHeight} mm`}
												</IonCardTitle>
											</IonCol>
										</IonRow>

										<IonRow style={{ borderBottom: "1px solid #242424", padding: "0.5rem" }}>
											<IonCol size="9">
												<IonCardTitle style={{ fontSize: "0.9rem", marginLeft: true ? "1.5rem" : "" }}>
													CW - Chassis Width (excl. mirrors)
												</IonCardTitle>
											</IonCol>

											<IonCol size="3">
												<IonCardTitle style={{ fontSize: "0.9rem" }}>
													{`${truck.overallWidth} mm`}
												</IonCardTitle>
											</IonCol>
										</IonRow>

										<IonRow style={{ borderBottom: "1px solid #242424", padding: "0.5rem" }}>
											<IonCol size="9">
												<IonCardTitle style={{ fontSize: "0.9rem", marginLeft: true ? "1.5rem" : "" }}>
													FH - Frame Height Rear Axle
												</IonCardTitle>
											</IonCol>

											<IonCol size="3">
												<IonCardTitle style={{ fontSize: "0.9rem" }}>
													{`${truck.frameHeightRearAxle} mm`}
												</IonCardTitle>
											</IonCol>
										</IonRow>

										<IonRow style={{ borderBottom: "1px solid #242424", padding: "0.5rem" }}>
											<IonCol size="9">
												<IonCardTitle style={{ fontSize: "0.9rem", marginLeft: true ? "1.5rem" : "" }}>
													FOH - Front Overhang
												</IonCardTitle>
											</IonCol>

											<IonCol size="3">
												<IonCardTitle style={{ fontSize: "0.9rem" }}>
													{`${truck.frontOverhang} mm`}
												</IonCardTitle>
											</IonCol>
										</IonRow>

										<IonRow style={{ borderBottom: "1px solid #242424", padding: "0.5rem" }}>
											<IonCol size="9">
												<IonCardTitle style={{ fontSize: "0.9rem", marginLeft: true ? "1.5rem" : "" }}>
													FT - Front Track
												</IonCardTitle>
											</IonCol>

											<IonCol size="3">
												<IonCardTitle style={{ fontSize: "0.9rem" }}>
													{`${truck.trackF} mm`}
												</IonCardTitle>
											</IonCol>
										</IonRow>

										<IonRow style={{ borderBottom: "1px solid #242424", padding: "0.5rem" }}>
											<IonCol size="9">
												<IonCardTitle style={{ fontSize: "0.9rem", marginLeft: true ? "1.5rem" : "" }}>
													FW - Frame Width (Rear)
												</IonCardTitle>
											</IonCol>

											<IonCol size="3">
												<IonCardTitle style={{ fontSize: "0.9rem" }}>
													{`${truck.frameWidth} mm`}
												</IonCardTitle>
											</IonCol>
										</IonRow>

										<IonRow style={{ borderBottom: "1px solid #242424", padding: "0.5rem" }}>
											<IonCol size="9">
												<IonCardTitle style={{ fontSize: "0.9rem", marginLeft: true ? "1.5rem" : "" }}>
													MGC - Minimum Ground Clearance
												</IonCardTitle>
											</IonCol>

											<IonCol size="3">
												<IonCardTitle style={{ fontSize: "0.9rem" }}>
													{`${truck.mgc} mm`}
												</IonCardTitle>
											</IonCol>
										</IonRow>

										<IonRow style={{ borderBottom: "1px solid #242424", padding: "0.5rem" }}>
											<IonCol size="9">
												<IonCardTitle style={{ fontSize: "0.9rem", marginLeft: true ? "1.5rem" : "" }}>
													ROH - Rear Overhang
												</IonCardTitle>
											</IonCol>

											<IonCol size="3">
												<IonCardTitle style={{ fontSize: "0.9rem" }}>
													{`${truck.rearOverhang} mm`}
												</IonCardTitle>
											</IonCol>
										</IonRow>

										<IonRow style={{ borderBottom: "1px solid #242424", padding: "0.5rem" }}>
											<IonCol size="9">
												<IonCardTitle style={{ fontSize: "0.9rem", marginLeft: true ? "1.5rem" : "" }}>
													RT - Rear Track
												</IonCardTitle>
											</IonCol>

											<IonCol size="3">
												<IonCardTitle style={{ fontSize: "0.9rem" }}>
													{`${truck.trackR} mm`}
												</IonCardTitle>
											</IonCol>
										</IonRow>

										<IonRow style={{ borderBottom: "1px solid #242424", padding: "0.5rem" }}>
											<IonCol size="9">
												<IonCardTitle style={{ fontSize: "0.9rem", marginLeft: true ? "1.5rem" : "" }}>
													Turn Radius (Curb to Curb)
												</IonCardTitle>
											</IonCol>

											<IonCol size="3">
												<IonCardTitle style={{ fontSize: "0.9rem" }}>
													{`${truck.turningRadiusKerb} mm`}
												</IonCardTitle>
											</IonCol>
										</IonRow>

										<IonRow style={{ borderBottom: "1px solid #242424", padding: "0.5rem" }}>
											<IonCol size="9">
												<IonCardTitle style={{ fontSize: "0.9rem", marginLeft: true ? "1.5rem" : "" }}>
													Turn Radius (Wall to Wall)
												</IonCardTitle>
											</IonCol>

											<IonCol size="3">
												<IonCardTitle style={{ fontSize: "0.9rem" }}>
													{`${truck.turningRadiusWall} mm`}
												</IonCardTitle>
											</IonCol>
										</IonRow>

										<IonRow style={{ borderBottom: "1px solid #242424", padding: "0.5rem" }}>
											<IonCol size="9">
												<IonCardTitle style={{ fontSize: "0.9rem", marginLeft: true ? "1.5rem" : "" }}>
													WB - Wheelbase (Standard)
												</IonCardTitle>
											</IonCol>

											<IonCol size="3">
												<IonCardTitle style={{ fontSize: "0.9rem" }}>
													{`${truck.wheelBase} mm`}
												</IonCardTitle>
											</IonCol>
										</IonRow>

										<IonRow style={{ borderBottom: "1px solid #242424", padding: "0.5rem" }}>
											<IonCol size="9">
												<IonCardTitle style={{ fontSize: "0.9rem", marginLeft: true ? "1.5rem" : "" }}>
													WB - Wheelbase (Min - Max)
												</IonCardTitle>
											</IonCol>

											<IonCol size="3">
												<IonCardTitle style={{ fontSize: "0.9rem" }}>
													{`${truck.wheelBaseMin} - ${truck.wheelBaseMax} mm`}
												</IonCardTitle>
											</IonCol>
										</IonRow>
									</IonCol>

									/** <!-- Dimensions --> */
								}

								{
									showGuidelines &&

									<IonCol size="12">
										<IonCardSubtitle className="ion-text-center" color="primary">
											More Specifications
										</IonCardSubtitle>

										<IonRow style={{ borderBottom: "1px solid #242424", padding: "0.5rem" }}>
											<IonCol size="7">
												<IonCardTitle style={{ fontSize: "0.9rem", marginLeft: true ? "1.5rem" : "" }}>
													Engine Type
												</IonCardTitle>
											</IonCol>

											<IonCol size="5">
												<IonCardTitle style={{ fontSize: "0.9rem" }}>
													{`${truck.engine} `}
												</IonCardTitle>
											</IonCol>
										</IonRow>

										<IonRow style={{ borderBottom: "1px solid #242424", padding: "0.5rem" }}>
											<IonCol size="7">
												<IonCardTitle style={{ fontSize: "0.9rem", marginLeft: true ? "1.5rem" : "" }}>
													Power (kW@rpm)
												</IonCardTitle>
											</IonCol>

											<IonCol size="5">
												<IonCardTitle style={{ fontSize: "0.9rem" }}>
													{`${truck.engineKW}`}
												</IonCardTitle>
											</IonCol>
										</IonRow>
										<IonRow style={{ borderBottom: "1px solid #242424", padding: "0.5rem" }}>
											<IonCol size="7">
												<IonCardTitle style={{ fontSize: "0.9rem", marginLeft: true ? "1.5rem" : "" }}>
													Torque (Nm@rpm)
												</IonCardTitle>
											</IonCol>

											<IonCol size="5">
												<IonCardTitle style={{ fontSize: "0.9rem" }}>
													{`${truck.engineTorque}`}
												</IonCardTitle>
											</IonCol>
										</IonRow>
										<IonRow style={{ borderBottom: "1px solid #242424", padding: "0.5rem" }}>
											<IonCol size="7">
												<IonCardTitle style={{ fontSize: "0.9rem", marginLeft: true ? "1.5rem" : "" }}>
													Emission Status
												</IonCardTitle>
											</IonCol>

											<IonCol size="5">
												<IonCardTitle style={{ fontSize: "0.9rem" }}>
													{`${truck.engineEmissionStatus}`}
												</IonCardTitle>
											</IonCol>
										</IonRow>

										<IonRow style={{ borderBottom: "1px solid #242424", padding: "0.5rem" }}>
											<IonCol size="7">
												<IonCardTitle style={{ fontSize: "0.9rem", marginLeft: true ? "1.5rem" : "" }}>
													Fuel Type
												</IonCardTitle>
											</IonCol>

											<IonCol size="5">
												<IonCardTitle style={{ fontSize: "0.9rem" }}>
													{`${truck.engineFuelType}`}
												</IonCardTitle>
											</IonCol>
										</IonRow>

										<IonRow style={{ borderBottom: "1px solid #242424", padding: "0.5rem" }}>
											<IonCol size="7">
												<IonCardTitle style={{ fontSize: "0.9rem", marginLeft: true ? "1.5rem" : "" }}>
													Fuel Tank Capacity
												</IonCardTitle>
											</IonCol>

											<IonCol size="5">
												<IonCardTitle style={{ fontSize: "0.9rem" }}>
													{`${truck.fuelTankCapacity} L`}
												</IonCardTitle>
											</IonCol>
										</IonRow>

										{ (!isBusOrIsDyna) && price !== null &&

											<>
												<IonRow style={{ borderBottom: "1px solid #242424", padding: "0.5rem" }}>
													<IonCol size="7">
														<IonCardTitle style={{ fontSize: "0.9rem", marginLeft: true ? "1.5rem" : "" }}>
															Recommended Retail Price incl VAT
														</IonCardTitle>
													</IonCol>
													<IonCol size="5">
														<IonCardTitle style={{ fontSize: "0.9rem" }}>
															{price}
														</IonCardTitle>
													</IonCol>
													<IonCol size="12">
														<IonNote style={{ fontSize: "0.9rem", marginLeft: true ? "1.5rem" : "" }}>
														Last updated: {'31/01/2025'}
														</IonNote>
													</IonCol>
												</IonRow>

												{/*<IonRow style={{ borderBottom: "1px solid #242424", padding: "0.5rem" }}>
													<IonCol size="7">
														<IonCardTitle style={{ fontSize: "0.9rem", marginLeft: true ? "1.5rem" : "" }}>
															Maximum Price
														</IonCardTitle>
													</IonCol>
													<IonCol size="5">
														<IonCardTitle style={{ fontSize: "0.9rem" }}>
															{`R ${(data as unknown as Array<ModelTruck.WebTruck>).filter(t => t.name.includes(truck.series))[0].maxPrice}`}
														</IonCardTitle>
													</IonCol>
										</IonRow> */}

											</>

										}

										<IonRow style={{ borderBottom: "1px solid #242424", padding: "0.5rem" }}>
											<IonCol size="12">
												<IonCardTitle style={{ fontSize: "0.9rem", marginLeft: true ? "1.5rem" : "" }}>
													{truck.additionalInfo}
												</IonCardTitle>
											</IonCol>
										</IonRow>
									

									</IonCol>

									/** <!-- More --> */
								}

								{
									showDiagrams &&

									<IonCol size="12">

										<IonCardSubtitle className="ion-text-center" color="primary">
											Diagrams
										</IonCardSubtitle>

										<IonRow>

											<IonCard mode="ios" style={ {width: `${isWide ? '768px' : '360px'}` }}>

											<IonCardContent className="ion-justify-content-center">
												<IonCardTitle>Side</IonCardTitle>
													<CnvsDimensions 
														values={values}
														view={'side'}/> 
											</IonCardContent>
												
											</IonCard>
										</IonRow>
									</IonCol>
								}

							</IonRow>

						</IonGrid>		
					</IonList>

					<IonRow className="ion-text-center">

						{
							showGuidelines &&

							<IonCol size="12">
								<IonButton mode="ios" routerLink={ROUTES.GUIDELINES_PAGE_ROUTE} expand="block" fill="outline" color="primary">
									<IonIcon icon={documentTextOutline} />&nbsp;
									Guidelines
								</IonButton>
							</IonCol>

								

						}

						<IonCol size="12">

							<IonButton mode="ios" routerLink={`${ROUTES.CALCULATIONS_PAGE_ROUTE}/${truck.chassisModel}`} expand="block" color="primary" disabled={truck.series === '500'}>
								<IonIcon icon={calculatorOutline} />&nbsp;
								Configure
							</IonButton>
							
							{/*
								isDealerShip ? 
								<IonButton mode="ios" onClick={() => setShowSubmitToBuilderModal(true)} expand="block" color="primary">
									<IonIcon icon={calculatorOutline} />&nbsp;
									Request Specification
								</IonButton> 
								:
								<IonButton mode="ios" routerLink={`${ROUTES.CALCULATIONS_PAGE_ROUTE}/${truck.chassisModel}`} expand="block" color="primary" disabled={truck.series === '500'}>
									<IonIcon icon={calculatorOutline} />&nbsp;
									Configure
								</IonButton>
							*/}
						</IonCol>
					</IonRow>

					<IonModal
						mode="ios"
						isOpen={showSubmitToBuilderModal}
						onDidDismiss={() => setShowSubmitToBuilderModal(false)}
						swipeToClose={true}> 
							<SubmitToBuilder 
								onDismissModal={() => setShowSubmitToBuilderModal(false)} 
								route={`${ROUTES.TRUCK_LIST_PAGE_ROUTE}/${truck.chassisModel}`}
								values={truck}/>
        			</IonModal>

					<IonModal mode="ios" isOpen={showPDFViewer} onDidDismiss={() => setShowPDFViewer(false)} swipeToClose={true}>
						<Viewer onDismissModal={() => setShowPDFViewer(false)} docTemplate={<TruckDoc specification={truck}/>}/>
					</IonModal>
                            
				</IonGrid>
			</IonContent>
		</IonPage>
	);
};

export default CalcTruck;