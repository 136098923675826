import { useEffect, useRef, useState } from 'react';
import { isPlatform, IonContent, IonPage, IonItem, IonLabel, IonHeader, IonToolbar, IonButtons, 
  IonButton, IonIcon, IonItemDivider, IonChip, IonFab, IonFabButton, IonModal, 
  IonTitle, IonRow, IonCol } from '@ionic/react';
import { options, close, arrowUp, arrowDown, filter, chatboxEllipsesOutline } from 'ionicons/icons';
import { ChatList } from 'react-chat-elements';
import Skeleton from 'react-loading-skeleton';
import { useMedia } from 'react-use';

import { useFetchDataFromDB } from '../services/DataApi';
import { genericFilter, genericSearch, genericSort, IFilter, ISorter } from '../utils';
import { GroupedLeads, Message } from '../models/Lead';
import SearchInput from '../components/SearchInput';
import SideMenu from '../components/Leads/Chats/SideMenu';
import { GroupedLeadsStore, LeadStatus } from '../services/leads';
import { SubmitToDealer, SubmitToBuilder } from '../components/Leads';
import { useStoreState } from 'pullstate';
import { decryptAES, getUser, UserPreferences } from '../services/user';
import * as ROUTES from '../config';

import 'react-loading-skeleton/dist/skeleton.css';
import './Leads.css';
import { useHistory } from 'react-router';



const messagesEndpoint =  ROUTES.MESSAGES_API_ENDPOINT;
//const dealersEndpoint = ROUTES.DEALERSHIPS_API_ENDPOINT;
//const buildersEndpoint = ROUTES.BUILDERS_API_ENDPOINT;


const Leads: React.FC = () => {

  const pageRef = useRef<HTMLElement>(null);
  const ios = isPlatform('ios');
  const isWide = useMedia('(min-width: 576x)', false);
  const history = useHistory();

  const { data, isLoading } = useFetchDataFromDB('messages', messagesEndpoint);
  //const { data: dealers, isLoading: isLoadingDealers } = useFetchDataFromDB('dealerships', dealersEndpoint);
  //const { data: builders, isLoading: isLoadingBuilders } = useFetchDataFromDB('preferred-suppliers', buildersEndpoint);

  /** User Details */
  const user = useStoreState(UserPreferences, getUser);
  const isDealership = user.role.includes('Hino Dealership');

  const [query, setQuery] = useState<string>("");
  const [showFilter, setShowFilter] = useState(false);
  const [showName, setShowName] = useState(false);
  const [showStatus, setShowStatus] = useState(false);
  const [showSort, setShowSort] = useState(false);

  const [sortProperty, setSortProperty] = useState<keyof Message>("date_submitted");
  const [activeSorter, setActiveSorter] = useState<ISorter<Message>>({
    property: sortProperty,
    isDescending: false,
  });
  const [activeFilters, setActiveFilters] = useState<Array<IFilter<Message>>>(
    []
  );

  const [showAZ, setShowAZ] = useState(activeSorter.isDescending);
  const [leads, setLeads] = useState<Array<Message>>([]);
  const [groupedLeads, setGroupedLeads] = useState<Array<GroupedLeads>>([]);
  const [filterDealerships, setFilterDealerships] = useState<Array<string>>([]);
  const [filterStatuses, setFilterStatuses] = useState<Array<LeadStatus | null>>([]);

  const [showSubmitToDealerModal, setShowSubmitToDealerModal] = useState(false);

  useEffect(() => {

    if (!isLoading) {

      const l: Message[] = data
        .filter((lead: Message) =>
          genericSearch<Message>(lead, ['salesperson','date_submitted', 'truck_id', 'body_type', 'builder_name', 'message_status', 'date_last_updated'], query))
        .filter((lead: Message) => 
          genericFilter<Message>(lead, activeFilters))
        .sort((leadA: Message, leadB: Message) =>
          genericSort<Message>(leadA, leadB, activeSorter) 
        );

      //Group leads from same Salesperson or Builder together and use their ids as keys
      const g: GroupedLeads[] = isDealership ? 

        l.reduce((r, a) => {
          r[a.preferred_supplier] = r[a.preferred_supplier] || [];
          r[a.preferred_supplier].push(a);
          return r;
        }, Object.create(null))

      :

        l.reduce((r, a) => {
          r[a.dealership] = r[a.dealership] || [];
          r[a.dealership].push(a);
          return r;
        }, Object.create(null))
      ;

      //List of Available Statuses and Dealerships
      const d = Array.from(new Set(l.map((o: Message) => o.salesperson)));
      //const e = new Set(d.map((s: string) => s.split(" ").map(c => c.charAt(0).toUpperCase())));
      const s = Array.from(new Set(l.map((o: Message) => o.message_status)));

      setLeads(l);
      setGroupedLeads(g);
      setFilterDealerships(d);
      setFilterStatuses(s);

      //setCities(c);
    }
  }, [query, data, activeFilters, activeSorter, isDealership, isLoading]);

  useEffect(() => {
    setActiveSorter({
      property: sortProperty,
      isDescending: showAZ
    })
  }, [showAZ, sortProperty]);

  let ionItems: JSX.Element[] = [], groupedLeadItems: JSX.Element[] = [];

  for (let i: number = 0; i < 10; i++) {

    ionItems.push(
      <IonItem className="leads-item" key={i} >
        <IonLabel>
          <h4>{<Skeleton/>}</h4>
          <p>{<Skeleton count={2}/>}</p>
        </IonLabel>
      </IonItem>
    )
  }

  
  for (let [key, value] of Object.entries(groupedLeads)) {

    if (leads.length > 0) {

      groupedLeadItems.push(

        <ChatList
          className='chat-list'
          lazyLoadingImage={`https://eu.ui-avatars.com/api/?name=${`${isDealership ? (value as unknown as Message[])[0].builder_name : (value as unknown as Message[])[0].salesperson}`.replace(" ", "+")}&size=250&background=ffc409&color=000`}
          key={key}
          id={key}
          onClick={() => {
            GroupedLeadsStore.update(s => {
              isDealership ? s.builder_name = key : s.salesperson = key; //If dealership, show that Builder's Name the message sent to
              isDealership ? s.salesperson = value.salesperson : s.builder_name = value.builder_name;
              s.leads = (value as unknown as Message[]);
              });

              history.replace(`${ROUTES.LEADS_LIST_PAGE_ROUTE}/${encodeURIComponent(key).toLowerCase()}`)
            }    
          }
          dataSource={[
            {
              //ToDo: Concat the Builder Name or Salesperson and add Actual Preferred Supplier Name and Dealership Name id: `${isDealership ? (value as unknown as Message[])[0].builder_name : (value as unknown as Message[])[0].salesperson}`,
              id: key,
              avatar: `https://eu.ui-avatars.com/api/?name=${`${isDealership ? (value as unknown as Message[])[0].builder_name : (value as unknown as Message[])[0].salesperson}`.replace(" ", "+")}&size=250&background=ffc409&color=000`,
              alt: `${isDealership ? (value as unknown as Message[])[0].builder_name : (value as unknown as Message[])[0].salesperson}`.split(" ", 3).map(s => s.charAt(0).toUpperCase()).filter(s => s !== '(').filter(s => s !== '&').flat().toString(),
              title: `${isDealership ? (value as unknown as Message[])[0].builder_name : (value as unknown as Message[])[0].salesperson}`,
              subtitle: `${(value as unknown as Message[])[0].message_notes === "" || (value as unknown as Message[])[0].message_notes === null ? (value as unknown as Message[])[0].body_type : decryptAES((value as unknown as Message[])[0].message_notes!, (value as unknown as Message[])[0].userid )}`,
              date: new Date((value as unknown as Message[])[0].date_last_updated.toString().substring(0, 10)),
              unread: (value as unknown as Message[]).length,
            }
        ]} />

       /* <IonItem 
          mode="ios" 
          key={key} 
          className="leads-item" 
          detail={false} 
          routerLink={`${ROUTES.LEADS_LIST_PAGE_ROUTE}/${encodeURIComponent(key).toLowerCase()}`}
          onClick={() => {
            GroupedLeadsStore.update(s => {
              isDealership ? s.builder_name = key : s.salesperson = key; //If dealership, show that Builder's Name the message sent to
              isDealership ? s.salesperson = value.salesperson : s.builder_name = value.builder_name;
              s.leads = (value as unknown as Message[]);
             })
          }
          }>

          <div className={(value as unknown as Message[]).filter(l => l.message_status?.toString() === 'Pending')[0].message_status?.toString() === 'Pending' ? "circle-warning" : "circle-success"}>
            <h4 className="text"><strong>{`${isDealership ? (value as unknown as Message[])[0].builder_name : (value as unknown as Message[])[0].salesperson}`.split(" ", 3).map(s => s.charAt(0).toUpperCase()).filter(s => s !== '(').filter(s => s !== '&')}</strong></h4>
          </div>
  
          <IonLabel>
            <h2><strong>{isDealership ? (value as unknown as Message[])[0].builder_name : (value as unknown as Message[])[0].salesperson}</strong></h2>
            <IonText>
              <p>{(value as unknown as Message[])[0].message_notes === "" || (value as unknown as Message[])[0].message_notes === null ? (value as unknown as Message[])[0].body_type : decryptAES((value as unknown as Message[])[0].message_notes!, (value as unknown as Message[])[0].userid )}</p>
            </IonText>
            <p className="time-right">{`${(value as unknown as Message[])[0].date_last_updated.toString().substring(0, 10)}`}</p>
          </IonLabel>
  
          <IonBadge slot="end" color={(value as unknown as Message[])[0].message_status?.toString() === 'Pending' ? "warning" : "success"}>
            {(value as unknown as Message[]).length}
          </IonBadge>  

        </IonItem> */
      )
    }
  }

  return (
    <IonPage ref={pageRef} id="leads-page">

<IonHeader>

<IonToolbar color="toolbar">
    <IonTitle><strong>Notifications</strong></IonTitle>
  </IonToolbar>

{
  !ios && 
  <IonToolbar mode="ios" className="ion-padding-top">
    <SearchInput onChangeSearchQuery={(query) => setQuery(query)} />
    <IonButtons slot="end">
      {
        !showFilter &&
        <IonButton color="dark" onClick={() => setShowFilter(true)}>
        {ios ? 'Filter' : <IonIcon icon={options} slot="icon-only" />}
        </IonButton>}
      {
        showFilter &&
        <IonButton color="dark" onClick={() => setShowFilter(false)}>
          {ios ? 'Close' : <IonIcon icon={close} slot="icon-only" />}
        </IonButton>
      }
    </IonButtons>
  </IonToolbar>
}

{
  !ios && showFilter &&

  <IonToolbar mode="ios">
    <IonItemDivider color='light'>
      <IonChip 
        color={showName ? "medium" : "primary"}
        slot="start"
        onClick={() => {
          setShowName(true);
          setShowStatus(false);
          setShowSort(false);
        }}>Name
        </IonChip>
      <IonChip 
        color={showStatus ? "medium" : "primary"}
        slot="start"
        onClick={() => {
          setShowStatus(true);
          setShowName(false);
          setShowSort(false);
        }}>Status
      </IonChip>
      
      <IonButton 
        slot="end"
        color={showSort ? "medium" : "dark"}
        disabled={showSort}
        onClick={() => {
          setShowStatus(false);
          setShowName(false);
          setShowSort(true);
        }}>{ios ? 'Sort' : <IonIcon icon={filter} slot="icon-only" />}
      </IonButton>

    </IonItemDivider>

    {
      showName && 

      <IonItemDivider color="light">

        {
          filterDealerships.map(d => 

            <IonChip 
              color="success" 
              key={d}
              onClick={() => {
                setActiveFilters([
                  ...activeFilters.filter(
                    (filter) => filter.property !== 'dealership'
                  ),
                  { property: 'dealership', isTruthyPicked: true },
                ]);
              }}
              >{d}
            </IonChip>
          )
        }
      </IonItemDivider>
    }

    {
      showStatus && 

      <IonItemDivider color="light">

        {
          filterStatuses.map(s => 

            <IonChip 
              color="success" 
              key={s}
              onClick={() => {
                setActiveFilters([
                  ...activeFilters.filter(
                    (filter) => filter.property !== 'message_status'
                  ),
                  { property: 'message_status', isTruthyPicked: true },
                ]);
              }}
              >{s}
            </IonChip>
          )
        }
      </IonItemDivider>
    }

    {
      showSort &&

      <IonItemDivider color='light'>
        <IonChip
          disabled={sortProperty === 'dealership'}
          onClick={() => setSortProperty('dealership')} 
          color={sortProperty === 'dealership' ? "medium" : "success"}
          slot="start">Dealer Name
        </IonChip>

        <IonChip
          disabled={sortProperty === 'date_submitted'}
          onClick={() => setSortProperty('date_submitted')}
          color={sortProperty === 'date_submitted' ? "medium" : "success"}
          slot="start">Date Created
        </IonChip>

        <IonChip
          disabled={sortProperty === 'message_status'}
          onClick={() => setSortProperty('message_status')}
          color={sortProperty === 'message_status' ? "medium" : "success"}
          slot="start">Status
        </IonChip>

        <IonButtons slot="end">
          {
            showAZ &&
            <IonButton color="dark" onClick={() => setShowAZ(false)}>
            {ios ? <p>A-Z</p> : <IonIcon icon={arrowDown} slot="icon-only" />}
            </IonButton>
          }

          {
            !showAZ &&
            <IonButton color="dark" onClick={() => setShowAZ(true)}>
            {ios ? <p>Z-A</p> : <IonIcon icon={arrowUp} slot="icon-only" />}
            </IonButton>
          }
        </IonButtons>
      </IonItemDivider>
    }
  </IonToolbar>
}
  
</IonHeader>

<IonContent fullscreen>
<IonHeader collapse="condense">
  <IonToolbar>
    <IonTitle size="large">Notifications</IonTitle>
  </IonToolbar>
  <IonToolbar className="ion-padding-top">
    <SearchInput onChangeSearchQuery={(query) => setQuery(query)} />
  <IonButtons slot="end">
    {
      !showFilter &&
      <IonButton color="dark" onClick={() => setShowFilter(true)}>
      {ios ? 'Filter' : <IonIcon icon={options} slot="icon-only" />}
      </IonButton>}
    {
      showFilter &&
      <IonButton color="dark" onClick={() => setShowFilter(false)}>
        {ios ? 'Close' : <IonIcon icon={close} slot="icon-only" />}
      </IonButton>
    }
  </IonButtons>
</IonToolbar>

{
  showFilter &&

  <IonToolbar mode="ios">
    <IonItemDivider color='light'>
      <IonChip 
        color={showName ? "medium" : "primary"}
        slot="start"
        onClick={() => {
          setShowName(true);
          setShowStatus(false);
          setShowSort(false);
        }}>Dealer Name
        </IonChip>
      <IonChip 
        color={showStatus ? "medium" : "primary"}
        slot="start"
        onClick={() => {
          setShowStatus(true);
          setShowName(false);
          setShowSort(false);
        }}>Status
      </IonChip>
      
      <IonButton 
        slot="end"
        color={showSort ? "medium" : "dark"}
        disabled={showSort}
        onClick={() => {
          setShowStatus(false);
          setShowName(false);
          setShowSort(true);
        }}>{ios ? 'Sort' : <IonIcon icon={filter} slot="icon-only" />}
      </IonButton>

    </IonItemDivider>

    {
      showName && 

      <IonItemDivider color="light">

        {
          filterDealerships.map(d => 

            <IonChip 
              color="success" 
              key={d}
              onClick={() => {
                setActiveFilters([
                  ...activeFilters.filter(
                    (filter) => filter.property !== 'dealership'
                  ),
                  { property: 'dealership', isTruthyPicked: true },
                ]);
              }}
              >{d}
            </IonChip>
          )
        }
      </IonItemDivider>
    }

    {
      showStatus && 

      <IonItemDivider color="light">

        {
          filterStatuses.map(s => 

            <IonChip 
              color="success" 
              key={s}
              onClick={() => {
                setActiveFilters([
                  ...activeFilters.filter(
                    (filter) => filter.property !== 'message_status'
                  ),
                  { property: 'message_status', isTruthyPicked: true },
                ]);
              }}
              >{s}
            </IonChip>
          )
        }
      </IonItemDivider>
    }

    {
      showSort &&

      <IonItemDivider color='light'>
        <IonChip
          disabled={sortProperty === 'dealership'}
          onClick={() => setSortProperty('dealership')} 
          color={sortProperty === 'dealership' ? "medium" : "success"}
          slot="start">Dealer Name
        </IonChip>

        <IonChip
          disabled={sortProperty === 'date_submitted'}
          onClick={() => setSortProperty('date_submitted')}
          color={sortProperty === 'date_submitted' ? "medium" : "success"}
          slot="start">Date Created
        </IonChip>

        <IonChip
          disabled={sortProperty === 'message_status'}
          onClick={() => setSortProperty('message_status')}
          color={sortProperty === 'message_status' ? "medium" : "success"}
          slot="start">Status
        </IonChip>

        <IonButtons slot="end">
          {
            showAZ &&
            <IonButton color="dark" onClick={() => setShowAZ(false)}>
            {ios ? <p>A-Z</p> : <IonIcon icon={arrowDown} slot="icon-only" />}
            </IonButton>
          }

          {
            !showAZ &&
            <IonButton color="dark" onClick={() => setShowAZ(true)}>
            {ios ? <p>Z-A</p> : <IonIcon icon={arrowUp} slot="icon-only" />}
            </IonButton>
          }
        </IonButtons>
      </IonItemDivider>
    }
  </IonToolbar>
}
</IonHeader>

        { isLoading && ionItems.map(i => i) }
      
        {
          !isLoading && leads.length === 0 &&
          
        <IonRow className="ion-text-center ion-justify-content-center ion-margin-top">
          <IonCol size="10">
              <lottie-player src={ROUTES.CONTENT_NOT_FOUND} mode="bounce" background="transparent" speed="0.8" loop autoplay></lottie-player>
            </IonCol>
          <IonCol size="10">
              <IonLabel>
                  <h2><strong>No Messages Found</strong></h2>
                  <p>{`${isDealership ? 'Please search for saved specifications and send a request to Hino Preferred Suppliers': 
                  'Please submit your saved specifications to a Hino Dealership'}`}</p>
              </IonLabel>
          </IonCol>
        </IonRow>
        }

        {
          !isWide && leads.length > 0 && 

            groupedLeadItems.map((gl: any, index: number) => (
            <IonCol size="12" sizeMd="6" sizeLg="4" sizeXl="4" key={`lead-${gl}-${index}`}>
                {gl}
            </IonCol>
            ))   
        }

        {
          !isWide && leads.length > 0 &&
          <IonCol size="12" sizeMd="6" sizeLg="4" sizeXl="4">
                <SideMenu />
            </IonCol>

        }
        
        <IonFab vertical="bottom" horizontal="end" slot="fixed">
          <IonFabButton onClick={() => setShowSubmitToDealerModal(true)}>
            <IonIcon icon={chatboxEllipsesOutline}></IonIcon>
          </IonFabButton>
        </IonFab>

        <IonModal
          mode="ios"
          isOpen={showSubmitToDealerModal}
          onDidDismiss={() => setShowSubmitToDealerModal(false)}
          swipeToClose={true}>
            {
              isDealership ? 
              
              <SubmitToBuilder 
                onDismissModal={() => setShowSubmitToDealerModal(false)} 
                route={ROUTES.TABS_LEADS_PAGE_ROUTE}
                values={null}/>

              :

              <SubmitToDealer 
                onDismissModal={() => setShowSubmitToDealerModal(false)} 
                route={ROUTES.LEADS_LIST_PAGE_ROUTE}
                values={null}/>
            }
        </IonModal>

      </IonContent>

    </IonPage>
  );
};

export default Leads;
