import { useState } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { IonBadge, IonIcon, IonLabel, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs } from '@ionic/react';
import { compass, compassOutline, home, homeOutline, person, personOutline, construct, constructOutline, busOutline, bus, notificationsOutline, notifications} from 'ionicons/icons';
import { UserPreferencesModel } from '../models/User';

import Home from './TabHome';
import Trucks from './TabTrucks';
import Dealerships from './TabDealerships';
import Notifications from './TabNotifications';
import PreferredSuppliers from './TabPreferredSuppliers';
import Account from './TabAccount';


import * as ROUTES from '../config';

interface Pages {
    title: string,
    path: string,
    icon: string,
    selected: string
}

interface TabProps {
    user: UserPreferencesModel
}

const tabRoutes = {
    builderPages: [
      { title: 'Home', path: ROUTES.TABS_HOME_PAGE_ROUTE, icon: homeOutline, selected: home },
      { title: 'Notifications', path: ROUTES.TABS_LEADS_PAGE_ROUTE, icon: notificationsOutline, selected: notifications },
      { title: 'Dealerships', path: ROUTES.TABS_DEALERSHIPS_LIST_PAGE_ROUTE, icon: compassOutline, selected: compass},
      { title: 'Account', path: ROUTES.TABS_ACCOUNT_PAGE_ROUTE, icon: personOutline, selected: person }
    ],
    dealershipPages: [
      { title: 'Home', path: ROUTES.TABS_HOME_PAGE_ROUTE, icon: homeOutline, selected: home },
      { title: 'Notifications', path: ROUTES.TABS_LEADS_PAGE_ROUTE, icon: notificationsOutline, selected: notifications },
      { title: 'Preferred Suppliers', path: ROUTES.TABS_HPS_LIST_PAGE_ROUTE, icon: constructOutline, selected: construct },
      { title: 'Account', path: ROUTES.TABS_ACCOUNT_PAGE_ROUTE, icon: personOutline, selected: person }
    ],
    otherUserPages: [
      { title: 'Home', path: ROUTES.TABS_HOME_PAGE_ROUTE, icon: homeOutline, selected: home },
      { title: 'Hino Trucks', path: ROUTES.TABS_TRUCK_LIST_PAGE_ROUTE, icon: busOutline, selected: bus },
      //{ title: 'Dealerships', path: ROUTES.TABS_DEALERSHIPS_LIST_PAGE_ROUTE, icon: compassOutline, selected: compass},
      //{ title: 'Preferred Suppliers', path: ROUTES.TABS_HPS_LIST_PAGE_ROUTE, icon: constructOutline, selected: construct },
      { title: 'Account', path: ROUTES.TABS_ACCOUNT_PAGE_ROUTE, icon: personOutline, selected: person }
    ]
};
  
const Tabs: React.FC<TabProps> = ({user}) => {

    const isBodyBuilder = !user.isDealership;
    const role = user.role;
    const showBadge = user.showBadge;
    const messageCount = user.messageCount;

    const [tabSelected, setTabSelected] = useState('');

    const renderlistItems = (list: Pages[]) => {
        
        return list
            .filter(route => !!route.path)
            .map(p => (
                    
                <IonTabButton key={p.title} tab={p.title} href={p.path}>
                    <IonIcon icon={tabSelected === p.title ? p.selected : p.icon} />
                    <IonLabel>{p.title}</IonLabel>
                    {
                        p.title === 'Notifications' && showBadge && 
                        <IonBadge color="warning">{messageCount}</IonBadge>
                    }
                </IonTabButton>
            ));
    }

    return (

        <IonTabs onIonTabsDidChange={(e) => setTabSelected(e.detail.tab)}>
            <Redirect exact path="/tabs" to={ROUTES.TABS_HOME_PAGE_ROUTE}/>
            <Route path="/tabs" render={() => <Home user={user}/>} />
           
            <IonRouterOutlet>
                <Route exact path={ROUTES.TABS_HOME_PAGE_ROUTE}>
                    <Home user={user}/>
                </Route>

                <Route exact path={ROUTES.TABS_HPS_LIST_PAGE_ROUTE}>
                    <PreferredSuppliers />
                </Route>

                <Route exact path={ROUTES.TABS_LEADS_PAGE_ROUTE}>
                    <Notifications />
                </Route>

                <Route exact path={ROUTES.TABS_DEALERSHIPS_LIST_PAGE_ROUTE}>
                    <Dealerships />
                </Route>

                <Route exact path={ROUTES.TABS_TRUCK_LIST_PAGE_ROUTE}>
                    <Trucks />
                </Route>
                <Route exact path={ROUTES.TABS_ACCOUNT_PAGE_ROUTE}>
                    <Account user={user}/>
                </Route>
            </IonRouterOutlet>

            <IonTabBar color="toolbar" slot="bottom">

                {
                    isBodyBuilder !== true && role.length > 0 &&
                    renderlistItems(tabRoutes.dealershipPages)
                }

                {
                    isBodyBuilder === true && role.length > 0 &&
                    renderlistItems(tabRoutes.builderPages)
                }

                {
                    role.length === 0 && 
                    renderlistItems(tabRoutes.otherUserPages)
                }

            </IonTabBar>
      </IonTabs>
    );
}

export default Tabs;
