import React, { useEffect, useState } from 'react';
import {
  Page,
  Image,
  Text,
  StyleSheet,
  Font,
} from '@react-pdf/renderer';
import { Canvg } from 'canvg';
import Table from './Table';
import { CalcValues, CalculationStore, Calculator, EmptyCalcValues, Payload, getTruckFromStorage } from '../../services/calculations';
import { decryptAES } from '../../services/user';
import { Body } from '../../models/Body';


const styles = StyleSheet.create({
    body: {
      paddingTop: 35,
      paddingBottom: 65,
      paddingHorizontal: 35,
    },
    title: {
      fontSize: 24,
      textAlign: 'center',
      fontFamily: 'Oswald',
    },
    author: {
      fontSize: 12,
      textAlign: 'center',
      marginBottom: 40,
    },
    subtitle: {
      fontSize: 18,
      margin: 12,
      fontFamily: 'Oswald',
    },
    text: {
      margin: 12,
      fontSize: 14,
      textAlign: 'justify',
      fontFamily: 'Times-Roman',
    },
    image: {
      marginVertical: 15,
      marginHorizontal: 100,
    },
    header: {
      fontSize: 12,
      marginBottom: 20,
      textAlign: 'center',
      color: 'grey',
    },
    pageNumber: {
      position: 'absolute',
      fontSize: 12,
      bottom: 30,
      left: 0,
      right: 0,
      textAlign: 'center',
      color: 'grey',
    },
  });

const SpecPage: React.FC = () => {

  const spec = CalculationStore.useState(s => s);
  //const values = CalcStore.useState(s => s);

  const compilerid = spec.userid;
  const truckSMC = spec.truckid;

  const _truck = getTruckFromStorage(truckSMC);

  const key = spec.userid || "";
  const encryptedBase64 = spec.bodySpecification as unknown as string;
  const decryptedText = decryptAES(encryptedBase64, key);
  const bodySpec = JSON.parse((decryptedText));
  const payload = spec.payload;
  const body = spec?.body === null || spec?.body === undefined ? "" : `with ${spec?.body} Body`;

  const  v = { ...new EmptyCalcValues()};
  v.truck = _truck;
  v.body = (bodySpec as Body || null);
  v.payload = (payload as Payload || null);

  const { ...truck} = Calculator(v as CalcValues);
  const title = `Specification for ${truck.title} ${body}`;
  const series = spec?.series;
  const subtitle = `Saved on ${spec?.savedDate.toString().substring(0, 10)} by ${compilerid}`
  const overview = `GVM: ${spec?.gvm} kg, Payload:  ${spec?.payload?.payload} kg`;
  const rules = `Mass: ${spec?.rules.mass}, Dimensions: ${spec?.rules.dimensions}, Bridge: ${spec?.rules.bridge} Circle: ${spec?.rules.circle}`;


  const [svgDataUri, setSvgDataUri] = useState<string>("")

  const svgToDataUri = async (svgString: string) => {

    try {
      const canvas: HTMLCanvasElement = document.createElement("canvas");
      const context = canvas.getContext("2d");

      if (context) {
        const v = Canvg.fromString(context, svgString.trim());
        await v.render({scaleWidth: 800, scaleHeight: 600 });
        const dataUri = canvas.toDataURL("image/png", 1.0);
        return dataUri
      }
    } catch (error) {
      console.error("Error occured:", error)
      return ""
    }
  }


  const massData = [
    {
      mass: "Chassis Weight",
      front: `${truck.mass.cwf}`,
      rear: `${truck.mass.cwr}`,
      total: `${truck.mass.cwt}`,
    },
    {
      mass: "Fuel and Crew",
      front: `${truck.mass.mcf}`,
      rear: `${truck.mass.mcr}`,
      total: `${truck.mass.mct}`,
    },
    {
      mass: "Body Weight",
      front: `${truck.mass.bwf}`,
      rear: `${truck.mass.bwr}`,
      total: `${truck.mass.bwt}`,
    },
    {
      mass: "Total Unladen",
      front: `${truck.mass.uwf}`,
      rear: `${truck.mass.uwr}`,
      total: `${truck.mass.uwt}`,
    },
    {
      mass: "Payload",
      front: `${truck.regulations.mass.pwf}`,
      rear: `${truck.regulations.mass.pwr}`,
      total: `${truck.regulations.mass.pwt}`,
    },
    {
      mass: "Gross Weight",
      front: `${truck.regulations.mass.twf}`,
      rear: `${truck.regulations.mass.twr}`,
      total: `${truck.regulations.mass.twt}`,
    },
    {
      mass: "Permissible",
      front: `${truck.regulations.mass.permf}`,
      rear: `${truck.regulations.mass.permr}`,
      total: `${truck.regulations.mass.permt}`,
    },
    {
      mass: "Unused",
      front: `${truck.regulations.mass.unusedF}`,
      rear: `${truck.regulations.mass.unusedR}`,
      total: `${truck.regulations.mass.unusedT}`,
    },
    {
      mass: "Utilisation (%)",
      front: `${truck.regulations.mass.utilF}`,
      rear: `${truck.regulations.mass.utilR}`,
      total: `${truck.regulations.mass.utilT}`,
    },
  ];


  const dimensionsData = [
    {
      id: 1,
      dimension: "Body (Inner)",
      length: `${truck.dimensions.ibl}`,
      width: `${truck.dimensions.ibw}`,
      height: `${truck.dimensions.ibh}`,
    },
    {
      id: 2,
      dimension: "Body (Outer)",
      length: `${truck.dimensions.bl}`,
      width: `${truck.dimensions.bw}`,
      height: `${truck.dimensions.bh}`,
    },
    {
      id: 3,
      dimension: "Body (Centre of Gravity)",
      length: `${truck.dimensions.bl*truck.dimensions.hcog/100}`,
      width: `${truck.dimensions.bw*truck.dimensions.lcog/100}`,
      height: `${truck.dimensions.bh*truck.dimensions.vcog/100}`,
    },
    {
      id: 4,
      dimension: "Overall",
      length: `${truck.regulations.dimensions.ol}`,
      width: `${truck.regulations.dimensions.ow}`,
      height: `${truck.regulations.dimensions.oh}`,
    },

  ];


  
  useEffect(() => {
    const convertSvgToDataUri = async () => {

      const data = await fetch(`assets/trucks/${series}/${truckSMC}/bridge/${spec?.body?.toLowerCase()}.svg`);
      const svgHtml = await data.text();

      /* const svg = (
        <svg data-src={`assets/trucks/${series}/${truckSMC}/bridge/${spec?.body?.toLowerCase()}.svg`} />
      )*/

      const dataUri = await svgToDataUri(svgHtml)

      setSvgDataUri(dataUri || "")
    }

    convertSvgToDataUri()

    

  }, [svgDataUri, series, truckSMC, spec?.body])



  return (
    <Page size="A4" orientation="landscape" style={styles.body}>
      <Text style={styles.header} fixed>{subtitle}</Text>
      <Text style={styles.title}>{title}</Text>
      <Text style={styles.author}>{overview}</Text>
      
      <Image style={styles.image} src="/assets/images/hino300.png" /> 
      {svgDataUri !== "" && <Image src={svgDataUri}/> }
      <Text style={styles.subtitle} break>Mass</Text>
      <Table
        tableHeaders={[
          { title: "", value: "mass" },
          { title: "Front (Kg)", value: "front" },
          { title: "Rear (Kg)", value: "rear" },
          { title: "Total (Kg)", value: "total" },
        ]}
        data={massData}
        heading=""
      />
      <Text style={styles.text}>
        Summary about Mass

      </Text>

      <Text style={styles.subtitle} break>Dimensions</Text>
      <Table
        tableHeaders={[
          { title: "", value: "dimension" },
          { title: "Length (mm)", value: "length" },
          { title: "Width (mm)", value: "width" },
          { title: "Height (mm)", value: "height" },
        ]}
        data={dimensionsData}
        heading=""
      />
      <Text style={styles.text}>
        Summary About Dimensions
      </Text>

      <Text style={styles.subtitle} break>Regulations</Text>

      <Text style={styles.text}>{rules}</Text>
    
      <Text
        fixed={true}
        style={styles.pageNumber}
        render={({ pageNumber, totalPages, subPageNumber, subPageTotalPages }) => {
          console.log(subPageNumber, subPageTotalPages);
          return `Page ${pageNumber} / ${totalPages}`;
        }
          
        }
      />
    </Page>
  );
}

Font.register({
    family: 'Oswald',
    src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf',
  });

export default SpecPage;
