import { useEffect, useState } from 'react';
import { round } from 'mathjs';
import CalcStore from './store';
import { ModelTruck, ModelBody, ModelRegulations } from '../../models';
import { getRules } from '../regulations';
import { Specification } from '../../models/Specification';
import { BodyHeight, BodyLength, BodyMass, BodyWidth } from '../../models/Body';
import { decryptAES } from '../user';


type Body = ModelBody.Body;
type Truck = ModelTruck.TruckSpecifications;

export interface CalcValues {
    body: Body | null;
    truck: Truck | string | null;
    payload: Payload | null;
    fuelAndCrew: FuelAndCrew | null;
    differences: Differences | null;
    rules: any;
    calcID: string;
}

export interface SaveCalcValues {
    userid: string;
    uniquename: string;
    v: CalcValues;
}

export interface  Differences {
    wbDiff: number;
    rohDiff: number;
}

export class FuelAndCrew {
    crewMass: number = 80;
    crewNumber: number = 1;
    fuelType: string = "Diesel";
    fuelDensity: number = 0.85;
    fuelTank: number = 100;
}

export interface Payload {
    payload: number | string;
    cog: number | string;
}

export class EmptyCalcValues {
    body: Body | null = null;
    truck: Truck | string | null = null;
    payload: Payload | null = null;
    fuelAndCrew: FuelAndCrew = new FuelAndCrew();
    differences: Differences | null = null;
    rules: any = null;
    calcID: string = "";
}


//const truckUrl = URL.BODIES_API_URL;
//const truckEndpoint = URL.BODY_SPECIFICATION_ENDPOINT_URL;

/** Takes the id of the selected truck on the Trucklist Page and returns its details from the DB */
const initCalculator = (id: any, specification?: Specification) => {

    const _id: string = JSON.stringify(id).replace(/['"]+/g, '');

    //ToDo: Fetch the truck from the DB
    const _truck = getTruckFromStorage(_id);
    const truck: Truck = JSON.parse(JSON.stringify(_truck)) as Truck;

    //Specification defined
    const key = specification?.userid || "";
    const encryptedBase64 = specification?.bodySpecification as unknown as string || "";
    const decryptedBodySpec = decryptAES(encryptedBase64, key);
    const bodySpecification = JSON.parse(JSON.stringify(decryptedBodySpec));

    const bodySpec = specification !== undefined ? bodySpecification : null;
    const bodyType = specification?.body === null || specification?.body === undefined ? 'unknown' : specification?.body;

    const v = new EmptyCalcValues();
    const values = { ...v};
    values.truck = truck;

    //Default Body parameters for the truck selected
    let bL: BodyLength | null = new BodyLength(values), 
        bH: BodyHeight | null = new BodyHeight(values), 
        bM: BodyMass | null = new BodyMass(values), 
        bW: BodyWidth | null = new BodyWidth(values),
        
        body: Body | null = null;

    //If bodySpec is not null, update default Body Parameters with ones from bodySpec
    if (bodySpec !==  null) {
        bL = (bodySpec as Body).length;
        bH = (bodySpec as Body).height;
        bM = (bodySpec as Body).mass;
        bW = (bodySpec as Body).width;

        body = {
            type: bodyType,
            //material: "steel",
            mass: bM,
            length: bL,
            width: bW,
            height: bH
        }
    }

    CalcStore.update((s: CalcValues) => {
        s.truck = truck;
        s.body = body;
    });
}

/*const useInitCalculator = (truckID: any, bodyID?: any) => {

    const _id: string = JSON.stringify(truckID).replace(/['"]+/g, '');
    const _bid: string = JSON.stringify(bodyID).replace(/['"]+/g, '');

    //ToDo: Fetch the truck from the DB
    const _truck = getTruckFromStorage(_id);
    console.log(_truck)
    const truck: Truck = JSON.parse(JSON.stringify(_truck)) as Truck;

    let _body: Body | null;

    if (bodyID !== undefined && bodyID !== null) {

        //const {data} = useFetchDataFromDB("bodies", bodyUrl, bodyEndpoint, _bid);

        _body = null;

    } else {
        _body = null;
    }

    //console.log(_truck)
    const body = JSON.parse(JSON.stringify(_body)) as Body;

    CalcStore.update((s: CalcValues) => {
        s.truck = truck;
        s.body = body;
    });

} */

/** Synchronise CalcValues stored on localStorage with ones stored on Memory */
const useLocalStore = (key: string, defaultValues: CalcValues) => {

    const [values, setValues] = useState(() => {
        const value = sessionStorage.getItem(key);
        return value !== null ? JSON.parse(value.toString()) : defaultValues;
    });

    useEffect(() => {
        const setObject = async () => sessionStorage.setItem(key, JSON.stringify(values));

        setObject();
        
    }, [key, values]);

    return [values, setValues];
}

/** Get Trucks from LocalStorage */
const getTruckFromStorage = (smc: string) => localStorage.getItem(`truck:${smc}`);
/*const getTruckFromStorage = (id: string) => {

    return new Promise(async (resolve) => {
        const truck = await Storage.get({key: `truck:${id}`});
        resolve(truck.value);
    })
}*/



/** Validates Rules through backend */
const validateRules = (truck: ModelTruck.TruckSpecifications) => {

    const totalMass: number = +(truck.chassisWeightF) + +(truck.chassisWeightR);
    const truckPower: number = +(truck.engineKW || 0);
    const hasTrailer: boolean = false;
    const numberOfAxels: number = 0;
    const isArticulatedVehicle: boolean = false;
    const isCombinationOfVehicles: boolean = false;
    const totalVehicleLength: number = +(truck.frontOverhang || 0) + +(truck.wbfas || 0) + +(truck.wheelBase || 0)
                                            + +(truck.wbRas || 0) + +(truck.rearOverhang || 0)

    const reqRules: ModelRegulations.Regulations = {
        totalMass: totalMass,
        truckPower: truckPower,
        hasTrailer: hasTrailer,
        numberOfAxels: numberOfAxels,
        isArticulatedVehicle: isArticulatedVehicle,
        isCombinationOfVehicles: isCombinationOfVehicles,
        totalVehicleLength: round(totalVehicleLength/1000, 1) //Convert to metres
    }

    return new Promise((resolve) => {
        getRules(reqRules)
            .then((response: any) => {
                setTimeout(() => {
                    return resolve(response);
                  }, 1000);
            })
    });
}

export default initCalculator;


export {default as Calculator } from './Calculator';
export {default as CalcStore, getTruck, CalculationStore } from './store';
export {default as saveCalculations } from './save';

export type { Action } from './utils';
export { mReducer, hReducer, wReducer, lReducer } from './utils';

export { getTruckFromStorage, useLocalStore, validateRules };    


