import { useState, useCallback, useEffect } from 'react';
import axios from 'axios';
import { User } from '../../models/User';

//const url = AUTHENTICATE_URL;

const useAxios = (url: string) => {
    const [response, setResponse] = useState<User | null>(null);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [online, setOnline] = useState(navigator.onLine);

    const source = axios.CancelToken.source();

    const postData = useCallback (async (data: any) => {

        setLoading(true);

        data.CancelToken = source.token;
        
        axios
            .post(url, data)
            .then((res) => {
                
                setResponse(res.data);

                if (res.data === null) {
                    setError(true);
                }
            })
            .catch((err) => {
                if (axios.isCancel(err)) {
                    //cancelled
                } else {
                setError(true);
                console.log(err)
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, [setLoading, setError, setResponse, url, source.token]);

    useEffect(() => {
        if (window.addEventListener) {
            window.addEventListener("online", () => setOnline(true), false);
            window.addEventListener("offline", () => setOnline(false), false);
        } else {
            window.ononline = () => setOnline(true);
            window.onoffline = () => setOnline(false);
        }

        return () => {
            source.cancel();
        }
    }, [source]);

    // custom hook returns response, error, online status and a psotData callback
    return [online, postData, response, error, loading] as const;
};

export default useAxios;