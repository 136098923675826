import React, { useEffect, useState } from 'react';
import { IonItem, IonRange, IonIcon, IonButton} from '@ionic/react';
import { add, remove } from 'ionicons/icons';
import { BodyHeight, BodyLength, BodyMass, BodyWidth, Body } from '../../../models/Body';
import { CalcStore, Calculator, CalcValues } from '../../../services/calculations';


interface COGProps {
    values: CalcValues;
    cog: 'side' | 'top' | 'front' | 'rear' | 'payload';
    setValues: (values: CalcValues) => void;
}

const cogSwitch = (cog: COGProps['cog'], values: CalcValues) => {

    const { regulations, dimensions } = Calculator(values);

    switch(cog) {
        case 'side':
            return dimensions.hcog;
        case 'top':
            return dimensions.lcog;
        case 'payload':
            return dimensions.pcog;
        case 'front': 
            return dimensions.vcog;
        case 'rear': 
            return dimensions.vcog;
    }
}

const CalcCOGSlider: React.FC<COGProps> = ({values, cog, setValues}) => {

    const [_values, _setValues] = useState(values) //Local values to prevent direct modification of Global values
    const [value, setValue] = useState(+(cogSwitch(cog, _values) || 0));

    let bL: BodyLength = new BodyLength(_values);
    let bW: BodyWidth = new BodyWidth(_values);
    let bM: BodyMass = new BodyMass(_values);
    let bH: BodyHeight = new BodyHeight(_values);

    const [bodyMass, setBodyMass] = useState<BodyMass>(_values.body?.mass || bM);
    const [bodyLength, setBodyLength] = useState<BodyLength>(_values.body?.length || bL);
    const [bodyHeight, setBodyHeight] = useState<BodyHeight>(_values.body?.height || bH);
    const [bodyWidth, setBodyWidth] = useState<BodyWidth>(_values.body?.width || bW);

    const updateValues = () => {

        const v = { ..._values};

        if (cog === 'side') {
            bL = { ...bodyLength};
            bL.centreOfGravity = value.toString();
            setBodyLength(bL);
        }

        if (cog === 'top') {
            bW = { ...bodyWidth};
            bW.centreOfGravity = value.toString();
            setBodyWidth(bW);
        }
        if (cog === 'front' || cog === 'rear') {
            bH = { ...bodyHeight};
            bH.centreOfGravity = value.toString();
            setBodyHeight(bH)
        }

        if (cog === 'payload' && v.payload !== null) {
            v.payload.cog = value;
        }

        const body: Body = {
            type: v.body?.type || "unknown",
            mass: bodyMass,
            length: bodyLength,
            width: bodyWidth,
            height: bodyHeight
        };

        v.body = { ...body};
        CalcStore.update(s => { s.body = body});
        setValues(v);
    }

    useEffect(() => {
        updateValues();
    }, [value]);

    return (

        <>
            {
                values.body !== null &&

                <>

                    <IonItem lines="none">
                        <IonRange min={0} max={100} pin={true} value={value} onIonChange={e => {
                            setValue(e.detail.value as number);
                            updateValues();
                            }} />
                    </IonItem>

                    <IonItem lines="none">
                        <IonButton color="dark" slot="start" onClick={() => {
                            setValue(value => value - 10);
                            updateValues();
                            }} >
                            <IonIcon size="large" icon={remove} />
                        </IonButton>
                        <IonButton color="dark" slot="end" onClick={() => {
                            setValue(value => value + 10);
                            updateValues();
                            }} >
                            <IonIcon size="large"icon={add} />
                        </IonButton>
                    </IonItem>
                </>
            }
            
        </>
    );
}

export default CalcCOGSlider;
