import React, { useRef, useState } from 'react';
import { IonItemSliding, IonItem, IonLabel, IonItemOptions, IonItemOption, IonIcon, IonModal } from '@ionic/react';
import { useHistory } from 'react-router-dom';
import { TruckSpecifications } from '../../models/Truck';
import { chevronForward } from 'ionicons/icons';
import { useStoreState } from 'pullstate';
import { getUser, UserPreferences } from '../../services/user';
import initCalculator from '../../services/calculations';
import { SubmitToBuilder } from '../Leads';


import './TruckListFilter.css';

interface TruckListItemProps {
    model: TruckSpecifications;
    listType?: "all" | "saved";
    isSaved?: boolean;
}

const TruckListItem: React.FC<TruckListItemProps> = ({ model }) => {

    const history = useHistory();
    const ionItemSlidingRef = useRef<HTMLIonItemSlidingElement>(null); 
    const user = useStoreState(UserPreferences, getUser);
    const isDealership = user.role.includes('Hino Dealership');
    const isBus = model.series.toLocaleLowerCase() === 'busco' || model.series.toLocaleLowerCase() === 'busmark';

    const [showSubmitToBuilderModal, setShowSubmitToBuilderModal] = useState(false);

    return (

        <IonItemSliding ref={ionItemSlidingRef}>
            <IonItem detail={true} className="truck-list-item" onClick={() => initCalculator(model.chassisModel)} routerLink={`trucks/${model.chassisModel}`}>
                {
                    isBus ?

                    <IonLabel>
                        <h3><strong>{`${model.description} (${model.chassisModel})`}</strong></h3>
                        <p>{model.additionalInfo.replace(' Seated Passengers, ', '').replace('32', '').replace('36', '').replace('44', '').replace('60', '').replace('65', '')}</p>
                        <p>Height&nbsp;&mdash;&nbsp;{model.overallHeight}&nbsp;mm;&nbsp;Lenght&nbsp;&mdash;&nbsp;{model.overAllLength}&nbsp;mm</p>
                        <p>Width&nbsp;&mdash;&nbsp;{model.overallWidth}&nbsp;mm;&nbsp;GVM&mdash;&nbsp;{model.maxGVMRating}&nbsp;kg</p>
                    </IonLabel>

                    :

                    <IonLabel>
                        <h3><strong>{`${model.description} (${model.chassisModel})`}</strong></h3>
                        <p>Drive&nbsp;System&nbsp;&mdash;&nbsp;{model.driveSystem}</p>
                        <p>Wheelbase&nbsp;&mdash;&nbsp;{model.wheelBase}&nbsp;mm</p>
                        <p>Max&nbsp;GVM&nbsp;Rating&nbsp;&mdash;&nbsp;{model.maxGVMRating}&nbsp;kg</p>
                    </IonLabel>
                }
            </IonItem>
            <IonItemOptions>

                {
                    isDealership ? 

                    <IonItemOption
                        color="success" 
                        onClick={() => {
                            setShowSubmitToBuilderModal(true)
                        }}>
                        Request Specification&nbsp;<IonIcon icon={chevronForward}/>
                    </IonItemOption>

                    :

                    <IonItemOption 
                        color="success" 
                        onClick={() => {
                            initCalculator(model.chassisModel);
                            history.replace(`calculations/${model.chassisModel}`)
                        }}>
                        Configure&nbsp;<IonIcon icon={chevronForward}/>
                    </IonItemOption>

                }
                   
            </IonItemOptions>
            <IonModal
                mode="ios"
                isOpen={showSubmitToBuilderModal}
                onDidDismiss={() => setShowSubmitToBuilderModal(false)}
                swipeToClose={true}> 
                    <SubmitToBuilder 
                        onDismissModal={() => setShowSubmitToBuilderModal(false)} 
                        route={`/trucks?series=${model.series}`}
                        values={model}/>
            </IonModal>
        </IonItemSliding>
    );

}

export default React.memo(TruckListItem);