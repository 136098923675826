import { useEffect, useRef, useState } from 'react';
import { IonContent,  IonLabel, IonPage, IonItem, IonHeader, IonToolbar, IonButtons, IonButton, IonIcon, isPlatform, IonItemDivider, IonChip, IonText, IonList, IonGrid, IonCol, IonRow, IonItemSliding, IonTitle, IonCardHeader, IonCardTitle, IonCard } from '@ionic/react';
import { options, close, arrowDown, arrowUp, filter } from 'ionicons/icons';
import Skeleton from 'react-loading-skeleton';
import { useStoreState } from 'pullstate';
import { getUser, UserPreferences } from '../services/user';
import BuilderItem from '../components/Leads/BuilderItem';
import { useFetchDataFromDB } from '../services/DataApi';
import { builderlist, HinoPreferredSupplier } from '../models/Builder';
import { genericFilter, genericSearch, genericSort, IFilter, ISorter } from '../utils';
import SearchInput from '../components/SearchInput';
import { formatTel } from '../services/dealerships';
import LeadStore from '../services/leads';

import * as ROUTES from '../config';

import './Dealerships.css';
import './PreferredSupplier.css';
import 'react-loading-skeleton/dist/skeleton.css';


const PreferredSuppliers: React.FC = () => {
  
  const pageRef = useRef<HTMLElement>(null);
  const ios = isPlatform('ios');
  const user = useStoreState(UserPreferences, getUser);
  const isDealership = user.role.includes('Hino Dealership');

  const { data, isLoading } = useFetchDataFromDB('preferred-suppliers', ROUTES.BUILDERS_API_ENDPOINT);

  const [query, setQuery] = useState<string>("");
  const [showFilter, setShowFilter] = useState(false);
  const [showRegions, setShowRegions] = useState(false);
  const [showTowns, setShowTowns] = useState(false);
  const [showSort, setShowSort] = useState(false);

  const [sortProperty, setSortProperty] = useState<keyof HinoPreferredSupplier>("Name");
  const [activeSorter, setActiveSorter] = useState<ISorter<HinoPreferredSupplier>>({
    property: sortProperty,
    isDescending: false,
  });
  const [activeFilters, setActiveFilters] = useState<Array<IFilter<HinoPreferredSupplier>>>(
    []
  );

  const [showAZ, setShowAZ] = useState(activeSorter.isDescending);
  const [preferredSuppliers, setPreferredSuppliers] = useState<Array<HinoPreferredSupplier>>([]);
  const [cities, setCities] = useState<Array<string>>([]);

  useEffect(() => {

    if (!isLoading) {

      const d: HinoPreferredSupplier[] = isDealership ? (data as unknown as HinoPreferredSupplier[]) : (builderlist as unknown as HinoPreferredSupplier[]); //If Salesperson, fetch only HPS registered on the App, else fetch all HPS
      const q = d.filter((builder: HinoPreferredSupplier) =>
          genericSearch<HinoPreferredSupplier>(builder, ['Name','Region', 'Town', 'Address'], query))
        .filter((builder: HinoPreferredSupplier) => 
          genericFilter<HinoPreferredSupplier>(builder, activeFilters))
        .sort((builderA: HinoPreferredSupplier, builderB: HinoPreferredSupplier) =>
          genericSort<HinoPreferredSupplier>(builderA, builderB, activeSorter) 
        );

      const c = Array.from(new Set(q.map((o: HinoPreferredSupplier) => o.Region.charAt(0))));

      setPreferredSuppliers(q);
      setCities(c);
    }
  }, [query, data, activeFilters, activeSorter]);

  useEffect(() => {
    setActiveSorter({
      property: sortProperty,
      isDescending: showAZ
    })
  }, [showAZ, sortProperty]);

  let ionItems: any = [];

  for (let i: number = 0; i < 30; i++) {

    ionItems.push(
      <IonCol size="12" sizeSm="6" sizeMd="6" sizeLg="4" sizeXl="4" key={`dealership-${i}`}>
        
        <IonCard  mode="ios">

          <IonCardHeader>
            <IonCardTitle color="light">
              <h2><strong><Skeleton /></strong></h2>
              <p><strong><Skeleton /></strong></p>

            </IonCardTitle>

          </IonCardHeader>

          <IonRow className="ion-no-padding justify-content-center">
      
            <IonCol size="6" className='ion-justify-content-end'>
                
            </IonCol>
        
            <IonCol size="6">
                <IonButton fill="clear" size="small">
                  &nbsp;<strong><Skeleton /></strong>&nbsp;
                </IonButton>
            </IonCol>
          </IonRow>

        </IonCard>
      
      </IonCol>
    )
  }



  return (
    <IonPage ref={pageRef} id="PreferredSuppliers-page">

      <IonHeader>

        <IonToolbar color="toolbar">
          <IonTitle><strong>Preferred Suppliers</strong></IonTitle>
        </IonToolbar>
        
        {
          !ios &&
          <IonToolbar mode="ios" className="ion-padding-top">
            <SearchInput onChangeSearchQuery={(query) => setQuery(query)} />
            <IonButtons slot="end">
              {
                !showFilter &&
                <IonButton color="dark" onClick={() => setShowFilter(true)}>
                  <IonIcon icon={options} slot="icon-only" />
              </IonButton>}
              {
                showFilter &&
                <IonButton color="dark" onClick={() => setShowFilter(false)}>
                  <IonIcon icon={close} slot="icon-only" />
                </IonButton>
              }
            </IonButtons>
          </IonToolbar>
        }

        {
          !ios && showFilter &&
    
          <IonToolbar mode="ios">
            <IonItemDivider color='light'>
              <IonChip 
                color={showRegions ? "medium" : "primary"}
                slot="start"
                onClick={() => {
                  setShowRegions(true);
                  setShowTowns(false);
                  setShowSort(false);
                }}>Regions
                </IonChip>
              <IonChip 
                color={showTowns ? "medium" : "primary"}
                slot="start"
                onClick={() => {
                  setShowTowns(true);
                  setShowRegions(false);
                  setShowSort(false);
                }}>Towns
              </IonChip>
              
              <IonButton 
                slot="end"
                color={showSort ? "medium" : "dark"}
                disabled={showSort}
                onClick={() => {
                  setShowTowns(false);
                  setShowRegions(false);
                  setShowSort(true);
                }}><IonIcon icon={filter} slot="icon-only" />
              </IonButton>

            </IonItemDivider>

            {
              showRegions && 

              <IonItemDivider color="light">

                {
                  cities.map(c => 

                    <IonChip 
                      color="success" 
                      key={c}
                      onClick={() => {
                        setActiveFilters([
                          ...activeFilters.filter(
                            (filter) => filter.property !== 'Town'
                          ),
                          { property: 'Town', isTruthyPicked: true },
                        ]);
                      }}
                      >{c}
                    </IonChip>
                  )
                }

              </IonItemDivider>
            }

            {
              showSort &&

              <IonItemDivider color='light'>
                <IonChip
                  disabled={sortProperty === 'Name'}
                  onClick={() => setSortProperty('Name')} 
                  color={sortProperty === 'Name' ? "medium" : "success"}
                  slot="start">Name
                </IonChip>

                <IonChip
                  disabled={sortProperty === 'Region'}
                  onClick={() => setSortProperty('Region')}
                  color={sortProperty === 'Region' ? "medium" : "success"}
                  slot="start">Region
                </IonChip>

                <IonChip
                  disabled={sortProperty === 'Town'}
                  onClick={() => setSortProperty('Town')}
                  color={sortProperty === 'Town' ? "medium" : "success"}
                  slot="start">Town
                </IonChip>

                <IonButtons slot="end">
                  {
                    showAZ &&
                    <IonButton color="dark" onClick={() => setShowAZ(false)}>
                    {ios ? <p>A-Z</p> : <IonIcon icon={arrowDown} slot="icon-only" />}
                    </IonButton>
                  }

                  {
                    !showAZ &&
                    <IonButton color="dark" onClick={() => setShowAZ(true)}>
                    {ios ? <p>Z-A</p> : <IonIcon icon={arrowUp} slot="icon-only" />}
                    </IonButton>
                  }
                
                </IonButtons>
              </IonItemDivider>
            }
          </IonToolbar>
        }
 
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Preferred Suppliers</IonTitle>
          </IonToolbar>

          <IonToolbar className="ion-padding-top">
          <SearchInput onChangeSearchQuery={(query) => setQuery(query)} />
          <IonButtons slot="end">
            {
              !showFilter &&
              <IonButton color="dark" onClick={() => setShowFilter(true)}>
                Filter
            </IonButton>}
            {
              showFilter &&
              <IonButton color="dark" onClick={() => setShowFilter(false)}>
                Close
              </IonButton>
            }
          </IonButtons>
        </IonToolbar>

        {
          showFilter &&
    
          <IonToolbar mode="ios">
            <IonItemDivider color='light'>
              <IonChip 
                color={showRegions ? "medium" : "primary"}
                slot="start"
                onClick={() => {
                  setShowRegions(true);
                  setShowTowns(false);
                  setShowSort(false);
                }}>Regions
                </IonChip>
              <IonChip 
                color={showTowns ? "medium" : "primary"}
                slot="start"
                onClick={() => {
                  setShowTowns(true);
                  setShowRegions(false);
                  setShowSort(false);
                }}>Towns
              </IonChip>
              
              <IonButton 
                slot="end"
                color={showSort ? "medium" : "dark"}
                disabled={showSort}
                onClick={() => {
                  setShowTowns(false);
                  setShowRegions(false);
                  setShowSort(true);
                }}>{ios ? 'Sort' : <IonIcon icon={filter} slot="icon-only" />}
              </IonButton>

            </IonItemDivider>

            {
              showRegions && 

              <IonItemDivider color="light" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>

                {
                  cities.map(c => 

                    <IonChip 
                      color="success"
                      key={c}
                      onClick={() => {
                        setActiveFilters([
                          ...activeFilters.filter(
                            (filter) => filter.property !== 'Town'
                          ),
                          { property: 'Town', isTruthyPicked: true },
                        ]);
                      } } placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}                      >{c}
                    </IonChip>
                  )
                }

              </IonItemDivider>
            }

            {
              showSort &&

              <IonItemDivider color='light'>
                <IonChip
                  disabled={sortProperty === 'Name'}
                  onClick={() => setSortProperty('Name')} 
                  color={sortProperty === 'Name' ? "medium" : "success"}
                  slot="start">Name
                </IonChip>

                <IonChip
                  disabled={sortProperty === 'Region'}
                  onClick={() => setSortProperty('Region')}
                  color={sortProperty === 'Region' ? "medium" : "success"}
                  slot="start">Region
                </IonChip>

                <IonChip
                  disabled={sortProperty === 'Town'}
                  onClick={() => setSortProperty('Town')}
                  color={sortProperty === 'Town' ? "medium" : "success"}
                  slot="start">Town
                </IonChip>

                <IonButtons slot="end">
                  {
                    showAZ &&
                    <IonButton color="dark" onClick={() => setShowAZ(false)}>
                      <p>A-Z</p>
                    </IonButton>
                  }

                  {
                    !showAZ &&
                    <IonButton color="dark" onClick={() => setShowAZ(true)}>
                      <p>Z-A</p>
                    </IonButton>
                  }
                
                </IonButtons>
              </IonItemDivider>
            }
          </IonToolbar>
        }

        </IonHeader>

        {
          isLoading && 
          
          <IonList>
            <IonGrid className="d-wrapper">
              <IonRow>{ionItems}</IonRow>
            </IonGrid>
          </IonList>}
 
        {preferredSuppliers.length === 0 && !isLoading && 
        
          <IonList className="ion-padding">
            <IonRow className="ion-text-center ion-justify-content-center ion-margin-top">
             <IonCol size="10">
                <lottie-player src={ROUTES.CONTENT_NOT_FOUND} mode="bounce" background="transparent" speed="0.8" loop autoplay></lottie-player>
              </IonCol>
              <IonCol size="10">
                  <IonLabel>
                    {
                      query === "" ?
                      <>
                        <h2><strong>Error: Failed to load Hino Preferred Suppliers</strong></h2>
                        <p>{'Please reload the page and make sure you are connected to the internet. If the problem persists, please contact support'}</p>
                      </>
                      :
                      <>
                        <h2><strong>Preferred Supplier(s) not found</strong></h2>
                        <p>{'Please search for different Hino Preferred Supplier(s)'}</p>
                      </>
                    }    
                  </IonLabel>
              </IonCol>
            </IonRow>
          </IonList>
        }

        {
          preferredSuppliers.length > 0 && (

            <IonGrid className="d-wrapper">

              <IonRow>
              {
                preferredSuppliers.map((d: HinoPreferredSupplier, index: number) => (

                  <IonCol size="12" sizeSm="6" sizeMd="6" sizeLg="4" sizeXl="4" key={`preferred-supplier-${d.id}-${index}`}>

                    {
                      isDealership &&
                       
                      <BuilderItem
                        key={d.id}
                        builder={d}
                      />
                    }
                    
                    {
                      !isDealership &&
                      <IonItemSliding>
                        <IonItem
                          mode="ios" 
                          key={d.BuilderCode} 
                          className="dealerships-item" 
                          detail={true}
                          //routerLink={`/preferred-suppliers/${encodeURIComponent(btoa(d.BuilderCode))}`}
                          onClick={() => LeadStore.update(s => {s.preferredSupplier = d})}>
                          <IonLabel>
                            <h2><strong>{d.Name}</strong></h2>
                            {/*<IonText><h3>{"Some awesome content"}</h3></IonText> */}
                            <p>{d.Address.replace(/(<([^>]+)>)/gi, ", ").split(',')[0]}</p>
                            {/*<p dangerouslySetInnerHTML={{ __html: d.Address}} /> */}
                            <p>{`${d.Town}, ${d.Region}`}</p>
                            <IonText><h6>{`Tel: ${formatTel(d.Region, d.Telephone)}`}</h6></IonText>  
                          </IonLabel>
                        </IonItem>
                      </IonItemSliding>
                    }

                  </IonCol>
                ))
              }
              </IonRow>

            </IonGrid>
          )
        }
      </IonContent>

    </IonPage>
  );

};

export default PreferredSuppliers;
