import React, { useCallback, useState } from 'react';
import { IonToolbar, IonButtons, IonHeader, IonSegmentButton,IonButton, IonContent, IonItem, IonCol, IonLabel, IonIcon, IonSegment,  isPlatform, IonTitle, IonFooter, IonRow } from '@ionic/react';
import { close, checkmark } from 'ionicons/icons';
import { CalcValues, Calculator } from '../../../services/calculations';
import {ConfigureBodyMass, ConfigureBodyDimensions, ConfigureBodyPayload, ConfigureBodyType, listOfBodies, ConfigureBodyPosition } from '../EditBody';
import { useStoreState } from 'pullstate';
import { getUser, UserPreferences } from '../../../services/user';

interface CalcEditBodyProps {
    onDismissModal: () => void;
    values: CalcValues;
    setValues: (v: CalcValues) => void;
}

/** CalcEditBody Component */
const CalcEditBody: React.FC<CalcEditBodyProps> = ({onDismissModal, values, setValues}) => {

    const ios = isPlatform('ios');

    const user = useStoreState(UserPreferences, getUser);
    const isDealership = user.role.includes('Hino Dealership');
    
    const [_values, _setValues] = useState(values); //Local values to prevent direct modification of Global values
    const { ...truck } = Calculator(_values);

    const [segment, setSegment] = useState<'payload' | 'dimensions' | 'mass' | 'position'>('mass');
    const [showBodyParameters, setShowBodyParameters] = useState(truck.bodyType.toLowerCase() !== 'unknown');

    const useSetShowBodyParameters = useCallback((show: boolean) => {
        setShowBodyParameters(show);
    }, [setShowBodyParameters]);

    return (
        <>
            <IonHeader>
                <IonToolbar color="toolbar">
                    {/*<IonButtons slot="end">
                        <IonButton 
                            onClick={() => {
                                const v = { ..._values};
                                //if Payload !== null, it means it was updated somewhere else use regulation value to update it
                                if (v.payload === null)  v.payload = {payload: truck.regulations.mass.pwt, cog: truck.dimensions.pcog};
                                setValues(v);
                                onDismissModal();
                            }
                            } disabled={!showBodyParameters}>Done&nbsp;
                            <IonIcon icon={checkmark} />
                        </IonButton>
                        </IonButtons>*/}
                    <IonButtons slot="start">
                        <IonButton 
                            onClick={() => {
                                onDismissModal()
                                }}>
                            <IonIcon icon={close} />
                        </IonButton>
                    </IonButtons>
                    <IonTitle>{`${showBodyParameters ? `Configure Body` : 'Select a Body'}`}</IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent>
                    
                { 
                    !showBodyParameters && 

                    <ConfigureBodyType values={_values} setValues={_setValues} show={useSetShowBodyParameters} /> 

                }
                    

                {
                    showBodyParameters && 

                    <>
                        <IonToolbar className='ion-padding-top ion-margin-top'>
                            {
                                listOfBodies.filter(b => b.value === truck.bodyType.toLowerCase()).map((b, i) => 


                                <IonItem key={i}>
                                    <IonCol size="5">
                                        <IonItem key={b.value} detail={false} lines="none">
                                            <IonLabel>{`${b.name}`}</IonLabel>  
                                        </IonItem>
                                    </IonCol>
                                    
                                    <IonCol size="7">

                                        <IonButton fill="outline" onClick={() => {
                                            setShowBodyParameters(false);
                                        }}>Change&nbsp;</IonButton>

                                        <IonButton onClick={() => {

                                            const v = { ..._values};
                                            v.body = null;
                                            v.payload = null;
                                            //_setValues(v);
                                            //setShowBodyParameters(false);
                                            setValues(v);
                                            onDismissModal();

                                        }}>Remove&nbsp;</IonButton>

                                    </IonCol>
                                </IonItem> 
  
                            )} 
                        </IonToolbar>
                        <IonToolbar>
                             <IonSegment mode={ios ? "ios" : "md"} value={segment} onIonChange={(e) => setSegment(e.detail.value as any)}>
                                <IonSegmentButton value="mass">Mass</IonSegmentButton>
                                <IonSegmentButton value="dimensions">Dimensions</IonSegmentButton>
                                <IonSegmentButton value="position">Position</IonSegmentButton>
                                <IonSegmentButton value="payload">Payload</IonSegmentButton>
                            </IonSegment>
                        </IonToolbar>

                        { segment === "mass" && <ConfigureBodyMass values={_values} setValues={_setValues} /> }
                        { segment === "dimensions" && <ConfigureBodyDimensions values={_values} setValues={_setValues} /> }
                        { segment === "payload" && <ConfigureBodyPayload values={_values} setValues={_setValues} /> }
                        { segment === "position" && <ConfigureBodyPosition values={_values} setValues={_setValues} /> }
                    </>
                }

            </IonContent>
            <IonFooter>
                <IonRow className="ion-padding ion-margin ion-justify-content-center" style={ {marginBottom: "60px"} }>

                    <IonCol size="12">
                        <IonButton
                            expand="block"
                            mode="ios"  
                            onClick={() => {
                                const v = { ..._values};
                                //if Payload !== null, it means it was updated somewhere else use regulation value to update it
                                if (v.payload === null)  v.payload = {payload: truck.regulations.mass.pwt, cog: truck.dimensions.pcog};
                                setValues(v);
                                onDismissModal();
                            }
                            } disabled={!showBodyParameters}>Submit&nbsp;
                            <IonIcon icon={checkmark} />
                        </IonButton>
                    </IonCol>
                    
                </IonRow>
            </IonFooter>
        </>
    );
}

export default CalcEditBody;