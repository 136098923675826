import { isPlatform } from "@ionic/react";

export const domain = process.env.REACT_APP_AUTH0_DOMAIN;
export const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID; 

const appId = process.env.REACT_APP_ID; 

// Use `auth0Domain` in string interpolation below so that it doesn't
// get replaced by the quickstart auto-packager
const auth0Domain = domain;
//const iosOrAndroid = isPlatform('ios') || isPlatform('android');
const ios = isPlatform('ios');
const uri = ios ? `${appId}://${auth0Domain}/capacitor/${appId}/callback/` : `${window.location.protocol}//${window.location.host}/`;
//const uri = `${window.location.protocol}//${window.location.host}`;

export const callbackUri = uri;