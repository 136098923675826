import { useRef, useEffect, useState } from 'react';
import { IonContent, IonButton, IonToolbar, IonHeader, IonPage, IonButtons, isPlatform, IonItem, IonIcon, IonTitle, IonList, IonListHeader, IonText, IonCardTitle, IonCol, IonCard } from '@ionic/react';
import {  callOutline, chevronForwardOutline, close,  mailOutline } from 'ionicons/icons';
import platform from 'platform';
import { useStoreState } from 'pullstate';

import packageInfo from '../../package.json';
import { UserPreferences, getUser } from '../services/user';

import * as ROUTES from '../config';


const Support: React.FC = () => {
  
  const pageRef = useRef<HTMLElement>(null);
  const ios = isPlatform('ios');
  const deviceInfo = platform;
   
  const email = 'support.mail@turbotics.co.za'//'app-support@hino.co.za';
  const hino = 'https://www.hino.co.za/contact-us'
  const Help = 'Hino Truck Body Builders App | Help';
  const Feedback = 'Hino Truck Body Builders App | Feedback';
  const userid = useStoreState(UserPreferences, getUser).userid;

  const [body, setBody] = useState("");

  useEffect(() => {

      setBody(`*** DO NOT DELETE THE CONTENT BELOW ***

      Userid: ${userid}
      App Version: ${ios ? packageInfo.ios : packageInfo.android} build ${ROUTES.BUILD_NUMBER}
      Device: ${deviceInfo.product}
      Manufacturer: ${deviceInfo.manufacturer}
      Platform: ${deviceInfo.description}
      OS Architecture: ${deviceInfo.os?.architecture}
      OS Family: ${deviceInfo.os?.family}
      OS Version: ${deviceInfo.os?.version}
      Browser Name: ${deviceInfo.name}
      Browser Layout: ${deviceInfo.layout}
      Browser Version: ${deviceInfo.version}
      UA: ${deviceInfo.ua}
    
      *** TYPE YOUR MESSAGE BELOW THIS LINE *** 
  `);

  }, [deviceInfo, ios, userid])

  return (
    <IonPage ref={pageRef} id="support-page">
      <IonHeader>
        <IonToolbar mode="ios" color="primary">
            <IonButtons slot="start">
              <IonButton routerLink={ROUTES.TABS_ACCOUNT_PAGE_ROUTE} routerDirection="back">
                <IonIcon icon={close} />
              </IonButton>
            </IonButtons>
            <IonTitle><strong>Support</strong></IonTitle>
        </IonToolbar>
      </IonHeader>
      
      <IonContent fullscreen>

        <IonCard mode="ios">
          
          <IonList lines="full">
            <IonListHeader className="ion-padding-top ion-margin-top">
              <h3><strong>Technical Support</strong></h3>
            </IonListHeader>

            <IonItem detail={false} lines="none" onClick={() => ios ? 
                window.location.assign(`tel:${+27118099111}`)
                : window.open(`tel:${+27118099111}`)
              } >
              
              <IonCol size="10" className="ion-padding-end">
                <IonText color='dark'>
                  <h5><strong>Hino SA Head Office</strong></h5>
                  <p>+27 11 809 9111</p>
                </IonText>
              </IonCol>
              <IonCol size="2" className='ion-justify-content-center'>
                <IonIcon size="large" icon={callOutline} color="dark" />
              </IonCol>

            </IonItem>

            <IonItem detail={false} className="ion-padding-bottom" onClick={() => ios ? 
                window.location.assign(`tel:${+27800022600}`)
                : window.open(`tel:${+27800022600}`)
              } >
              
              <IonCol size="10" className="ion-padding-end">
                <IonText color='dark'>
                  <h5><strong>Roadside Assistance</strong></h5>
                  <p>0800 022 600</p>
                </IonText>
              </IonCol>

              <IonCol size="2" className='ion-justify-content-center'>
                <IonIcon size="large" icon={callOutline} color="dark" />
              </IonCol>
            </IonItem>

            <IonListHeader>
              <h3><strong>App Support</strong></h3>
            </IonListHeader>

            <IonItem detail={false} lines="none" onClick={() => ios ? 
                window.location.assign(`mailto:${email}?subject=${Help}&body=${encodeURIComponent(body)}`)
                : window.open(`mailto:${email}?subject=${Help}p&body=${encodeURIComponent(body)}`)
              } >
              
              <IonCol size="10" className="ion-padding-end">
                <IonText color='dark'>
                  <h5><strong>Help</strong></h5>
                  <p>Looking for help with the App?</p>
                </IonText>
              </IonCol>
              <IonCol size="2" className='ion-justify-content-center'>
                <IonIcon size="large" icon={mailOutline} color="dark" />
              </IonCol>
            </IonItem>

            <IonItem className="ion-padding-bottom" detail={false} onClick={() => ios ? 
                  window.location.assign(`mailto:${email}?subject=${Feedback}&body=${encodeURIComponent(body)}`)
                  : window.open(`mailto:${email}?subject=${Feedback}p&body=${encodeURIComponent(body)}`)
                } >
                  
              <IonCol size="10" className="ion-padding-end">
                <IonText color='dark'>
                  <h5><strong>Feedback</strong></h5>
                  <p>Your feedback helps us to make this app better</p>
                </IonText> 
              </IonCol>
              <IonCol size="2" className='ion-justify-content-center'>
                <IonIcon size="large" icon={mailOutline} color="dark" />
              </IonCol>
            </IonItem>
            
            <IonItem detail={false} lines="none" 
              onClick={() => ios ? 
                window.location.assign(`${hino}`)
                : window.open(`${hino}`)
              } >
              <IonCol size='10'>
                <IonText color='dark'>
                  <h5><strong>For more contact details, please visit hino.co.za</strong></h5>
                </IonText>
              </IonCol> 
              <IonCol size='2' className='ion-justify-content-center'>
                <IonIcon size="small" icon={chevronForwardOutline} color="dark" />
              </IonCol>
            </IonItem>
            {/*<IonItem detail={false} lines="none" 
              onClick={() => ios ? 
                window.location.assign(`https://turbotics.co.za`)
                : window.open(`https://turbotics.co.za`)
              } >
              <IonCol size='10'>
                <IonText color='dark'>
                  <h5><strong>About the App, please visit turbotics.co.za</strong></h5>
                </IonText>
              </IonCol> 
              <IonCol size='2' className='ion-justify-content-center'>
                <IonIcon size="small" icon={chevronForwardOutline} color="dark" />
              </IonCol>
            </IonItem> */}

          </IonList>

        </IonCard>












        {/*

        <IonList lines="full">
          <IonListHeader className="ion-padding-top ion-margin-top">
            <IonCardTitle mode="ios">Technical Support</IonCardTitle>
          </IonListHeader>

          <IonItem detail={false} lines="none" onClick={() => ios ? 
              window.location.assign(`tel:${+27118099111}`)
              : window.open(`tel:${+27118099111}`)
            } >
            
            <IonCol size="10" className="ion-padding-end">
              <IonText color='dark'>
                <h5><strong>Hino SA Head Office</strong></h5>
                <p>+27 11 809 9111</p>
              </IonText>
            </IonCol>
            <IonCol size="2" className='ion-justify-content-center'>
              <IonIcon size="large" icon={callOutline} color="dark" />
            </IonCol>

          </IonItem>

          <IonItem detail={false} className="ion-padding-bottom" onClick={() => ios ? 
              window.location.assign(`tel:${+27800022600}`)
              : window.open(`tel:${+27800022600}`)
            } >
            
            <IonCol size="10" className="ion-padding-end">
              <IonText color='dark'>
                <h5><strong>Roadside Assistance</strong></h5>
                <p>0800 022 600</p>
              </IonText>
            </IonCol>

            <IonCol size="2" className='ion-justify-content-center'>
              <IonIcon size="large" icon={callOutline} color="dark" />
            </IonCol>
          </IonItem>

          <IonListHeader>
            <IonCardTitle mode="ios">App Support</IonCardTitle>
          </IonListHeader>

          <IonItem detail={false} lines="none" onClick={() => ios ? 
              window.location.assign(`mailto:${email}?subject=${Help}&body=${encodeURIComponent(body)}`)
              : window.open(`mailto:${email}?subject=${Help}p&body=${encodeURIComponent(body)}`)
            } >
            
            <IonCol size="10" className="ion-padding-end">
              <IonText color='dark'>
                <h5><strong>Help</strong></h5>
                <p>Looking for help with the App?</p>
              </IonText>
            </IonCol>
            <IonCol size="2" className='ion-justify-content-center'>
              <IonIcon size="large" icon={mailOutline} color="dark" />
            </IonCol>
          </IonItem>

          <IonItem className="ion-padding-bottom" detail={false} onClick={() => ios ? 
                window.location.assign(`mailto:${email}?subject=${Feedback}&body=${encodeURIComponent(body)}`)
                : window.open(`mailto:${email}?subject=${Feedback}p&body=${encodeURIComponent(body)}`)
              } >
                
            <IonCol size="10" className="ion-padding-end">
              <IonText color='dark'>
                <h5><strong>Feedback</strong></h5>
                <p>Your feedback helps us to make this app better</p>
              </IonText> 
            </IonCol>
            <IonCol size="2" className='ion-justify-content-center'>
              <IonIcon size="large" icon={mailOutline} color="dark" />
            </IonCol>
          </IonItem>
            
          <IonItem detail={false} lines="none" 
            onClick={() => ios ? 
              window.location.assign(`${hino}`)
              : window.open(`${hino}`)
            } >
            <IonCol size='10'>
              <IonText color='dark'>
                <h5><strong>For more contact details, please visit hino.co.za</strong></h5>
              </IonText>
            </IonCol> 
            <IonCol size='2' className='ion-justify-content-center'>
              <IonIcon size="small" icon={chevronForwardOutline} color="dark" />
            </IonCol>
          </IonItem>

          </IonList> */}

      </IonContent>
    </IonPage>
  );
};

export default Support;
