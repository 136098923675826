import { useRef, useState } from 'react';
import { isPlatform, IonContent, IonButton, IonToolbar, IonHeader, IonPage, IonButtons, IonIcon, IonTitle, IonList, IonItem, IonLabel, IonCol, IonBadge, IonCard, IonCardContent, IonText } from '@ionic/react';
import { chevronBack } from 'ionicons/icons';

import GuidelineStore from '../../services/guidelines';
import { AARTORegulations } from '../../models/Guidelines';

import * as ROUTES from '../../config';

import '../Guidelines.css'

const Regulations: React.FC = () => {
  
  const pageRef = useRef<HTMLElement>(null);
  const ios = isPlatform('ios');

  const [query, setQuery] = useState<string>("");
  const [showFilter, setShowFilter] = useState(false);

  const regulations = AARTORegulations.reduce((r, a) => {
    r[a.title] = r[a.title] || [];
    r[a.title].push(a);
    return r;
  }, Object.create(null));

  let regulationItems: JSX.Element[] = [];


  for (let [key, value] of Object.entries(regulations)) {

    regulationItems.push(
      <IonItem 
        key={`${key}`}
        detail={false}
        //routerLink={`${ROUTES.AARTO_REGULATIONS_PAGE_ROUTE}/${encodeURIComponent(btoa(key))}`}
        onClick={() => GuidelineStore.update(s => {
          s.regulations = (value as unknown as string[]);
          s.title = key;
        })}
        >
          <IonCol size="12">
                    
            <div style={{ marginTop: "1.5rem" }}>
                <IonText color="dark">
                    <h2 className="ion-padding-start ion-padding-end">{key}</h2>
                </IonText>
                {/*Definition
                
                <IonText color="medium">
                  <p className="ion-padding-start ion-padding-end">
                    {value !== null && (value as unknown as any[]).map((regulation) => regulation.definition)}
                  </p>  
                </IonText>*/}
            </div>

            <IonCard mode="ios">
              <IonCardContent>

                <div className={ "ion-padding-top"}>
                  <IonBadge color="primary">{ key }</IonBadge>
                  <br />
                    {value !== null && (value as unknown as any[]).map((regulation, index) => {

                      const v: string = regulation.value;

                      return (
                        <p key={`regulation-${key}_${ index }`} className={ index > 0 ? "ion-padding-top" : "" }>
                            { index + 1 }.&nbsp;
                            { (regulation.description as string).replace(v, `${v}`) }&nbsp;
                            
                                { regulation.regulation !== "" ? 
                                    <IonBadge color="warning"> {`Reg. ${regulation.regulation}`}</IonBadge>
                                : 
                                    "" 
                                }&nbsp;
                            
                        </p>
                      ); 
                  })}

                </div> 

              </IonCardContent>
            </IonCard>
          </IonCol>
      </IonItem>
    )
  }

  return (
    <IonPage ref={pageRef} id="regulations-page">
      <IonHeader>
        <IonToolbar mode="ios" color="toolbar">
            <IonButtons slot="start">
            <IonButton routerLink={ROUTES.GUIDELINES_PAGE_ROUTE}>
              <IonIcon icon={chevronBack} />
              </IonButton>
            </IonButtons>
            <IonTitle>AARTO Regulations</IonTitle>
        </IonToolbar>
        {/*
          //!ios &&
          <IonToolbar mode="ios" className="ion-padding-top">
            <SearchInput onChangeSearchQuery={(query) => setQuery(query)} />
            <IonButtons slot="end">
              {
                !showFilter &&
                <IonButton color="dark" onClick={() => setShowFilter(true)}>
                  <IonIcon icon={options} slot="icon-only" />
              </IonButton>}
              {
                showFilter &&
                <IonButton color="dark" onClick={() => setShowFilter(false)}>
                  <IonIcon icon={close} slot="icon-only" />
                </IonButton>
              }
            </IonButtons>
          </IonToolbar>
            */}
      </IonHeader>
      
      <IonContent fullscreen>
        {/*<IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">General Precautions</IonTitle>
          </IonToolbar>

          <IonToolbar className="ion-padding-top">
            <SearchInput onChangeSearchQuery={(query) => setQuery(query)} />
            <IonButtons slot="end">
              {
                !showFilter &&
                <IonButton color="dark" onClick={() => setShowFilter(true)}>
                  Filter
              </IonButton>}
              {
                showFilter &&
                <IonButton color="dark" onClick={() => setShowFilter(false)}>
                  Close
                </IonButton>
              }
            </IonButtons>
          </IonToolbar>
            </IonHeader>*/}

        <IonList>

          {
            regulationItems.sort((a: any, b: any) => {
              if (a.key > b.key) {
                  return 1;
              } else if (a.key < b.key) {
                  return -1;
              } else {
                  return 0;
              }
            }).map((r: any, index: number) => 

            <IonCol size="12" sizeMd="6" sizeLg="4" sizeXl="4" key={`regulation-${r.key}-${index}`}>
              {r}
            </IonCol>
            
            )
          }

        </IonList>

      </IonContent>
    </IonPage>
  );
};

export default Regulations;
