import { atan, sqrt, ceil, max, min, round, asin, tan, floor } from 'mathjs';
import { MassRegulations, DimensionsRegulations, CircleRegulations } from '../../../models/Regulations';
import { postDataToDB } from '../../../services/DataApi';
import { BodyAdapter } from '../adapters';


/** Interface: Minimum Dimension Parameters Required to be tested against Rules and Regaulations */
interface RegDimValues {
    foh: number; fas: number; wb: number; ras: number; roh: number; bbc: number; ca: number;

    tck: number; //Kerb Turning Radius
    tcw: number; //Wall Turning Radius
    tkf: number; //Front Track
    tkr: number; //Rear Track

    bw: number; ibw: number; lbw: number; rbw: number; cw: number;
    bh: number; ibh: number; sfh: number; ssh: number; rth: number; ch: number; 
    bl: number; ibl: number; tb: number; hb: number; gap: number;
}

/** Interface: Minimum Mass Parameters Required to be tested against Rules and Regaulations */
interface RegMassValues {
    cwf: number; cwr: number; cwt: number;
    mcf: number; mcr: number; mct: number;
    bwf: number; bwr: number; bwt: number;
    uwf: number; uwr: number; uwt: number;
    pwf: number; pwr: number; pwt: number;
    twf: number; twr: number; twt: number;

    gaf: number; gar: number; rating: number;
    trf: number; trr: number;

    dpcog: number;
}

/** Endpoint for Rules */
const url = 'rules';

/** Function: Fetches rules from the DB */
export const getRules = (content: any) => postDataToDB(url, content);

/** Regulates Mass */
const massRegulator = (values: RegMassValues, wb: number, fas: number, ras: number) => {

    const reg = new MassRegulations();

    const regSteerAxle: number = fas > 0 ? 2 * (+reg.steeringAxle) : +reg.steeringAxle;
    const regNonSteerAxle: number = ras > 0 ? 2 * (+reg.nonSteeringAxle) : +reg.nonSteeringAxle;
    const regTotal = regSteerAxle + regNonSteerAxle;

    const gvmRating: number = values.rating > 0 ? values.rating : 1000000;
    const sal: number = round(values.twf/values.twt, 1)*100; //Load on Steering Axle
    const salW: boolean = sal >= +reg.minLoadOnSteeringAxleGoodsVehicle;

    //Bridge Results
    const distance: number = wb + fas + ras;
    const rounded: number = ceil(distance/100)*100;
    const gross: number = values.twt;
    const permissible: number = rounded*2.1 + 18000;
    const unused: number = permissible - gross;
    const utilisation: number = round(gross/permissible, 1)*100;

    //GVM: Permissible 
    const permf: number = min(values.trf || 1000000, values.gaf || 1000000, regSteerAxle);
    const permr: number = min(values.trr || 1000000, values.gar || 1000000, regNonSteerAxle);
    const permt: number = min(permissible, gvmRating, regTotal);

    //GVM: Available Payload
    const regPayloadT: number = permt - values.uwt;

    const twb = wb + fas/2 + ras/2; //Technical Wheelbase
    const regPayloadR: number = permr - values.uwr;
    const regPayloadF: number = permf - values.uwf;

    //console.log(regPayloadF)
    //console.log(regPayloadR)
    //console.log(regPayloadT)
    //const regPayloadF: number = round(regPayloadT/twb*values.dpcog);
    //const regPayloadR: number = round(regPayloadT - regPayloadF);

    //If Body is loaded and payload is not set manually return available payload
    const pwf = values.bwf > 0 && values.pwf === 0 ? regPayloadF : values.pwf;
    const pwr = values.bwr > 0 && values.pwr === 0 ? regPayloadR : values.pwr;
    const pwt = values.bwt > 0 && values.pwt === 0 ? regPayloadT : values.pwt;

    //GVM: Total Mass
    const twf = values.uwf + pwf;
    const twr = values.uwr + pwr;
    const twt = values.uwt + pwt;

    //GVM: Unused Capacity
    const unusedF: number = min(permf || 1000000, regSteerAxle) - twf;
    const unusedR: number = min(permr || 1000000, regNonSteerAxle) - twr;
    const unusedT: number = min(permissible, gvmRating, regTotal) - twt;

    //GVM: Utilisation %
    const utilF: number = round(twf/min(permf || 1000000, regSteerAxle)*100);
    const utilR: number = round(twr/min(permr || 1000000, regNonSteerAxle)*100);
    const utilT: number = round(twt/min(permissible, gvmRating, regTotal)*100);

    const twfW: boolean = twf <= min(permf || 1000000, regSteerAxle);
    const twrW: boolean = twr <= min(permr || 1000000, regNonSteerAxle);
    const twtW: boolean = twt <= min(permissible, gvmRating, regTotal);

    return {
        twfW, twrW, twtW,
        regSteerAxle, regNonSteerAxle, regTotal,
        permf, permr, permt,
        unusedF, unusedR, unusedT,
        utilF, utilR, utilT,

        pwf, pwr, pwt,
        twf, twr, twt,

        sal, salW,

        regPayloadT,

        distance, rounded, gross, permissible, unused, utilisation
    }
}

/** Regulates Dimensions */
const dimRegulator = (dim: RegDimValues, rating: number) => {

    const reg = new DimensionsRegulations();

    //If Body, factor its length to determine overall length, else use truck default spec
    const ol = dim.bl > 0 ? 
                (dim.bbc + dim.gap + dim.bl) :
                (dim.foh + dim.wb + dim.roh + dim.fas + dim.ras); 

    const ow = max(dim.bw, dim.cw);
    const oh = max(dim.ch, dim.bh);

    const rohpct = round(dim.roh/dim.wb*100, 1);

    const maxWidth = rating > 12000 ? "maxWidthGVMgt12" : "maxWidthGVMlt12";
    const maxWidthComment = `GVM of the truck ${rating > 12000 
        ? 'is greater than 12000 kg (applicable max width: 2.6 m)'
        : 'is less than 12000 kg (applicable max width: 2.5 m)'}; width, therefore,`; 

    const { isWithin: ww, comment: wc } = RegulateDimensions(maxWidth, ow/1000, maxWidthComment); //Divide by 1000; convert from mm to m
    const { isWithin: hw, comment: hc } = RegulateDimensions("maxHeight", oh/1000, "Height"); //Divide by 1000; convert from mm to m
    const { isWithin: lw, comment: lc } = RegulateDimensions("maxLength", ol/1000, "Length"); //Divide by 1000; convert from mm to m
    const { isWithin: wbw, comment: wbc } = RegulateDimensions("maxWB", dim.wb/1000, "Wheelbase"); //Divide by 1000; convert from mm to m
    const { isWithin: rohw } = RegulateDimensions("maxROH", rohpct, "Rear overhang");

    const rohc = rohw ? `Rear overhang is less than ${reg.maxROH} % of the wheelbase`: `Rear overhang is greater than ${reg.maxROH} % of the wheelbase`;

    return {
        ww, hw, lw, wbw, rohw,
        wc, hc, lc, wbc, rohc,

        oh, ol, ow, rohpct
    }
}

/** Function: Calculates Turning Circle 
 ** @param-input
 ** tcw: wall turn-radius
 ** tck: kerb-turn-radius
 ** wb: wheelbase
 ** tkf: front-track
 ** foh: front-overhang
 **
 ** @param-output
 ** maxRadius: maximum radius as per regulation
 ** innerRadius: minimum radius formed by inner rear wheels
 ** swept path: difference between kerb-turn-radius and inner-radius
**/
const circRegulator = (tcw: number, tck: number, wb: number, tkf: number, foh: number, tkr: number, ow: number) => {

    const reg = new CircleRegulations();
    const maxRadius = +(reg.maxRadius || 1) * 1000;//1000: mm to m

    const pi: number = 4*atan(1);

    //Use Pythagoras: a^2 + b^2 = c^2 to solve for innerRadius; a = wb + foh, b = (tkf + tkr)/2 + innerRadius and c = tcw
    //const innerRadius: number = round(sqrt(tcw*tcw - (wb*wb + foh*foh)) - (tkf + tkr)/2, 0);
    const steeringAngle = round(asin(wb/(tck - (tkf - tkr)/2))*180/pi,2)
    //const steeringAngle = round(atan((wb)/(innerRadius + (tkf + tkr)/2))*180/pi, 2);
    //const innerRadius = round(wb/tan(steeringAngle*pi/180) - (tkf - tkr)/2);

    const innerRadius = floor((sqrt(tcw*tcw - (foh + wb)*(foh + wb)) - ow)/10)*10;
    const frontAxleCentreRadius = ceil(sqrt((innerRadius + 0.5*ow)*(innerRadius + 0.5*ow) + wb*wb));

    const sweptPath: number = tck - innerRadius; 

    return { maxRadius, frontAxleCentreRadius, innerRadius, sweptPath, steeringAngle }
}

export const Regulator = (m: RegMassValues, d: RegDimValues) => {

    const f = d.fas, r = d.ras, w = d.wb, c = d.tcw, k = d.tck, t = d.tkf, q = d.tkr, o = d.foh, g = m.rating, i = d.cw; //o=front overhang, g=GVM, i=cab width; 

    const { ...dimensions } = dimRegulator(d, g);
    const { ...mass } = massRegulator(m, w, f, r);
    const { ...circle } = circRegulator(c, k, w, t, o, q, i);

    const wallRadius = c;
    const kerbRadius = k;
    const maxRadius = circle.maxRadius;
    const innerRadius = circle.innerRadius;
    const steeringAngle = circle.steeringAngle;
    const sweptPath = circle.sweptPath;
    
    const isBridgePass: boolean = mass.gross <= mass.permissible; 
    const isDimensionsPass: boolean = dimensions.hw && dimensions.lw && dimensions.ww && dimensions.rohw && dimensions.wbw;
    const isGVMPass: boolean = mass.twfW && mass.twrW && mass.twtW; 
    const isCirclePass: boolean = k <= maxRadius;
    
    return {
        isBridgePass, isDimensionsPass, isCirclePass, isGVMPass, 
        maxRadius, innerRadius, steeringAngle, sweptPath, wallRadius, kerbRadius,
        mass, dimensions
    }
}


/** Functions: Regulate mass, dimensions and circle values */

/** @@Params_start
 **
 ** ___input___
 ** property: regulation property to evaluate eg maxMass
 ** value: calculated value e.g gvm.twt
 **
 ** ___output___
 ** isWithin: value within regulation limit e.g. true / false 
 ** comment: user friendly messages e.g. Height is more than 4.3 metres
 **
 ** @@Params_end*/

 export const RegulateGVM = (property: string, value: number, description: string) => {

    const mass = new MassRegulations();
    const isWithin: boolean = value <= (+(mass as any)[property]); 
    const comment: string = `${description} is ${isWithin ? "less": "greater"} than ${(mass as any)[property]} kg`;
  
    return { isWithin, comment };
  }
  
  export const RegulateDimensions = (property: string, value: number, description: string) => {
  
    const dimensions = new DimensionsRegulations();
    const isWithin: boolean = value <= (+(dimensions as any)[property]);  
    const comment: string = `${description} is ${isWithin ? "less": "greater"} than ${(dimensions as any)[property]} m`;
  
    return { isWithin, comment };
  }
  
