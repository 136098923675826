import { IonButton, IonCol, IonContent, IonHeader, IonRouterOutlet, IonRow, IonSplitPane, IonToolbar } from "@ionic/react";


import { Redirect, Route, Switch } from "react-router";

import Support from "../pages/Support";
import Menu from "../components/Menu";
import LoginDesktop from "../pages/auth/LoginDesktop";
import TopNavbar from "./pages/Home";

import LoginAccountDeleted from "../pages/auth/LoginAccountDeleted";
import LoginCode from "../pages/auth/LoginCode";
import LoginEmail from "../pages/auth/LoginEmail";
import Privacy from "../pages/settings/Privacy";
import TOS from "../pages/settings/Tos";

import * as ROUTES from "../config";

import './desktop.css';
import Guidelines from "../pages/Guidelines";
import Precaution from "../pages/guidelines/Precaution";
import Precautions from "../pages/guidelines/Precautions";
import ElectricalSystem from "../pages/guidelines/ElectricalSystem";
import ElectricalSystems from "../pages/guidelines/ElectricalSystems";
import Regulations from "../pages/guidelines/Regulations";

const PublicDesktop: React.FC = () => {

  return (

      <IonRouterOutlet>
        
          {/* <IonSplitPane contentId="main" when="md">

              <Menu />*/}

              <IonRouterOutlet id="main">
                <Switch>
                  {/** Auth Routes */}
                  <Route path={ROUTES.LOGIN_EMAIL_ROUTE} component={LoginDesktop} exact={true} /> 
                  <Route path={ROUTES.LOGIN_DIGITS_ROUTE} component={LoginEmail} exact={true} /> 
                  <Route path={ROUTES.LOGIN_CODE_ROUTE} component={LoginCode} exact={true} /> 
                  <Route path={ROUTES.LOGIN_ACCOUNT_DELETED_ROUTE} component={LoginAccountDeleted} exact={true} /> 

                  {/** Legal Routes */}
                  <Route path={ROUTES.SUPPORT_PAGE_ROUTE} component={Support} exact={true} />
                  <Route path={ROUTES.SETTINGS_LEGAL_PRIVACY_PAGE_ROUTE} component={Privacy} exact={true} />
                  <Route path={ROUTES.SETTINGS_LEGAL_TOS_PAGE_ROUTE} component={TOS} exact={true} />

                  {/** Guidelines Routes*/}
                  <Route path={ROUTES.GUIDELINES_PAGE_ROUTE} component={Guidelines} exact={true} />
                  <Route path={ROUTES.PRECAUTIONS_DETAILS_PAGE_ROUTE} component={Precaution} exact={true} />
                  <Route path={ROUTES.PRECAUTIONS_PAGE_ROUTE} component={Precautions} exact={true} />
                  <Route path={ROUTES.ELECTRICAL_SYSTEMS_DETAILS_PAGE_ROUTE} component={ElectricalSystem} exact={true} />
                  <Route path={ROUTES.ELECTRICAL_SYSTEMS_PAGE_ROUTE} component={ElectricalSystems} exact={true} />
                  <Route path={ROUTES.AARTO_REGULATIONS_PAGE_ROUTE} component={Regulations} exact={true} />

                  {/** Default Route */}
                  <Route exact path="/" render={() => <Redirect to={ROUTES.LOGIN_EMAIL_ROUTE} />} />

                  {/** Non-Existent Routes */}
                  <Route path='*' render={() => <Redirect to={ROUTES.LOGIN_EMAIL_ROUTE} />} />
                </Switch>

              </IonRouterOutlet>

            {/*</IonSplitPane>*/}
        
      </IonRouterOutlet>
  );
}

export default PublicDesktop;