
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { IonRow, IonGrid, IonCol, IonCard, IonCardTitle,  IonCardContent,  IonIcon, IonModal, IonItem, IonNote, IonListHeader, IonButton, IonLabel, IonToolbar, IonText, IonList, isPlatform, IonCardHeader } from '@ionic/react';
import { bookOutline, documentsOutline, documentOutline, newspaperOutline, calculatorOutline } from 'ionicons/icons';
import SwiperCore, { Navigation, Pagination, EffectCoverflow, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from 'swiper/react';
import { useMedia } from 'react-use';
import { max, min } from 'mathjs';
import Skeleton from 'react-loading-skeleton';

import { TruckSpecifications } from '../../models/Truck';
import { useDefaultTrucks } from '../../services/trucks';
import TruckListFilter, { applicationArray, listOfModels } from '../Trucks/TruckListFilter';
import { UserPreferencesModel } from '../../models/User';

import * as ROUTES from '../../config';

import 'react-loading-skeleton/dist/skeleton.css';

// Import Swiper styles
import './swiper/swiper.min.css';
import './swiper/swiper-bundle.css';
import './swiper/effect-coverflow.min.css';
import './swiper/pagination.min.css';
import './swiper/navigation.min.css';




interface HDProps {
    user: UserPreferencesModel,
    openWindow: (url: string) => void,
    greeting?: {message: string, icon: string}
}

/*const specCardLabel = {
  dealers: "View Hino Trucks; Request Hino Preferred Suppliers to configure, validate and save Body Specifications for you",
  mobile_users: "View Hino Trucks, Dealerships and Preferred Suppliers; Request Hino Preferred Suppliers to configure and save Body Specifications for you",
}*/


const HomeDealerships: React.FC<HDProps> = ({ user, openWindow }) => {

  const history = useHistory();
  const isWide = useMedia('(min-width: 576px)');
  const ios = isPlatform('ios');

  const isDealership = user.role.includes('Hino Dealership');

  /** Trucks */
  const {defaultTrucks, isLoading: isLoadingTrucks } = useDefaultTrucks(null);
  const [showTruckListFilter, setShowTruckListFilter] = useState(false);
  const seriesList = defaultTrucks.series.flat(1).map(series => series.name);

  /** eToyota Urls */
  const submitJobUrl = "https://toyapps3.e-toyota.co.za/Trucks/HQSECO.nsf/HPS_CAP?OpenForm";
  const viewJobsUrl = ios ? "https://portal.e-toyota.co.za" : "https://toyapps3.e-toyota.co.za/Trucks/HQSECO.nsf/HPS_JobView?OpenForm";

  const hinoNewsRoomUrl = "https://www.hino.co.za/discover/news";

  /** GVMs */
  const gvmArray = (series: string) => {
    const c = listOfModels(defaultTrucks, series).flat(1);
    const g = Array.from(new Set(c.map((o: TruckSpecifications) => +o.maxGVMRating)));

    return g;
}

  /** Reload Swiper Modules each time seriesList is updated */
  useEffect(() => {
    SwiperCore.use([Navigation, Pagination, EffectCoverflow, Autoplay]);
  }, [seriesList]);

  return (

    <IonGrid className="h-wrapper">

      {isLoadingTrucks &&
        
        <IonGrid>

          <IonRow className="ion-padding-start ion-padding-end">
              
              <IonCol sizeSm="12" sizeMd="10" sizeLg="10">
  
                <IonLabel className="ion-margin-top">
                  <h2 className="ion-justify-content-center ion-text-center"><strong><Skeleton/></strong></h2>
                  <h2><Skeleton/></h2> 
                  <h2><Skeleton/></h2> 
                  <h2><Skeleton/></h2>
                </IonLabel>
  
              </IonCol>
            </IonRow>
  
            <IonRow className="ion-justify-content-center">
              <IonCol sizeSm="12" sizeMd="8" sizeLg="8">
                <IonCard mode="ios">
                  <IonCardContent>
                    <IonCardTitle>{<Skeleton/>}</IonCardTitle>
                    <p>{<Skeleton/>}</p>
                    <IonButton expand="block" className="ion-margin-top">{<Skeleton/>}</IonButton>
                  </IonCardContent>
                </IonCard>
              </IonCol>
            </IonRow>
  
            <IonRow className="models ion-padding-top ion-justify-content-center">
              
              <IonListHeader className="ion-padding-bottom">{<Skeleton/>}</IonListHeader>
              
              <IonCol sizeSm="12" sizeMd="8" sizeLg="8">
                
                  <IonCard className="models">
                    <IonCardContent className="model-card">
                      <div className="model-details">
                        <IonCardTitle>
                          {<Skeleton/>}
                        </IonCardTitle>
                        <IonNote>{<Skeleton/>}</IonNote>
                      </div>
                    </IonCardContent>
                  </IonCard>
              
                </IonCol>
              </IonRow>  

        </IonGrid>

        }

  
        

        {
          isDealership && !isLoadingTrucks &&

          <>


            <IonRow className="ion-padding-start ion-padding-end">
              
              <IonCol sizeSm="12" sizeMd="10" sizeLg="10">
  
                <IonLabel className="ion-margin-top">
                  <h2 className="ion-justify-content-center ion-text-center"><strong>Welcome to Hino Truck Body Builders App | Learn about Hino Body Mounting Guidelines; View and Share Specifications of Hino Models</strong></h2>
                  {/*<IonToolbar
                    style={{
                      height: 60,
                      marginTop: 35,
                      paddingTop: 10,
                      paddingBottom: 10,
                      paddingLeft: 15,
                      backgroundColor: '#F5F5F7',
                      borderRadius: 30,
                      alignItems: 'center',
                      flexDirection: 'row',
                    }}>
                    <IonInput
                      disabled={true}
                      style={{fontSize: 18, marginLeft: 5}}
                      placeholder="Search to get started">
                        <IonIcon size="large" slot="start" icon={search} />
                    </IonInput>
  
                  </IonToolbar> */}
                </IonLabel>
  
              </IonCol>
      
            </IonRow>
  
            <IonItem className='ion-no-margin ion-no-padding' lines="full" detail={false} color="light"/>

            <IonRow
              style={{
                paddingVertical: 25,
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}>
              <IonToolbar color="light">
              
                <IonButton mode="ios" fill="clear" slot="start">
                  <IonText style={{color: 'var(--ion-color-dark)',fontSize: 20, fontWeight: 'bold'}}>Hino Models</IonText>
                </IonButton>
                <IonButton mode="ios" fill="clear" slot="end" onClick={() => setShowTruckListFilter(true)}>
                  <IonText className="ion-justify-content-end" style={{fontSize: 18, fontWeight: 'bold', color: 'var(--ion-color-primary)'}}>
                    View All
                  </IonText>
                </IonButton>
              
              </IonToolbar>
            </IonRow>

            
            {/** Hino Models Slider */}
            <IonRow>

              <Swiper
                //navigation={true}
                pagination={{ clickable: true, type: 'bullets' }}
                effect="coverflow"
                autoplay={ {delay: 2000} }
                loop={true}
                coverflowEffect={{
                  rotate: 50,
                  stretch: 0,
                  depth: 100,
                  modifier: 1,
                  slideShadows: false
                }}
                slidesPerView={isWide ? 2 : 1.25}
                centeredSlides
              >

              {
                seriesList && seriesList.sort() && seriesList.map((s: string) => (
                  
                  <SwiperSlide key={s} style={ {background: "white"} } className='ion-margin-bottom'>
                    
                      <IonCardContent mode='ios'>

                        <IonCardTitle mode='ios'>{s === 'dyna' ? `Toyota ${s.charAt(0).toUpperCase() + s.slice(1)} ` : `Hino ${s.charAt(0).toUpperCase() + s.slice(1)}`}</IonCardTitle>
                        
                        <IonNote>
                          {
                            listOfModels(defaultTrucks, s).length < 2 ? 

                            `GVM: ${min(gvmArray(s))} kg` 

                            : 

                            `${listOfModels(defaultTrucks, s).length} Models (GVM: ${min(gvmArray(s)) === max(gvmArray(s)) ? `${min(gvmArray(s))}` : `${min(gvmArray(s))} - ${max(gvmArray(s))}`} kg)`
                          }
                        </IonNote>

                        <img style={ {objectFit: "contain", height: 100, width: "auto"} } src={`/assets/images/hino${s}.png`} alt={s}/>

                        <h2><strong>Applications</strong></h2>

                        <IonNote>{applicationArray(defaultTrucks, s)}</IonNote>

                        <IonRow className='ion-justify-content-center ion-padding'>
                          <IonButton mode='ios' routerLink={`${ROUTES.TRUCK_LIST_PAGE_ROUTE}?series=${s}&from=${ROUTES.TABS_HOME_PAGE_ROUTE}`}>
                            View {listOfModels(defaultTrucks, s).length < 2 ? 'Model ' :  'Models '}&rarr;
                          </IonButton>
                        </IonRow>

                      </IonCardContent>
                    
                  </SwiperSlide>
                ))
              }

              </Swiper>

            </IonRow>
 
            <IonItem detail={false} color="light"></IonItem>

            {
            isDealership && 

            <>




              <IonRow className="ion-justify-content-center">
                <IonCol sizeSm="12" sizeMd="8" sizeLg="8">
                  <IonCard mode="ios" className="ion-text-center saved-spec-card">

                    <IonCardContent>

                      <IonCardTitle className="ion-justify-content-center" color="light">Ready to get started?</IonCardTitle>
                      <h2 className="title-white">Estimate payload of Hino Trucks based on body application</h2>
                      <IonButton
                        className="ion-margin-top" 
                        color="primary"
                        fill="solid"
                        routerLink={ROUTES.CALCULATIONS_PAGE_ROUTE}>
                        Start Trucking &rarr;
                      </IonButton>
                
                    </IonCardContent>
                  </IonCard>
                </IonCol>
              </IonRow> 


           {/* <IonRow className="models ion-padding-top ion-justify-content-center">
              
              <IonListHeader className="ion-padding-bottom">{<Skeleton/>}</IonListHeader>
              
                <IonCol sizeSm="12" sizeMd="8" sizeLg="8">
                
                  <IonCard className="model-card" routerLink={ROUTES.CALCULATIONS_PAGE_ROUTE}>
                  
                    <IonCardContent className="model-details">
                      <IonCardTitle>
                        Build a Hino Truck
                      </IonCardTitle>
                      <IonNote>Build a Hino Truck based on application and payload &rarr;</IonNote>
                    </IonCardContent>
                  </IonCard>
              
                </IonCol>
              </IonRow> */} 

              <IonRow
                style={{
                  paddingVertical: 25,
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}>
                <IonToolbar color="light">
                  <IonButton mode="ios" fill="clear" slot="start">
                    <IonText style={{color: 'var(--ion-color-dark)',fontSize: 20, fontWeight: 'bold'}}>Resources</IonText>
                  </IonButton>
                </IonToolbar>
              </IonRow>

              <IonList className='ion-no-padding ion-no-margin'>

                <IonCol sizeXs="12" sizeSm="12" sizeMd="4" sizeLg="4" sizeXl='4'>
                  <IonItem detail={true} className="ion-no-padding ion-no-margin" onClick={() => history.replace(ROUTES.GUIDELINES_PAGE_ROUTE)}>
                    <IonCard mode="ios" color="warning" style={{ height: '4rem', width: '4rem'}}>
                      <IonCardContent>
                        <IonIcon icon={bookOutline} color='dark' style={{ fontSize: "1.5rem" }} />
                      </IonCardContent>
                    </IonCard>
                    <IonLabel>
                      <h5 className="ion-justify-content-center"><strong>&nbsp;Body Mounting Guidelines</strong></h5>
                      <p className="ion-justify-content-center">&nbsp;General Precautions, Electrical Systems,</p>
                      <p className="ion-justify-content-center">&nbsp;AARTO Regulations</p>
                    </IonLabel>
                  </IonItem>
                </IonCol>

                {
                  !ios && 
                  <IonCol sizeXs="12" sizeSm="12" sizeMd="6" sizeLg="4" sizeXl='4'>
                    <IonItem detail={true} className="ion-no-padding ion-no-margin" onClick={() => openWindow(submitJobUrl)}>
                      <IonCard mode="ios" color= "success" style={{ height: '4rem', width: '4rem'}}>
                        <IonCardContent>
                          <IonIcon icon={documentOutline} color='light' style={{ fontSize: "1.5rem" }} />
                        </IonCardContent>
                      </IonCard>
                      <IonLabel>
                        <h5 className="ion-justify-content-center"><strong>&nbsp;Capture Job</strong></h5>
                        <p className="ion-justify-content-center">&nbsp;Capture Job on eToyota Portal</p>
                      </IonLabel>
                    </IonItem>
                  </IonCol>
                }
            
                <IonCol sizeXs="12" sizeSm="12" sizeMd="4" sizeLg="4" sizeXl='4'>
                  <IonItem detail={true} className="ion-no-padding ion-no-margin" onClick={() => openWindow(viewJobsUrl)}>
                    <IonCard mode="ios" color={ios ? "success" : "tertiary"} style={{ height: '4rem', width: '4rem'}}>
                      <IonCardContent>
                        <IonIcon icon={documentsOutline} color='light' style={{ fontSize: "1.5rem" }} />
                      </IonCardContent>
                    </IonCard>
                    <IonLabel>
                      <h5 className="ion-justify-content-center"><strong>&nbsp;View Jobs</strong></h5>
                      <p className="ion-justify-content-center">&nbsp;View Jobs on eToyota Portal</p>
                    </IonLabel>
                  </IonItem>
                </IonCol>

                
                <IonCol sizeXs="12" sizeSm="12" sizeMd="4" sizeLg="4" sizeXl='4'>
                  <IonItem detail={true} className="ion-no-padding ion-no-margin" onClick={() => openWindow(hinoNewsRoomUrl)}>
                    <IonCard mode="ios" color="danger" style={{ height: '4rem', width: '4rem'}}>
                      <IonCardContent>
                        <IonIcon icon={newspaperOutline} color='light' style={{ fontSize: "1.5rem" }} />
                      </IonCardContent>
                    </IonCard>
                    <IonLabel>
                      <h5 className="ion-justify-content-center"><strong>&nbsp;News Room</strong></h5>
                      <p className="ion-justify-content-center">&nbsp;News and Updates about Hino</p>
                    </IonLabel>
                  </IonItem>
                </IonCol>

              </IonList>
            
            </>
          }
               
         </>
                
      }

      <IonModal
        mode="ios"
        isOpen={showTruckListFilter}
        onDidDismiss={() => setShowTruckListFilter(false)}
        swipeToClose={true}> 
          <TruckListFilter onDismissModal={() => setShowTruckListFilter(false)} route={ROUTES.TABS_HOME_PAGE_ROUTE}/>
      </IonModal>

    </IonGrid>  
  );

};

export default HomeDealerships;
