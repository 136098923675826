import React, { useState } from 'react';
import { IonCard, IonCardHeader,  IonButton, IonCol, IonIcon, IonModal, IonCardTitle, IonItem, IonText, IonRow } from '@ionic/react';
import {  chevronDown, chevronUp, mailOutline, mapOutline } from 'ionicons/icons';
import { Specification } from '../../models/Specification';
import { HinoPreferredSupplier } from '../../models/Builder';
import SubmitToBuilder from './SubmitToBuilder';
import { GroupedLeadsStore } from '../../services/leads';

import * as ROUTES from '../../config';


interface BuilderItemProps {
  builder: HinoPreferredSupplier;
  specifications?: Specification[];
}

const BuilderItem: React.FC<BuilderItemProps> = ({ builder, specifications }) => {

    const [showSubmitToBuilderModal, setShowSubmitToBuilderModal] = useState(false);
    const [showContactDetails, setShowContactDetails] = useState(false);
    GroupedLeadsStore.update(s => {
        s.builder_name = builder.Name;
       })

    return (
        <>
        <IonCard mode="ios">
            

            <IonCardHeader className="builder-card">
                
                <IonCardTitle color="light">
                    <h2><strong>{builder.Name}</strong></h2>
                    <p><strong>{builder.PreferredSupplier}</strong></p>

                </IonCardTitle>


            </IonCardHeader>

            {/*<IonCardHeader>
                <h5 style={ {color: "var(--ion-color-dark)"} } className='ion-no-padding ion-no-margin'>
                    <strong>{builder.Address}, {builder.Region}</strong>
                </h5>
                
    </IonCardHeader>*/}

            <IonRow className="ion-no-padding justify-content-center">

                    {
                        !showContactDetails &&

                        <>

                            <IonCol size="6" className='ion-justify-content-end'>
                                
                            </IonCol>
                        
                            <IonCol size="6">
                                <IonButton fill="clear" size="small"
                                    onClick={() => setShowContactDetails(!showContactDetails)}>
                                        &nbsp;<strong>Contact Details</strong>&nbsp;
                                    <IonIcon icon={showContactDetails? chevronUp : chevronDown}/>
                                </IonButton>
                            </IonCol>

                            
                        </>
                    }

                    {
                        showContactDetails &&

                        <>

                            <IonCol size="12">

                                <IonItem detail={false} lines="none">
                                    <IonCol size="2">
                                        <IonIcon className="ion-padding-end ion-margin-end" icon={mapOutline} size="large"/>
                                    </IonCol>
                                    <IonCol size="10">
                                        <IonText className='ion-no-padding ion-no-margin'>
                                            <p><strong>{builder.Address}, {builder.Region}</strong></p>
                                        </IonText>
                                    </IonCol>
                                </IonItem>
                                {/*<IonItem detail={false} lines="none">
                                    <IonCol size="2">
                                        <IonIcon className="ion-padding-end ion-margin-end" icon={callOutline} size="large"/>
                                    </IonCol>
                                    <IonCol size="10">
                                        <IonText className='ion-no-padding ion-no-margin'>
                                            <a style= { {color: "var(--ion-color-dark)", textDecorationLine: "none"} } href={`tel:${formatTel(dealer.Region, dealer.Telephone)}`}><strong>{dealer.Telephone}</strong></a>
                                        </IonText>
                                    </IonCol>
                    </IonItem>*/}
                                <IonItem detail={false} lines="none">
                                    <IonCol size="2">
                                        <IonIcon className="ion-padding-end ion-margin-end" icon={mailOutline} size="large"/>
                                    </IonCol>
                                    <IonCol size="10">
                                        <IonText onClick={() => setShowSubmitToBuilderModal(true)}>
                                            <p><strong>Message</strong></p>
                                        </IonText>
                                    </IonCol>
                                </IonItem>

                            </IonCol>

                            <IonCol size="6">
                                
                            </IonCol>

                            <IonCol size="6" className='ion-justify-content-end'>
                                <IonButton fill="clear" size="small"
                                    onClick={() => setShowContactDetails(!showContactDetails)}>
                                        &nbsp;<strong>Contact Details</strong>
                                    <IonIcon icon={showContactDetails? chevronUp : chevronDown}/>
                                </IonButton>
                            </IonCol>

                        </>
                    }
                </IonRow>











           {/* <IonCardContent>
                
                
                    <IonCol size="4">
                        <IonButton fill="clear" size="small"
                            onClick={() => shareToUsersNotOnTheApp(builder.Name)}>
                            <IonIcon icon={shareSocialOutline}/>&nbsp;Share
                        </IonButton>
                    </IonCol>

                    <IonCol size="6">
                        <IonButton fill="clear" size="small" disabled
                            routerLink={`/preferred-suppliers/${encodeURI(builder.Name).toLowerCase()}`}
                            onClick={() => LeadStore.update(s => {s.preferredSupplier = builder})}>
                            <IonIcon icon={calculatorOutline}/><strong>&nbsp;Specifications</strong>
                        </IonButton>
                    </IonCol>
                    
                    <IonCol size="6">
                        <IonButton fill="clear"  size="small" onClick={() => setShowSubmitToBuilderModal(true)}>
                            <IonIcon icon={chatboxEllipsesOutline}/><strong>&nbsp;Message</strong>
                        </IonButton>
                    </IonCol>

            </IonCardContent> */}
        </IonCard>
        <IonModal
            mode="ios"
            isOpen={showSubmitToBuilderModal}
            onDidDismiss={() => setShowSubmitToBuilderModal(false)}
            swipeToClose={true}> 
                <SubmitToBuilder 
                onDismissModal={() => setShowSubmitToBuilderModal(false)} 
                route={ROUTES.TABS_HPS_LIST_PAGE_ROUTE}
                values={builder}/>
            </IonModal>
        </>
    );
};

export default BuilderItem;