import { floor, round } from "mathjs";
import { DefaultBodySpec } from "../../models/Body";
import { CalcValues } from '.';
import { BodyAdapter, TruckAdapter } from "./adapters";
import { Regulator } from "./regulators";

interface Material {
    material: string;
    value: string;
    udl: string;
    kg: string;
}

let 
//Gross
twt: number, twr: number, twf: number,

//Payload
pwt: number, pwr: number, pwf: number, pcog: number, dpcog: number,

//Total Unladen
uwt: number, uwr: number, uwf: number,

//Chassis
cwt: number, cwr: number, cwf: number, wb: number, twb: number, ca: number, application: string, title: string,

//Fuel and Crew 
nc: number, mc: number, 
mct: number, mcr: number, mcf: number,

fd: number, ft: number,
mft: number, mfr: number, mff: number,

//Body
bwt: number, bwr: number, bwf: number, bl: number, ibl: number, croh: number, bodyMaterial: string, bodyMaterialValue: string, bodyType: string, gap: number, hb: number, tb: number, hcog: number, lcog: number, vcog: number, cog: number; 

//Calculator
const GVM = (values: CalcValues) => {

    const { ...truck} = TruckAdapter(values);
    const { ...body} = BodyAdapter(values);

    title = truck.dimensions.title;
    application = truck.dimensions.application;

    mc = truck.mass.mc;
    nc = truck.mass.nc;

    fd = truck.mass.fd;
    ft = truck.mass.ft;

    cwf = truck.mass.cwf;
    cwr = truck.mass.cwr;
    
    wb = truck.dimensions.wb;
    twb =  truck.dimensions.twb; //Technical Wheelbase

    ca = truck.dimensions.ca;
    gap = body.dimensions.length.gap;
    hb = body.dimensions.length.hb;
    tb = body.dimensions.length.tb;

    hcog = body.dimensions.length.hcog; //Default Horizontal COG
    lcog = body.dimensions.width.lcog; //Default Lateral COG
    vcog = body.dimensions.height.vcog; //Default Vertical COG

    bl = body.dimensions.length.bl;
    ibl = body.dimensions.length.ibl;

    //Complete Rear Overhang
    croh = bl - ca;

    //dcog = floor((ca - (bl*hcog/100 + hb + gap))/5)*5; // Horizontal COG; 5/5 - round to the lowest 5
    const dcog = floor(ca - (bl*hcog/100 + gap));

    //Chassis Mas
    cwt = cwf + cwr;

    //Crew Mass
    mct = round(mc*nc);
    //ToDo: Use Actual Crew Cog, 55 = placeholder
    mcf = round((mct*(twb)/twb)); //10/10 - round to the nearest 10
    mcr = round(mct - mcf);

    //Fuel Mass
    mft = round(fd*ft);
    mff = round((mft*(twb)/twb));
    mfr = round(mft - mff);

    //Temp: used to assign cog until a way to assign cog through the ui is implemented
    cog = dcog

    //Body
    bodyType = body.mass.bodyType;

    const dbs = new DefaultBodySpec();
    const materialList: Array<Material> = ((dbs as any)[bodyType.toLowerCase()]['mass']);

    bodyMaterial = materialList.filter(m => m.material.toLowerCase() === body.mass.material.toLowerCase())[0].material;
    bodyMaterialValue = materialList.filter(m => m.material.toLowerCase() === body.mass.material.toLowerCase())[0].value;

    bwt = round(+(bodyMaterialValue || 0)*bl/1000); //Divide by 1000; convert mm to m
    bwf = round(bwt*cog/twb);    
    bwr = round(bwt - bwf);

    //Payload
    pcog = body.dimensions.payload.pcog;
    dpcog = floor(ca - (ibl*pcog/100 + gap));
    pwt = round(body.dimensions.payload.pwt);

    //floor((ca - (bl*hcog/100 + gap)))
    pwf = round(pwt*dpcog/twb);
    pwr = round(pwt - pwf);

    //Unladen Mass
    uwf = cwf + mcf + bwf;
    uwr = cwr + mcr + bwr;
    uwt = uwr + uwf;

    //Total Mass
    twf = uwf + pwf;
    twr = uwr + pwr;
    twt = twf + twr;

    //Permissible
    const mass = {
        ...truck.mass, 
        cwt: cwt,
        mcf: mcf, mcr: mcr, mct: mct,
        mff: mff, mfr: mfr, mft: mft,
        bwf: bwf, bwr: bwr, bwt: bwt,
        uwf: uwf, uwr: uwr, uwt: uwt,
        pwf: pwf, pwr: pwr, pwt: pwt,
        twf: twf, twr: twr, twt: twt,
        dpcog,
    };
    const dimensions = { 
        ...truck.dimensions, 
        ...body.dimensions.length,
        ...body.dimensions.height,
        ...body.dimensions.width,
        pcog, 
        croh
    };

    const regulations = Regulator(mass, dimensions)

    return {
        title, application,
        bodyType, bodyMaterial, 
        mass, dimensions, regulations
    };
}

export default GVM;

//const sumMass = (obj: ModelBody.BodyMass)  => Object.values(obj).reduce((a: string, b: string) => (+(a)) + (+(b)));




