import React, { useState } from 'react';
import { IonItem, IonCol, IonToast, IonChip, IonRow } from '@ionic/react';
import { Calculator, CalcValues } from '../../../services/calculations';
import { TruckSpecifications } from '../../../models/Truck';

import './styles.css';


 interface GVMProps {
     values: CalcValues;
 }

const CalcGVM: React.FC<GVMProps> = ({values}) => {

    const { mass, dimensions, regulations, bodyType } = Calculator(values);
    const truck = JSON.parse(values.truck as string) as TruckSpecifications;

    const body = bodyType.toLowerCase() !== 'unknown' ? `with a ${bodyType.charAt(0).toUpperCase() + bodyType.slice(1)}` : '';
    const title = `${truck.name} (${truck.chassisModel}) ${body}`;

    const [comment, setComment] = useState("");
    const [colour, setColour] = useState("");
    const [showToast, setShowToast] = useState(false);

    const twfW = regulations.mass.twfW; 
    const twrW = regulations.mass.twrW; 
    const twtW = regulations.mass.twtW; 

    return (
        <>
            <IonRow className="ion-justify-content-center ion-padding">
                <IonCol size="12" className="ion-text-center">
                    <IonRow className="ion-justify-content-center">
                        <strong>{title}</strong>
                    </IonRow>
                </IonCol>
            </IonRow>

            <IonItem color="dark">
                <IonCol><strong>Mass</strong></IonCol>

                { //2-Axles
                    dimensions.ras === 0 && 

                    <>
                        <IonCol className="ion-text-center"><strong>Front (A)</strong></IonCol>
                        <IonCol className="ion-text-center"><strong>Rear (B)</strong></IonCol>
                    </>
                }

                { //3-Axles
                    dimensions.fas === 0 && dimensions.ras > 0 &&
                    
                    <> 
                        <IonCol className="ion-text-center"><strong>Front (A)</strong></IonCol>
                        <IonCol className="ion-text-center"><strong>Rear (BC)</strong></IonCol>
                    </>
                }

                { //4-Axles
                    dimensions.fas > 0 && dimensions.ras > 0 &&
                
                    <> 
                        <IonCol className="ion-text-center"><strong>Front (AB)</strong></IonCol>
                        <IonCol className="ion-text-center"><strong>Rear (CD)</strong></IonCol>
                    </>
                }

                <IonCol className="ion-text-center"><strong>Total</strong></IonCol>
            </IonItem>

            <IonItem lines="none">
                <IonCol>Chassis Mass</IonCol>
                <IonCol className="ion-text-center">{mass.cwf}</IonCol>
                <IonCol className="ion-text-center">{mass.cwr}</IonCol>
                <IonCol className="ion-text-center">{mass.cwt}</IonCol>
            </IonItem>

            <IonItem lines="none">
                <IonCol>Fuel &amp; Crew</IonCol>
                <IonCol className="ion-text-center">{mass.mcf}</IonCol>
                <IonCol className="ion-text-center">{mass.mcr}</IonCol>
                <IonCol className="ion-text-center">{mass.mct}</IonCol>
            </IonItem>
            
           {  //Don't show body parameters if not loaded
                bodyType.toLowerCase() !== 'unknown' &&

                <IonItem lines="none"> 
                    <IonCol>{bodyType}</IonCol>
                    <IonCol className="ion-text-center">{mass.bwf}</IonCol>
                    <IonCol className="ion-text-center">{mass.bwr}</IonCol>
                    <IonCol className="ion-text-center">{mass.bwt}</IonCol>
                </IonItem> 
            }

            <IonItem lines="none" color="secondary">
                <IonCol>Total Unladen</IonCol>
                <IonCol className="ion-text-center">{mass.uwf}</IonCol>
                <IonCol className="ion-text-center">{mass.uwr}</IonCol>
                <IonCol className="ion-text-center">{mass.uwt}</IonCol>
            </IonItem>

            <IonItem>
                <IonCol>Payload</IonCol>
                <IonCol className="ion-text-center">{regulations.mass.pwf}</IonCol>
                <IonCol className="ion-text-center">{regulations.mass.pwr}</IonCol>
                <IonCol className="ion-text-center">{regulations.mass.pwt}</IonCol>
            </IonItem> 

            <IonItem lines="none">
                <IonCol>Gross</IonCol>

                <IonCol className="ion-text-center">
                    <IonChip
                        onClick={() => {
                            setComment(twfW ? "Mass on front axle(s) is within the permissible value" : "Mass on front axle(s) is greater than the permissible value");
                            setColour(twfW ? "success" : "danger");
                            setShowToast(true);
                        }}
                        color={twfW ? "success" : "danger"} 
                        text-center>
                            {regulations.mass.twf}
                    </IonChip>
                </IonCol>

                <IonCol className="ion-text-center">
                    <IonChip
                        onClick={() => {
                            setComment(twrW ? "Mass on rear axle(s) is within the permissible value" : "Mass on rear axle(s) is greater than the permissible value");
                            setColour(twrW ? "success" : "danger");
                            setShowToast(true);
                        }}
                        color={twrW ? "success" : "danger"} >{regulations.mass.twr}
                    </IonChip>
                </IonCol>

                <IonCol className="ion-text-center">
                    <IonChip
                        onClick={() => {
                            setComment(twtW ? "Total mass on axles is within the permissible value" : "Total mass on axles is more than the permissible value");
                            setColour(twtW ? "success" : "danger");
                            setShowToast(true);
                        }}
                        color={twtW ? "success" : "danger"} >{regulations.mass.twt}
                    </IonChip>
                </IonCol>

            </IonItem>
            
            <IonItem>
                <IonCol>Permissible</IonCol>
                <IonCol className="ion-text-center">{regulations.mass.permf} </IonCol>
                <IonCol className="ion-text-center">{regulations.mass.permr}</IonCol>
                <IonCol className="ion-text-center">{regulations.mass.permt}</IonCol>
            </IonItem>
           <IonItem lines="none">
                <IonCol>Unused Capacity</IonCol>
                <IonCol className="ion-text-center">{regulations.mass.unusedF}</IonCol>
                <IonCol className="ion-text-center">{regulations.mass.unusedR}</IonCol>
                <IonCol className="ion-text-center">{regulations.mass.unusedT}</IonCol>
            </IonItem>
            <IonItem>
                <IonCol>Utilisation</IonCol>
                <IonCol className="ion-text-center">
                    <IonChip
                        onClick={() => {
                            setComment(twfW ? "Utilisation on front axle(s) is within the permissible value" : "Utilisation on front axle(s) is more than the permissible value");
                            setColour(twfW ? "success" : "danger");
                            setShowToast(true);
                        }}
                        color={twfW ? "success" : "danger"} > {regulations.mass.utilF}%
                    </IonChip>
                </IonCol>
                <IonCol className="ion-text-center">
                    <IonChip
                        onClick={() => {
                            setComment(twrW ? "Utilisation on rear axle(s) is within the permissible value" : "Utilisation on rear axle(s) is more than the permissible value");
                            setColour(twrW ? "success" : "danger");
                            setShowToast(true);
                        }}
                        color={twrW ? "success" : "danger"} > {regulations.mass.utilR}%
                    </IonChip> 
                </IonCol>
                <IonCol className="ion-text-center">
                    <IonChip
                        onClick={() => {
                            setComment(twtW ? "Total utilisation on axles is within the permissible value" : "Total utilisation on axles is more than the permissible value");
                            setColour(twtW ? "success" : "danger");
                            setShowToast(true);
                        }}
                        color={twtW ? "success" : "danger"} >  {regulations.mass.utilT}%
                    </IonChip>
  
                </IonCol>
            </IonItem>

            <IonToast
                isOpen={showToast}
                onDidDismiss={() => setShowToast(false)}
                message={comment}
                duration={3000}
                color={colour}
            />
        </>
    );
}

export default React.memo(CalcGVM);