import { Store } from 'pullstate';
import { createSelector } from 'reselect';
import { CalcValues } from './';
import { Calculation } from '../../models/Calculation';

const CalcStore = new Store<CalcValues>({
    body: null,
    truck: null,
    payload: null,
    fuelAndCrew: null,
    differences: null,
    rules: null,
    calcID: ""
});

const CalculationStore = new Store<Calculation>({
    id: "",
    bodySpecification: {
        type: null,

        length: null,
        height:  null,
        width:  null,
        mass:  null
      },
    rules: {
        bridge: false,
        circle: false,
        mass: false,
        dimensions: false,
    },
    userid: "",
    savedDate: "",
    calculationID: "",
    truck: "",
    truckid: "",
    series: "",
    gvm: "",
    body: null,
    payload: null,
    uniquename: ""
});

export default CalcStore;
export { CalculationStore };

const getState = (state: any) => state;
export const getTruck = createSelector(getState, state => state.truck);
export const getBody = createSelector(getState, state => state.body);
export const getFuelAndCrew = createSelector(getState, state => state.fuelAndCrew);
export const getPayload = createSelector(getState, state => state.payload);
export const getDifferences = createSelector(getState, state => state.differences);
export const getRules = createSelector(getState, state => state.rules);