import { useRef } from "react";
import { IonItem } from "@ionic/react";
import { useLocation } from "react-router";

import Chat from "./Chat";
import SendChat from "./SendChat";

import { useFetchDataFromDB } from "../../../services/DataApi";
import { MessageDetails } from "../../../models/Lead";
import { decryptAES } from "../../../services/user";

import * as ROUTES from '../../../config';

import './chats.css';


const messagesEndpoint =  ROUTES.MESSAGES_API_ENDPOINT;

const ChatBox = () => {

  const scroll = useRef<HTMLSpanElement | null>(null);
  const urlParams = useLocation().search;
  const message_id = new URLSearchParams(urlParams).get('q');
  const { data: messages, isLoading } = useFetchDataFromDB(`messages-${message_id}`, `${messagesEndpoint}/${message_id}`);

  return (
    <main className="chat-box">

      {isLoading && <IonItem>Loading Chats...</IonItem>}

      {
        !isLoading &&

        <>

          <div className="messages-wrapper">
            {
              messages.sort((a: MessageDetails, b: MessageDetails) => {

                if (a.timestamp_sent > b.timestamp_sent) {
                    return 1;
                } else if (a.timestamp_sent < b.timestamp_sent) {
                    return -1;
                } else {
                    return 0;
                }

            }) && messages.map((message: MessageDetails) => {
 
              const { ...m } = message; //copy message so that we don't modify the original

              //Decrypt the message text
              const key = m.userid;
              const encryptedBase64 = m.text;
              const decryptedText = decryptAES(encryptedBase64, key);
            
              m.text = decryptedText; //append decrypted text to m

              return <Chat key={message.id} message={m} /> 
            }

              
            )}
          </div>


          {/* when a new message enters the chat, the screen scrolls down to the scroll div */}
          <span ref={scroll} style={ {background: "var(--ion-color-light-shade)"} } />

          <SendChat scroll={scroll} messages={messages} />
        
        </>
      }
      
    </main>
  );
};

export default ChatBox;