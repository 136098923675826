import React, { useEffect, useState } from 'react';
import { IonToolbar, IonButtons, IonItemDivider, IonItem, IonButton, IonContent, IonIcon, IonHeader,  IonLabel,  IonRow, IonLoading, IonCol, IonRadio, IonImg, IonRange, 
    IonCard, IonCardContent, IonCardHeader, IonTitle, isPlatform, IonChip, IonListHeader,
    IonFooter,
    IonModal,
    IonItemSliding,
    IonItemOptions,
    IonItemOption} from '@ionic/react';
import { add, arrowBackOutline, chevronDownOutline, chevronUpOutline, close, openOutline, options, remove, saveOutline, searchOutline, shareSocialOutline } from 'ionicons/icons';
import { useHistory } from 'react-router';
import { useMutation, useQueryClient } from 'react-query';
import { useStoreState } from 'pullstate';

import { encryptAES, getUser, UserPreferences } from '../../services/user';
import { listOfBodies } from '../calculations/EditBody';
import { HinoDealership } from '../../models/Dealership';
import { postMessagesToDB } from '../../services/DataApi';
import { Calculation } from '../../models/Calculation';
import { useDefaultTrucks } from '../../services/trucks';
import { TruckSpecifications } from '../../models/Truck';
import { HinoPreferredSupplier } from '../../models/Builder';
import { Calculator, CalcValues, EmptyCalcValues } from '../../services/calculations';
import { ModelBody } from '../../models';
import { CnvsDimensions } from '../canvas';
import SearchInput from '../SearchInput';
import { Share } from '@capacitor/share';
import SubmitToBuilder from './SubmitToBuilder';
import TruckListFilter from '../Trucks/TruckListFilter';

interface BuildTruckPayload {
    value: number | string;
    attribute: 'min' | 'max' | 'exact'
}


interface BuildTruckProps {
    onDismissModal: () => void;
    route: string;
    values?: HinoDealership | TruckSpecifications | HinoPreferredSupplier | Calculation | {id: null | string} | null;
}

const shareConfig = async(attribute: 'min' | 'max' | 'exact', bodyType: string, SMC: string, payload: String | Number) => {

    const b = bodyType;
    const a = attribute;
    const p = payload;
    const c = SMC;

    await Share.share({
        title: `${c} - ${b} Payload Estimation`,
        text: 'Really awesome thing you need to see right now',
        url: `${window.location.href}?a=${a}&b=${b}&c=${c}&p=${p}`,
        dialogTitle: 'Share with buddies',
    });
}

const BuildTruck: React.FC<BuildTruckProps> = ({ onDismissModal, route }) => {

    const history = useHistory();
    const ios = isPlatform('ios');
    const user = useStoreState(UserPreferences, getUser);

    const userid = user.userid;
    const isDealership = user.role.includes('Hino Dealership');

    const { data, isLoading: isLoadingTrucks } = useDefaultTrucks(null);
    const trucks: TruckSpecifications[] = data;

    let listOfPayloads: Array<{smc: string, payload: string | number}> = []

    /** Function: Converts body string (van, flatdeck, dropside, etc) to application (cargo, freight carrier, dump, etc)*/
    const convertApplication = (body: string) => {

        const appLower = body.toLowerCase().trim();
        const appArray = Array<String>()

        switch(appLower) {
            case 'van':
                appArray.push('general use'); 
                appArray.push('cargo');
                break;
            case 'tautliner':
                appArray.push('general use'); 
                appArray.push('cargo');
                break;
            case 'flatdeck':
            case 'dropside':
                appArray.push('general use');
                break;
            case 'tipper':
                appArray.push('dump');
                break;
            /*case 'tanker':
                appArray.push('tanker');
                break;
            case 'livestock':
                appArray.push('livestock');
                break;*/
            default:
                appArray.push('general use');
        }
        return appArray;
    }

    /** Function: Generates a view for list of filtered trucks based on application and payload configuration */
    const generateValues = (trucks: Array<TruckSpecifications>, application: Array<String>, payload: BuildTruckPayload) => {

        /** The cool stuff starts here 
         *****************************************************************************
         ** @params listOfPayloads => an Array to keep track of payloads            **
         ** @params values => an EmptyCalcValues object to be used for each truck   **
         ** @params payloadOperators => determines the direction of payload filter  **
         **                                                                         **
         ** @function steps                                                         **
         *****************************************************************************
         ** Filter trucks based on body application;                                **
         ** Map each truck and body to each CalcValues;                             **
         ** Call Calculator to return regulated payload for each CalcValues;        **
         ** Filter regulated payloads based on user payload configurations          **
         ** Return JSX Elements;                                                    **
         *****************************************************************************/

        const { value, attribute } = payload;
        const payloadOperators = {
            'min': (payload: number, value: number) => payload >= value,
            'max': (payload: number, value: number) => payload <= value,
            'exact': (payload: number, value: number) => payload >= value*0.95 && payload <= value*1.05 //Exact value with plus-minus 5% Error Margin
        }

        const isTankerOrLivestock = bodyValue?.includes('tanker') || bodyValue?.includes('livestock')
        const crewCabs = ['FC3', 'FF3', 'FG3'];
        const isRollback = bodyValue?.includes('rollback');
        const rollbacks = ['FD3', 'FE3'];

        const ionItems: JSX.Element[] = trucks.filter(t => //!(rollbacks.includes(t.chassisModel) && isRollback) //916 and Rollback 
                                                             (!(crewCabs.includes(t.chassisModel) && isTankerOrLivestock) //Livestock Carrier or Tank Body with 300 Crew Cabs
                                                            && application.includes(t.application.toLowerCase()) //application
                                                            && t.series !== '500' && t.series !== '700')) //500 or 700
                                                            .map(truck => {

            const values = new EmptyCalcValues()
            const v = { ...values };

            const body: ModelBody.Body = {
                type: bodyValue || "unknown",
                mass: null,
                length: null,
                width: null,
                height: null
            };

            v.truck = JSON.stringify(truck)
            v.body = body;

            /** Init Calculator */
            const { regulations, dimensions, bodyType } = Calculator(v);

            /** Title for the canvas*/
            const bdy = bodyType.toLowerCase() !== 'unknown' ? `with a ${bodyType.charAt(0).toUpperCase() + bodyType.slice(1)}` : '';
            const title = `${truck.name} (${truck.chassisModel}) ${bdy}`;
        
            /** Axle titles (2-axles (AB), 3-axles (ABC), 4-axles (ABCD)) */
            const axles = dimensions.fas === 0 && dimensions.ras === 0 ? {front: "A", rear: "B"} 
                            : dimensions.fas === 0 && dimensions.ras > 0 ? {front: "A", rear: "BC"} 
                            :  {front: "AB", rear: "CD"}; 

            /** Update list of payloads based on operators */
            if (payloadOperators[attribute](Number(regulations.mass.pwt), Number(value))) listOfPayloads.push({smc: truck.model, payload: regulations.mass.pwt});

            return (
                <React.Fragment key={truck.id}>

                    {
                        payloadOperators[attribute](Number(regulations.mass.pwt), Number(value)) &&
                        
                        (
                            <IonItemSliding>
                                <IonItem lines={truckId !== null && truckId === truck.id ? 'none' : "full"} detail={false} 
                                    onClick={() => {
                                        setTruckId(truck.id);
                                        setShowCanvas(!showCanvas);
                                    }}>
                                    <IonLabel>
                                        <h2><strong>{`${truck.description} (${truck.chassisModel})`}</strong></h2>
                                        <h3>Available Payload - {regulations.mass.pwt} kg</h3>
                                        <h3>Truck Retail Price - {
                                            new Intl.NumberFormat('en-ZA', {
                                                style: 'currency',
                                                currency: 'ZAR',
                                            
                                                // These options are needed to round to whole numbers if that's what you want.
                                                //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
                                                //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
                                            }).format(+truck.price).replace('ZA', '')
                                        }</h3>
                                        <h3>{bodyType} Retail Price - Not Available</h3>
                                    </IonLabel>
                                    <IonIcon color='dark' icon={showCanvas && truckId !== null && truckId === truck.id ? chevronUpOutline : chevronDownOutline} slot="end" />
                                </IonItem>

                                { showCanvas && truckId !== null && truckId === truck.id &&

                                    <IonCard mode='ios' className='ion-padding'>
                                        
                                        <IonCardHeader>

                                            <IonRow>
                                            
                                                <IonCol size="4" className="ion-padding-start ion-padding-end">
                                                    <IonRow className="ion-justify-content-center ion-text-center"><strong>{`Front (${axles.front})`}</strong></IonRow>
                                                    <IonRow className="ion-justify-content-center ion-text-center">
                                                        <IonChip
                                                            color={regulations.mass.twfW ? "success" : "danger"}>
                                                            <strong>{`${regulations.mass.utilF} %`}</strong>
                                                        </IonChip>
                                                    </IonRow>
                                                </IonCol>
                                                <IonCol size="4" className="ion-padding-start ion-padding-end">
                                                    <IonRow className="ion-justify-content-center ion-text-center"><strong>{`Rear (${axles.rear})`}</strong></IonRow>
                                                    <IonRow className="ion-justify-content-center ion-text-center">
                                                        <IonChip
                                                            color={regulations.mass.twrW ? "success" : "danger"}>
                                                            <strong>{`${regulations.mass.utilR} %`}</strong>
                                                        </IonChip>
                                                    </IonRow>
                                                </IonCol>
                                                <IonCol size="4" className="ion-padding-start ion-padding-end">
                                                <IonRow className="ion-justify-content-center ion-text-center"><strong>Total</strong></IonRow>
                                                    <IonRow className="ion-justify-content-center ion-text-center">
                                                        <IonChip
                                                            color={regulations.mass.twtW ? "success" : "danger"}>
                                                            <strong>{`${regulations.mass.utilT} %`}</strong>
                                                        </IonChip>
                                                    </IonRow>
                                                </IonCol>
                                            </IonRow>

                                        </IonCardHeader>
                                        <IonCardContent>
                                            <CnvsDimensions values={v} view={'side'} />
                                            
                                        </IonCardContent>
                                        <IonCardHeader>
                                            <IonItem detail={false} lines='none'>
                                                <IonButton slot='start' fill='clear' size='small' onClick={async(e) => await shareConfig(attribute, bodyType, truck.chassisModel, value)} disabled>Share&nbsp;<IonIcon icon={shareSocialOutline} color='primary'/></IonButton>
                                                {/*<IonButton disabled slot='end' fill='clear' size='small'>Save&nbsp;<IonIcon icon={saveOutline} color='primary'/></IonButton>*/}
                                            </IonItem>
                                        </IonCardHeader>
                                    </IonCard>
                                }

                                <IonItemOptions> 
                                    <IonItemOption 
                                        disabled
                                        color="danger"
                                        onClick={() => {
                                        console.log('')
                                        }}>
                                        Request HPS to optimise
                                    </IonItemOption>
                                    <IonItemOption color="success" onClick={() => alert("Calculation Deleted")} disabled>
                                        Save
                                    </IonItemOption>
                                </IonItemOptions>

                            </IonItemSliding>
                            
                        )
                    }
 
                </React.Fragment>
            );
        })

        return ionItems;
    }

    const [showBodies, setShowBodies] = useState(true);

    const [query, setQuery] = useState<string>("");
    const [showFilter, setShowFilter] = useState(false);

    const [message, setMessage] = useState<'success' | 'error' | null>(null);
    const [body, setBody] = useState<string | null | undefined>(null);
    const [application, setApplication] = useState<Array<String>>([]);
    const [bodyValue, setBodyValue] = useState<string | null | undefined>(null);
    const [showCanvas, setShowCanvas] = useState(false);
    const [showSubmitToBuilderModal, setShowSubmitToBuilderModal] = useState(false);
    const [showTruckListFilter, setShowTruckListFilter] = useState(false);

    const [truckId, setTruckId] = useState<string | null>(null);

    const [value, setValue] = useState(1000);
    const [attribute, setAttribute] = useState<'min' | 'max' | 'exact'>('max');
    
    const [rangeValue, setRangeValue] = useState<{
        lower: number;
        upper: number;
    }>({ lower: value, upper: 9000 });

    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation(postMessagesToDB, {
        onSuccess: () => {
            //console.log(data);
            setMessage("success")
          },
          onError: () => {
            setMessage("error")
          },
          onSettled: () => {
            queryClient.invalidateQueries('messages');
          }
    });

    useEffect(() => {
        const app = bodyValue !== null && bodyValue !== undefined ? convertApplication(bodyValue) : [];
    
        setApplication(app);

        //const lower = min(listOfPayloads.map(p => Number(p.payload)));
        //const upper = max(listOfPayloads.map(p => Number(p.payload)));

        //setRangeValue({lower: lower, upper: upper})

        //setRangeValue({lower: lower, upper: upper});
    }, [bodyValue, value, attribute])

    const minMax = attribute === 'exact' ? 'Exact' : attribute.slice(0, 1).toUpperCase() + attribute.slice(1) + 'imum';
    const plTitle = minMax + ' Payload'

    return (
        <>
            <IonHeader>
                <IonToolbar color="primary">
                   {/*<IonButtons slot="end">
                        <IonButton 
                            disabled={isLoading || message === "success" || body === null}
                            onClick={() => {


                            }
                            }>Save&nbsp;
                            <IonIcon icon={checkmark} />
                        </IonButton>
                    </IonButtons> */}
                    <IonButtons slot="start">
                        <IonButton 
                            disabled={message === "success"}
                            onClick={() => {
                                onDismissModal();
                                history.push(route);
                                }}>
                            <IonIcon icon={arrowBackOutline} />
                        </IonButton>
                    </IonButtons>
                    <IonTitle>Estimate Payload</IonTitle>
                </IonToolbar>
                <IonToolbar mode="ios" className="ion-padding-top">
                    <SearchInput onChangeSearchQuery={(query) => setQuery(query)} />
                    <IonButtons slot="end">
                        {
                        !showFilter &&
                        <IonButton color="dark" onClick={() => setShowFilter(true)}>
                        {ios ? 'Filter' : <IonIcon icon={options} slot="icon-only" />}
                        </IonButton>}
                        {
                        showFilter &&
                        <IonButton color="dark" onClick={() => setShowFilter(false)}>
                            {ios ? 'Close' : <IonIcon icon={close} slot="icon-only" />}
                        </IonButton>
                        }
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                
                <IonCard mode='ios'>

                    {
                        message === null &&

                        <>
                            {
                                !showCanvas && !showBodies &&
                                <IonRow className='ion-justify-content-center'>
                                    <IonCol size='4' className='ion-padding ion-text-center'>
                                        <IonButton size='small'onClick={() => setAttribute('min')} disabled={attribute === 'min'}>Minimum</IonButton>
                                    </IonCol>
                                    
                                    <IonCol size='4' className='ion-padding ion-text-center'>
                                    <IonButton size='small' onClick={() => setAttribute('max')} disabled={attribute === 'max'}>Maximum</IonButton>
                                    </IonCol>

                                    <IonCol size='4' className='ion-padding ion-text-center'>
                                        <IonButton size='small' onClick={() => setAttribute('exact')} disabled={attribute === 'exact'}>Exact</IonButton>
                                    </IonCol>

                                </IonRow>
                            }
                            

                            <IonRow className="ion-no-padding ion-justify-content-center">

                                <IonItemDivider mode="ios" color={bodyValue === null || bodyValue === undefined ? "dark" : "dark"}
                                    className='ion-padding ion-margin-top' sticky
                                    onClick={() => {
                                        setShowCanvas(false);
                                        bodyValue === null || bodyValue === undefined ? setShowBodies(true) : setShowBodies(!showBodies);
                                    }} >
                                    <IonLabel color='light'>
                                        <h2><strong>{bodyValue === null || bodyValue === undefined ? 'Application' : body}</strong></h2>
                                        <h4>{bodyValue === null || bodyValue === undefined ? 'Select Body Application' : `You Selected a ${body} Application`}</h4>
                                    </IonLabel>
                                    { bodyValue !== null && bodyValue !== undefined && <IonIcon slot="end" size="large" color='light' icon={showBodies ? close : searchOutline} />}
                                </IonItemDivider>

                                {
                                    showBodies && listOfBodies.map ((b, index) => 

                                        <IonCol size="6" key={index}>
                                            <IonItem detail={false} color='dark'>
                                                <IonCol size="6">
                                                    <IonRow className="ion-padding-bottom"><IonLabel>{b.name}</IonLabel></IonRow>
                                                    <IonRow>
                                                        <IonImg src={b.image} />
                                                        <IonRadio 
                                                            value={b.value} 
                                                            onClick={() => {
                                                                setBody(b.name);
                                                                setBodyValue(b.value);
                                                                setShowBodies(false);
                                                            }} 
                                                            disabled={b.value === 'tipper' || b.value === 'rollback' || b.value === 'beavertail' || b.value === 'timber'}
                                                            ></IonRadio>
                                                    </IonRow>
                                                </IonCol>  
                                            </IonItem>
                                        </IonCol>
                                    )
                                }; 
                            </IonRow>

                            {
                                !showBodies && bodyValue !== null && bodyValue !== undefined && !isLoadingTrucks && application.length > 0 &&

                                <>
                                
                                    <IonRow className="ion-no-padding ion-justify-content-center">

                                        <IonCol size='12'>
                                            <IonItem lines='none' detail={false}>
                                                <IonLabel>
                                                    <h2><strong>{plTitle} - {value} kg / {value/1000} Ton</strong></h2>
                                                    {attribute === 'exact' && <p><em>5% margin of error</em></p>} 
                                                </IonLabel>
                                            </IonItem>
                                        </IonCol>

                                        {/* !showCanvas &&
                                            <>
                                                <IonCol size='12'>
                                                    <IonRange min={rangeValue.lower} max={rangeValue.upper} step={500} snaps={true} color="dark" value={value} onIonChange={(e) => setValue(e.detail.value as any)}>
                                                        <IonButton size='small' slot="start" color="dark" onClick={() => setValue(value - 500)}>
                                                            <IonIcon icon={remove} color='light'/>
                                                        </IonButton>
                                                        <IonButton size='small' slot="end" color='dark' onClick={() => setValue(value + 500)}>
                                                            <IonIcon icon={add} color='light'/>
                                                        </IonButton>
                                                    </IonRange>
                                                </IonCol>
                                                <IonItem className='ion-no-margin ion-no-padding' lines='full'/>
                                            </>
                                        */}

                                    </IonRow>
                                    
                                    <IonRow>
                                        
                                            <IonListHeader lines='full' className='ion-padding-bottom ion-margin-bottom'>
                                                List of Trucks
                                            </IonListHeader>
                                            
                                            <IonCol size='12'>
                                                { generateValues(trucks, application, {value: value, attribute: attribute}) }     
                                            </IonCol>
                                            {
                                                listOfPayloads.length === 0 && 

                                                <IonCol>
                                                    <IonItem>
                                                        <IonLabel>
                                                            <h2><strong>{listOfPayloads.length} Trucks Found</strong></h2>
                                                            
                                                            {
                                                                isDealership ? 

                                                                <p><em>Toggle Payload or contact Preferred Supplier</em></p>
                                                                    
                                                                :

                                                                <p><em>Toggle Payload or Configure Trucks</em></p>
                                                                    
                                                            }
                                                            

                                                        </IonLabel>

                                                    </IonItem>

                                                    <IonItem lines='none'>
                                                        {
                                                            isDealership ?

                                                            <IonButton fill='clear' onClick={() => setShowSubmitToBuilderModal(true)}>Contact Preferred Supplier&nbsp;<IonIcon icon={openOutline} /></IonButton>

                                                            :

                                                            <IonButton fill='clear' onClick={() => setShowTruckListFilter(true)}>Configure&nbsp;<IonIcon icon={openOutline} /></IonButton>

                                                        }
                                                    </IonItem>
                                                </IonCol>
                                            }
                                        
                                        {/*
                                            listOfPayloads.length === 0 &&
                                            <IonCol>
                                                <IonItem lines='full'>
                                                    <IonLabel>
                                                        <h2><strong>No Trucks Found</strong></h2>
                                                        <p><em>Try adjusting your search criteria</em></p>
                                                    </IonLabel>
                                                </IonItem>
                                            </IonCol>

                                        */}
                                    </IonRow>
                                    
                                </>
                            }

  
                        </>  
                    }

 

  

                </IonCard>

                <IonLoading
                    //cssClass='my-custom-class'
                    isOpen={isLoading}
                    message={`Saving your configuration. Please wait...`}
                />

                <IonModal
                    mode="ios"
                    isOpen={showSubmitToBuilderModal}
                    onDidDismiss={() => setShowSubmitToBuilderModal(false)}
                    swipeToClose={true}> 
                        <SubmitToBuilder 
                            onDismissModal={() => setShowSubmitToBuilderModal(false)} 
                            route={'/calculations'}
                            notes={`Please spec a Hino Truck with a ${body} body application and ${attribute} payload of ${value} kg`}
                            application={body!}
                            values={null}/>
                </IonModal>

                <IonModal
                    mode="ios"
                    isOpen={showTruckListFilter}
                    onDidDismiss={() => setShowTruckListFilter(false)}
                    swipeToClose={true}> 
                    <TruckListFilter onDismissModal={() => setShowTruckListFilter(false)} route={'/calculations'}/>
                </IonModal>
        

            </IonContent>

            {
                !showCanvas && !showBodies &&

                <IonFooter>
                    <IonToolbar mode='ios' color='primary'>
                        <IonRange min={rangeValue.lower} max={rangeValue.upper} step={500} snaps={true} color="light" value={value} onIonChange={(e) => setValue(e.detail.value as any)}>
                            <IonButton size='small' slot="start" color="light" onClick={() => setValue(value - 500)}>
                                <IonIcon icon={remove} color='primary'/>
                            </IonButton>
                            <IonButton size='small' slot="end" color='light' onClick={() => setValue(value + 500)}>
                                <IonIcon icon={add} color='primary'/>
                            </IonButton>
                        </IonRange>

                    </IonToolbar>
                </IonFooter>
            }
            
        </>    
    )
}

export default BuildTruck;