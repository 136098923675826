import { ConnectionStatus, Network } from '@capacitor/network';
import { useBooleanState } from 'webrix/hooks';
import React, { ReactNode, useEffect } from 'react';
import { IonButton, IonCol, IonContent, IonGrid, IonImg, IonRow, IonText } from '@ionic/react';

import './Login.css';


let isConnected = true;
const networkStatus = async () => await Network.getStatus();

const Offline : React.FC <{children: ReactNode}> = ({ children }) => {

	networkStatus().then((connection: ConnectionStatus) => isConnected = connection.connected);

	const { value: online, setFalse: setOffline, setTrue: setOnline } = useBooleanState(isConnected);
	
	useEffect(() => {
		window.addEventListener('online', setOnline);
		window.addEventListener('offline', setOffline);

		return () => {
			window.removeEventListener('online', setOnline);
			window.removeEventListener('offline', setOffline);
		};
	}, [online, setOnline, setOffline]);


		
	return (
		<>
			{!online && 

			<div>
				<IonContent fullscreen className="ion-padding">

					<IonGrid>

						<IonRow className="header">
							<IonCol className="header-logo-img">
								<IonCol className="header-logo">
									<IonCol className="header-logo-text"></IonCol>
								</IonCol>
							</IonCol>
							<IonCol className="header-img"></IonCol>
						</IonRow>

						<IonRow className="ion-text-center ion-justify-content-center ion-padding ion-margin">
							<IonImg src="/assets/images/offline.svg" className="offline-img" />
							<IonText className="offline-title"><strong>No Internet Connection</strong></IonText>
							<IonText className="offline-subtitle ion-padding ion-margin"><h5><strong>There seems to be trouble with internet. Please check your network connection or reload this page in a few seconds</strong></h5></IonText>
						</IonRow>

						<IonRow className="ion-text-center ion-justify-content-center ion-no-margin ion-no-padding">
							<IonButton 
								mode="ios" 
								className='offline-button'
								onClick={() => window.location.reload}
								>Reload
							</IonButton> 
						</IonRow>

					</IonGrid>

				</IonContent>
			</div>
			
			}

			{online && children} 
		</>
	)
}

export default Offline;