import React, { useState } from "react";
import { useContext } from "react";
import {
  arrowDown,
  arrowForward,
  help,
  homeOutline,
  informationCircleOutline,
} from "ionicons/icons";
import { IonMenu, IonHeader, IonToolbar, IonTitle, IonContent, IonList, IonMenuToggle, IonItem, IonIcon, IonLabel } from "@ionic/react";

const SideMenu: React.FC = () => {
  //const { firstName, lastName } = useContext(AuthContext);
  const [isVisible, setIsVisible] = useState(false);

  return (
    <React.Fragment>
      <IonMenu content-id="main-content">
        <IonHeader>
          <IonToolbar color="primary">
            <IonTitle>
              Hi,
            </IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent id="main-content">
          <IonList>
            <IonMenuToggle auto-hide="false">
              <IonItem button routerLink="/homePage">
                <IonIcon slot="start" icon={homeOutline}></IonIcon>
                <IonLabel>HOME</IonLabel>
              </IonItem>

         </IonMenuToggle>
         </IonList>

        <IonItem
            button
            onClick={() => {
              setIsVisible(true);
              if (isVisible === true) {
                setIsVisible(false);
              }
            }}
          >
            <IonIcon slot="start" icon={informationCircleOutline}></IonIcon>
            <IonLabel>HELP</IonLabel>
            <IonIcon
              slot="end"
              icon={isVisible ? arrowDown : arrowForward}
            ></IonIcon>
          </IonItem>
          <IonMenuToggle>
            <IonList>
              <IonItem
                hidden={!isVisible}
                button
                routerLink="/item2"
              >
                <IonIcon slot="start" icon={help}></IonIcon>
                <IonLabel>Sub Item 1</IonLabel>
              </IonItem>
              <IonItem
                hidden={!isVisible}
                button
                routerLink="/item1"
              >
                <IonIcon slot="start" icon={help}></IonIcon>
                <IonLabel>Sub Item 2</IonLabel>
              </IonItem>
            </IonList>
          </IonMenuToggle>

        </IonContent>
      </IonMenu>
    </React.Fragment>
  );
};

export default SideMenu;