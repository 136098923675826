import React from 'react';
import { IonCol, IonGrid, IonItem, IonItemDivider, IonItemGroup, IonLabel, 
    IonList, IonListHeader, IonRow } from '@ionic/react';

import TruckListItem from './TruckListItem';
import { ModelTruck } from '../../models';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

interface TrucksProps {
    trucks: ModelTruck.Truck;
    listType: 'all' | 'saved';
    hide: boolean;  
    isLoading: boolean; 
}

interface TruckListProps extends TrucksProps {};

let ionLoadingItems: any[] = [];
  
for (let i = 0; i < 10; i++) {

ionLoadingItems.push(
    
    <IonItemGroup key={i}>
        <IonCol size="12" sizeMd="6" sizeLg="4" sizeXl="4">
            <IonItemDivider sticky>
                <IonLabel>
                    {<Skeleton/>}
                </IonLabel>
            </IonItemDivider>
            <IonItem className="truck-list-item">
                <IonLabel>
                <h4>{<Skeleton/>}</h4>
                <p>{<Skeleton count={3}/>}</p>
                </IonLabel>
            </IonItem>
        </IonCol>
        
    </IonItemGroup> 

)
}

const TruckList: React.FC<TruckListProps> = ({trucks, listType, hide, isLoading}) => {

    if(trucks.series.length === 0 && !hide) {

        if (isLoading) {

            return (

                <IonList>
                    <IonGrid className="tl-wrapper">
                        {ionLoadingItems}  
                    </IonGrid>
                </IonList>
            );
        
        } else {

            return (
                <IonList>
                    <IonListHeader>
                        No Trucks Found
                    </IonListHeader>
                </IonList>
            );
        }  
    }

    return (
        <>
            <IonList style={hide ? { display: 'none'} : {}}>

                <IonGrid className="tl-wrapper">
                    
                {
                    trucks.series.map((series, index: number) => (

                        <IonItemGroup key={`series-${index}`}>
                            <IonItemDivider sticky>
                                <IonLabel>
                                    {series.name.charAt(0).toUpperCase() + series.name.slice(1)}&nbsp;&mdash;&nbsp;Series
                                </IonLabel>
                            </IonItemDivider>

                            <IonRow>

                                {series.models.map((model: ModelTruck.TruckSpecifications, modelIndex: number) => (

                                    <IonCol size="12" sizeMd="6" sizeLg="4" sizeXl="4" key={`model-${index}-${modelIndex}`}>
                                        <TruckListItem
                                        isSaved={model.id.length > 7}
                                        key={`model-${index}-${modelIndex}`}
                                        model={model}
                                        listType={listType}
                                        />
                                    </IonCol> 
                                    ))
                                    
                                }

                            </IonRow>
                        </IonItemGroup>
                    ))
                }
                </IonGrid>
            </IonList> 
        </>
    );
}

export default TruckList;

