import { useEffect, useRef, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { IonIcon, IonButton, IonRow, IonSpinner,  IonCard, IonCol, IonTextarea, IonModal } from '@ionic/react';
import {  send, close, happyOutline, attachOutline } from 'ionicons/icons';
import EmojiPicker from 'emoji-picker-react';
import { flushSync } from 'react-dom';
import { useStoreState } from "pullstate";

import { encryptAES, getUser, UserPreferences } from "../../../services/user";
import { MessageDetails } from "../../../models/Lead";
import { postMessageToDB } from "../../../services/DataApi";

import './chats.css';
import SubmitToDealer from '../SubmitToDealer';

interface SendChatProps {
  scroll: React.MutableRefObject<HTMLSpanElement | null>;
  messages: Array<MessageDetails>;
}


const SendChat: React.FC<SendChatProps> = ({ scroll, messages }) => {

  const user = useStoreState(UserPreferences, getUser);
  const userid = user.userid;
  const isBodyBuilder = user.role.includes('Hino Preferred Supplier');

  const message = messages[0];
  
  const emojiPickerRef = useRef<HTMLDivElement>(null)

  const [text, setText] = useState<string>("");
  const [sentSuccessfully, setSentSuccessfully] = useState<boolean>(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [showAttachSpec, setShowAttachSpec] = useState(false);


  const queryClient = useQueryClient();
  const { mutate, isLoading } = useMutation(postMessageToDB, {
      onSuccess: data => {
        setSentSuccessfully(true);
        console.log(data);
      },
      onError: error => {
        setSentSuccessfully(false);
        console.log(error);
      },
      onSettled: () => queryClient.invalidateQueries(`messages-${message.message_id}`)
  });

  const sendMessage = async () => {
    
    //event.preventDefault();
    if (text.trim() === "") {
      alert("Enter valid message");
      return;
    }

    const { message_id, recepient_name, recepient_id, sender_name, sender_id } = message;
    let r_id = recepient_id, r_name = recepient_name, s_name = sender_name; //Initialise local variables 

    //Update local variables to swap sender and recepient
    if (userid === message.recepient_id) {
      r_id = sender_id;
      r_name = sender_name;
      s_name = recepient_name;
    } 

    const m: MessageDetails = {
      id: null,
      sender_id: userid,
      sender_name: s_name,
      message_id: message_id,
      recepient_id: r_id,
      recepient_name: r_name,
      timestamp_read: new Date().toLocaleString( 'en-ZA', { timeZoneName: 'short' } ),
      timestamp_sent: new Date().toLocaleString( 'en-ZA', { timeZoneName: 'short' } ),
      text: encryptAES(text, userid),
      userid: userid
    }

    mutate(m)
    
    flushSync(() => setText(""));
    scroll.current?.scrollIntoView({ behavior: "smooth" });
  };

  let inputClear = () => {}

  useEffect(() => {
    if (sentSuccessfully)  inputClear();

    return () => {
      setSentSuccessfully(false);
    }
  }, [sentSuccessfully]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (emojiPickerRef.current && !emojiPickerRef.current.contains(event.target as Node)) {
        setShowEmojiPicker(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [emojiPickerRef]);


  const dealerId = userid === message.sender_id ? message.recepient_id : message.sender_id;
  const dealerName = userid === message.sender_id ? message.recepient_name : message.sender_name;
  const uri = encodeURIComponent(dealerId);


  return (

    <>
      <IonRow className="ion-text-center ion-justify-content-center send-message">

        {
          showEmojiPicker &&

          <IonCard mode="ios">

            <EmojiPicker 
              onEmojiClick={(e) => {
                setText(text + e.emoji);
                setShowEmojiPicker(false);
                }
              } 
            />

          </IonCard>
        }

        
          
        {/*<Input
          maxHeight={200}
          minHeight={50}
          placeholder='Type here...'
          multiline={true}
          value={text}
          inputStyle={ {background: "var(--ion-color-light)", color: "var(--ion-color-dark)"} }
          clear={(clear: any) => inputClear = clear} 
          onChange={(e: any) => setText(e.target.value)}
          leftButtons={<IonButton fill="clear" size="small" onClick={() => showEmojiPicker ? setShowEmojiPicker(false) : setShowEmojiPicker(true)}>{showEmojiPicker ? <Emoji unified="274c" size={25} /> : <Emoji unified="1f600" size={25} />}</IonButton>}
          rightButtons={<IonButton fill="clear" size="small" onClick={() => sendMessage()} disabled={isLoading || text.length < 1} >{isLoading ? <IonSpinner color="dark"/> : <IonIcon color="dark" size="small" icon={send}/>}</IonButton>}
        /> */}
        

        <div className='rce-container-input'>

          <IonCol size='1' className='rce-input-buttons ion-margin-start ion-no-padding ion-text-center ion-justify-content-center'>
            <IonButton fill="clear" size="small" onClick={() => setShowEmojiPicker(!showEmojiPicker)}>{showEmojiPicker ? <IonIcon icon={close} color="dark" size="large" /> : <IonIcon icon={happyOutline} color="dark" size="large" />  }</IonButton> {/*<Emoji unified="274c" size={25} /> : <Emoji unified="1f600" size={25} /> */}
          </IonCol>

          {
            isBodyBuilder &&
            <IonCol size='1' className='rce-input-buttons ion-no-margin ion-no-padding ion-text-center ion-justify-content-center'>
              <IonButton disabled fill="clear" size="small" onClick={() => setShowAttachSpec(!showAttachSpec)}>{showAttachSpec ? <IonIcon icon={close} color="dark" size="large" /> : <IonIcon icon={attachOutline} color="dark" size="large" />  }</IonButton> {/*<Emoji unified="274c" size={25} /> : <Emoji unified="1f600" size={25} /> */}
            </IonCol>
          }

          <IonCol size='8' className='ion-no-margin ion-no-padding'>
            <IonTextarea mode="ios" style={ {backgroundColor: 'var(--ion-color-light)', borderRadius: '5px'} } className='ion-no-margin' minlength={1} name="messageInput" id="messageInput" autoCorrect="off" autoCapitalize="off" spellCheck="false" clearOnEdit={false} color="dark" placeholder='Type here...' value={text} onIonChange={(e) => setText(e.detail.value || "")}/> 
          </IonCol>

          <IonCol size='1' className='rce-input-buttons ion-margin-end ion-no-padding ion-text-center ion-justify-content-center'>
            <IonButton fill="clear" size="small" onClick={() => sendMessage()} disabled={isLoading || text.length < 1} >{isLoading ? <IonSpinner color="dark"/> : <IonIcon color="dark" size="small" icon={send}/>}</IonButton>
          </IonCol>
          
        </div>

       {/* <IonCol size='2' className='rce-input-buttons'>
        <IonButton  fill="clear" size="small" onClick={() => setShowEmojiPicker(!showEmojiPicker)}>{showEmojiPicker ? <IonIcon icon={close} color="dark" size="large" /> : <IonIcon icon={happyOutline} color="dark" size="large" />  }</IonButton>
        </IonCol>
        <IonCol size='8'>
          <IonItem color="light-shade" className="ion-no-padding ion-no-margin">
            <IonTextarea mode="ios" className="ion-no-padding ion-no-margin" minlength={1} name="messageInput" id="messageInput" autoCorrect="off" autoCapitalize="off" spellCheck="false" clearOnEdit={false} color="dark" placeholder='type here' value={text} onIonChange={(e) => setText(e.detail.value || "")}/> 
          </IonItem>
        </IonCol>
        <IonCol size='2'>
        <IonButton fill="clear" size="small" onClick={() => sendMessage()} disabled={isLoading || text.length < 1} >{isLoading ? <IonSpinner color="dark"/> : <IonIcon color="dark" size="small" icon={send}/>}</IonButton>
        </IonCol> */}
        
      </IonRow>



     {/* <form onSubmit={(event) => sendMessage(event)} className="send-message">
        <label htmlFor="messageInput" hidden>
          Enter Message
        </label>
          <textarea
            style={{ background: 'white'}}
            id="messageInput"
            name="messageInput"
            className="form-input__input"
            placeholder="type message..."
            value={text}
            onChange={(e) => setText(e.target.value || "")}
          />

          
            
            <button disabled={isLoading} type="submit" >Send</button>
        

      </form> */}

      

      <IonModal isOpen={showAttachSpec} onDidDismiss={() => setShowAttachSpec(false)} swipeToClose={true}>
        {/*<EmojiPicker 
          onSkinToneChange={(skinTone: SkinTones) => setText(text + " " + skinTone)}
          onEmojiClick={(e) => {
            setText(text + " " + e.emoji)
            console.log(e)
            setShowEmojiPicker(false)
          }
          } 
        />

        <IonPicker columns={[]} isOpen={false}></IonPicker>

        <Emoji unified="1f423" size={45} /> */}
        <SubmitToDealer onDismissModal={() => setShowAttachSpec(false)} values={ {id: dealerId, name: dealerName} } route={`/notifications/${uri}/chats?q=${message.message_id}`} />
      </IonModal>  
    
    
    </>
    
  );
};


export default SendChat;