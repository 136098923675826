import { Page, Text, View, Image, Document, StyleSheet } from '@react-pdf/renderer';
import { ReactSVG } from 'react-svg';
import { renderToString } from "react-dom/server"
import packageInfo from '../../../package.json';

import { Body } from '../../models/Body';
import { HinoPreferredSupplier } from '../../models/Builder';
import { Calculation } from '../../models/Calculation';

import { Calculator, CalcValues, EmptyCalcValues, getTruckFromStorage, Payload } from '../../services/calculations';
import { decryptAES } from '../../services/user';

import * as ROUTES  from '../../config';
import { useEffect, useRef, useState } from 'react';

import axios from 'axios';
import { Canvg } from 'canvg';

import { Redirect, useLocation } from 'react-router';



interface SpecDocProps {
    builder?: HinoPreferredSupplier;
    specification?: Calculation;
  }

  const svgToDataUri = async (svgString: string) => {

    try {
      const canvas: HTMLCanvasElement = document.createElement("canvas");
      const context = canvas.getContext("2d");
        
      if (context) {
        const v = Canvg.fromString(context, svgString.trim())
        await v.render()
        const dataUri = canvas.toDataURL("image/png")
        console.log(dataUri)
        return dataUri
      }
    } catch (error) {
      console.error("Error occured:", error)
      return ""
    }
  }




  
// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#FFFFFF'
  },
  section: {
    marginLeft: 10,
    marginRight: 10,
    marginBottom: 10,
    marginTop: 5,
    padding: 10,
    flexGrow: 1
  },
  heading: {
    fontSize: 30,
    fontWeight: 'bold'
  },
  spec_wrapper: {
    //backgroundColor: "#f6f6f5",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    padding: 5
},
  spec_container: {
      backgroundColor: "#f6f6f5",
      display: "flex",
      flexDirection: "column",
      padding: 5,
      justifyContent: "center"
  },
  spec_details: {
      fontSize: 15,
      //display: "flex",
      marginLeft: 5
  },
  spec_title: {
      fontSize: 15,
      marginBottom: 10,
      marginTop: 10,
      fontWeight: 900,
      marginLeft: 5
  },
  spec_overview: {
      fontSize: 10
  },

  image: {
      height: 240,
      width: 320
  },
  subtitle: {
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "row",
      width: 150,
      alignItems: "center",
      marginBottom: 12
  },
  vote: {
      display: "flex",
      flexDirection: "row"
  },
  rating: {
      height: 10,
      width: 10
  },
  vote_text: {
      fontSize: 10
  },
  vote_pop: {
      fontSize: 10,
      padding: 2,
      backgroundColor: "#61C74F",
      color: "#fff"
  },
  vote_pop_text: {
      fontSize: 10,
      marginLeft: 4
  },
  overviewContainer: {
      minHeight: 110
  },
  detailsFooter: {
      display: "flex",
      flexDirection: "row"
  },
  lang: {
      fontSize: 8,
      fontWeight: 700
  },
  vote_average: {
      fontSize: 8,
      marginLeft: 4,
      fontWeight: "bold"
  }
});

/*const loadSvg = (url: string) => {
  return fetch(url)
    .then(function (response) {
      console.log(response);
      return response.text();
    })
    .then(function (raw) {
      return new DOMParser().parseFromString(raw, "image/svg+xml");
    });
};*/

// Create Document Component
const SpecDoc: React.FC<SpecDocProps> = ({specification}) => {

  const pageRef = useRef<HTMLElement>(null);

  const location = useLocation<{"calculation": Calculation}>();

  if (location.state !== undefined) <Redirect to={ROUTES.CALCULATIONS_PAGE_ROUTE}/>

  const spec = location.state.calculation;

  console.log(spec)



  const compilerid = spec?.userid;
  const response = axios.get(`${ROUTES.USERS_API_ENDPOINT}/${compilerid}`).then(res => res.data);

  const [compilerName, setCompilerName] = useState("Unknown");


  useEffect(() => {
    if (response) {
      const name = async () => await response.then(e => e.data.name)
      //setCompilerName(name);
      console.log(name)
    }
  }, [response]);

  const truckSMC = spec?.truckid;
  //initCalculator(truckSMC, spec); //Likely not needed

  const _truck = getTruckFromStorage(truckSMC as string);

  const key = spec?.userid || "";
  const encryptedBase64 = spec?.bodySpecification as unknown as string;
  const decryptedText = decryptAES(encryptedBase64, key);

  const bodySpec = JSON.parse((decryptedText));

  const payload = spec?.payload;

  const  v = { ...new EmptyCalcValues()};
  v.truck = _truck;
  v.body = (bodySpec as Body || null);
  v.payload = (payload as Payload || null);

  const { ...truck} = Calculator(v as CalcValues);

  const body = spec?.body === null || spec?.body === undefined ? "" : `with ${spec?.body} Body`;

  const title = `Specification for ${truck.title} ${body}`;
  const series = spec?.series;
  const subtitle = `Saved on ${spec?.savedDate.toString().substring(0, 10)} by ${compilerName}`
  const overview = `GVM: ${spec?.gvm} kg, Payload:  ${spec?.payload?.payload} kg`;

  const rules = `Mass: ${spec?.rules.mass}, Dimensions: ${spec?.rules.dimensions}, Bridge: ${spec?.rules.bridge} Circle: ${spec?.rules.circle}`;



  const [svgDataUri, setSvgDataUri] = useState<string>("")
  
  useEffect(() => {
    async function convertSvgToDataUri() {
      const svg = (
        <ReactSVG
          src={`/assets/trucks/${series}/${truckSMC}/bridge/index.svg`}
        />
      )

      const dataUri = await svgToDataUri(renderToString(svg))
      setSvgDataUri(dataUri || "")
    }
    convertSvgToDataUri()
  }, [series, truckSMC])
  
  return (

    <Document
      title={title}
      keywords={`${spec?.truck}, ${spec?.series}, ${spec?.body}`}
      author={`${compilerName}`}
      subject={title}
      creator={`Hino Truck Body Builders App v${packageInfo.version}`}
      producer={`Hino Truck Body Builders App v${packageInfo.version}`}
      language="en-za"
    >
      <Page 
        size="A4"
        style={{
            border: 1,
            margin: 20,
          }}>

        {/** Header */}
        <View style={styles.section}>

          <View style={styles.spec_wrapper}>
            <View style={[{display: "flex", justifyContent: "center"}]}>
              {/*<Image src={`/assets/icon/icon_1024.png`} style={{ height: 50, width: 50}}/>*/}
            </View>
          
            <View>
              {/*<Text style={styles.heading}>{builder?.Name}</Text>*/}
              <Text style={[styles.spec_title, {fontWeight: "bold"}]}>{title}</Text>
              <Text style={styles.spec_details}>{subtitle}</Text>
              {/*<Text style={styles.spec_details}> {`Date: ${spec?.savedDate}`}</Text>*/}
            </View>
          </View>

        </View>

        {/** Specification Overview */}
        <View style={styles.section}>
          <Text style={styles.heading}>Overview</Text>
          <Text style={[styles.spec_details, {fontWeight: "bold"}]}>{overview}</Text>
          <Text style={[styles.spec_details, {fontWeight: "bold"}]}>{rules}</Text>
          {/*<Image src={`/assets/images/hino${series}.png`} style={styles.image}/>*/}
          {svgDataUri !== "" && <Image src={svgDataUri}/> }
          <ReactSVG src={`/assets/trucks/${series}/${truckSMC}/bridge/index.svg`}/>

          {/** Specification Details */}
          <View style={styles.spec_wrapper}>
            <View style={styles.spec_container}>
              <Text style={styles.spec_title}>Mass</Text>
              <Text style={styles.spec_title}>Body</Text>
              <Text style={styles.spec_details}>{`Front: ${truck.mass.bwf} kg`}</Text>
              <Text style={styles.spec_details}>{`Rear: ${truck.mass.bwr} kg`}</Text>
              <Text style={styles.spec_details}>{`Total: ${truck.mass.bwt} kg`}</Text>
              <Text style={styles.spec_title}>Truck</Text>
              <Text style={styles.spec_details}>{`A - Front: ${truck.mass.trf} kg`}</Text>
              <Text style={styles.spec_details}>{`A - Rear:  ${truck.mass.trr} kg`}</Text>
              <Text style={styles.spec_details}>{`D/T - ${truck.mass.gcm} kg`}</Text>
              <Text style={styles.spec_details}>{`GA - Front: ${truck.mass.gaf} kg`}</Text>
              <Text style={styles.spec_details}>{`GA - Rear: ${truck.mass.gar} kg`}</Text>
              <Text style={styles.spec_details}>{`GCM: ${truck.mass.gcm} kg`}</Text>
              <Text style={styles.spec_details}>{`GVM: ${truck.mass.rating} kg`}</Text>
              <Text style={styles.spec_details}>{`Tare - Front: ${(+truck.mass.cwf)} kg`}</Text>
              <Text style={styles.spec_details}>{`Tare - Rear: ${(+truck.mass.cwr)} kg`}</Text>
              <Text style={styles.spec_details}>{`Tare - Total: ${truck.mass.cwt} kg`}</Text>
              <Text style={styles.spec_details}>{`V - Rating: ${truck.mass.rating} kg`}</Text>
            </View>
            <View style={{padding: 5, justifyContent: "center"}}>
              <Text style={styles.spec_title}>Dimensions</Text>
              <Text style={styles.spec_title}>Body</Text>
              <Text style={styles.spec_details}>{`BH: ${truck.dimensions.bh} mm`}</Text>
              <Text style={styles.spec_details}>{`VCOG: ${truck.dimensions.vcog} %`}</Text>
              <Text style={styles.spec_details}>{`BL: ${truck.dimensions.bl} mm`}</Text>
              <Text style={styles.spec_details}>{`HCOG: ${truck.dimensions.hcog} %`}</Text>
              <Text style={styles.spec_details}>{`BW: ${truck.dimensions.bw} mm`}</Text>
              <Text style={styles.spec_details}>{`LCOG: ${truck.dimensions.lcog} %`}</Text>
              <Text style={styles.spec_title}>Truck</Text>
              <Text style={styles.spec_details}>{`CA: ${truck.dimensions.ca} mm`}</Text>
              <Text style={styles.spec_details}>{`WB: ${truck.dimensions.wb} mm`}</Text>
              <Text style={styles.spec_details}>{`OH: ${truck.regulations.dimensions.oh} mm`}</Text>
              <Text style={styles.spec_details}>{`OL: ${truck.regulations.dimensions.ol} mm`}</Text>
              <Text style={styles.spec_details}>{`OW: ${truck.regulations.dimensions.ow} mm`}</Text>
              <Text style={styles.spec_details}>{`ROH: ${truck.dimensions.roh} mm`}</Text>
            </View>
            <View style={styles.spec_container}>
              <Text style={styles.spec_title}>Bridge</Text>
              <Text style={styles.spec_details}>{`Distance: ${truck.regulations.mass.distance} mm`}</Text>
              <Text style={styles.spec_details}>{`Gross: ${truck.regulations.mass.gross} kg`}</Text>
              <Text style={styles.spec_details}>{`Permissible: ${truck.regulations.mass.permissible} kg`}</Text>
              <Text style={styles.spec_details}>{`Utilisation: ${truck.regulations.mass.utilisation} %`}</Text>  
              <Text style={styles.spec_title}>Circle</Text>
              <Text style={styles.spec_details}>{`Kerb Radius: ${truck.regulations.kerbRadius} mm`}</Text>
              <Text style={styles.spec_details}>{`Wall Radius: ${truck.regulations.wallRadius} mm`}</Text>
              <Text style={styles.spec_details}>{`Swept Path: ${truck.regulations.sweptPath} mm`}</Text>
              <Text style={styles.spec_details}>{`Inner Radius: ${truck.regulations.innerRadius} mm`}</Text>
              <Text style={styles.spec_details}>{`Steering Angle: ${truck.regulations.steeringAngle} degrees`}</Text>
            </View>
          </View>
        </View>

        {/** Specification Details
        <View style={styles.section}>
          <View style={styles.spec_wrapper}>
            <View style={styles.spec_container}>
              <Text style={styles.spec_title}>Mass</Text>
              <Text style={styles.spec_title}>Body</Text>
              <Text style={styles.spec_details}>{`Front: ${truck.mass.bwf} kg`}</Text>
              <Text style={styles.spec_details}>{`Rear: ${truck.mass.bwr} kg`}</Text>
              <Text style={styles.spec_details}>{`Total: ${truck.mass.bwt} kg`}</Text>
              <Text style={styles.spec_title}>Truck</Text>
              <Text style={styles.spec_details}>{`A - Front: ${truck.mass.trf} kg`}</Text>
              <Text style={styles.spec_details}>{`A - Rear:  ${truck.mass.trr} kg`}</Text>
              <Text style={styles.spec_details}>{`D/T - ${truck.mass.gcm} kg`}</Text>
              <Text style={styles.spec_details}>{`GA - Front: ${truck.mass.gaf} kg`}</Text>
              <Text style={styles.spec_details}>{`GA - Rear: ${truck.mass.gar} kg`}</Text>
              <Text style={styles.spec_details}>{`GCM: ${truck.mass.gcm} kg`}</Text>
              <Text style={styles.spec_details}>{`GVM: ${truck.mass.rating} kg`}</Text>
              <Text style={styles.spec_details}>{`Tare - Front: ${(+truck.mass.cwf)} kg`}</Text>
              <Text style={styles.spec_details}>{`Tare - Rear: ${(+truck.mass.cwr)} kg`}</Text>
              <Text style={styles.spec_details}>{`Tare - Total: ${truck.mass.cwt} kg`}</Text>
              <Text style={styles.spec_details}>{`V - Rating: ${truck.mass.rating} kg`}</Text>
            </View>
            <View style={{padding: 5, justifyContent: "center"}}>
              <Text style={styles.spec_title}>Dimensions</Text>
              <Text style={styles.spec_title}>Body</Text>
              <Text style={styles.spec_details}>{`BH: ${truck.dimensions.bh} mm`}</Text>
              <Text style={styles.spec_details}>{`VCOG: ${truck.dimensions.vcog} %`}</Text>
              <Text style={styles.spec_details}>{`BL: ${truck.dimensions.bl} mm`}</Text>
              <Text style={styles.spec_details}>{`HCOG: ${truck.dimensions.hcog} %`}</Text>
              <Text style={styles.spec_details}>{`BW: ${truck.dimensions.bw} mm`}</Text>
              <Text style={styles.spec_details}>{`LCOG: ${truck.dimensions.lcog} %`}</Text>
              <Text style={styles.spec_title}>Truck</Text>
              <Text style={styles.spec_details}>{`CA: ${truck.dimensions.ca} mm`}</Text>
              <Text style={styles.spec_details}>{`WB: ${truck.dimensions.wb} mm`}</Text>
              <Text style={styles.spec_details}>{`OH: ${truck.regulations.dimensions.oh} mm`}</Text>
              <Text style={styles.spec_details}>{`OL: ${truck.regulations.dimensions.ol} mm`}</Text>
              <Text style={styles.spec_details}>{`OW: ${truck.regulations.dimensions.ow} mm`}</Text>
              <Text style={styles.spec_details}>{`ROH: ${truck.dimensions.roh} mm`}</Text>
            </View>
            <View style={styles.spec_container}>
              <Text style={styles.spec_title}>Bridge</Text>
              <Text style={styles.spec_details}>{`Distance: ${truck.regulations.mass.distance} mm`}</Text>
              <Text style={styles.spec_details}>{`Gross: ${truck.regulations.mass.gross} kg`}</Text>
              <Text style={styles.spec_details}>{`Permissible: ${truck.regulations.mass.permissible} kg`}</Text>
              <Text style={styles.spec_details}>{`Utilisation: ${truck.regulations.mass.utilisation} %`}</Text>  
              <Text style={styles.spec_title}>Circle</Text>
              <Text style={styles.spec_details}>{`Kerb Radius: ${truck.regulations.kerbRadius} mm`}</Text>
              <Text style={styles.spec_details}>{`Wall Radius: ${truck.regulations.wallRadius} mm`}</Text>
              <Text style={styles.spec_details}>{`Swept Path: ${truck.regulations.sweptPath} mm`}</Text>
              <Text style={styles.spec_details}>{`Inner Radius: ${truck.regulations.innerRadius} mm`}</Text>
              <Text style={styles.spec_details}>{`Steering Angle: ${truck.regulations.steeringAngle} degrees`}</Text>
            </View>
          </View>
        </View>  */}

        

        {/** Specification Details 
        <View style={styles.section}>
          <Text style={styles.heading}>Dimensions</Text>

          <View style={styles.spec_wrapper}>
            
            <View style={styles.spec_container}>
              <Text style={styles.spec_title}>Truck</Text>
              <Text style={styles.spec_details}>{`CA - ${truck.cabToRearAxleCentre} mm`}</Text>
              <Text style={styles.spec_details}>{`WB - ${truck.wheelBase} mm`}</Text>
              <Text style={styles.spec_details}>{`OH - ${truck.overallHeight} mm`}</Text>
              <Text style={styles.spec_details}>{`OL - ${truck.overAllLength} mm`}</Text>
              <Text style={styles.spec_details}>{`OW - ${truck.overallWidth} mm`}</Text>
              <Text style={styles.spec_details}>{`ROH - ${truck.rearOverhang} mm`}</Text>
            </View>
            <View style={{padding: 5, justifyContent: "center"}}>
              <Text style={styles.spec_title}>Body</Text>
              <Text style={styles.spec_details}>{`BH - ${truck.cabToRearAxleCentre} mm`}</Text>
              <Text style={styles.spec_details}>{`VCOG - ${truck.wheelBase} mm`}</Text>
              <Text style={styles.spec_details}>{`BL - ${truck.overallHeight} mm`}</Text>
              <Text style={styles.spec_details}>{`HCOG - ${truck.overAllLength} mm`}</Text>
              <Text style={styles.spec_details}>{`BW - ${truck.overallWidth} mm`}</Text>
              <Text style={styles.spec_details}>{`LCOG - ${truck.overallWidth} mm`}</Text>
            </View>
            <View style={styles.spec_container}>
              <Text style={styles.spec_title}>Truck</Text>
              <Text style={styles.spec_details}>{`CA - ${truck.cabToRearAxleCentre} mm`}</Text>
              <Text style={styles.spec_details}>{`WB - ${truck.wheelBase} mm`}</Text>
              <Text style={styles.spec_details}>{`OH - ${truck.overallHeight} mm`}</Text>
              <Text style={styles.spec_details}>{`OL - ${truck.overAllLength} mm`}</Text>
              <Text style={styles.spec_details}>{`OW - ${truck.overallWidth} mm`}</Text>
              <Text style={styles.spec_details}>{`ROH - ${truck.rearOverhang} mm`}</Text>
            </View>
            <View style={{padding: 5, justifyContent: "center"}}>
              <Text style={styles.spec_title}>Body</Text>
              <Text style={styles.spec_details}>{`BH - ${truck.cabToRearAxleCentre} mm`}</Text>
              <Text style={styles.spec_details}>{`VCOG - ${truck.wheelBase} mm`}</Text>
              <Text style={styles.spec_details}>{`BL - ${truck.overallHeight} mm`}</Text>
              <Text style={styles.spec_details}>{`HCOG - ${truck.overAllLength} mm`}</Text>
              <Text style={styles.spec_details}>{`BW - ${truck.overallWidth} mm`}</Text>
              <Text style={styles.spec_details}>{`LCOG - ${truck.overallWidth} mm`}</Text>
            </View>
          </View>
        </View>
        <View style={styles.section}>
          <Text style={styles.heading}>Mass</Text>
          <View style={styles.spec_container}>
            <Text style={styles.spec_title}>Truck</Text>
            <View style={styles.spec_details}>
              <Text>{`GVM - ${truck.GVM} kg`}</Text>
              <Text>{`GCM - ${truck.GCM} kg`}</Text>
              <Text>{`Body Type - ${truck.overallHeight} kg`}</Text>
              <Text>{`Body Mass - ${truck.overAllLength} kg`}</Text>
              <Text>{`Payload - ${truck.overallWidth} kg`}</Text>
            </View>
          </View>
          
        </View>*/}
      </Page>
    </Document>

  );
};

export default SpecDoc;