import { useRef } from 'react';
import { isPlatform, IonContent, IonButton, IonToolbar, IonHeader, IonPage, IonButtons, 
  IonItem, IonLabel, IonIcon, IonTitle } from '@ionic/react';
import { close } from 'ionicons/icons';
import GuidelineStore from '../../services/guidelines';

import * as ROUTES from '../../config';
 
const Legal: React.FC = () => {
  
  const pageRef = useRef<HTMLElement>(null);
  const ios = isPlatform('ios');

  console.log(ios)

  return (
    <IonPage ref={pageRef} id="legal-page">
      <IonHeader>
        <IonToolbar mode="ios" color="toolbar">
            <IonButtons slot="start">
            <IonButton routerLink={ROUTES.TABS_ACCOUNT_PAGE_ROUTE} routerDirection="back">
              <IonIcon icon={close} />
              
              </IonButton>
            </IonButtons>
            <IonTitle>Legal</IonTitle>
        </IonToolbar>
      </IonHeader>
      
      <IonContent fullscreen>

        <IonItem
          routerLink={`${ROUTES.SETTINGS_LEGAL_TOS_PAGE_ROUTE}`}
          onClick={() => GuidelineStore.update(s => {
            s.url = 'Terms';
            s.title = 'terms of service';
          })} disabled>
          <IonLabel>
            <h4>Terms</h4>
            <p>Terms of Service</p>
          </IonLabel>
        </IonItem>

        <IonItem 
          onClick={() => ios ? 
            setTimeout(() => window.open('https://www.hino.co.za/privacy-notice'), 1000)
            : window.open('https://www.hino.co.za/privacy-notice')} 
          detail={true}>
          <IonLabel>
            <h4>Privacy</h4>
            <p>Privacy Notice and Data Protection</p>
          </IonLabel>
        </IonItem>
        <IonItem 
          onClick={() => ios ? 
            setTimeout(() => window.open('https://www.hino.co.za/privacy-notice'), 1000)
            : window.open('https://www.hino.co.za/privacy-notice')} 
          detail={true} disabled>
          <IonLabel>
            <h4>Delete Account</h4>
            <p>Delete Personal Data Created / Shared within the App</p>
          </IonLabel>
        </IonItem>
        <IonItem 
          onClick={() => ios ? 
            setTimeout(() => window.open('https://www.hino.co.za/privacy-notice'), 1000)
            : window.open('https://www.hino.co.za/privacy-notice')} 
          detail={true} disabled>
          <IonLabel>
            <h4>Credits and Licenses</h4>
            <p>Credits and Legal Licenses</p>
          </IonLabel>
        </IonItem>
        
      </IonContent>
    </IonPage>
  );
};

export default Legal;
