import { CalcValues } from '../../../../services/calculations';
import Dropside from './Dropside';
import FlatDeck from './FlatDeck';
import Livestock from './Livestock';
import Tanker from './Tanker';
import Tautliner from './Tautliner';
import Timber from './Timber';
import Van from './Van';

interface BodyProps {
    type: string;
    values: CalcValues;
    setValues: (v: CalcValues) => void;
}

const BodyDimensions: React.FC<BodyProps> = ({type, values, setValues}) => {

    let body = <Tautliner values={values} setValues={setValues} />

    switch (type) {
        case 'van':
            //console.log(values.body?.length)
            body = <Van values={values} setValues={setValues} />
        break;
        case 'tautliner':
            body = <Tautliner values={values} setValues={setValues} />
        break;
        case 'dropside':
            body = <Dropside values={values} setValues={setValues} />
        break;
        case 'flatdeck':
            body = <FlatDeck values={values} setValues={setValues} />
        break;
        case 'tanker':
            body = <Tanker values={values} setValues={setValues} />
        break;
        case 'timber':
            body = <Timber values={values} setValues={setValues} />
        break;
        case 'livestock':
            body = <Livestock values={values} setValues={setValues} />
        break;
        case 'rollback':
            //body = <Rollback parameter={parameter} truck={truck} dispatcher={dispatcher} state={state} />
        break;
        case 'tipper':
            //body = <Tipper parameter={parameter} truck={truck} dispatcher={dispatcher} state={state} />
        break;
    }

    return body;
}

export default BodyDimensions;