import React, { useState } from 'react';
import { isPlatform, IonInput, IonToolbar, IonButtons, IonHeader, IonSegmentButton,
    IonButton, IonContent, IonList, IonText, IonItem, IonCol, IonListHeader, IonLabel, 
    IonIcon, IonSelect, IonSelectOption, IonSegment, IonTitle } from '@ionic/react';

import { checkmark, close, construct, chevronDown, chevronForward, chevronBack } from 'ionicons/icons';
import  { CalcValues, FuelAndCrew, Calculator } from '../../../services/calculations';

interface CalcEditBodyProps {
    onDismissModal: () => void;
    values: CalcValues;
    setValues: (v: CalcValues) => void;
  }
  
const CalcEditTruck: React.FC<CalcEditBodyProps> = ({onDismissModal, values, setValues}) => {

    const ios = isPlatform('ios');
    const [segment, setSegment] = useState<'crew' | 'dimensions' | 'mass' | 'fuel'>('dimensions');

    const [turnCircle, setTurnCircle] = useState(false);
    const [showLength, setShowLength] = useState(false);
    const [showWidth, setShowWidth] = useState(false);

    const [axle1, setAxle1] = useState(false);
    const [axle2, setAxle2] = useState(false);
    const [axle3, setAxle3] = useState(false);
    const [tyres, setTyres] = useState(false);
    const [cog, setCog] = useState(false);

    const [crewMass, setCrewMass] = useState(80);
    const [crewPersons, setCrewPersons] = useState(1);
    const [fuelType, setFuelType] = useState("Diesel");
    const [fuelDensity, setFuelDensity] = useState(0.85);

    //const truck = JSON.parse(values.truck as string) as ModelTruck.TruckSpecifications;
    const fuelAndCrew = new FuelAndCrew();

    const { mass: m, dimensions: d, regulations: r } = Calculator(values);

    const rd = r.dimensions;
    const rm = r.mass;

    return (
        <>
            <IonHeader>
                <IonToolbar color="primary">
                    <IonButtons slot="end">
                        {/*<IonButton 
                            onClick={() => {
                            
                               const fuelAndCrew: FuelAndCrew = {
                                    crewMass: crewMass,
                                    crewNumber: crewPersons,
                                    fuelType: fuelType,
                                    fuelDensity: fuelDensity,
                                    fuelTank: m.ft
                                }; 

                                const v = { ...values};
                                v.fuelAndCrew = { ...fuelAndCrew};
                               
                                CalcStore.update(s => {s.fuelAndCrew = fuelAndCrew});
                                setValues(v);

                                onDismissModal();
                            
                            }}>Done&nbsp;
                            <IonIcon icon={checkmark} />
                        </IonButton> */}
                    </IonButtons>
                    <IonButtons slot="start">
                        <IonButton 
                            onClick={onDismissModal}>
                            <IonIcon icon={close} />
                        </IonButton>
                    </IonButtons>
                    <IonTitle>Truck Specifications</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>

                    {/*<IonListHeader className="ion-padding">Truck Specifications</IonListHeader>*/}

                <IonToolbar>
                    <IonSegment mode="md" value={segment} onIonChange={(e) => setSegment(e.detail.value as any)}>
                        <IonSegmentButton value="dimensions">
                            Dimensions
                        </IonSegmentButton>
                        <IonSegmentButton value="mass">
                            Mass
                        </IonSegmentButton>
                        <IonSegmentButton value="fuel">
                            Fuel
                        </IonSegmentButton>
                        <IonSegmentButton value="crew">
                            Crew
                        </IonSegmentButton>
                    </IonSegment>
                </IonToolbar>

                {
                    segment === "dimensions" &&

                    <IonList>
        
                        <IonListHeader className="ion-padding-bottom">Configure Dimensions</IonListHeader>
        
                        <IonToolbar color="light">
                            <IonText className="ion-padding">Length</IonText>
                            <IonButtons slot="end">
                            {
                                !showLength &&
                                <IonButton onClick={() => {
                                    setShowWidth(false);
                                    setTurnCircle(false);
                                    setShowLength(true);
                                }}>
                                <IonIcon icon={chevronForward} slot="icon-only" />
                                </IonButton>}
                            {
                                showLength &&
                                <IonButton onClick={() => setShowLength(false)}>
                                <IonIcon icon={chevronDown} slot="icon-only" />
                                </IonButton>
                            }
                            </IonButtons>
                        </IonToolbar>
        
                        {
                            showLength &&
                            <>
                                {d.ras > 0 &&
                                <IonItem>
                                    <IonCol size="8">
                                        { ios &&
                                            <IonIcon slot="start" icon={construct} color="medium" />
                                        }
                                        <IonLabel>
                                            <h3>RAS</h3>
                                            <p>Axle 2 to 3</p>
                                        </IonLabel>
                                    </IonCol>
                                    <IonCol size="8">
                                        <IonInput color="primary" placeholder={`${d.ras} mm`} onIonChange={e => console.log(e.detail.value)}>
                                        </IonInput>
                                    </IonCol>
                                </IonItem> }
                                <IonItem> 
                                    <IonCol size="8">
                                        { ios &&
                                            <IonIcon slot="start" icon={construct} color="medium" />
                                        }
                                        <IonLabel>
                                            <h3>Wheelbase</h3>
                                            <p>{`${d.wbmin} - ${d.wbmax} mm`}</p>
                                        </IonLabel>
                                    </IonCol>
                                    <IonCol size="8">
                                        <IonInput color="primary" placeholder={`${d.wb} mm`} onIonChange={e => console.log(e.detail.value)}>
                                        </IonInput>
                                    </IonCol>
                                    
                                </IonItem>
                                <IonItem>
                                    <IonCol size="8">
                                        { ios &&
                                            <IonIcon slot="start" icon={construct} color="medium" />
                                        }
                                        <IonLabel>
                                            <h3>CA</h3>
                                            <p>Cab to rear end axle</p>
                                        </IonLabel>
                                    </IonCol>
                                    <IonCol size="8">
                                        <IonInput color="primary" placeholder={`${d.ca} mm`} onIonChange={e => console.log(e.detail.value)}>
                                        </IonInput>
                                    </IonCol>
                                </IonItem>
                                <IonItem>
                                    <IonCol size="8">
                                        { ios &&
                                            <IonIcon slot="start" icon={construct} color="medium" />
                                        }
                                        <IonLabel>
                                            <h3>Rear overhang</h3>
                                            <p>{`${rd.rohpct} %`}</p>
                                        </IonLabel>
                                    </IonCol>
                                    <IonCol size="8">
                                        <IonInput color="primary" placeholder={`${d.roh} mm`} onIonChange={e => console.log(e.detail.value)}>
                                        </IonInput>
                                    </IonCol>
                                </IonItem>
                            </>
                        }

                        <IonToolbar color="light">
                            <IonText className="ion-padding">Turn Circle</IonText>
                            <IonButtons slot="end">
                            {
                                !turnCircle &&
                                <IonButton onClick={() => {
                                    setShowWidth(false);
                                    setTurnCircle(true);
                                    setShowLength(false);
                                }}>
                                <IonIcon icon={chevronForward} slot="icon-only" />
                                </IonButton>}
                            {
                                turnCircle &&
                                <IonButton onClick={() => setTurnCircle(false)}>
                                <IonIcon icon={chevronDown} slot="icon-only" />
                                </IonButton>
                            }
                            </IonButtons>
                        </IonToolbar>
                            
                        {
                            turnCircle &&
                            <>
                                <IonItem>
                                    <IonCol size="8">
                                        { ios &&
                                            <IonIcon slot="start" icon={construct} color="medium" />
                                        }
                                        <IonLabel>
                                            <h3>Steering Ratio</h3>
                                            <p>Steering Angle</p>
                                        </IonLabel>
                                    </IonCol>
                                    <IonCol size="4">
                                        <IonInput color="primary" onIonChange={e => console.log(e.detail.value)}></IonInput>
                                    </IonCol>
                                </IonItem>
                                <IonItem>
                                    <IonCol size="8">
                                        { ios &&
                                            <IonIcon slot="start" icon={construct} color="medium" />
                                        }
                                        <IonLabel>
                                            <h3>Curb to curb radius</h3>
                                            <p>Curb to curb radius</p>
                                        </IonLabel>
                                    </IonCol>
                                    <IonCol size="4">
                                        <IonInput color="primary" placeholder={`${d.tck} mm`} onIonChange={e => console.log(e.detail.value)}></IonInput>
                                    </IonCol>
                                </IonItem>
                                <IonItem>
                                    <IonCol size="8">
                                        { ios &&
                                            <IonIcon slot="start" icon={construct} color="medium" />
                                        }
                                        <IonLabel>
                                            <h3>Wall to wall radius</h3>
                                            <p>Wall to wall radius</p>
                                        </IonLabel>
                                    </IonCol>
                                    <IonCol size="4">
                                        <IonInput color="primary" placeholder={`${d.tcw} mm`} onIonChange={e => console.log(e.detail.value)}></IonInput>
                                    </IonCol>
                                </IonItem>
                            </>
                        }

                        <IonToolbar color="light">
                            <IonText className="ion-padding">Width</IonText>
                            <IonButtons slot="end">
                            {
                                !showWidth &&
                                <IonButton onClick={() => {
                                    setShowWidth(true);
                                    setTurnCircle(false);
                                    setShowLength(false);
                                }}>
                                <IonIcon icon={chevronForward} slot="icon-only" />
                                </IonButton>}
                            {
                                showWidth &&
                                <IonButton onClick={() => setShowWidth(false)}>
                                <IonIcon icon={chevronDown} slot="icon-only" />
                                </IonButton>
                            }
                            </IonButtons>
                        </IonToolbar>

                        {
                            showWidth &&
                            <>
                                <IonItem lines="none">
                                    <IonCol size="8">
                                        { ios &&
                                            <IonIcon slot="start" icon={construct} color="medium" />
                                        }
                                        <IonLabel>
                                            <h3>Width</h3>
                                            <p>Cab width</p>
                                        </IonLabel>
                                    </IonCol>
                                    <IonCol size="4">
                                            <IonInput color="primary" placeholder={`${d.cw} mm`} onIonChange={e => console.log(e.detail.value)}></IonInput>
                                    </IonCol>
                                </IonItem>
                                <IonItem lines="none">
                                    <IonCol size="8">
                                        { ios &&
                                            <IonIcon slot="start" icon={construct} color="medium" />
                                        }
                                        <IonLabel>
                                            <h3>Rear width</h3>
                                            <p>Frame Width (Rear)</p>
                                        </IonLabel>
                                    </IonCol>
                                    <IonCol size="4">
                                            <IonInput color="primary" placeholder={`${d.fwr} mm`} onIonChange={e => console.log(e.detail.value)}></IonInput>
                                    </IonCol>
                                </IonItem>
                                <IonItem lines="none">
                                    <IonCol size="8">
                                        { ios &&
                                            <IonIcon slot="start" icon={construct} color="medium" />
                                        }
                                        <IonLabel>
                                            <h3>Track Width (Front)</h3>
                                            <p>Front Track Width</p>
                                        </IonLabel>
                                    </IonCol>
                                    <IonCol size="4">
                                            <IonInput color="primary"  placeholder={`${d.tkf} mm`} onIonChange={e => console.log(e.detail.value)}></IonInput>
                                    </IonCol>
                                </IonItem>
                                <IonItem lines="none">
                                    <IonCol size="8">
                                        { ios &&
                                            <IonIcon slot="start" icon={construct} color="medium" />
                                        }
                                        <IonLabel>
                                            <h3>Track Width (Rear)</h3>
                                            <p>Rear Track Width</p>
                                        </IonLabel>
                                    </IonCol>
                                    <IonCol size="4">
                                            <IonInput color="primary" placeholder={`${d.tkr} mm`} onIonChange={e => console.log(e.detail.value)}></IonInput>
                                    </IonCol>
                                </IonItem>
                                <IonItem lines="none">
                                    <IonCol size="8">
                                        { ios &&
                                            <IonIcon slot="start" icon={construct} color="medium" />
                                        }
                                        <IonLabel>
                                            <h3>Chassis Width</h3>
                                            <p>Chassis Width</p>
                                        </IonLabel>
                                    </IonCol>
                                    <IonCol size="4">
                                            <IonInput color="primary" placeholder={`${rd.ow} mm`} onIonChange={e => console.log(e.detail.value)}></IonInput>
                                    </IonCol>
                                </IonItem>
                            </>
                        }
                    </IonList>
                }
                
                {
                    segment === "mass" &&
                    <IonList>

                        <IonListHeader className="ion-padding-bottom">Configure Mass and Axles</IonListHeader>

                        <IonToolbar color="light">
                            <IonText className="ion-padding">Front Axles</IonText>
                            <IonButtons slot="end">
                            {
                                !axle1 &&
                                <IonButton onClick={() => {
                                    setAxle1(true);
                                    setAxle2(false);
                                    setAxle3(false);
                                    setTyres(false);
                                    setCog(false);
                                }}>
                                <IonIcon icon={chevronForward} slot="icon-only" />
                                </IonButton>}
                            {
                                axle1 &&
                                <IonButton onClick={() => setAxle1(false)}>
                                <IonIcon icon={chevronDown} slot="icon-only" />
                                </IonButton>
                            }
                            </IonButtons>
                        </IonToolbar>
                            
                        {
                            axle1 &&
                            <>
                                <IonItem>
                                    <IonCol size="8">
                                        { ios &&
                                            <IonIcon slot="start" icon={construct} color="medium" />
                                        }
                                        <IonLabel>
                                            <h3>Chassis Mass</h3>
                                            <p>Vehicle Mass on the front axle</p>
                                        </IonLabel>
                                    </IonCol>
                                    <IonCol size="4">
                                        <IonInput color="primary" placeholder={`${m.cwf} kg`} onIonChange={e => console.log(e.detail.value)}></IonInput>
                                    </IonCol>
                                </IonItem>
                                <IonItem>
                                    <IonCol size="8">
                                        { ios &&
                                            <IonIcon slot="start" icon={construct} color="medium" />
                                        }
                                        <IonLabel>
                                            <h3>Axle Capacity</h3>
                                            <p>Gross mass rating on front axle</p>
                                        </IonLabel>
                                    </IonCol>
                                    <IonCol size="4">
                                        <IonInput color="primary" placeholder={`${m.gaf} kg`} onIonChange={e => console.log(e.detail.value)}></IonInput>
                                    </IonCol>
                                </IonItem>
                                <IonItem>
                                    <IonCol size="8">
                                        { ios &&
                                            <IonIcon slot="start" icon={construct} color="medium" />
                                        }
                                        <IonLabel>
                                            <h3>Suspension Capacity</h3>
                                            <p>Front suspension capacity of vehicle</p>
                                        </IonLabel>
                                    </IonCol>
                                    <IonCol size="4">
                                        <IonInput color="primary" placeholder={`${m.gaf} kg`} onIonChange={e => console.log(e.detail.value)}></IonInput>
                                    </IonCol>
                                </IonItem>
                                <IonItem>
                                    <IonCol size="8">
                                        { ios &&
                                            <IonIcon slot="start" icon={construct} color="medium" />
                                        }
                                        <IonLabel>
                                            <h3>Tyre Capacity</h3>
                                            <p>Front tyre capacity of vehicle</p>
                                        </IonLabel>
                                    </IonCol>
                                    <IonCol size="4">
                                        <IonInput color="primary" placeholder={`${m.trf} kg`} onIonChange={e => console.log(e.detail.value)}></IonInput>
                                    </IonCol>
                                </IonItem>
                                <IonItem>
                                    <IonCol size="8">
                                        { ios &&
                                            <IonIcon slot="start" icon={construct} color="medium" />
                                        }
                                        <IonLabel>
                                            <h3>Regulation</h3>
                                            <p>Regulation</p>
                                        </IonLabel>
                                    </IonCol>
                                    <IonCol size="4">
                                        <IonInput color="primary" placeholder={`${rm.regSteerAxle} kg`}  onIonChange={e => console.log(e.detail.value)}></IonInput>
                                    </IonCol>
                                </IonItem>
                                <IonItem>
                                    <IonCol size="8">
                                        { ios &&
                                            <IonIcon slot="start" icon={construct} color="medium" />
                                        }
                                        <IonLabel>
                                            <h3>Permissible</h3>
                                            <p>Lowest ratings permitted</p>
                                        </IonLabel>
                                    </IonCol>
                                    <IonCol size="4">
                                        <IonInput color="primary" placeholder={`${rm.permf} kg`} onIonChange={e => console.log(e.detail.value)}></IonInput>
                                    </IonCol>
                                </IonItem>
                            </>
                        }

                        <IonToolbar color="light">
                            <IonText className="ion-padding">Rear Axles</IonText>
                            <IonButtons slot="end">
                            {
                                !axle2 &&
                                <IonButton onClick={() => {
                                    setAxle1(false);
                                    setAxle2(true);
                                    setAxle3(false);
                                    setTyres(false);
                                    setCog(false);
                                }}>
                                <IonIcon icon={chevronForward} slot="icon-only" />
                                </IonButton>}
                            {
                                axle2 &&
                                <IonButton onClick={() => setAxle2(false)}>
                                <IonIcon icon={chevronDown} slot="icon-only" />
                                </IonButton>
                            }
                            </IonButtons>
                        </IonToolbar>
                            
                        {
                            axle2 &&
                            <>
                                <IonItem>
                                    <IonCol size="8">
                                        { ios &&
                                            <IonIcon slot="start" icon={construct} color="medium" />
                                        }
                                        <IonLabel>
                                            <h3>Chassis Mass</h3>
                                            <p>Vehicle Mass on the rear axle</p>
                                        </IonLabel>
                                    </IonCol>
                                    <IonCol size="4">
                                        <IonInput color="primary" placeholder={`${m.cwr} kg`} onIonChange={e => console.log(e.detail.value)}></IonInput>
                                    </IonCol>
                                </IonItem>
                                <IonItem>
                                    <IonCol size="8">
                                        { ios &&
                                            <IonIcon slot="start" icon={construct} color="medium" />
                                        }
                                        <IonLabel>
                                            <h3>Axle Capacity</h3>
                                            <p>Gross mass rating on rear axle</p>
                                        </IonLabel>
                                    </IonCol>
                                    <IonCol size="4">
                                        <IonInput color="primary" placeholder={`${m.gar} kg`} onIonChange={e => console.log(e.detail.value)}></IonInput>
                                    </IonCol>
                                </IonItem>
                                <IonItem>
                                    <IonCol size="8">
                                        { ios &&
                                            <IonIcon slot="start" icon={construct} color="medium" />
                                        }
                                        <IonLabel>
                                            <h3>Suspension Capacity</h3>
                                            <p>Rear suspension capacity of vehicle</p>
                                        </IonLabel>
                                    </IonCol>
                                    <IonCol size="4">
                                        <IonInput color="primary" placeholder={`${m.gar} kg`} onIonChange={e => console.log(e.detail.value)}></IonInput>
                                    </IonCol>
                                </IonItem>
                                <IonItem>
                                    <IonCol size="8">
                                        { ios &&
                                            <IonIcon slot="start" icon={construct} color="medium" />
                                        }
                                        <IonLabel>
                                            <h3>Tyre Capacity</h3>
                                            <p>Rear tyre capacity of vehicle</p>
                                        </IonLabel>
                                    </IonCol>
                                    <IonCol size="4">
                                        <IonInput color="primary" placeholder={`${m.trr} kg`} onIonChange={e => console.log(e.detail.value)}></IonInput>
                                    </IonCol>
                                </IonItem>
                                <IonItem>
                                    <IonCol size="8">
                                        { ios &&
                                            <IonIcon slot="start" icon={construct} color="medium" />
                                        }
                                        <IonLabel>
                                            <h3>Regulation</h3>
                                            <p>Regulation</p>
                                        </IonLabel>
                                    </IonCol>
                                    <IonCol size="4">
                                        <IonInput color="primary" placeholder={`${rm.regNonSteerAxle} kg`} onIonChange={e => console.log(e.detail.value)}></IonInput>
                                    </IonCol>
                                </IonItem>
                                <IonItem>
                                    <IonCol size="8">
                                        { ios &&
                                            <IonIcon slot="start" icon={construct} color="medium" />
                                        }
                                        <IonLabel>
                                            <h3>Permissible</h3>
                                            <p>Lowest ratings permitted</p>
                                        </IonLabel>
                                    </IonCol>
                                    <IonCol size="4">
                                        <IonInput color="primary" placeholder={`${rm.permr} kg`} onIonChange={e => console.log(e.detail.value)}></IonInput>
                                    </IonCol>
                                </IonItem>
                            </>
                        }

                        <IonToolbar color="light">
                            <IonText className="ion-padding">All Axles</IonText>
                            <IonButtons slot="end">
                            {
                                !axle3 &&
                                <IonButton onClick={() => {
                                    setAxle1(false);
                                    setAxle2(false);
                                    setAxle3(true);
                                    setTyres(false);
                                    setCog(false);
                                }}>
                                <IonIcon icon={chevronForward} slot="icon-only" />
                                </IonButton>}
                            {
                                axle3 &&
                                <IonButton onClick={() => setAxle3(false)}>
                                <IonIcon icon={chevronDown} slot="icon-only" />
                                </IonButton>
                            }
                            </IonButtons>
                        </IonToolbar>
                            
                        {
                            axle3 &&
                            <>
                                <IonItem>
                                    <IonCol size="8">
                                        { ios &&
                                            <IonIcon slot="start" icon={construct} color="medium" />
                                        }
                                        <IonLabel>
                                            <h3>Gross Vehicle Mass</h3>
                                            <p>Total Vehicle Mass</p>
                                        </IonLabel>
                                    </IonCol>
                                    <IonCol size="4">
                                        <IonInput color="primary" placeholder={`${m.cwt} kg`} onIonChange={e => console.log(e.detail.value)}></IonInput>
                                    </IonCol>
                                </IonItem>
                                <IonItem>
                                    <IonCol size="8">
                                        { ios &&
                                            <IonIcon slot="start" icon={construct} color="medium" />
                                        }
                                        <IonLabel>
                                            <h3>Regulation</h3>
                                            <p>Regulation</p>
                                        </IonLabel>
                                    </IonCol>
                                    <IonCol size="4">
                                        <IonInput color="primary" placeholder={`${rm.regTotal} kg`} onIonChange={e => console.log(e.detail.value)}></IonInput>
                                    </IonCol>
                                </IonItem>
                                <IonItem>
                                    <IonCol size="8">
                                        { ios &&
                                            <IonIcon slot="start" icon={construct} color="medium" />
                                        }
                                        <IonLabel>
                                            <h3>Permissible</h3>
                                            <p>Lowest ratings permitted</p>
                                        </IonLabel>
                                    </IonCol>
                                    <IonCol size="4">
                                        <IonInput color="primary" placeholder={`${rm.permt} kg`} onIonChange={e => console.log(e.detail.value)}></IonInput>
                                    </IonCol>
                                </IonItem>
                            </>
                        }

                        <IonToolbar color="light">
                            <IonText className="ion-padding">Centre of Gravity</IonText>
                            <IonButtons slot="end">
                            {
                                !cog &&
                                <IonButton onClick={() => {
                                    setAxle1(false);
                                    setAxle2(false);
                                    setAxle3(false);
                                    setTyres(false);
                                    setCog(true);
                                }}>
                                <IonIcon icon={chevronForward} slot="icon-only" />
                                </IonButton>}
                            {
                                cog &&
                                <IonButton onClick={() => setCog(false)}>
                                <IonIcon icon={chevronDown} slot="icon-only" />
                                </IonButton>
                            }
                            </IonButtons>
                        </IonToolbar>
                            
                        {
                            cog &&
                            <>
                                <IonItem>
                                    <IonCol size="8">
                                        { ios &&
                                            <IonIcon slot="start" icon={construct} color="medium" />
                                        }
                                        <IonLabel>
                                            <h3>Horizontal CoG of Truck (mm)</h3>
                                            <p>Horizontal centre of gravity</p>
                                        </IonLabel>
                                    </IonCol>
                                    <IonCol size="4">
                                        <IonInput color="primary" onIonChange={e => console.log(e.detail.value)}>
                                        </IonInput>
                                    </IonCol>
                                </IonItem>
                                <IonItem>
                                    <IonCol size="8">
                                        { ios &&
                                        <IonIcon slot="start" icon={construct} color="medium" />
                                        }
                                        <IonLabel>
                                            <h3>Vertical CoG of Truck (mm)</h3>
                                            <p>Vertical centre of gravity</p>
                                        </IonLabel>
                                    </IonCol>
                                    <IonCol size="4">
                                        <IonInput color="primary" onIonChange={e => console.log(e.detail.value)}>
                                        </IonInput>
                                    </IonCol>
                                </IonItem>
                                <IonItem>
                                    <IonCol size="8">
                                        { ios &&
                                            <IonIcon slot="start" icon={construct} color="medium" />
                                        }
                                        <IonLabel>
                                            <h3>Lateral CoG of Truck (mm)</h3>
                                            <p>Lateral centre of gravity</p>
                                        </IonLabel>
                                    </IonCol>
                                    <IonCol size="4">
                                        <IonInput color="primary" onIonChange={e => console.log(e.detail.value)}>
                                        </IonInput>
                                    </IonCol>
                                </IonItem>
                            </>
                        }

                        <IonToolbar color="light">
                            <IonText className="ion-padding">Tyres</IonText>
                            <IonButtons slot="end">
                            {
                                !tyres &&
                                <IonButton onClick={() => {
                                    setAxle1(false);
                                    setAxle2(false);
                                    setAxle3(false);
                                    setTyres(true);
                                    setCog(false);
                                }}>
                                <IonIcon icon={chevronForward} slot="icon-only" />
                                </IonButton>}
                            {
                                tyres &&
                                <IonButton onClick={() => setTyres(false)}>
                                <IonIcon icon={chevronDown} slot="icon-only" />
                                </IonButton>
                            }
                            </IonButtons>
                        </IonToolbar>
                            
                        {
                            tyres &&
                            <>
                                <IonItem>
                                    <IonCol size="8">
                                        { ios &&
                                            <IonIcon slot="start" icon={construct} color="medium" />
                                        }
                                        <IonLabel>
                                            <h3>No. of Tyres on Front Axle</h3>
                                            <p>No. of Tyres on Axle</p>
                                        </IonLabel>
                                    </IonCol>
                                    <IonCol size="4">
                                        <IonInput color="primary" onIonChange={e => console.log(e.detail.value)}></IonInput>
                                    </IonCol>
                                    <IonCol size="8">
                                        { ios &&
                                            <IonIcon slot="start" icon={construct} color="medium" />
                                        }
                                        <IonLabel>
                                            <h3>Front Tyre Width</h3>
                                            <p>Lowest ratings permitted</p>
                                        </IonLabel>
                                    </IonCol>
                                    <IonCol size="4">
                                        <IonInput color="primary" onIonChange={e => console.log(e.detail.value)}></IonInput>
                                    </IonCol>
                                </IonItem>
                                <IonItem>
                                    <IonCol size="8">
                                        { ios &&
                                            <IonIcon slot="start" icon={construct} color="medium" />
                                        }
                                        <IonLabel>
                                            <h3>No. of Tyres on Rear Axle</h3>
                                            <p>No. of Tyres on Axle</p>
                                        </IonLabel>
                                    </IonCol>
                                    <IonCol size="4">
                                        <IonInput color="primary" onIonChange={e => console.log(e.detail.value)}></IonInput>
                                    </IonCol>
                                    <IonCol size="8">
                                        { ios &&
                                            <IonIcon slot="start" icon={construct} color="medium" />
                                        }
                                        <IonLabel>
                                            <h3>Rear Tyre Width</h3>
                                            <p>Lowest ratings permitted</p>
                                        </IonLabel>
                                    </IonCol>
                                    <IonCol size="4">
                                        <IonInput color="primary"  onIonChange={e => console.log(e.detail.value)}></IonInput>
                                    </IonCol>
                                </IonItem>
                            </>
                        }

                    </IonList>
                }

                {
                    segment === "crew" &&

                    <IonList>
                        <IonListHeader>Configure Crew</IonListHeader>

                        <IonItem>
                            <IonCol size="8">
                                { ios &&
                                    <IonIcon slot="start" icon={construct} color="medium" />
                                }
                                <IonLabel>
                                    <h3>Average mass of crew</h3>
                                    <p>Average mass of each crew member</p>
                                </IonLabel>
                            </IonCol>
                            <IonCol size="4">
                                <IonInput 
                                    color="primary" 
                                    placeholder={fuelAndCrew.crewMass.toString()} 
                                    onIonChange={e => setCrewMass(e.detail.value as unknown as number) }>
                                </IonInput>
                            </IonCol>
                        </IonItem>
                        <IonItem>
                            <IonCol size="8">
                                { ios &&
                                    <IonIcon slot="start" icon={construct} color="medium" />
                                }
                                <IonLabel>
                                    <h3>Number of crew persons</h3>
                                    <p>Total number of crew members</p>
                                </IonLabel>
                            </IonCol>
                            <IonCol size="4">
                                <IonInput 
                                    color="primary" 
                                    placeholder={fuelAndCrew.crewNumber.toString()} 
                                    onIonChange={e => setCrewPersons(e.detail.value as unknown as number)}>
                                </IonInput>
                            </IonCol>
                        </IonItem>
                        <IonItem>
                            <IonCol size="8">
                                { ios &&
                                    <IonIcon slot="start" icon={construct} color="medium" />
                                }
                                <IonLabel>
                                    <h3>Horizontal CoG of Crew (mm)</h3>
                                    <p>Horizontal centre of gravity</p>
                                </IonLabel>
                            </IonCol>
                            <IonCol size="4">
                                <IonInput color="primary" onIonChange={e => console.log(e.detail.value)}>
                                </IonInput>
                            </IonCol>
                        </IonItem>
                        <IonItem>
                            <IonCol size="8">
                                { ios &&
                                    <IonIcon slot="start" icon={construct} color="medium" />
                                }
                                <IonLabel>
                                    <h3>Vertical CoG of Crew (mm)</h3>
                                    <p>Vertical centre of gravity</p>
                                </IonLabel>
                            </IonCol>
                            <IonCol size="4">
                                <IonInput color="primary" onIonChange={e => console.log(e.detail.value)}>
                                </IonInput>
                            </IonCol>
                        </IonItem>
                        <IonItem>
                            <IonCol size="8">
                                { ios &&
                                    <IonIcon slot="start" icon={construct} color="medium" />
                                }
                                <IonLabel>
                                    <h3>Lateral CoG of Crew (mm)</h3>
                                    <p>Lateral centre of gravity</p>
                                </IonLabel>
                            </IonCol>
                            <IonCol size="4">
                                <IonInput color="primary" onIonChange={e => console.log(e.detail.value)}>
                                </IonInput>
                            </IonCol>
                        </IonItem>
        
                    </IonList>
                }

                {
                    segment === "fuel" &&

                    <IonList>

                        <IonListHeader className="ion-padding-bottom">Factory-fitted Fuel Tanks</IonListHeader>

                        <>
                            <IonItem>

                                { ios &&
                                    <IonIcon slot="start" icon={construct} color="medium" />
                                }
                                <IonLabel>
                                    <h3>Fuel Type</h3>
                                    <p>Petrol, Diesel, BioDiesel</p>
                                </IonLabel>

                                <IonSelect placeholder="Diesel" onIonChange={e => {
                                        setFuelType(e.detail.value);
                                        fuelType === "Diesel" ? setFuelDensity(0.85) :
                                        fuelType === 'Petrol' ? setFuelDensity(0.75) :
                                        setFuelDensity(0.88);
                                    }}>

                                    {/* data.map((d: any, index: number) => (

                                        <IonSelectOption key={index} value={d}>
                                        {d}
                                        </IonSelectOption>

                                    )) */}

                                        <IonSelectOption key="Diesel">
                                            Diesel
                                        </IonSelectOption>
                                        <IonSelectOption key="Petrol">
                                            Petrol
                                        </IonSelectOption>
                                        <IonSelectOption key="BioDiesel">
                                            BioDiesel
                                        </IonSelectOption>

                                </IonSelect>
                            </IonItem>

                            <IonItem>
                                <IonCol size="8">
                                    { ios &&
                                        <IonIcon slot="start" icon={construct} color="medium" />
                                    }
                                    <IonLabel>
                                        <h3>Fuel Specific Gravity (kg/L)</h3>
                                        <p>Density of Fuel</p>
                                    </IonLabel>
                                </IonCol>
                                <IonCol size="4">
                                    <IonInput 
                                        color="primary" 
                                        value={`${fuelDensity} Kg/L`} 
                                        disabled/>
                                </IonCol>
                            </IonItem>
                            <IonItem disabled={true}>
                                <IonCol size="8">
                                    { ios &&
                                        <IonIcon slot="start" icon={construct} color="medium" />
                                    }
                                    <IonLabel>
                                        <h3>Fuel Tank</h3>
                                        <p>Capacity of Fuel Tank</p>
                                    </IonLabel>
                                </IonCol>
                                <IonCol size="4">
                                    <IonInput color="primary" value={`${m.ft} L`} onIonChange={e => console.log(e.detail.value)}></IonInput>
                                </IonCol>
                            </IonItem>
                        </>  
                    </IonList>
                }

                {/*<TruckSpecifications listType={segment} show={segment === "dimensions"} values={values} />
                <TruckSpecifications listType={segment} show={segment === "mass"} values={values} />
                <TruckSpecifications listType={segment} show={segment === "fuel"} values={values}/>
                        <TruckSpecifications listType={segment} show={segment === "crew"} values={values} />*/}
        
            </IonContent>
                
         </>    
    )
}

export default CalcEditTruck;