import axios from 'axios';
import { useQuery } from 'react-query';
import * as URL from '../../config';

axios.defaults.headers.get["Content-Type"] = "application/json";

const url = URL.HINO_DEALERSHIPS_FROM_WEBSITE_URL;
const regions = ["Botswana","Namibia", "Swaziland"];

/** Fetch Dealership details from Hino Website */
const useFetchDealerships = () => {

    const { status, data, error, isLoading } = useQuery('hino-dealerships', () => {

        return new Promise<any>( async (resolve) => {
            //const response = await axios.get(url);
            const response = fetch(url).then(res => res.json());
            setTimeout(() => resolve(response), 1000);

            try {
                const r = await axios.get(url);
                const d = r.data;
                console.log('data: ', d);
            } catch (error) {
                console.log('error: ', error);
            }

            
            //const data = response.data;
            //setTimeout(() => resolve(data), 1000);
        } ); // </Promise>
    }); // </useQuery>

    return {status, data, error, isLoading};
};

/** Format Telephone numbers of Dealerships */
const formatTel = (region: string, tel: string) => {

	const replace0 = tel.replace(/[()']+/g, '').replace('0', '+27'); //South Africa
	const replace00 = tel.replace(/[()']+/g, '').replace('00', '+'); //International

    //console.log(tel)

    //Remove Whitespaces;
	return regions.includes(region) ? 
        replace00.substring(0,4).replace(' ', '') + replace00.substring(4,7).replace(' ', '') + replace00.substring(7).replace(' ', '') 
        : 
        replace0.substring(0,5).replace(' ', '') + replace0.substring(5,8).replace(' ', '') + replace0.substring(8).replace(' ', '') ;
}

export { formatTel, useFetchDealerships } ;