import { IonCard, IonCol,  IonLabel, IonRow, IonChip, IonSpinner } from "@ionic/react";
import { CalcValues, Calculator } from "../../services/calculations";
import { TruckSpecifications } from "../../models/Truck";
import { ReactSVG } from "react-svg";
import { useMedia } from "react-use";

interface BridgeProps {
    values: CalcValues;
    view: 'front' | 'rear' | 'side'| 'top';
}

const CnvsBridge: React.FC<BridgeProps> = ({values, view}) => {

    const isWide = useMedia('(min-width: 768px)');
    const { regulations, dimensions, bodyType } = Calculator(values);
    const truck = JSON.parse(values.truck as string) as TruckSpecifications;

    const file = bodyType === 'unknown' ? `index` : `${bodyType.toLowerCase()}`;
    const filepath = `assets/trucks/${truck.series}/${truck.chassisModel}/bridge/${file}.svg`;

    const busfilepath = `assets/trucks/${truck.series}/${truck.chassisModel}/${view}/${file}.png`;

    const isBus = truck.series.toLowerCase().includes('bus');

    return (
        <>
            <IonRow className="ion-justify-content-center">

            { //2-Axles
                dimensions.ras === 0 && 

                <IonCol size="12" className="ion-text-center">
                    <IonRow className="ion-justify-content-center ion-padding">
                        <strong>{`${truck.name} (${truck.chassisModel}) Bridge (A - B)`}</strong>
                    </IonRow>
                    <IonRow className="ion-justify-content-center">
                        <IonChip color={regulations.mass.utilisation < 101 ? "success" : "danger"}>
                            {` Utilisation: ${regulations.mass.utilisation} %`}
                        </IonChip>
                    </IonRow>
                </IonCol>
            }

            { //3-Axles
                dimensions.fas === 0 && dimensions.ras > 0 &&
                
                <IonCol size="12" className="ion-text-center">
                    <IonRow className="ion-justify-content-center ion-padding">
                        <strong>{`${truck.name} (${truck.chassisModel}) Bridge (A - C)`}</strong>
                    </IonRow>
                    <IonRow className="ion-justify-content-center">
                        <IonChip color={regulations.mass.utilisation < 101 ? "success" : "danger"}>
                            {`Utilisation: ${regulations.mass.utilisation} %`}
                        </IonChip>
                    </IonRow>
                </IonCol>
            }

            { //4-Axles
                dimensions.fas > 0 && dimensions.ras > 0 &&

                <IonCol size="12" className="ion-text-center">
                    <IonRow className="ion-justify-content-center ion-padding">
                        <strong>{`${truck.name} (${truck.chassisModel}) Bridge (A - D)`}</strong>
                    </IonRow>
                    <IonRow className="ion-justify-content-center">
                        <IonChip color={regulations.mass.utilisation < 101 ? "success" : "danger"}>
                            {`Utilisation: ${regulations.mass.utilisation} %`}
                        </IonChip>
                    </IonRow>
                </IonCol>
            }
            </IonRow>

            <IonRow className="ion-justify-content-center">
                <IonCard  mode="ios" style={ {width: `${isWide ? '768px' : '360px'}` }}>
                    {
                        isBus ?

                        <img src={busfilepath} alt={truck.series} /> 

                        :

                        <ReactSVG 
                            fallback={() => <IonLabel>Not Found</IonLabel> }
                            loading={() => <IonSpinner color="primary"/>} 
                            src={filepath}/>
                    } 
                </IonCard>
            </IonRow>

            <IonRow className="ion-justify-content-center ion-margin-start ion-margin-end">

                <IonCol size="6" className="ion-padding-start ion-padding-end">
                    <IonRow className="ion-justify-content-center ion-text-center">
                        <strong>Distance</strong>
                    </IonRow>
                    <IonRow className="ion-justify-content-center ion-text-center">
                        <IonChip color="medium">
                            <strong>{`${regulations.mass.distance} mm`}</strong>
                        </IonChip>
                    </IonRow>
                </IonCol>

                <IonCol size="6" className="ion-padding-start ion-padding-end">
                    <IonRow className="ion-justify-content-center ion-text-center">
                        <strong>Rounded</strong>
                    </IonRow>
                    <IonRow className="ion-justify-content-center ion-text-center">
                        <IonChip color="medium">
                            <strong>{`${regulations.mass.rounded} mm`}</strong>
                        </IonChip>
                    </IonRow>
                </IonCol>
                <IonCol size="6" className="ion-padding-start ion-padding-end">
                    <IonRow className="ion-justify-content-center ion-text-center">
                        <strong>Gross</strong>
                    </IonRow>
                    <IonRow className="ion-justify-content-center ion-text-center">
                        <IonChip color="medium">
                            <strong>{`${regulations.mass.gross} kg`}</strong>
                        </IonChip>
                    </IonRow>
                </IonCol>
                <IonCol size="6" className="ion-padding-start ion-padding-end">
                    <IonRow className="ion-justify-content-center ion-text-center">
                        <strong>Permissible</strong>
                    </IonRow>
                    <IonRow className="ion-justify-content-center ion-text-center">
                        <IonChip color="medium">
                            <strong>{`${regulations.mass.permissible} kg`}</strong>
                        </IonChip>
                    </IonRow>
                </IonCol>
                <IonCol size="6" className="ion-padding-start ion-padding-end">
                    <IonRow className="ion-justify-content-center ion-text-center">
                        <strong>Unused Capacity</strong>
                    </IonRow>
                    <IonRow className="ion-justify-content-center ion-text-center">
                        <IonChip color="medium">
                            <strong>{`${regulations.mass.unused} kg`}</strong>
                        </IonChip>
                    </IonRow>
                </IonCol>
            </IonRow>
        </>
    );
}

export default CnvsBridge;