import { useEffect, useState } from "react";
import { IonCol, IonIcon, IonInput, IonItem, IonLabel, IonList, IonListHeader, isPlatform } from "@ionic/react";
import { CalcValues, Calculator } from "../../../services/calculations";
import { construct } from "ionicons/icons";

interface PayloadProps {
    values: CalcValues;
    setValues: (v: CalcValues) => void;
}

const ConfigurePayload: React.FC<PayloadProps> = ({values, setValues}) => {

    const ios = isPlatform('ios');
    const { ...truck } = Calculator(values);

    const [pl, setPl] = useState(truck.regulations.mass.pwt);
    const [cog, setCOG] = useState(truck.dimensions.pcog);

    useEffect(() => {
        const v = { ...values};
        v.payload = {payload: pl, cog: cog};
        setValues(v);
    }, [pl, cog]);

    return (
        <IonList>

            <IonListHeader className="ion-padding-bottom">Configure Payload on Body</IonListHeader>

            <IonItem>

                <IonCol size="8">
                    { ios &&
                        <IonIcon slot="start" icon={construct} color="medium" />
                    }
                    <IonLabel>
                        <h3>Payload (kg)</h3>
                        <p>{`Available: ${truck.regulations.mass.regPayloadT} kg`}</p>

                    </IonLabel>
                </IonCol>

                <IonCol size="4">
                    <IonInput 
                        color="primary" 
                        onIonChange={e => setPl(e.detail.value as unknown as number)} 
                        placeholder={`${pl} kg`}/>
                </IonCol>
                </IonItem>
                <IonItem>

                <IonCol size="8">
                    { ios &&
                        <IonIcon slot="start" icon={construct} color="medium" />
                    }
                    <IonLabel>
                        <h3>Centre of Gravity (%)</h3>
                        <p>{`Metric: ${truck.dimensions.pcog/100*truck.dimensions.ibl} mm`}</p>

                    </IonLabel>
                </IonCol>

                <IonCol size="4">
                    <IonInput 
                        color="primary" 
                        onIonChange={e => setCOG(e.detail.value as unknown as number)}
                        placeholder={`${cog} %`}/>

                </IonCol>
            </IonItem>
        </IonList>
    );
}

export default ConfigurePayload;