import React, { useState } from 'react';
import { IonCard, IonCardHeader, IonRow, IonButton, IonCol, IonIcon, IonModal, IonCardTitle, IonItem, IonText } from '@ionic/react';
import { callOutline, chevronDown, chevronUp, mailOutline, mapOutline } from 'ionicons/icons';
import { Specification } from '../../models/Specification';
import { HinoDealership } from '../../models/Dealership';
import { formatTel } from '../../services/dealerships';
import SubmitToDealer from './SubmitToDealer';
import { GroupedLeadsStore } from '../../services/leads';

import * as ROUTES from '../../config';

import '../../pages/Dealerships.css';



interface DealerItemProps {
  dealer: HinoDealership;
  salesperson: HinoDealership;
  specifications?: Specification[];
}

const DealerItem: React.FC<DealerItemProps> = ({ dealer, salesperson }) => {


    const [showSubmitToDealerModal, setShowSubmitToDealerModal] = useState(false);
    const [showContactDetails, setShowContactDetails] = useState(false);
    GroupedLeadsStore.update(s => {
        s.salesperson = dealer.Name;
       })

    return (
        <>
        <IonCard mode="ios">
            <IonCardHeader className="dealer-card">
            <IonCardTitle color="light">
                    <h2><strong>{dealer.SalesPerson}</strong></h2>
                    <p><strong>{dealer.Name}</strong></p>

                </IonCardTitle>

            </IonCardHeader>
            
                <IonRow className="ion-no-padding justify-content-center">

                    {
                        !showContactDetails &&

                        <>

                            <IonCol size="6" className='ion-justify-content-end'>
                                
                            </IonCol>
                        
                            <IonCol size="6">
                                <IonButton fill="clear" size="small"
                                    onClick={() => setShowContactDetails(!showContactDetails)}>
                                        &nbsp;<strong>Contact Details</strong>&nbsp;
                                    <IonIcon icon={showContactDetails? chevronUp : chevronDown}/>
                                </IonButton>
                            </IonCol>

                            
                        </>
                    }

                    {
                        showContactDetails &&

                        <>

                            <IonCol size="12">

                                <IonItem detail={false} lines="none">
                                    <IonCol size="2">
                                        <IonIcon className="ion-padding-end ion-margin-end" icon={mapOutline} size="large"/>
                                    </IonCol>
                                    <IonCol size="10">
                                        <IonText className='ion-no-padding ion-no-margin'>
                                            <p><strong>{dealer.Address}, {dealer.Region}</strong></p>
                                        </IonText>
                                    </IonCol>
                                </IonItem>
                                <IonItem detail={false} lines="none">
                                    <IonCol size="2">
                                        <IonIcon className="ion-padding-end ion-margin-end" icon={callOutline} size="large"/>
                                    </IonCol>
                                    <IonCol size="10">
                                        <IonText className='ion-no-padding ion-no-margin'>
                                            <a style= { {color: "var(--ion-color-dark)", textDecorationLine: "none"} } href={`tel:${formatTel(dealer.Region, dealer.Telephone)}`}><strong>{dealer.Telephone}</strong></a>
                                        </IonText>
                                    </IonCol>
                                </IonItem>
                                <IonItem detail={false} lines="none">
                                    <IonCol size="2">
                                        <IonIcon className="ion-padding-end ion-margin-end" icon={mailOutline} size="large"/>
                                    </IonCol>
                                    <IonCol size="10">
                                        <IonText onClick={() => setShowSubmitToDealerModal(true)}>
                                            <p><strong>Message</strong></p>
                                        </IonText>
                                    </IonCol>
                                </IonItem>

                            </IonCol>

                            <IonCol size="6">
                                
                            </IonCol>

                            <IonCol size="6" className='ion-justify-content-end'>
                                <IonButton fill="clear" size="small"
                                    onClick={() => setShowContactDetails(!showContactDetails)}>
                                        &nbsp;<strong>Contact Details</strong>
                                    <IonIcon icon={showContactDetails? chevronUp : chevronDown}/>
                                </IonButton>
                            </IonCol>

                        </>
                    }
                </IonRow>

        
        </IonCard>
        <IonModal
            mode="ios"
            isOpen={showSubmitToDealerModal}
            onDidDismiss={() => setShowSubmitToDealerModal(false)}
            swipeToClose={true}> 
                <SubmitToDealer 
                onDismissModal={() => setShowSubmitToDealerModal(false)} 
                route={ROUTES.TABS_DEALERSHIPS_LIST_PAGE_ROUTE}
                values={dealer}/>
            </IonModal>
        </>
    );
};

export default DealerItem;