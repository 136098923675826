import * as AUTH0 from '../auth.config';
import packageInfo from '../../package.json';

/** List of Constants */

/** Development Stages */
export const STAGE = 'alpha'

/** URLS */
export const BASEURL = process.env.REACT_APP_API_GATEWAY || `https://ah07pwfutg.execute-api.af-south-1.amazonaws.com/${STAGE}/hino-app/`;
export const AUTHENTICATE_URL = `https://d1pn8kkxpb.execute-api.eu-west-1.amazonaws.com/${STAGE}/authenticate`;
export const USERS_URL = `https://d1pn8kkxpb.execute-api.eu-west-1.amazonaws.com/${STAGE}/users`;

export const POST_SIGNUP_URL = `https://${AUTH0.domain}/dbconnections/signup`;
export const APPLE_APP_STORE_URL = `https://apps.apple.com/za/app/hino-truck-body-builders-app/id6444159258`;
export const GOOGLE_PLAY_STORE_URL = `https://play.google.com/store/apps/details?id=za.co.turbotics.app.hino&hl=en_ZA&gl=US`;

export const TRUCKS_API_ENDPOINT = 'trucks';
export const PRICES_API_ENDPOINT = 'prices';
export const SPECIFICATIONS_API_ENDPOINT = 'specifications'
export const MESSAGES_API_ENDPOINT = 'messages';
export const DEALERSHIPS_API_ENDPOINT = 'dealerships';
export const BUILDERS_API_ENDPOINT = 'preferred-suppliers';
export const USERS_API_ENDPOINT = 'users';

//export const DEALERSHIPS_FROM_WEBSITE_URL = "https://www.hino.co.za/api/dealers/get";
//export const HINO_DEALERSHIPS_FROM_WEBSITE_URL = "https://api-hino.azure-api.net/suppliers?filter[where][supplierType]=dealer&filter[where][hinoSupplier]=Y";
//export const HINO_MODELS_FROM_WEBSITE_URL = "https://api-hino.azure-api.net/vehicle-models?filter[where][brand]=1";
//export const HINO_SERIES_FROM_WEBSITE_URL = "https://api-hino.azure-api.net/vehicle-ranges?filter[where][brand]=1";
//export const HINO_MODEL_FROM_WEBSITE_URL = "https://api-hino.azure-api.net/vehicle-specifications?filter[where][derivativeCode]"; // =SMC

export const HINO_DEALERSHIPS_FROM_WEBSITE_URL = "https://middleware.hino.co.za/suppliers?filter[where][supplierType]=dealer&filter[where][hinoSupplier]=Y";
export const HINO_MODELS_FROM_WEBSITE_URL = "https://middleware.hino.co.za/vehicle-models?filter[where][brand]=1";
export const HINO_SERIES_FROM_WEBSITE_URL = "https://middleware.hino.co.za/vehicle-ranges?filter[where][brand]=1";
export const HINO_MODEL_FROM_WEBSITE_URL = "https://middleware.hino.co.za/vehicle-specifications?filter[where][derivativeCode]"; // =SMC

export const CHASSIS_SERIES_FILTER_URL = "chasis";
export const CHASSIS_SERIES_FILTER_ENDPOINT = "seriesFilters";

/** URLS: GUIDELINES */
//export const GUIDELINES_BODY_ATTACHMENTS_URL = `/assets/documents/guidelines/142.pdf`;
//export const GUIDELINES_ROH_URL = `/assets/documents/guidelines/roh.pdf`;
//export const GUIDELINES_SUBFRAME_URL = `/assets/documents/guidelines/subframe.pdf`;
export const GUIDELINES_BODY_ATTACHMENTS_URL = "https://hino-documents.s3.af-south-1.amazonaws.com/142.pdf";
export const GUIDELINES_ROH_URL = "https://hino-documents.s3.af-south-1.amazonaws.com/roh.pdf";
export const GUIDELINES_SUBFRAME_URL = "https://hino-documents.s3.af-south-1.amazonaws.com/subframe.pdf";
export const GUIDELINES_ELECTRIC_WELDING_WORK_URL = "https://hino-documents.s3.af-south-1.amazonaws.com/electric-welding-work.pdf";
export const GUIDELINES_CAB_TILTING_URL = "https://hino-documents.s3.af-south-1.amazonaws.com/cab-tilting.pdf";
export const GUIDELINES_EBSVSC_URL = "https://hino-documents.s3.af-south-1.amazonaws.com/ebsvsc.pdf";

export const GUIDELINES_FUSE_BLOCK_URL = "https://hino-documents.s3.af-south-1.amazonaws.com/fusebox.pdf";
export const GUIDELINES_TURN_LAMP_URL = "https://hino-documents.s3.af-south-1.amazonaws.com/turn-lamp.pdf";

/** ROUTES */
export const CALCULATIONS_TABS_ROUTE = "/calculations";
export const GUIDELINES_PAGE_ROUTE = "/design-guidelines";
export const MAIN_TABS_ROUTE = "/tabs";
export const ONBOARDING_PAGE_ROUTE = "/onboarding";
export const SETTINGS_PAGE_ROUTE = "/settings";
export const SETTINGS_LEGAL_PAGE_ROUTE = "/account/legal";
export const SETTINGS_LEGAL_PRIVACY_PAGE_ROUTE = "/account/legal/privacy";
export const SETTINGS_LEGAL_TOS_PAGE_ROUTE = "/account/legal/terms";
export const SETTINGS_LEGAL_TOS_DOCUMENT_PAGE_ROUTE = "/account/legal/terms/document";
export const SETTINGS_PREFERENCES_PAGE_ROUTE = "/account/preferences";
export const SETTINGS_PROFILE_PAGE_ROUTE = "/account/personal-info";
export const SUPPORT_PAGE_ROUTE = "/account/support";
export const ELECTRICAL_SYSTEMS_PAGE_ROUTE = "/design-guidelines/electrical-systems";
export const ELECTRICAL_SYSTEMS_DETAILS_PAGE_ROUTE = "/design-guidelines/electrical-systems/:id";
export const PRECAUTIONS_PAGE_ROUTE = "/design-guidelines/general-precautions";
export const PRECAUTIONS_DETAILS_PAGE_ROUTE = "/design-guidelines/general-precautions/:id";
export const AARTO_REGULATIONS_PAGE_ROUTE = "/design-guidelines/aarto-regulations";
export const AARTO_REGULATIONS_DETAILS_PAGE_ROUTE = "/design-guidelines/aarto-regulations/:id";

export const LOGIN_CODE_ROUTE = "/delete-account/code";
export const LOGIN_DIGITS_ROUTE = "/delete-account";
export const LOGIN_ACCOUNT_DELETED_ROUTE = "/delete-account/successfully-initiated";
export const LOGIN_EMAIL_ROUTE = "/login";
export const LOGOUT_ROUTE = "/logout";

export const CALCULATIONS_DETAIL_PAGE_ROUTE = "/calculations/:id";
export const CALCULATIONS_SAVE_PAGE_ROUTE = "/calculations/:id/save";
export const CALCULATIONS_PDF_PAGE_ROUTE = "/calculations/:id/view";
export const CALCULATIONS_PAGE_ROUTE = "/calculations";
export const HPS_PROFILE_PAGE_ROUTE = "/preferred-suppliers/:id";
export const DEALERSHIPS_SINGLE_PAGE_ROUTE = "/dealerships/:id";
export const LEADS_LIST_PAGE_ROUTE = "/notifications";
export const LEADS_SINGLE_PAGE_ROUTE = "/notifications/:user";
export const LEADS_CHAT_PAGE_ROUTE = "/notifications/:user/chats";
export const TRUCK_LIST_PAGE_ROUTE = "/trucks";
export const TRUCK_DETAIL_PAGE_ROUTE = "/trucks/:id/detail";
export const TRUCK_SINGLE_PAGE_ROUTE = "/trucks/:id";

export const TABS_ACCOUNT_PAGE_ROUTE = "/tabs/account";
export const TABS_CALCULATIONS_PAGE_ROUTE = "/tabs/calculations";
export const TABS_DEALERSHIPS_LIST_PAGE_ROUTE = "/tabs/dealerships";
export const TABS_HPS_LIST_PAGE_ROUTE = "/tabs/preferred-suppliers";
export const TABS_HOME_PAGE_ROUTE = "/tabs/home";
export const TABS_TRUCK_LIST_PAGE_ROUTE = "/tabs/trucks";
export const TABS_LEADS_PAGE_ROUTE = "/tabs/notifications";

//Lottie Animations
export const CONTENT_NOT_FOUND = "https://assets3.lottiefiles.com/packages/lf20_i2rkmcsu.json";
export const CONTENT_NOT_FOUND2 = "https://assets7.lottiefiles.com/packages/lf20_n2m0isqh.json";

export const DOWNLOAD_FILE = '/assets/animations/download-file.json';
export const MESSAGE_SUBMITTED_SUCCESSFULLY = '/assets/animations/message-submitted-successfully.json';
export const FORBIDDEN = '/assets/animations/forbidden.json';

//export const MESSAGE_SUBMITTED_SUCCESSFULLY = "https://assets7.lottiefiles.com/packages/lf20_jbrw3hcz.json"

//Other
export const BUILD_NUMBER = `${new Date().getFullYear()}${new Date().getMonth() + 1 < 10 ? `0${new Date().getMonth() + 1}` : new Date().getMonth() + 1}${packageInfo.version.split('.').join('')}`




