import React, { useState } from 'react';
import { isPlatform, IonToolbar, IonButtons, IonItemDivider, IonItem, IonLabel, IonCol, 
    IonInput, IonButton, IonContent, IonList, IonListHeader,  IonIcon, IonHeader } from '@ionic/react';

import { checkmark, close, construct } from 'ionicons/icons';

import { CalcStore, Calculator, CalcValues, Payload } from '../../../services/calculations';

interface CalcEditTrailerProps {
    onDismissModal: () => void;
    values: CalcValues;
    setValues: (v: CalcValues) => void;
  }
  
const CalcEditTrailer: React.FC<CalcEditTrailerProps> = ({onDismissModal, values, setValues}) => {

    const ios = isPlatform('ios');

    const [payload, setPayload] = useState<string | null | undefined>("");
    const [cog, setCog] = useState<string | null | undefined>("");

    const { mass, dimensions } = Calculator(values);

    return (
        <>
            <IonHeader>
                <IonToolbar color="primary">
                    <IonButtons slot="end">
                        <IonButton 
                            onClick={() => {

                                const pwt = +(payload || mass.rating - mass.uwt);
                                const pcog = +(cog || dimensions.pcog);

                                const pl: Payload = {
                                    payload: pwt,
                                    cog: pcog
                                }

                                const v = { ...values}
                                v.payload = { ...pl};
                                
                                setValues(v);
                                CalcStore.update(s => {s.payload = pl})
                                
                                onDismissModal();
                            }
                            }>Done&nbsp;
                            <IonIcon icon={checkmark} />
                        </IonButton>
                    </IonButtons>
                    <IonButtons slot="start">
                        <IonButton 
                            onClick={onDismissModal}>
                            <IonIcon icon={close} />
                            &nbsp;Cancel
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                
                <IonList>
                    <IonListHeader className="ion-padding">Configure Trailer(s)</IonListHeader>

                    <IonItemDivider>Trailer</IonItemDivider>

                    <IonItem>
                        <IonLabel className="ion-padding"><h1>Coming Soon :)</h1></IonLabel>
                    </IonItem>

                </IonList>
            </IonContent>
        </>    
    )
}

export default CalcEditTrailer;