import { JSXElementConstructor, ReactElement, useState } from 'react';
import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonPage, IonToolbar, isPlatform } from "@ionic/react";
import { close, downloadOutline } from 'ionicons/icons';
import { Filesystem, Directory } from '@capacitor/filesystem';
import { Share } from '@capacitor/share';
import { BlobProvider, PDFDownloadLink } from "@react-pdf/renderer";
import { Document, DocumentProps, Page, pdfjs } from "react-pdf";
//import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';

import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';


interface ViewerProps {
    onDismissModal: () => void;
    docTemplate?: ReactElement<DocumentProps, string | JSXElementConstructor<any>>
}

const options = {
    cMapUrl: 'cmaps/',
    cMapPacked: true,
    standardFontDataUrl: 'standard_fonts/',
  };

//Auto Load pdfjs service worker
//pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

/** Function: For sharing the PDf through other media */

const shareOutsideTheApp = async (pdf: string) => {

    return Filesystem.writeFile({
        path: `${new Date().getTime()}-HinoMobileApp.pdf`,
        data: `data:application/pdf;base64,${pdf}`,
        directory: Directory.Cache
      })
        .then(() => {
          return Filesystem.getUri({
            directory: Directory.Cache,
            path: `${new Date().getTime()}-HinoMobileApp.pdf`
          });
        })
        .then((uriResult) => {
          return Share.share({
            title: `Hino Truck Body Builders App`,
            text: `Check out latest body specifications for Hino Trucks`,
            url: uriResult.uri,
        });
    });

}


const PDFViewer: React.FC<ViewerProps> = ({onDismissModal, docTemplate}) => {

    const ios = isPlatform('ios');

    const [pdfString, setPdfString] = useState('');
    const [numPages, setNumPages] = useState(null);
  
    const onDocumentLoadSuccess = ({numPages: nextNumPages} : any ) => {
      setNumPages(nextNumPages);
    }

    const filename = `hino-mobile-app-${new Date().getTime()}-${window.crypto.getRandomValues(new Uint32Array(1))[0].toString(16)}.pdf`;

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="primary">
                    <IonButtons slot="start">
                        <IonButton slot="start" onClick={() => onDismissModal()}><IonIcon icon={close}/></IonButton>
                    </IonButtons>
                    <IonButtons slot="end">
                        {/*<IonButton slot="start"><IonIcon size="large" icon={add}/></IonButton>
                        <IonButton slot="start"><IonIcon size="large" icon={remove}/></IonButton>
                        <IonButton slot="start" onClick={() => shareOutsideTheApp(pdfString)}><IonIcon size="large" icon={shareSocialOutline}/></IonButton>*/}

                        {
                            !ios &&

                            <IonButton slot="end">
                                <PDFDownloadLink document={docTemplate as ReactElement} fileName={filename}>
                                { ({ loading }) => loading ? 'Loading document...' : <IonIcon size="large" style={{color: "white"}} icon={downloadOutline}/> }
                                </PDFDownloadLink>
                            </IonButton>
                        }

                    </IonButtons>
                      
                </IonToolbar>

            </IonHeader>
            <IonContent className="container">
                
                <BlobProvider document={docTemplate as ReactElement}>

                    {({ blob, loading, error }) => {

                    if (loading) return <div>Loading Document...</div>;
                    if (error) return <div>Failed to load document...</div>

                    if (blob === null) return <div>null</div>

                    if (blob !== null) {

                        let base64String, reader = new FileReader();

                        reader.readAsDataURL(blob);
                        reader.onloadend = () => {
                        base64String = reader.result;
                        setPdfString((base64String as string).substr((base64String as string).indexOf(',') + 1));
                        }
                    
                        return (
                        <div className="container__document">
                            <Document file={`data:application/pdf;base64,${pdfString}`} onLoadSuccess={onDocumentLoadSuccess} onLoadError={(e) => console.log(e)} options={options}>
                            {Array.from(new Array(numPages), (el, index) => (
                                <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                            ))}
                            </Document>
                        </div>
                        );
                    }
                    }
                }
                </BlobProvider>

            </IonContent>

        </IonPage>
    );

}

export default PDFViewer;