import { useEffect, useReducer } from 'react';
import { IonItem, IonCol, IonIcon, IonLabel, IonInput, isPlatform } from '@ionic/react';
import { construct } from 'ionicons/icons';
import { BodyLength } from '../../../../../../models/Body';
import { Calculator, CalcValues, lReducer } from '../../../../../../services/calculations';

interface LengthProps {
    title: string;
    description: string;
    values: CalcValues;
    setValues: (v: CalcValues) => void;
}

const Internal: React.FC<LengthProps> = ({title, description, values, setValues}) => {

    const ios = isPlatform('ios');
    const { ...truck } = Calculator(values);
    const [state, dispatcher] = useReducer(lReducer, new BodyLength(values));

    useEffect(() => {
        const v = { ...values};
        v.body!.length = state;
        setValues(v);

    }, [state]);

    return (

        <IonItem>
            <IonCol size="8">
                { ios &&
                    <IonIcon slot="start" icon={construct} color="medium" />
                }
                <IonLabel>
                    <h3>{title}</h3>
                    <p>{description}</p>
                </IonLabel>
            </IonCol>
            <IonCol size="8">
                <IonInput color="primary" placeholder={`${truck.dimensions.ibl} mm`} onIonChange={e => {
                    const l = e.detail.value as string;
                    dispatcher({type: 'LENGTH_INTERNAL', payload: { ...state, bodyLengthInternal: l} });
                }}>
                </IonInput>
            </IonCol>
        </IonItem>
    );
}

export default Internal;