import React, { useEffect, useState } from 'react';
import { IonToolbar, IonButtons, IonItemDivider, IonItem, IonButton, IonContent, IonList, 
    IonListHeader,  IonIcon, IonHeader,  IonLabel, IonTextarea, IonSearchbar, IonRow, IonLoading, IonCol, IonRadio, IonImg, IonCard, IonCardContent, IonSpinner } from '@ionic/react';
import { checkmark, close, searchOutline } from 'ionicons/icons';
import { useHistory } from 'react-router';
import { useMutation, useQueryClient } from 'react-query';
import { useStoreState } from 'pullstate';

import SearchInput from '../SearchInput';
import { encryptAES, getUser, UserPreferences } from '../../services/user';
import { CalcStore, CalcValues } from '../../services/calculations';
import { LeadStatus } from '../../services/leads';
import { listOfBodies } from '../calculations/EditBody';
import { HinoDealership } from '../../models/Dealership';
import { postMessagesToDB } from '../../services/DataApi';
import { useFetchDataFromDB } from '../../services/DataApi';
import { Calculation } from '../../models/Calculation';
import { useDefaultTrucks } from '../../services/trucks';
import { TruckSpecifications } from '../../models/Truck';
import { HinoPreferredSupplier } from '../../models/Builder';
import { Message } from '../../models/Lead';

import * as ROUTES from '../../config';
import { ReactSVG } from 'react-svg';


interface SubmitToBuilderProps {
    onDismissModal: () => void;
    route: string;
    values?: HinoDealership | TruckSpecifications | HinoPreferredSupplier | Calculation | {id: null | string} | null;
    notes?: string;
    application?: string;
  }

const SubmitToBuilder: React.FC<SubmitToBuilderProps> = ({onDismissModal, route, values, notes, application}) => {

    const history = useHistory();
    const user = useStoreState(UserPreferences, getUser);
    const userid = user.userid;

    const { data: calculations } = useFetchDataFromDB("specifications", ROUTES.SPECIFICATIONS_API_ENDPOINT);
    const { data: defaultTrucks, isLoading: isLoadingTrucks } = useDefaultTrucks(null);
    const { data: builders, isLoading: isLoadingBuilders } = useFetchDataFromDB("preferred-suppliers", ROUTES.BUILDERS_API_ENDPOINT);


    let trucks: TruckSpecifications[] = defaultTrucks;

    const [showBuilders, setShowBuilders] = useState(false);
    const [showTrucks, setShowTrucks] = useState(false);
    const [showBodies, setShowBodies] = useState(false);

    const [query, setQuery] = useState<string>("");

    const [message, setMessage] = useState<'success' | 'error' | null>(null);
    const [_notes, setNotes] = useState<string | null | undefined>(notes ?? null);
    const [name, setName] = useState<string | null | undefined>((values as HinoPreferredSupplier)?.Name || null);
    const [body, setBody] = useState<string | null | undefined>(application ?? null);
    const [bodyValue, setBodyValue] = useState<string | null | undefined>(application?.split(" ")[0] ?? null);
    const [truck, setTruck] = useState<TruckSpecifications | null>(route.includes('trucks') ? (values as TruckSpecifications) : null);
    const [builderID, setBuilderID] = useState<string | null | undefined>((values as HinoPreferredSupplier)?.id || null);

    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation(postMessagesToDB, {
        onSuccess: () => {
            //console.log(data);
            setCalculation("");
            setCalculationID("");
            setMessage("success")
          },
          onError: () => {
            setMessage("error")
          },
          onSettled: () => {
            queryClient.invalidateQueries('messages');
          }
    });

    const [calculation, setCalculation] = useState<string | Calculation | null | undefined>(route.includes('calculations') ? (values as Calculation) : null);
    const [calculationID, setCalculationID] = useState(CalcStore.useState((s: CalcValues) => s.calcID));

    //Use calcID to find the calculation from a list of calculations
    useEffect(() => {

        if ((calculation === undefined || calculation === null) && calculationID !== "" && calculations) {
            const c = (calculations as Array<Calculation>).filter(c => c.calculationID === calculationID);
            setCalculation(c[0]);
        }
    }, [calculationID, calculation, calculations]);

    return (
        <>
            <IonHeader>
                <IonToolbar color="primary">
                   <IonButtons slot="end">
                        <IonButton 
                            disabled={isLoading || message === "success" || name === null || (application === undefined ? (truck === null || body === null) : false)}
                            onClick={() => {

                                const l: Message = {
                                    id: null,
                                    preferred_supplier: builderID!,
                                    builder_name: name!,
                                    salesperson: user.fullname,
                                    dealership: `${userid}`,
                                    specification_id: calculation === null ? null : (calculation as Calculation).id,
                                    message_notes: encryptAES(_notes || `Please add specifications for ${truck?.chassisModel} ${body !== null && body !== undefined ? `with ${body}` : ""}`, userid),
                                    truck_id: truck?.chassisModel || null,
                                    specification: encryptAES(JSON.stringify(null), userid),
                                    body_type: bodyValue || null,
                                    date_submitted: new Date().toLocaleString( 'en-ZA', { timeZoneName: 'short' } ),
                                    date_last_updated: new Date().toLocaleString( 'en-ZA', { timeZoneName: 'short' } ),
                                    message_status: ("Pending" as unknown as LeadStatus),
                                    userid: userid
                                }

                                mutate(l);
                            }
                            }>Submit&nbsp;
                            <IonIcon icon={checkmark} />
                        </IonButton>
                        </IonButtons>
                    <IonButtons slot="start">
                        <IonButton 
                            disabled={message === "success"}
                            onClick={() => {
                                onDismissModal();
                                history.push(route);
                                }}>
                            <IonIcon icon={close} />
                            &nbsp;Cancel
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                
                <IonList>

                    <IonListHeader className="ion-padding">Request Specifications</IonListHeader>

                    {
                        message === null &&

                        <>

                            {
                                !showTrucks && !showBuilders && !showBodies &&

                                <IonItem color={name === null ? "danger" : ""} onClick={() => setShowBuilders(true)}>
                                    <IonLabel>
                                        <h2><strong>{name === null ? 'Hino Preferred Supplier' : name}</strong></h2>
                                        <p>{name === null ? 'Select a Hino Preferred Supplier' : `Your Request will be sent to ${name}`}</p>
                                    </IonLabel>
                                    <IonIcon slot="end" size="large" icon={searchOutline}/>
                                </IonItem>
                            }

                            {
                                isLoadingTrucks &&

                                <IonItem color="warning">
                                    <IonLabel>
                                        <h2><strong>Loading Trucks</strong></h2>
                                        <p>Please wait...</p>
                                    </IonLabel>
                                    <IonIcon slot="end" size="large" icon={searchOutline}/>
                                </IonItem>

                            }

                            {
                                !showTrucks && !showBuilders && !showBodies && !isLoadingTrucks && application === undefined &&

                                <>
                                    
                                    <IonItem color={truck !== null ? ((truck as TruckSpecifications)?.id !== "" ? "" : "warning") : "danger"}onClick={() => setShowTrucks(true)}>
                                        <IonLabel>
                                            <h2><strong>{truck === null ? 'Hino Truck' : (truck as TruckSpecifications)?.name}</strong></h2>
                                            <p>{truck === null ? 'Select Truck' : `Truck Selected: ${(truck as TruckSpecifications)?.name}`}</p>
                                        </IonLabel>
                                        <IonIcon slot="end" size="large" icon={searchOutline}/>
                                    </IonItem>

                                </>
                            }

                        </>
                    }

                    {
                        message === null && !isLoading && showBuilders && !isLoadingBuilders &&

                        <IonList>

                            <IonItemDivider mode="ios" color="primary" sticky>
                                <SearchInput onChangeSearchQuery={(query) => setQuery(query)} color="light"/>
                                <IonIcon onClick={() => setShowBuilders(false)} slot="end" size="large" icon={close} />
                            </IonItemDivider>

                            {(builders as unknown as Array<HinoPreferredSupplier>).map((d: any, index) => (

                                <IonItem key={`${d.id}-${index}`} onClick={() => {
                                    setName(d.Name);
                                    setBuilderID(d.id);
                                    setShowBuilders(false);
                                    }}> {`${d.Name} (${d.PreferredSupplier})`}
                                </IonItem> 
                            ))} 

                        </IonList>
                    }

                    {
                        message === null && !isLoading && showTrucks &&

                        <>
                            
                            { isLoadingTrucks && <IonItem><strong>Loading Trucks...</strong></IonItem> }

                            {
                                !isLoadingTrucks &&

                                <IonList>

                                    <IonItemDivider mode="ios" color="primary" sticky>
                                        <IonSearchbar color="light" placeholder="Search for a Hino Truck"></IonSearchbar>
                                        <IonIcon onClick={() => setShowTrucks(false)} slot="end" size="large" icon={close} />
                                    </IonItemDivider>

                                    {
                                        trucks.sort((a, b) => {
                                            if (a.name > b.name) {
                                                return 1;
                                            } else if (a.name < b.name) {
                                                return -1;
                                            } else {
                                                return 0;
                                            }
                                        })
                                        
                                        && trucks.filter(t => t.series !== 'busco').filter(t => t.series !== '500').filter(t => t.application.toLowerCase() !== 'semi-tractor').map((t: TruckSpecifications, index: number) => (

                                        <IonItem key={index} onClick={() => {
                                            setTruck(t);
                                            setShowTrucks(false);
                                            }}> {t.name}
                                        </IonItem> 
                                        ))} 
                            
                                </IonList>
                            }
                        </>  
                    }

                    {
                        message === null && !isLoading && showBodies &&

                        <>
                            <IonList>

                                <IonItemDivider mode="ios" color="primary" sticky>
                                    <IonSearchbar color="light" placeholder="Search for Bodies / Trailers"></IonSearchbar>
                                    <IonIcon onClick={() => setShowBodies(false)} slot="end" size="large" icon={close} />
                                </IonItemDivider>

                                <IonRow className="ion-no-padding ion-justify-content-center">

                                    {
                                        listOfBodies.map ((b, index) => 
                            
                                        <IonCol size="6" key={index}>
                                            <IonItem detail={false}>
                                                <IonCol size="6">
                                                    <IonRow className="ion-padding-bottom"><IonLabel>{b.name}</IonLabel></IonRow>
                                                    <IonRow>
                                                        <IonImg src={b.image} />
                                                        <IonRadio 
                                                            value={b.value} 
                                                            onClick={() => {
                                                                setBody(b.name);
                                                                setBodyValue(b.value);
                                                                setShowBodies(false);
                                                            }} 
                                                            disabled={b.value === 'tipper' || b.value === 'rollback' || b.value === 'beavertail' || b.value === 'timber'}
                                                            ></IonRadio>
                                                    </IonRow>
                                                </IonCol>  
                                            </IonItem>
                                        </IonCol>
                                    )}; 
                                </IonRow>
                                
                            </IonList>
                            
                        </>  
                    }

                    {
                        !showTrucks && !showBuilders && !showBodies && !isLoading && message === null &&

                        <IonList>
                            <IonItem color={application === undefined ? (body === null ? "danger" : "") : ''} onClick={() => setShowBodies(true)} disabled={application === undefined ? (truck === null ? true : false) : true}>
                                <IonLabel>
                                    {/*<h2><strong>{body === null ? 'Body and /or Trailer(s)' : body}</strong></h2>
                                    <p>{body === null ? 'Select Body and/or Trailer(s)' : `You selected ${body}`}</p>*/}
                                    <h2><strong>{bodyValue === null ? 'Body and /or Trailer(s)' : listOfBodies.filter(b => b.value === bodyValue?.toLowerCase()).map(b => b.name)}</strong></h2>
                                    <p>{bodyValue === null ? 'Select Body and/or Trailer(s)' : `You selected ${listOfBodies.filter(b => b.value === bodyValue?.toLowerCase()).map(b => b.name)}`}</p>
                                </IonLabel>
                                <IonIcon slot="end" size="large" icon={searchOutline}/>
                            </IonItem>

                            
                            {
                                body !== null && truck !== null &&
                                
                                <>
                                    <IonItem>
                                        <IonLabel>
                                            <h2><strong>Summary of Truck and Body Selected</strong></h2>
                                        </IonLabel>
                                    </IonItem>
                                    
                                    <IonCard mode="ios">
                                        <IonCardContent>
                                            <ReactSVG 
                                                fallback={() => <IonLabel>Not Found</IonLabel> }
                                                loading={() => <IonSpinner color="primary"/>} 
                                                src={`assets/trucks/${(truck as TruckSpecifications).series}/${(truck as TruckSpecifications).chassisModel}/bridge/${bodyValue === null ? 'index' : bodyValue?.toLowerCase()}.svg`}/> 
                                        </IonCardContent>
                                    </IonCard>
                                    
                                </>
                            }
                    
                            <IonItem>
                                <IonLabel>
                                    <h2><strong>Notes</strong></h2>
                                </IonLabel>
                            <IonTextarea 
                                rows={6}
                                spellcheck={true}
                                autoCapitalize='sentences'
                                autoGrow={true}
                                style={ { borderLeft: '2px solid var(--ion-color-primary)', paddingLeft: '10px' }}
                                onIonChange={e => setNotes(e.detail.value)}
                                placeholder={_notes ?? `Brief Notes to ${name === null ? "a Hino Preferred Supplier" : name}`}/>
                            </IonItem>
                        </IonList>
                    }

                    {
                        message === "success" &&

                        <IonList className="ion-padding">
                            <IonItem>Your specifications were submitted successfully to {name}</IonItem>
                            
                            <IonRow className="ion-padding ion-justify-content-center">
                                <IonCol size="12">
                                    <lottie-player src={ROUTES.MESSAGE_SUBMITTED_SUCCESSFULLY} mode="bounce" background="transparent" speed="0.8" loop autoplay></lottie-player>
                                </IonCol>
                                <IonCol size="6" className="ion-justify-content-center ion-text-center">
                                    <IonButton fill="outline" onClick={() => {
                                        setCalculation(null);
                                        setName(null);
                                        setMessage(null);
                                    }}>Another Request</IonButton>
                                </IonCol>
                               <IonCol size="6" className="ion-justify-content-center ion-text-center">
                                <IonButton fill="solid" onClick={()=> {
                                        onDismissModal();
                                        history.replace(route);
                                    }}>No, I'm Done</IonButton>
                               </IonCol>
                                {/*<IonButton fill="solid" onClick={()=> onDismissModal()}>Close</IonButton>*/}
                                </IonRow>
                        </IonList>
                    }

                </IonList>

                <IonLoading
                    //cssClass='my-custom-class'
                    isOpen={isLoading}
                    message={`Submitting your your request to ${name}. Please wait...`}
                />
                
            </IonContent>
        </>    
    )
}

export default SubmitToBuilder;