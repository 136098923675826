import React from 'react';
import { IonCol, IonContent, IonGrid, IonPage, IonProgressBar, IonRow, IonText } from '@ionic/react';

import './Login.css';

interface SplashScreenProps {
	text: string;
	progress: number;
}


const SplashScreen : React.FC<SplashScreenProps> = ({text, progress}) => {
	
	return (

		<IonPage>
			<IonContent fullscreen className="ion-padding">

				<IonGrid>

					<IonRow className="header">
						<IonCol className="header-logo-img">
							<IonCol className="header-logo">
								<IonCol className="header-logo-text"></IonCol>
								<IonCol className="offline-img" style={ {visibility: 'hidden'} }></IonCol>
							</IonCol>
						</IonCol>
						<IonCol className="header-img"></IonCol>
					</IonRow>

					<IonRow className="login">
						<IonText className="login-title"><strong>Please wait ...</strong></IonText>
						<IonText className="login-subtitle-black"><h5><strong>{text}</strong></h5></IonText>
					</IonRow>
					<IonRow className="ion-text-center ion-justify-content-center footer">
						<IonProgressBar style={ {fontWeight: "900px"} } color='primary' value={progress/100} />
					</IonRow>

				</IonGrid>

			</IonContent>
		</IonPage>

	)
}

export default SplashScreen;