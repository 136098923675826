import React, { useCallback, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { IonPage, IonInput, IonText, IonButton, IonContent,  IonGrid, IonCol, IonRow, IonItem } from '@ionic/react';
import { Buffer } from 'buffer';
import { Link } from 'react-router-dom';

import useAxios from '../../services/auth/useAxios';

import * as URL from '../../config';

import './Login.css';


const url = URL.AUTHENTICATE_URL;

const LoginEmail: React.FC<RouteComponentProps> = ({ history }) => {
    
    const [email, setEmail] = useState("");
    const [online, postData, response, error, loading] = useAxios(url);

    //const msg: string = "There was an error connecting to the service. Contact administrators";

    //Post data to api via axios
    const login = useCallback(() => {

        //Remove whitespace from email input and change email to lower cases
        setEmail(email.trim().toLocaleLowerCase());

        if (email.length > 5) {
            postData({ "stage": "sendcode", "emailAddress": email });
        }

        return;

    }, [postData, email]);

    useEffect(() => {

        let isMounted = true; //Needed because postData is async

        //Verify response !== null
        if (response !== null && isMounted) {
            sessionStorage.setItem(Buffer.from("email").toString('base64'), Buffer.from(`${email}`).toString('base64'));
            history.replace(URL.LOGIN_CODE_ROUTE);
        }
        
        
        
        return () => {
            isMounted = false;
        }
  
    }, [postData, response, email, online, history, error]);


    return (

        <IonPage>

            <IonContent fullscreen className="ion-padding">

                <IonGrid>
                <IonRow className="header">
                        <IonCol className="header-logo-img">
                            <IonCol className="header-logo">
                                <IonCol className="header-logo-text"></IonCol>
                            </IonCol>
                        </IonCol>
                        <IonCol className="header-img"></IonCol>
                    </IonRow>

                    <IonRow className="login">
                        <IonText className="login-title"><strong>Delete Account</strong></IonText>

                        {
                            online && 
                            <IonText className="login-subtitle-black"><h5>Please enter your email to continue</h5></IonText>
                        }
                        
                        {
                            !online && 
                            <IonText className="login-subtitle-black"><h5>Please connect to a network or wifi to login</h5></IonText>
                        }

                    {
                        !error &&
                        <>
                            <IonText className="login-email-title"><h5>Email</h5></IonText> 

                            <IonInput
                                className="login-email"
                                type="email" 
                                pattern="email" 
                                name="email" 
                                placeholder="Enter your email address"
                                onIonChange={e => setEmail((e.target as HTMLInputElement).value)}/>
                    
                            <IonItem className="login-underline"></IonItem>
                        </>
                    }

                    {
                        error &&
                        <>
                            <IonText className="login-email-title-error">Email</IonText> 
                            <IonInput
                                className="login-email-error"
                                type="email" 
                                pattern="email" 
                                name="email" 
                                placeholder="Enter your email address"
                                onIonChange={e => setEmail((e.target as HTMLInputElement).value)}/>
                    
                            <IonItem lines="none" className="login-underline-error"></IonItem>
                            <IonText color="primary" className="login-text-error">The email you entered is incorrect</IonText> 
                        </>
                    }

                    </IonRow>

                    <IonRow className="ion-text-center ion-justify-content-center ion-no-margin ion-no-padding footer-button">

                    { 
                        !loading && online &&
                        
                            
                            <IonRow className="ion-text-center ion-justify-content-center footer">
                                <IonButton mode="ios" className="login-button" onClick={login} >Continue</IonButton>
                                <IonText className="ion-margin-top ion-padding-top">
                                    <p><Link to={URL.SETTINGS_LEGAL_PRIVACY_PAGE_ROUTE}>Cancel</Link></p>
                                </IonText>
                            </IonRow>
                        
                    }

                    { 
                        loading &&
                        <IonButton mode="ios" className="login-button" disabled>Please Wait...</IonButton>
                    }

                    {
                        !online &&
                        <IonButton mode="ios" className="ios login-button" disabled>No Internet Connection</IonButton> 
                    }

                    </IonRow>

                </IonGrid>

            </IonContent>
        </IonPage>
    )
}

export default LoginEmail;
