import React, { useState, useEffect, useRef } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { 
    isPlatform, IonPage, IonHeader, IonToolbar, IonButton, IonButtons, IonContent, IonTitle, IonIcon, IonChip, 
    IonItemDivider, IonListHeader, IonList, IonItemGroup, IonLabel, IonItem, IonGrid, IonRow, IonCol, IonModal, IonFab, IonFabButton } from '@ionic/react';

import { options, close, arrowDown, arrowUp, filter,  swapHorizontalOutline } from 'ionicons/icons';
import Skeleton from 'react-loading-skeleton';
import { useStoreState } from 'pullstate';
import { getUser, UserPreferences } from '../../services/user';

import TruckList from '../../components/Trucks/TruckList';
import { useDefaultTrucks } from '../../services/trucks';
import TruckListItem from '../../components/Trucks/TruckListItem';
import TruckListFilter, { applicationArray } from '../../components/Trucks/TruckListFilter';
import { TruckSpecifications } from '../../models/Truck';
import SearchInput from '../../components/SearchInput';

import {ISorter, IFilter, genericSearch, genericSort, genericFilter } from '../../utils';

import * as ROUTES from '../../config';
import 'react-loading-skeleton/dist/skeleton.css';
import './calc.css';


/** NewCalcTruckList Component */
const CalcTruckList: React.FC = () => {

  const pageRef = useRef<HTMLElement>(null);
  const ios = isPlatform('ios');
  const user = useStoreState(UserPreferences, getUser);
  //const isDealership = user.role.includes('Hino Dealership');
  //const isBodyBuilder = user.role.includes('Hino Preferred Supplier');

  /** UI */
  const [segment, setSegment] = useState<'all' | 'saved'>('all');
  const [showFilter, setShowFilter] = useState(false);
  const [showApplication, setShowApplication] = useState(false);
  const [showAxleLayouts, setShowAxleLayouts] = useState(false);
  const [showPayload, setShowPayload] = useState(false);
  const [showSort, setShowSort] = useState(false);
  const [showTruckListFilter, setShowTruckListFilter] = useState(false);

  /** Search, Filter and Sort */
  const [query, setQuery] = useState<string>("");
  const [filterProperty, setFilterProperty] = useState<keyof TruckSpecifications>("driveSystem");
  const [sortProperty, setSortProperty] = useState<keyof TruckSpecifications>("chassisModel");
  const [activeSorter, setActiveSorter] = useState<ISorter<TruckSpecifications>>({
    property: sortProperty,
    isDescending: false,
  });
  const [activeFilters, setActiveFilters] = useState<Array<IFilter<TruckSpecifications>>>(
    []
  );
  const [showAZ, setShowAZ] = useState(activeSorter.isDescending);
  const [filterChecked, setFilterChecked] = useState(false);
  const [isTruthyPicked, setIsTruthyPicked] = useState(true);


  /** Fetch Trucks from the DB */
  const urlParams = useLocation().search;
  const [series, setSeries] = useState(new URLSearchParams(urlParams).get('series'));
  const [from, setFrom] = useState(new URLSearchParams(urlParams).get('from') || ROUTES.TABS_HOME_PAGE_ROUTE);

  /** List of Applications */
  //const app: Array<string> = localStorage.getItem(`${series}-series-application`)!.replace(/["']/g, "").split(",");
  const [application, setApplication] = useState('');

  const { defaultTrucks: defaultTrucksFromDB, isLoading: isLoadingDefaultTrucks, error } = useDefaultTrucks(series);

  let _defaultTrucks: TruckSpecifications[] = [];
  defaultTrucksFromDB.series.forEach((series) => _defaultTrucks = series.models);

  const [defaultTrucks, setDefaultTrucks] = useState<Array<TruckSpecifications>>(_defaultTrucks);
  const app = applicationArray(defaultTrucksFromDB, series || "").replace(/["']/g, "").split(",");

  useEffect(() => {

    if (!isLoadingDefaultTrucks) {

      const t = _defaultTrucks.filter((models) => 
        genericSearch<TruckSpecifications>(models, ["model", "chassisModel", "GVM", "driveSystem", "wheelBase", "description", "application"], query))
        //.filter((models) => genericFilter<TruckSpecifications>(models, activeFilters))
        .sort((modelA, modelB) => genericSort<TruckSpecifications>(modelA, modelB, activeSorter))

      setDefaultTrucks(t);

    }
    
  }, [series, query, activeSorter]);

  useEffect(() => {
    setActiveSorter({
      property: sortProperty,
      isDescending: showAZ
    })
  }, [showAZ, sortProperty]);

  useEffect(() => {
    //checked
            //? 
            setActiveFilters([
                ...activeFilters.filter(
                  (filter) => filter.property !== filterProperty
                ),
                { property: filterProperty, isTruthyPicked },
              ])
            //: setActiveFilters(
               // activeFilters.filter(
                  //(filter) => filter.property !== changedFilterProperty
                //)
              //);
  }, [isTruthyPicked, filterProperty])

  useEffect(() => {
    if (from == undefined) setFrom(ROUTES.TABS_HOME_PAGE_ROUTE)
  }, [from])


  let ionLoadingItems: any[] = [];
  
  for (let i = 0; i < 30; i++) {

    ionLoadingItems.push(
      <IonItemGroup key={i}>
        <IonItemDivider sticky>
            <IonLabel>
                {<Skeleton/>}
            </IonLabel>
        </IonItemDivider>

        <IonCol size="12" sizeMd="6" sizeLg="4" sizeXl="4">
            <IonItem className="truck-list-item">
                <IonLabel>
                <h4>{<Skeleton/>}</h4>
                <p>{<Skeleton count={3}/>}</p>
                </IonLabel>
            </IonItem>
        </IonCol>
   
      </IonItemGroup>
    )
  }

  if (series === null) {
		return (
			<Redirect to={from} />
		);
	}

  return (

    <IonPage ref={pageRef} id="truck-list-page">
      <IonHeader>

        <IonToolbar mode="ios" color="toolbar">

          <IonButtons slot="start">
            <IonButton routerLink={from} routerDirection="back">
              <IonIcon icon={close} /></IonButton>
          </IonButtons>
          
          <IonTitle><strong>Select a Model</strong></IonTitle>

        </IonToolbar>

        <IonToolbar mode="ios" className="ion-padding-top">
          <SearchInput onChangeSearchQuery={(query) => setQuery(query)}/> 
          <IonButtons slot="end">
            {
              !showFilter &&
              <IonButton color="dark" onClick={() => setShowFilter(true)}>
              {ios ? 'Filter' : <IonIcon icon={options} slot="icon-only" />}
            </IonButton>}
            {
              showFilter &&
              <IonButton color="dark" onClick={() => setShowFilter(false)}>
                {ios ? 'Close' : <IonIcon icon={close} slot="icon-only" />}
              </IonButton>
            
            }
          </IonButtons>
        </IonToolbar>

        {
          showFilter &&

          <IonToolbar mode="ios">
            <IonItemDivider color="light">
              <IonButton 
                slot="end"
                color={showSort ? "medium" : "dark"}
                disabled={showSort}
                onClick={() => {
                  setShowApplication(false);
                  setShowAxleLayouts(false);
                  setShowPayload(false);
                  setShowSort(true);
                }}>{ios ? 'Sort' : <IonIcon icon={filter} slot="icon-only" />}
              </IonButton>
            </IonItemDivider>

            <IonItemDivider color="light">

              <IonChip 
                slot="start"
                disabled
                color={showApplication ? "medium" : "primary"}
                //disabled={showApplication}
                onClick={() => {
                  setShowApplication(true);
                  setShowAxleLayouts(false);
                  setShowPayload(false);
                  setShowSort(false);
                }}>Application
              </IonChip>

              <IonChip 
                slot="start"
                color={showAxleLayouts ? "medium" : "primary"}
                //disabled={showAxleLayouts}
                disabled
                onClick={() => {
                  setShowApplication(false);
                  setShowAxleLayouts(true);
                  setShowPayload(false);
                  setShowSort(false);
                  setFilterProperty('driveSystem');
                }}>Drive System
              </IonChip>

              <IonChip 
                slot="start"
                color={showPayload ? "medium" : "primary"}
                //disabled={showPayload}
                disabled
                onClick={() => {
                  setShowApplication(false);
                  setShowAxleLayouts(false);
                  setShowPayload(true);
                  setShowSort(false);
                  setFilterProperty('maxGVMRating');
                }}>GVM / GCM
              </IonChip>

            </IonItemDivider>

            {
              showApplication &&

              <IonItemDivider color="light">

                {app && app.sort() && app.map((a: string) => (
                  <IonChip 
                    color={application === a ? "medium" : "success"}
                    slot="start" 
                    key={a} 
                    onClick={() => setApplication(a)}>{a.charAt(0).toUpperCase() + a.slice(1)}
                  </IonChip>)
                ) }
              </IonItemDivider>
            }

            {
              showAxleLayouts &&

            
              <IonItemDivider color="light">

                {/* isLoadingSeriesFilter && <IonListHeader>Please wait...</IonListHeader> */}
                {
                  Array.from(new Set(defaultTrucks.map((o: TruckSpecifications) => 
                  o.driveSystem))).sort().map((ds) => 
                    <IonChip
                      key={ds}
                      onClick={() => {
                        setIsTruthyPicked(true);
                        setFilterChecked(true);
                      }} 
                      color="success"
                      slot="start">{ds}
                    </IonChip>
                  )}
                
              </IonItemDivider>
            }

            {
              showPayload &&

              <IonItemDivider color='light'>
                <IonChip
                  onClick={() => console.log('Payload')} 
                  //color={sortProperty === 'chassisModel' ? "medium" : "success"}
                  color="success"
                  slot="start">Less than 12 Ton
                </IonChip>

                <IonChip
                  onClick={() => console.log('Payload')} 
                  //color={sortProperty === 'chassisModel' ? "medium" : "success"}
                  color="success"
                  slot="start">More than 12 Ton
                </IonChip>

              </IonItemDivider>
            } 

            {
              showSort &&

              <IonItemDivider color='light'>
                
                <IonChip
                  onClick={() => setSortProperty('chassisModel')} 
                  color={sortProperty === 'chassisModel' ? "medium" : "success"}
                  slot="start">SMC
                </IonChip>

                <IonChip
                  onClick={() => setSortProperty('description')} 
                  color={sortProperty === 'description' ? "medium" : "success"}
                  slot="start">Model
                </IonChip>

                <IonChip
                  onClick={() => setSortProperty('wheelBase')} 
                  color={sortProperty === 'wheelBase' ? "medium" : "success"}
                  slot="start">Wheelbase
                </IonChip>

                <IonChip
                  onClick={() => setSortProperty('maxGVMRating')} 
                  color={sortProperty === 'maxGVMRating' ? "medium" : "success"}
                  slot="start">GVM
                </IonChip>

                <IonButtons slot="end">
                  {
                    showAZ &&
                    <IonButton color="dark" onClick={() => setShowAZ(false)}>
                    {ios ? 'A-Z' : <IonIcon icon={arrowDown} slot="icon-only" />}
                    </IonButton>
                  }

                  {
                    !showAZ &&
                    <IonButton color="dark" onClick={() => setShowAZ(true)}>
                    {ios ? 'Z-A' : <IonIcon icon={arrowUp} slot="icon-only" />}
                    </IonButton>
                  }
                
                </IonButtons>
              </IonItemDivider>
            }
          </IonToolbar>
        }
      </IonHeader>

      <IonContent fullscreen={true}>

        {/*<IonToolbar>
          <IonSegment value={segment} onIonChange={(e) => setSegment(e.detail.value as any)}>
            <IonSegmentButton value="all">
              Default
            </IonSegmentButton>
            <IonSegmentButton value="saved">
              Saved
            </IonSegmentButton>
          </IonSegment>
      </IonToolbar>*/}

       {
         series === null &&
         <TruckList
            trucks={defaultTrucksFromDB}
            listType={segment}
            hide={segment === 'saved'}
            isLoading={isLoadingDefaultTrucks} />
        }

       {
         series !== null &&

          <IonList style={segment === 'saved' ? { display: 'none'} : {}}>

            {isLoadingDefaultTrucks && 
              <IonList>
                <IonGrid className="tl-wrapper">
                  {ionLoadingItems}
                </IonGrid>
              </IonList>}
            {
              error && 
              <IonList>
                <IonListHeader className="ion-padding">
                    An Unexpected Error was experienced.
                </IonListHeader>
                <p>Please check your internet connection.</p>
              </IonList>
            
            }

            {!error && defaultTrucks.length === 0 && !isLoadingDefaultTrucks && 

              <IonList className="ion-padding">
                <IonRow className="ion-text-center ion-justify-content-center ion-margin-top">
                  <IonCol size="10">
                      <IonLabel>
                        {
                          query === "" ?
                          <>
                            <h2><strong>Error: Failed to load Trucks</strong></h2>
                            <p>{'Please reload the page and make sure you are connected to the internet. If the problem persists, please contact support'}</p>
                          </>
                          :
                          <>
                            <h2><strong>Truck(s) not found</strong></h2>
                            <p>{'Please search for different truck(s)'}</p>
                          </>
                        }    
                        <lottie-player src={ROUTES.CONTENT_NOT_FOUND} mode="bounce" background="transparent" speed="0.8" loop autoplay></lottie-player>
                      </IonLabel>
                  </IonCol>
                </IonRow>
              </IonList>
            }

            {
              defaultTrucks.length > 0 && !isLoadingDefaultTrucks &&

              <IonGrid className="tl-wrapper">
                  
                    <IonItemGroup key={`series-${series}`}>
                      <IonItemDivider sticky>
                          <IonLabel>
                              {series!.charAt(0).toUpperCase() + series!.slice(1)}&nbsp;&mdash;&nbsp;Series
                          </IonLabel>
                      </IonItemDivider>

                      <IonRow className="ion-content-justify-center">
                      {
                        defaultTrucks.map((truck, index: number) => (

                          <IonCol size="12" sizeMd="6" sizeLg="4" sizeXl="4" key={`model-${index}-${truck.chassisModel}`}>
                            <TruckListItem
                                isSaved={truck.id.length > 7}
                                key={`model-${index}-${truck.chassisModel}`}
                                model={truck}
                                listType={segment}
                            />
                          </IonCol>
                        )) 
                      }
                      </IonRow>
                    </IonItemGroup>
                  
              </IonGrid>
            }

          </IonList>
        }

        {/*<TruckList
          trucks={savedTrucks}
          listType={segment}
          hide={segment === 'all'} 
          isLoading={isLoadingSavedTrucks}
        />  */}

        <IonModal
          mode="ios"
          isOpen={showTruckListFilter}
          onDidDismiss={() => setShowTruckListFilter(false)}
          swipeToClose={true}> 
            <TruckListFilter updateSeries={setSeries} onDismissModal={() => setShowTruckListFilter(false)} route={from} />
        </IonModal>
        
        <IonFab vertical="bottom" horizontal="end" slot="fixed">
          <IonFabButton onClick={() =>  {
            setShowTruckListFilter(true)
            }}>
            <IonIcon icon={swapHorizontalOutline}></IonIcon>
          </IonFabButton>
        </IonFab>

      </IonContent>
    
    </IonPage>
  );
}

export default CalcTruckList;
