import React, { useEffect, useState } from 'react';
import { IonApp, IonRouterOutlet } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { QueryClient, QueryClientProvider } from 'react-query';
import { App as CapApp } from '@capacitor/app';
import { Browser } from '@capacitor/browser';
import { useAuth0 } from "@auth0/auth0-react";
import { ConnectionStatus, Network } from '@capacitor/network';
import { useBooleanState } from 'webrix/hooks';
import { useMedia } from 'react-use';
import { callbackUri } from "./auth.config";

import Offline from './pages/auth/Offline';
import SplashScreen from './pages/auth/SplashScreen';

import PrivateRoutes from './PrivateRoutes';
import PublicRoutes from './PublicRoutes';

import PrivateDesktop from './desktop/PrivateDesktop';
import PublicDesktop from './desktop/PublicDesktop';


/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';


/** Query Client */
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false, // Disables automatic retries
      retryOnMount: false,
      staleTime: 10000, // Time in milliseconds a query is considered fresh
      cacheTime: 30000, // Cache time for inactive queries
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
      refetchInterval: false,
      refetchIntervalInBackground: false,
      suspense: false,
      //networkMode: 'always',
      keepPreviousData: false,
      structuralSharing: true,
      notifyOnChangeProps: 'tracked',
      //suspense: false,
      useErrorBoundary: false,
      //queryFnParamsFilter: args => args,
      //queryKeySerializerFn: queryKey => [JSON.stringify(queryKey), queryKey],
      behavior: undefined
    }
  }
  
});

//let isConnected = true;
const networkStatus = async () => await Network.getStatus();

const App: React.FC = () => {

  const isWide = useMedia('(min-width: 768px)', false);
  const [isConnected, setIsConnected] = useState(true)
	const { value: online, setFalse: setOffline, setTrue: setOnline } = useBooleanState(isConnected);

  
	useEffect(() => {

    networkStatus().then((status: ConnectionStatus) => setIsConnected(status.connected))

		window.addEventListener('online', setOnline);
		window.addEventListener('offline', setOffline);

		return () => {
			window.removeEventListener('online', setOnline);
			window.removeEventListener('offline', setOffline);
		};
	}, [online, setOnline, setOffline, isConnected]);
  

  /** Login and Redirects */
  const { handleRedirectCallback, isAuthenticated, isLoading, user } = useAuth0();

  useEffect(() => {

    //const eToyotaUrls = ["https://toyapps3.e-toyota.co.za", "https://identity.e-toyota.co.za", "https://portal.e-toyota.co.za"];

    CapApp.addListener("appUrlOpen", async ({ url }) => {
      if (url.startsWith(callbackUri)) {
        if (
          url.includes("state") &&
          (url.includes("code") || url.includes("error"))
        ) {
          await handleRedirectCallback(url);
        }

        await Browser.close();
      }
    });
  }, [handleRedirectCallback]);


  /** Loading User */
  if (isConnected) {
    if (isLoading) {
      return <SplashScreen progress={25} text={`Verifying your login details`}/>;
    }
  }

  /** Desktop */
  if (isWide) {
    return (

      <QueryClientProvider client={queryClient} contextSharing={true}>
        
        { isAuthenticated && 

          <IonApp>
            <IonReactRouter>
              <PrivateDesktop auth0User={user}/>
            </IonReactRouter>
          </IonApp>
          
        }
        { !isAuthenticated && 
          
          <>
            {/*<div style={ {textAlign: "center", marginTop: "10em"} }><img src="/assets/images/hino-logo.svg" width="auto" height="30px" alt="Hino Logo"/></div>
            <div style={ {textAlign: "center", marginTop: "2em"} }>Hello, there. Please visit the url using an internet browser on your mobile device.</div> 
            <div style={ {textAlign: "center", marginTop: "2em"} }>
              <button onClick={() => Browser.open({url: "https://www.hino.co.za", windowName: "_self"})}>Go to Hino website</button>
        </div>*/}

          <IonApp>
            <IonReactRouter>
              <PublicDesktop/> 
            </IonReactRouter>
          </IonApp>
          </>
        }
              
     </QueryClientProvider>
    );
  }

  /** Mobile */
  else {

    return (
  
      <QueryClientProvider client={queryClient} contextSharing={true}>
        <IonApp>
          <IonReactRouter>
            <IonRouterOutlet id="main">
              <Offline>
              { isAuthenticated && <PrivateRoutes auth0User={user}/> }
              { !isAuthenticated && <PublicRoutes /> }
              </Offline>
            </IonRouterOutlet>
          </IonReactRouter>
        </IonApp>
     </QueryClientProvider>
    );
  }
}

export default App;
