import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from "@sentry/react";
import { Auth0Provider } from "@auth0/auth0-react";
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { registerLicense } from '@syncfusion/ej2-base';

import { domain as auth0Domain, clientId, callbackUri } from "./auth.config";
import packageInfo from '../package.json';

import App from './App';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';


Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing(), 
    new Sentry.Replay()
  ],
  environment: "production",
  release: `hino-mobile-app@${packageInfo.version}` ,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

//registerLicense('ORg4AjUWIQA/Gnt2XVhhQlJHfVxdXHxLflFzVWFTfVZ6dVdWACFaRnZdR11iSn5SckFkXXlXdndR');
registerLicense('Ngo9BigBOggjHTQxAR8/V1NMaF5cXmBCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdmWXxec3VURGlZUU1+XEo=');

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      domain={auth0Domain!}
      clientId={clientId!}
      redirectUri={callbackUri}
      audience={'https://api.app.hino.co.za'}
      scope={'openid profile email create:builders create:trucks create:dealerships create:messages create:specifications delete:messages delete:specifications read:builders read:dealerships read:messages read:specifications read:trucks update:builders update:dealerships update:messages update:specifications update:trucks'}

      /* Uncomment the following lines for better support  in browers like Safari where third-party cookies are blocked.
         See https://auth0.com/docs/libraries/auth0-single-page-app-sdk#change-storage-options for risks. */
      //cacheLocation="localstorage"
      //useRefreshTokens={true}

      useRefreshTokensFallback={false}
    >
      <App /> 
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

//Call the element loader after the platform has been bootstrapped
defineCustomElements(window);
