import { IonCard, IonCardContent, IonChip,  IonCol,  IonGrid,  IonItem, IonLabel, IonRow, IonToast } from "@ionic/react";
import { useMedia } from "react-use";
import { CalcValues,  Calculator } from "../../../services/calculations";
import { TruckSpecifications } from "../../../models/Truck";
import { CalcCOGSlider } from "../../calculations";
import { CnvsDimensions } from "../../canvas";
import { useState } from "react";
import { MassRegulations } from "../../../models/Regulations";

interface DimensionsProps {
    values: CalcValues;
    view: 'front' | 'rear' | 'side'| 'top';
    slider: {cog: 'side' | 'top' | 'front' | 'rear' | 'payload', show: boolean, setValues: (values: CalcValues) => void};
}

const CalcDimensions: React.FC<DimensionsProps> = ({values, view, slider}) => {

    const { regulations, dimensions, bodyType } = Calculator(values);
    const isWide = useMedia('(min-width: 768px)');
    const reg = new MassRegulations();
    
    const truck = JSON.parse(values.truck as string) as TruckSpecifications;

    const body = bodyType.toLowerCase() !== 'unknown' ? `with a ${bodyType.charAt(0).toUpperCase() + bodyType.slice(1)}` : '';
    const title = `${truck.name} (${truck.chassisModel}) ${body} (${view} view)`;

    const axles = dimensions.fas === 0 && dimensions.ras === 0 ? {front: "A", rear: "B"} 
                    : dimensions.fas === 0 && dimensions.ras > 0 ? {front: "A", rear: "BC"} 
                    :  {front: "AB", rear: "CD"}; 

    const [comment, setComment] = useState("");
    const [colour, setColour] = useState("");
    const [showToast, setShowToast] = useState(false);
                    
    return (
        <IonGrid>

            <IonRow className="ion-justify-content-center ion-padding-start ion-padding-end">

                <IonCol size="12" className="ion-text-center ion-no-margin ion-no-padding">
                    <strong>{title}</strong>
                </IonCol>
                <IonCol size="12" className="ion-text-center ion-no-margin ion-no-padding">
                    <IonLabel>
                        <h2><strong></strong></h2>
                        <p>
                            Gross Mass on Vehicle Front
                            <IonChip
                                onClick={() => {
                                    setComment(`Gross Mass on Vehicle Front on Goods Vehicles should be at least ${reg.minLoadOnSteeringAxleGoodsVehicle} %`);
                                    setColour(regulations.mass.salW? "success" : "warning");
                                    setShowToast(true);
                                }}
                            
                                color={regulations.mass.salW ? "success" : "warning"}> 
                                <strong>{`${regulations.mass.sal} %`}</strong>
                            </IonChip>
                        </p>  
                    </IonLabel>
                </IonCol>
            </IonRow>

            <IonRow className="ion-justify-content-center ion-padding-top">
                            
                <IonCol size="4" className="ion-padding-start ion-padding-end">
                    <IonRow className="ion-justify-content-center ion-text-center"><strong>{`Front (${axles.front})`}</strong></IonRow>
                    <IonRow className="ion-justify-content-center ion-text-center">
                        <IonChip
                            onClick={() => {
                                setComment(regulations.mass.twfW ? "Utilisation on front axle(s) is within the permissible value" : "Utilisation on front axle(s) is more than the permissible value")
                                setColour(regulations.mass.twfW ? "success" : "danger");
                                setShowToast(true);
                            }}
    
                            color={regulations.mass.twfW ? "success" : "danger"}>
                            <strong>{`${regulations.mass.utilF} %`}</strong>
                        </IonChip>
                    </IonRow>
                </IonCol>
                <IonCol size="4" className="ion-padding-start ion-padding-end">
                    <IonRow className="ion-justify-content-center ion-text-center"><strong>{`Rear (${axles.rear})`}</strong></IonRow>
                    <IonRow className="ion-justify-content-center ion-text-center">
                        <IonChip
                            onClick={() => {
                                setComment(regulations.mass.twrW ? "Utilisation on rear axle(s) is within the permissible value" : "Utilisation on rear axle(s) is more than the permissible value")
                                setColour(regulations.mass.twrW ? "success" : "danger");
                                setShowToast(true);
                            }}
                            
                            color={regulations.mass.twrW ? "success" : "danger"}>
                            <strong>{`${regulations.mass.utilR} %`}</strong>
                        </IonChip>
                    </IonRow>
                </IonCol>
                <IonCol size="4" className="ion-padding-start ion-padding-end">
                <IonRow className="ion-justify-content-center ion-text-center"><strong>Total</strong></IonRow>
                    <IonRow className="ion-justify-content-center ion-text-center">
                        <IonChip
                            onClick={() => {
                                setComment(regulations.mass.twtW ? "Total utilisation on axles is within the permissible value" : "Total utilisation on axles is more than the permissible value")
                                setColour(regulations.mass.twtW ? "success" : "danger");
                                setShowToast(true);
                            }} 

                            color={regulations.mass.twtW ? "success" : "danger"}>
                            <strong>{`${regulations.mass.utilT} %`}</strong>
                        </IonChip>
                    </IonRow>
                </IonCol>
                
            </IonRow>	
           
            <IonRow className="ion-justify-content-center ion-no-margin ion-no-padding">

                <IonCard mode="ios" style={ {width: `${isWide ? '768px' : '360px'}` }}>

                    <IonCardContent>

 
                        <CnvsDimensions values={values} view={view} />

                        { 
                            slider.show &&

                            <>
                                <IonRow className="ion-justify-content-center ion-no-padding">
                                    <IonItem className="ion-text-center" lines="none">
                                        {
                                            slider.cog === 'payload' && view === 'side' && 
                                            <h5><strong>Payload COG: {dimensions.pcog} %</strong></h5>
                                        }

                                        {slider.cog === 'side' && <h5><strong>Horizontal COG: {dimensions.hcog} %</strong></h5>}

                                        {slider.cog === 'top' && <h5><strong>Lateral COG: {dimensions.lcog} %</strong></h5>}

                                        {
                                            (slider.cog === 'front' || slider.cog === 'rear') && 
                                            <h5><strong>Vertical COG: {dimensions.vcog} %</strong></h5>
                                        }
                                    </IonItem>
                                </IonRow>

                                <CalcCOGSlider values={values} cog={slider.cog} setValues={slider.setValues}/> 
                            </>   
                        }

                    </IonCardContent>

                </IonCard>

            </IonRow>

            <IonToast
                isOpen={showToast}
                onDidDismiss={() => setShowToast(false)}
                message={comment}
                duration={3000}
                color={colour}
            />

        </IonGrid>
    );
}

export default CalcDimensions;