
import React from 'react';
import { Document } from '@react-pdf/renderer';
import packageInfo from '../../../package.json';
import SpecPage from './SpecPage';






const MyDoc: React.FC = () => (
    <Document
      title={""}
      keywords={``}
      author={``}
      subject={""}
      creator={`Hino Truck Body Builders App v${packageInfo.version}`}
      producer={`Hino Truck Body Builders App v${packageInfo.version}`}
      language="en-za"
    >
      <SpecPage />
    </Document>
  );

  export default MyDoc;