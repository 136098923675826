import { IonButton, IonButtons, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonItem,  IonItemSliding, 
	IonLabel, IonList, IonModal, IonPage, IonRow, IonText, IonTitle, IonToolbar, isPlatform } from '@ionic/react';
import { chevronBack, checkmark, close, options } from 'ionicons/icons';
import { useRef, useState } from 'react';
import { Redirect } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import { useStoreState } from 'pullstate';
import LeadStore from '../services/leads';
import { Calculation } from '../models/Calculation';
import { HinoPreferredSupplier } from '../models/Builder';
import Viewer from '../components/pdf';
import SpecDoc from '../components/documents/SpecDoc';
import { getUser, UserPreferences } from '../services/user';
import SearchInput from '../components/SearchInput';
import { useFetchDataFromDB } from '../services/DataApi';
import * as ROUTES from '../config';

import './Calculations.css';
import 'react-loading-skeleton/dist/skeleton.css';


const Builder = () => {

	const pageRef = useRef();
	const ios = isPlatform('ios');
	const user = useStoreState(UserPreferences, getUser);
	const isDealership = user.role.includes('Hino Dealership');
	
	const preferredSupplier: HinoPreferredSupplier = LeadStore.useState((s: any) => s.preferredSupplier);
	const hps_id: string | undefined = preferredSupplier === null ? undefined : preferredSupplier.id

	const { data: calculations, isLoading } = useFetchDataFromDB("specifications", `${ROUTES.SPECIFICATIONS_API_ENDPOINT}/${hps_id}`);

	const [query, setQuery] = useState<string>("");
	const [calculation, setCalculation] = useState<Calculation | null>(null);

	const [showFilter, setShowFilter] = useState(false);
	const [showPDFViewer, setShowPDFViewer] = useState(false);


	let ionLoadingItems: any = [];

	for (let i: number = 0; i < 10; i++) {
  
	  ionLoadingItems.push(
		<IonCol size="12" sizeMd="6" sizeLg="4" sizeXl="4" key={i}>
		  <IonItemSliding key={i} className="calculations-item">
			<IonItem>
			  <IonLabel>
				<h3><strong><Skeleton/></strong></h3>
				<h5><Skeleton/></h5>
				<h6>
				  <Skeleton/>
				</h6>
				<p><Skeleton/></p>
			  </IonLabel>
			</IonItem>
		  </IonItemSliding>
		</IonCol>
	  )
	}

	if (preferredSupplier === null) {
		return (
			<Redirect to={ROUTES.TABS_HPS_LIST_PAGE_ROUTE} />
		);
	}

	return (
		<IonPage ref={pageRef}>
			<IonHeader>
				<IonToolbar mode="ios" color="toolbar">
					<IonTitle><strong>{preferredSupplier.Name.split(" ", 3).map((s: string) => s.charAt(0).toUpperCase())}'s Specification</strong></IonTitle>
					<IonButtons slot="start">
            			<IonButton routerDirection="back" routerLink={ROUTES.TABS_HPS_LIST_PAGE_ROUTE}>
              				<IonIcon icon={chevronBack}  />
						</IonButton>
          			</IonButtons>

					{/*<IonButtons slot="end">
						<IonButton onClick={() => alert('Set as preferred Dealership?')}>
							<IonIcon icon={bookmarkOutline} size="large" />
						</IonButton>
	</IonButtons>*/}
				</IonToolbar>
				<IonToolbar mode="ios" className="ion-padding-top">
					<SearchInput onChangeSearchQuery={(query) => setQuery(query)} />
						<IonButtons slot="end">
							{
							!showFilter &&
							<IonButton color="dark" onClick={() => setShowFilter(true)}>
							{ios ? 'Filter' : <IonIcon icon={options} slot="icon-only" />}
							</IonButton>}
							{
							showFilter &&
							<IonButton color="dark" onClick={() => setShowFilter(false)}>
								{ios ? 'Close' : <IonIcon icon={close} slot="icon-only" />}
							</IonButton>
							}
						</IonButtons>
				</IonToolbar>
			</IonHeader>
			<IonContent fullscreen>

			{
				isLoading && 

				<IonList>
					<IonGrid className="c-wrapper">
					<IonRow className="ion-justify-content-center">
						{ionLoadingItems}
					</IonRow>
					</IonGrid>
				</IonList>
			}

			{
				!isLoading && calculations !== undefined && calculations.length === 0 &&
				
				<IonList className="ion-padding">
					<IonRow className="ion-text-center ion-justify-content-center ion-margin-top">
					<IonCol size="10">
						<lottie-player src={ROUTES.CONTENT_NOT_FOUND} mode="bounce" background="transparent" speed="0.8" loop autoplay></lottie-player>
					</IonCol>
					<IonCol size="10">
						<IonLabel> 
							{
							query === "" ?
							<>
								<h2><strong>No Saved Specification Found</strong></h2>
								<p>{`${isDealership ? 'Please contact Hino Preferred Suppliers (HPS) to add specifications for you': 'Add new Specifications'}`}</p>
							</>
							:
							<>
								<h2><strong>Specification(s) not found</strong></h2>
								<p>{`Please search for different specifications or ${isDealership ? 'contact Hino Preferred Suppliers (HPS) to add specifications for you': 'add new Specifications'} `}</p>
							</>
							}  
							
						</IonLabel>
					</IonCol>
					</IonRow>
				</IonList>
			}

			{
				!isLoading && calculations !== undefined && calculations.length > 0 && (

				<IonGrid className="c-wrapper">
					<IonRow>
					{
						calculations.map((c: Calculation) => (

						<IonCol size="12" sizeMd="6" sizeLg="6" sizeXl="6" key={`calculation-${c.id}`}>
							<IonItemSliding  className="calculations-item" key={c.id}>
								<IonItem
									mode="ios"
									onClick={() => {
										setCalculation(c);
                          				setShowPDFViewer(true);
									}} >
									<IonLabel>
									<h2><strong>{c.uniquename}</strong></h2>
									<h5>
										{`${c.body !== null ? `${c.truck} - ${c.body.charAt(0).toUpperCase() + c.body.slice(1)} Body` : c.truck}`}
									</h5>
									<h6>
										<IonText>Mass&nbsp;
										<IonIcon icon={c.rules.mass ? checkmark : close} color={c.rules.mass ? "success" : "danger"}/>
										</IonText>
										<IonText>&nbsp;Dimensions&nbsp;
										<IonIcon icon={c.rules.dimensions ? checkmark : close} color={c.rules.dimensions ? "success" : "danger"}/>
										</IonText>
										<IonText>&nbsp;Bridge&nbsp;
										<IonIcon icon={c.rules.bridge ? checkmark : close} color={c.rules.bridge ? "success" : "danger"}/>
										</IonText>
										<IonText>&nbsp;Circle&nbsp;
										<IonIcon icon={c.rules.circle ? checkmark : close} color={c.rules.circle ? "success" : "danger"}/>
										</IonText>
									</h6>
									<p>Saved: {(c.savedDate).toString().substring(0, 10)}</p>
									</IonLabel>
									{/*<IonAvatar color="primary">
									<IonImg src={hino500} alt={initials}/>
									</IonAvatar>*/}
								</IonItem>
							{/*<IonItemOptions>
								<IonItemOption color="danger" onClick={() => alert("Calculation Deleted")}>
									Delete
								</IonItemOption> 
								<IonItemOption 
									color="success" 
									onClick={() => {
									initCalculator(c.id);
									const v = { ...values };
									v.calcID = c.id;
									CalcStore.update((s: CalcValues) => {
										s.calcID = c.id;
									});
									setCalculation(c);
									setShowSubmitToDealerModal(true);
									}}>
									Submit to Dealer
								</IonItemOption>
								</IonItemOptions> */}
							</IonItemSliding>
						</IonCol>
						))
					}
					</IonRow>
				</IonGrid>
			)}

				<IonModal mode="ios" isOpen={showPDFViewer} onDidDismiss={() => setShowPDFViewer(false)} swipeToClose={true}>
					<Viewer onDismissModal={() => setShowPDFViewer(false)} docTemplate={<SpecDoc specification={calculation as Calculation} builder={preferredSupplier}/>}/>
				</IonModal>
				
				{/*<IonModal
					mode="ios"
					isOpen={showSubmitToBuilderModal}
					onDidDismiss={() => setShowSubmitToBuilderModal(false)}
					swipeToClose={true}> 
						<SubmitToBuilder 
							onDismissModal={() => setShowSubmitToBuilderModal(false)} 
							route={`/preferred-suppliers/${preferredSupplier.DealerCode}`}
							values={preferredSupplier}/>
				</IonModal>

				<IonModal
					mode="ios"
					isOpen={showTruckListFilter}
					onDidDismiss={() => setShowTruckListFilter(false)}
					swipeToClose={true}> 
						<TruckListFilter onDismissModal={() => setShowTruckListFilter(false)} />
					</IonModal>

				<IonFab vertical="bottom" horizontal="end" slot="fixed">
					<IonFabButton onClick={() =>  {
					setShowTruckListFilter(true)
					}}>
					<IonIcon icon={isDealership ? chatboxEllipsesOutline: add}></IonIcon>
					</IonFabButton>
				</IonFab>*/}
			</IonContent>
		</IonPage>
	);
};

export default Builder;