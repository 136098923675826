import { IonList, IonListHeader } from "@ionic/react";
import { CalcValues, Calculator } from "../../../services/calculations";
import BodyDimensions from "./Bodies";

interface DimensionProps {
    values: CalcValues;
    setValues: (v: CalcValues) => void;
}

const ConfigureDimensions: React.FC<DimensionProps> = ({values, setValues}) => {

    const { bodyType } = Calculator(values);

    return (
        <IonList>

            <IonListHeader className="ion-padding-bottom">Configure Dimensions</IonListHeader>

            <BodyDimensions 
                type={bodyType.toLowerCase()}
                values={values}
                setValues={setValues}
            /> 

        </IonList>
    );
}

export default ConfigureDimensions;