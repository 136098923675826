import { BodyMass, BodyLength, BodyWidth, BodyHeight, BodySpecifications, Body as TruckBody } from '../../../models/Body';


export interface Action {
    type: string;
    payload: BodyMass | BodyLength | BodyWidth | BodyHeight;
}

/** Mass Reducer */
export const mReducer = (state: BodyMass, action: Action) => {

    switch (action.type) {
        case 'MASS_TOTAL' :
             return { ...state, totalMass: (action.payload as BodyMass).totalMass};
        case 'MASS_UDL' :
            return { ...state, udl: (action.payload as BodyMass).udl};
        case 'MASS_HEAD' :
            return { ...state, guardframeMass: (action.payload as BodyMass).guardframeMass};
        case 'MASS_TAIL' :
            return { ...state, tailgateMass: (action.payload as BodyMass).tailgateMass};
        case 'MASS_SIDE' :
            return { ...state, sidegateMass: (action.payload as BodyMass).sidegateMass};
        case 'MASS_ROOF' :
            return { ...state, roofConstructMass: (action.payload as BodyMass).roofConstructMass};
        case 'MASS_MATERIAL' :
            return { ...state, material: (action.payload as BodyMass).material};
        case 'MASS_FLOOR' :
            return { ...state, floorConstructMass: (action.payload as BodyMass).floorConstructMass};
        case 'MASS_MODE' :
            return { ...state, mode: (action.payload as BodyMass).mode};
        default: 
            return state;      
    }
}


/** Lenght Reducer */
 export const lReducer = (state: BodyLength, action: Action) => {

    const tb = (action.payload as BodyLength).guardframeLength;
    const hb = (action.payload as BodyLength).tailgateLength;
    let bl = (action.payload as BodyLength).bodyLengthExternalInclRearDoors;
    let ibl = (action.payload as BodyLength).bodyLengthInternal;

    switch (action.type) {

        case 'LENGTH_EXTERNAL' :
            ibl = ((+bl) - (+hb) - (+tb)).toString();
            return { ...state, bodyLengthExternalInclRearDoors: bl, bodyLengthInternal: ibl};

        case 'LENGTH_INTERNAL' :
            bl = ((+ibl) + (+hb) + (+tb)).toString();
            return { ...state, bodyLengthInternal: ibl, bodyLengthExternalInclRearDoors: bl};

        case 'LENGTH_HEADBOARD' :
            ibl = ((+bl) - (+hb) - (+tb)).toString();
            return { ...state, guardframeLength: hb, bodyLengthInternal: ibl};

        case 'LENGTH_TAILBOARD' :
            ibl = ((+bl) - (+hb) - (+tb)).toString();
            return { ...state, tailgateLength: tb, bodyLengthInternal: ibl};

        case 'LENGTH_GAP' :
            return { ...state, cabGapRecommendedMin: (action.payload as BodyLength).cabGapRecommendedMin};

        case 'LENGTH_COG' :
            return { ...state, centreOfGravity: (action.payload as BodyLength).centreOfGravity};
        case 'LENGTH_ROH' :
            return { ...state, rearOverhang: (action.payload as BodyLength).rearOverhang};
        case 'LENGTH_REAR_PROTRUSION' :
            return { ...state, rearProtrusion: (action.payload as BodyLength).rearProtrusion};
        case 'LENGTH_REAR_PROTRUSION_CA' :
            return { ...state, rearMostProtrudingItemBehindCabToRearAxles: (action.payload as BodyLength).rearMostProtrudingItemBehindCabToRearAxles};
        case 'LENGTH_BUMPER_TO_BODY' :
            return { ...state, bumperToStartOfBodyInternal: (action.payload as BodyLength).bumperToStartOfBodyInternal};
        case 'LENGTH_CROH' :
            return { ...state, rearOverhang: (action.payload as BodyLength).rearOverhang};
        case 'LENGTH_BREAKPOINT_ROH' :
            return { ...state, breakpointForRearOverhangFromRearMostAxle: (action.payload as BodyLength).breakpointForRearOverhangFromRearMostAxle};
        case 'LENGTH_BREAKPOINT_WB' :
            return { ...state, breakpointForWheelbaseFromRearMostAxleFront: (action.payload as BodyLength).breakpointForWheelbaseFromRearMostAxleFront};
        case 'LENGTH_SIDEGATE' :
            return { ...state, sidegateLength: (action.payload as BodyLength).sidegateLength};
        case 'LENGTH_LOAD_COMPARTMENT' :
            return { ...state, loadCompartmentLength: (action.payload as BodyLength).loadCompartmentLength};
        default: 
            return state;      
    }
}

/** Height Reducer */
export const hReducer = (state: BodyHeight, action: Action) => {

    switch (action.type) {
        case 'HEIGHT_EXTERNAL' :
            return { ...state, bodyHeightExternal: (action.payload as BodyHeight).bodyHeightExternal};
        case 'HEIGHT_INTERNAL' :
            return { ...state, loadCompartmentHeight: (action.payload as BodyHeight).loadCompartmentHeight};
        case 'HEIGHT_SUBSTRUCTURE' :
            return { ...state, floorHeight: (action.payload as BodyHeight).floorHeight};
        case 'HEIGHT_ROOFSTRUCTURE' :
            return { ...state, roofHeight: (action.payload as BodyHeight).roofHeight};
        case 'HEIGHT_SUBFRAME' :
            return { ...state, subFrameHeight: (action.payload as BodyHeight).subFrameHeight};
        case 'HEIGHT_COG' :
            return { ...state, centreOfGravity: (action.payload as BodyHeight).centreOfGravity};
        case 'HEIGHT_POSITION' :
            return { ...state, bodyPosition: (action.payload as BodyHeight).bodyPosition};
        case 'HEIGHT_OVERALL' :
            return { ...state, completeOverallBodyAndEquipmentHeight: (action.payload as BodyHeight).completeOverallBodyAndEquipmentHeight};
        default: 
            return state;      
    }
}

/** Width Reducer */
export const wReducer = (state: BodyWidth, action: Action) => {

    switch (action.type) {
        case 'WIDTH_EXTERNAL' :
            return { ...state, bodyWidthExternal: (action.payload as BodyWidth).bodyWidthExternal};
        case 'WIDTH_INTERNAL' :
            return { ...state, loadCompartmentWidth: (action.payload as BodyWidth).loadCompartmentWidth};
        case 'WIDTH_LEFT' :
            return { ...state, leftBodyThickness: (action.payload as BodyWidth).leftBodyThickness};
        case 'WIDTH_RIGHT' :
            return { ...state, rightBodyThickness: (action.payload as BodyWidth).rightBodyThickness};
        case 'WIDTH_COG' :
            return { ...state, centreOfGravity: (action.payload as BodyWidth).centreOfGravity};
        case 'WIDTH_OVERALL' :
            return { ...state, completeOverallBodyAndEquipmentWidth: (action.payload as BodyWidth).completeOverallBodyAndEquipmentWidth};
        default: 
            return state;      
    }
}

/** function that takes Body as parameter and returns BodySpecifications*/
export const transformBodyToBodySpec = (body: TruckBody) => {
  
    // The object which contains the final result
    let result: BodySpecifications | {} = {};
  
    // loop through the object "body"
    for (const i in body) {

        // We check the type of the i using typeof() function and recursively call the function again
        if ((typeof (body as any)[i]) === 'object') {

            const temp = transformBodyToBodySpec((body as any)[i]);

            for (const j in temp) {

                // Store temp in result
                (result as any)[i + '_' + j] = (temp as any)[j];
            }
        }
  
        // Else store body[i] in result directly
        else {
            (result as any)[i] = (body as any)[i];
        }
    }
    return result as BodySpecifications;
};

/** function that takes Body as parameter and returns BodySpecifications*/
export const transformBodySpecToBody = (bodySpec: BodySpecifications) => {
  
    // The object which contains the final result
    let result: TruckBody | {} = {};

    let f : Array<Array<string>> = [];
  
    // loop through the object "body"
    for (let [key, value] of Object.entries(bodySpec)) {

        

        const g = key.split('_').map(s => s);
        g[1] = g[1] + ' : ' + value;

        f.push(g)

        //console.log(g)

        //const o = g.reduce((a, b) => Object.assign(result, b), {});

        //console.log(o)

         /*g.reduce((r, a) => {
            r[0] = r[0] || [];
            r[0].push(`${r[0]}: ${a}`);
            console.log(r)
            return r;
          }, Object.create(null)); */
    }

    const t: Array<string> = [];

    const mass = f.filter(a => a[0] === 'mass').map(s => s)
    const length = f.filter(a => a[0] === 'length').reduce((r, a) => Object.assign(r, a), {});
    const height = f.filter(a => a[0] === 'height').flatMap(h => `{${h[1]} : ${h[2]}}`);
    const width = f.filter(a => a[0] === 'width').flatMap(w => `{${w[1]} : ${w[2]}}`);


    console.log(Object.fromEntries(f))
    console.log(t)
    console.log(length)
    console.log(width)


    return result as BodySpecifications;
};

