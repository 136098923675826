import { Store } from 'pullstate';
import { createSelector } from 'reselect';
import { HinoPreferredSupplier } from '../../models/Builder';
import { HinoDealership } from '../../models/Dealership';
import { GroupedLeads } from '../../models/Lead';

interface LeadValues {
    calculationID: string | null;
    truckID: string | null;
    notes: string | null;
    dealershipID?: string | null;
    salespersonID?: string | null;
    preferredSupplierID?: string | null;
    builderID?: string | null;
    dateSubmitted: string | null;
    dateClosed: string | null;
    status: LeadStatus | null;
    dealership: HinoDealership | null;
    preferredSupplier: HinoPreferredSupplier | null;
}

export enum LeadStatus {'Pending', 'Closed - Deal sealed', 'Closed - Deal rejected'}

const LeadStore = new Store<LeadValues>({
    calculationID: null,
    notes: null,
    truckID: null,
    dealershipID: null,
    salespersonID: null,
    preferredSupplierID: null,
    builderID: null,
    dateSubmitted: null,
    dateClosed: null,
    status: null,
    dealership: null,
    preferredSupplier: null
});

const GroupedLeadsStore = new Store<GroupedLeads>({
    salesperson: "",
    builder_name: "",
    leads: []
});

const getState = (state: any) => state;

export default LeadStore;
export { GroupedLeadsStore }
export const getCalcID = createSelector(getState, state => state.calculationID);

