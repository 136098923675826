import React, { useState, useRef } from 'react';
import { Redirect, RouteComponentProps, useLocation } from 'react-router';
import { useMutation, useQueryClient } from 'react-query';
import { IonToolbar, IonButtons, IonItemDivider, IonItem,  IonCol, IonPage, IonInput, 
    IonButton, IonContent, IonList, IonListHeader,  IonIcon, IonHeader, IonRow, IonCardTitle, IonModal, 
    IonAlert, IonLoading, IonCardContent, IonCard, IonLabel, IonSpinner, IonText } from '@ionic/react';
import { checkmark, close } from 'ionicons/icons';
import { useStoreState } from 'pullstate';

import { getUser, UserPreferences } from '../../services/user';
import { saveCalculations, CalcValues, CalcStore, SaveCalcValues, EmptyCalcValues, Calculator } from '../../services/calculations';
import { TruckSpecifications } from '../../models/Truck';
import SubmitToDealer from '../../components/Leads/SubmitToDealer';

import * as ROUTES from '../../config';
import { ReactSVG } from 'react-svg';

const CalcSave : React.FC<RouteComponentProps> = ({ history }) => {

    const pageRef = useRef<HTMLElement>(null);
    const user = useStoreState(UserPreferences, getUser); 

    //ToDo: only get a single calculation using calcID
    //const { data: calculations, isLoading: isLoadingCalculations } = useFetchDataFromDB("calculations", ROUTES.CALCULATIONS_API_URL, ROUTES.CALCULATIONS_GET_ALL_ENDPOINT_URL);

    const location = useLocation<{"values": CalcValues}>();

    const values = location.state !== undefined ? location.state.values : new EmptyCalcValues();
    const truck = values.truck !== null ? JSON.parse(values.truck as string) as TruckSpecifications : null;

    const { regulations, bodyType } = Calculator(values);
    const { isBridgePass, isCirclePass, isDimensionsPass, isGVMPass } = regulations;
    const mass = isGVMPass || null, dimensions = isDimensionsPass || null, bridge = isBridgePass || null, circle = isCirclePass || null;
    
    const [truckName, setTruckName] = useState<string | null | undefined>(truck !== null ? truck.description : null);
    const [calcName, setCalcName] = useState<string | null | undefined>(truck !== null ? truck.description : null);
    const [calcID, setCalcID] = useState({id: null});
    const [calculation, setCalculation] = useState<SaveCalcValues | null>(null);

    const [showSubmitToDealerModal, setShowSubmitToDealerModal] = useState(false);
    const [showSaveSuccessAlert, setShowSaveSuccessAlert] = useState(false);
    const [showErrorAlert, setShowErrorAlert] = useState(false);

    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation(saveCalculations, {
        onSuccess: response => {

            //console.log(response.data.replace('Put item ', ''))
            //console.log(response.data.slice(9, response.data.length))

            if(response.status > 400) history.replace(ROUTES.CALCULATIONS_PAGE_ROUTE)
              
            CalcStore.update(s => {s.calcID = response.data.replace('Put item ')})
            setCalcID({id: response.data});
            setShowSaveSuccessAlert(true);
          },
          onError: (error) => {
            console.log(error);
            setShowErrorAlert(true)
            setTimeout(() => {
                history.replace(ROUTES.CALCULATIONS_PAGE_ROUTE)
            }, 1000)
          },
          onSettled: () => {
            queryClient.invalidateQueries('calculations');
          }
    });

    if (truck === null) {
		return (
			<Redirect to={ROUTES.TRUCK_LIST_PAGE_ROUTE} />
		);
	}

    const file = bodyType === 'unknown' ? `index` : `${bodyType.toLowerCase()}`;
    const filepath = `assets/trucks/${truck.series}/${truck.chassisModel}/bridge/${file}.svg`;

    return (
        <IonPage  ref={pageRef} id="save-calculation-page">
            <IonHeader>
                <IonToolbar mode="ios" color="primary">
                    <IonButtons slot="end">
                        <IonButton 
                            onClick={() => {

                                truck.description = (truckName || truck.description);

                                const v: CalcValues = { ...values}
                                v.truck = { ...truck};

                                const _name = calcName || v.truck.description;
                                const scv: SaveCalcValues = { v: v, uniquename: _name, userid: user.userid } as SaveCalcValues;

                                mutate(scv);
                                setCalculation(scv);

                            }}>Save&nbsp;

                            <IonIcon icon={checkmark} />
                        </IonButton>
                    </IonButtons> 
                    <IonButtons slot="start">
                        <IonButton routerLink={`${ROUTES.CALCULATIONS_PAGE_ROUTE}/${truck.id}`}>
                            <IonIcon icon={close} />
                            &nbsp;Cancel
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>

                <IonList mode="ios">
                    <IonListHeader className="ion-padding">Save Specifications</IonListHeader>

                    <IonItemDivider>Summary Details of Specifications</IonItemDivider>
                    
                   
                            <IonItem>
                                <IonCol size="12">

                                    <IonRow style={{ borderBottom: "1px solid #242424", padding: "0.5rem" }}>
                                        <IonCol size="6">
                                            <IonCardTitle style={{ fontSize: "0.9rem", marginLeft: true ? "1.5rem" : "" }}>
                                                Unique Name
                                            </IonCardTitle>
                                        </IonCol>
                                        <IonCol size="6">
                                            <IonInput placeholder={`${truck.description}`} onIonChange={(e) => setCalcName(e.detail.value)}></IonInput>
                                        </IonCol>
                                    </IonRow>
            
                                    <IonRow style={{ borderBottom: "1px solid #242424", padding: "0.5rem" }}>
                                        <IonCol size="6">
                                            <IonCardTitle style={{ fontSize: "0.9rem", marginLeft: true ? "1.5rem" : "" }}>
                                                Truck Description
                                            </IonCardTitle>
                                        </IonCol>
                                        <IonCol size="6">
                                            <IonInput placeholder={`${truck.description}`} onIonChange={(e) => setTruckName(e.detail.value)}></IonInput>
                                        </IonCol>
                                    </IonRow>

                                    <IonRow style={{ borderBottom: "1px solid #242424", padding: "0.5rem" }}>
                                        <IonCol size="6">
                                            <IonCardTitle style={{ fontSize: "0.9rem", marginLeft: true ? "1.5rem" : "" }}>
                                                Body Type
                                            </IonCardTitle>
                                        </IonCol>

                                        <IonCol size="6">
                                            <IonCardTitle style={{ fontSize: "0.9rem" }}>
                                                {values.body === null ? "None" : `${values.body?.type!.charAt(0).toUpperCase() + values.body?.type!.slice(1)}`}
                                            </IonCardTitle>
                                        </IonCol>
                                    </IonRow>

                                    <IonRow style={{ borderBottom: "1px solid #242424", padding: "0.5rem" }}>
                                        <IonCol size="6">
                                            <IonCardTitle style={{ fontSize: "0.9rem", marginLeft: true ? "1.5rem" : "" }}>
                                                Payload
                                            </IonCardTitle>
                                        </IonCol>

                                        <IonCol size="6">
                                            <IonCardTitle style={{ fontSize: "0.9rem" }}>
                                                {`${values.payload?.payload || 0} kg`}
                                            </IonCardTitle>
                                        </IonCol>
                                    </IonRow>
                                    
                                </IonCol>
                            </IonItem>
                            <IonItemDivider>Summary Rules</IonItemDivider>
                            <IonItem>
                                <IonCol size="12">
                                    <IonRow style={{ borderBottom: "1px solid #242424", padding: "0.5rem" }}>
                                        <IonCol size="12">
                                        {
                                            values.rules !== null && values.rules !== undefined ? 

                                            <>
                                                <IonItem>
                                                   <IonLabel>
                                                        <h2><strong>{calcName}</strong></h2>
                                                        <h5>
                                                            {`${values.body !== null ? `${truckName} - ${values.body.type!.charAt(0).toUpperCase() + values.body.type!.slice(1)} Body` : truckName}`}
                                                        </h5>
                                                        <h6>
                                                            <IonText>Mass&nbsp;
                                                                <IonIcon icon={mass ? checkmark : close} color={mass ? "success" : "danger"}/>
                                                            </IonText>
                                                            <IonText>&nbsp;Dimensions&nbsp;
                                                                <IonIcon icon={dimensions ? checkmark : close} color={dimensions ? "success" : "danger"}/>
                                                            </IonText>
                                                            <IonText>&nbsp;Bridge&nbsp;
                                                                <IonIcon icon={bridge ? checkmark : close} color={bridge ? "success" : "danger"}/>
                                                            </IonText>
                                                            <IonText>&nbsp;Circle&nbsp;
                                                                <IonIcon icon={circle ? checkmark : close} color={circle ? "success" : "danger"}/>
                                                            </IonText>
                                                        </h6>
                                                    </IonLabel>
                                                </IonItem>

                                                <IonCard mode="ios"> 
                                                    <IonCardContent>
                                                        <ReactSVG 
                                                            fallback={() => <IonLabel>Not Found</IonLabel> }
                                                            loading={() => <IonSpinner color="primary"/>} 
                                                            src={filepath}/> 
                                                    </IonCardContent>
                                                </IonCard>
                                            
                                            </>

                                             
                                                    
                                            : 
                                            
                                            <IonItem>Sorry, we weren't able to validate your specifications online against AARTO regulations</IonItem>
                                         }
                                                  
                                        </IonCol>
                                    </IonRow>
                                </IonCol>
                            </IonItem>

                </IonList>

                <IonLoading
                    isOpen={isLoading}
                    message={'Saving your Specifications. Please wait...'}
                />

                <IonModal
                    mode="ios"
                    isOpen={showSubmitToDealerModal}
                    onDidDismiss={() => setShowSubmitToDealerModal(false)}
                    swipeToClose={true}> 
                        <SubmitToDealer
                            onDismissModal={() => setShowSubmitToDealerModal(false)} 
                            route={ROUTES.CALCULATIONS_PAGE_ROUTE} />
                </IonModal>
                <IonAlert
                    isOpen={showSaveSuccessAlert}
                    header="Success"
                    subHeader="Your specifications were saved successfully"
                    message="Would you like to submit them to a dealership?"
                    onDidDismiss={() => setShowSaveSuccessAlert(false)}
                    buttons={[{
                    text: 'Yes',
                    handler: () => {
                        //Get Calc Values

                        //if (!isLoadingCalculations) {
                            //const c = (calculations as Array<Calculation>).filter(c => c.calculationID === calcID);
                            //setCalculation(c[0]);
                            //setCalculation();
                            //console.log(calculationID)
                            setShowSaveSuccessAlert(false);

                            history.replace(ROUTES.CALCULATIONS_PAGE_ROUTE)
                            //setShowSubmitToDealerModal(true);
                        //}

                        //else {

                       // }

                    }
                    }, {
                    text: 'No',
                    role: 'cancel',
                    handler: () => {
                        setShowSaveSuccessAlert(false);
                        history.push(ROUTES.TRUCK_LIST_PAGE_ROUTE);
                    }
                    }]}
                />

                    <IonAlert
                        isOpen={showErrorAlert}
                        header="Error"
                        subHeader="There was an error in saving your specification"
                        message="Would you like try again?"
                        onDidDismiss={() => setShowErrorAlert(false)}
                        buttons={[{
                        text: 'Yes',
                        role: 'cancel',
                        handler: () => {
                            console.log('Try again, futile. Should be: report the matter');
                
                        }
                        }, {
                        text: 'No',
                        handler: () => {
                            setShowErrorAlert(false);
                            history.push(ROUTES.CALCULATIONS_PAGE_ROUTE);
                        }
                    }]}
                />

            </IonContent>
        </IonPage>    
    );
}

export default CalcSave;