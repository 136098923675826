import { Store } from 'pullstate';
import { UserPreferencesModel } from '../../models/User';


export const defaultUserPreferences: UserPreferencesModel = {
    route: null,
    email: null,
    org: null,
    userid: '',
    fullname: '',
    initials: '',
    role: [],
    showBadge: false,
    messageCount: 0,
    accessToken: '',
    isDealership: false,
    enableNotifications: false,
    enableHinoBlackTheme: false,
    preferences: {
        units: {
            mass: 'kg',
            dimensions: 'mm'
        }  
    }
};

const UserPreferences = new Store<UserPreferencesModel>(defaultUserPreferences);

export default UserPreferences;
