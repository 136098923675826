import React from 'react';
import { IonPage,  IonText, IonContent,  IonGrid, IonCol, IonRow, IonButton } from '@ionic/react';
//import { Buffer } from 'buffer';
import * as ROUTES from '../../config';
import './Login.css';


const LoginEmail: React.FC = () => {
    
    //const emailb64: string | null = sessionStorage.getItem(Buffer.from("email").toString('base64'));
    //const email: string = Buffer.from(emailb64 === null ? "" : emailb64, 'base64').toString();

    return (

        <IonPage>

            <IonContent fullscreen className="ion-padding">

                <IonGrid>
                <IonRow className="header">
                        <IonCol className="header-logo-img">
                            <IonCol className="header-logo">
                                <IonCol className="header-logo-text"></IonCol>
                            </IonCol>
                        </IonCol>
                        <IonCol className="header-img"></IonCol>
                    </IonRow>

                    <IonRow className="login">
                        <IonText className="ion-padding-end ion-margin-end ion-text-center login-title">Account Deletion Initiated</IonText>
                        <IonText className="ion-padding-end ion-margin-end ion-text-center login-email-title">
                            <h5>
                                A request to deactivate your <strong>Hino Truck Body Builders App</strong> account, and delete all of your information from the App's servers, has been received successfully. We will send you an email as soon as all of your information has been completely deleted. The process will take a maximum of 7 days.
                            </h5>
                        </IonText>
                    </IonRow>

                    <IonRow className="ion-text-center ion-justify-content-center ion-no-margin ion-no-padding footer-button" >
                        <IonButton mode="ios" className="login-button" routerLink={ROUTES.LOGOUT_ROUTE}>Done</IonButton>
                    </IonRow>

                </IonGrid>

            </IonContent>
        </IonPage>
    )
}

export default LoginEmail;
