import { useEffect, useState } from "react";
import { IonCol, IonImg, IonItem, IonLabel, IonRadio, IonRadioGroup, IonRow, IonSegment, IonSegmentButton, IonToolbar, isPlatform } from "@ionic/react";
import { CalcValues, Calculator } from "../../../services/calculations";

import van from '../../../assets/bodies/vanBody.png';
import dropside from '../../../assets/bodies/dropsideBody.png';
import flatdeck from '../../../assets/bodies/flatdeckBody.png';
import tanker from '../../../assets/bodies/tankBody.png';
import tautliner from '../../../assets/bodies/tautlinerBody.png';
import rollback from '../../../assets/bodies/rollbackBody.png';
import beavertail from '../../../assets/bodies/beavertailBody.png';
import timber from '../../../assets/bodies/timberBody.png';
import tipper from '../../../assets/bodies/tipperBody.png';
import livestock from '../../../assets/bodies/livestockcarrierBody.png';
import { ModelBody } from "../../../models";


interface BodyTypeProps {
    values: CalcValues;
    setValues: (v: CalcValues) => void;
    show: (s: boolean) => void;
}

interface Bodies {
    name: string,
    image: string,
    value: string
}

export const listOfBodies =  [
      { name: 'Van', image: van, value: 'van' },
      { name: 'Dropside', image: dropside, value: 'dropside' },
      { name: 'Flatdeck', image: flatdeck, value: 'flatdeck' },
      { name: 'Tautliner', image: tautliner, value: 'tautliner' },
      { name: 'Tanker', image: tanker, value: 'tanker' },
      { name: 'Tipper', image: tipper, value: 'tipper' },
      { name: 'Timber', image: timber, value: 'timber' },
      { name: 'Rollback', image: rollback, value: 'rollback' },
      { name: 'Livestock Carrier', image: livestock, value: 'livestock' },
      { name: 'Beavertail', image: beavertail, value: 'beavertail' }
    ];

const SelectBodyType: React.FC<BodyTypeProps> = ({values, setValues, show}) => {

    const ios = isPlatform('ios');

    const { ...truck } = Calculator(values);
    const [bodyType, setBodyType] = useState<string | null | undefined>(truck.bodyType);

    const [segment, setSegment] = useState<'default' | 'custom'>('default');

    /** Temp: Trucks without Diagrams */
    const is300CrewCab =  truck.title.includes('FC3') || truck.title.includes('FF3') || truck.title.includes('FG3');
    const is500or700Truck = truck.title.includes('500') || truck.title.includes('700');
    const isTankerDisabled = is300CrewCab || is500or700Truck;
    //const isLivestockDisabled = isTankerDisabled;
    //const isTipper = truck.application.toLowerCase().includes('dump') || truck.application.toLowerCase().includes('tipper') || truck.title.toLowerCase().includes('tip') || truck.title.toLowerCase().includes('tipper')

    //console.log(isTankerDisabled);

    const renderListOfBodies = (list: Bodies[]) => {

        return list.map ((b, index) => 
    
            <IonCol size="6" key={index}>
                <IonItem detail={false}>
                    <IonCol size="12">
                        <IonRow className="ion-padding-bottom"><IonLabel>{b.name}</IonLabel></IonRow>
                        <IonRow>
                            <IonImg src={b.image} />
                            <IonRadio 
                                value={b.value} 
                                onClick={() => show(true)} 
                                disabled={ b.value === 'tipper' || b.value === 'rollback' || b.value === 'beavertail' || b.value === 'timber' || (b.value === 'tanker' && isTankerDisabled)}
                                ></IonRadio>
                        </IonRow>
                    </IonCol>  
                </IonItem>
            </IonCol>
        );
    }

    useEffect(() => {

        const v = { ...values };

        const body: ModelBody.Body = {
            type: bodyType || "unknown",
            mass: null,
            length: null,
            width: null,
            height: null
        };

        v.body = body;
        setValues(v);

    }, [bodyType])

    return(

        <>
            <IonToolbar>
                <IonSegment mode={ios ? "ios" : "md"} value={segment} onIonChange={(e) => setSegment(e.detail.value as any)}>
                    <IonSegmentButton value="default">Standard Bodies</IonSegmentButton>
                    <IonSegmentButton value="custom" disabled>My Saved Bodies</IonSegmentButton>
                </IonSegment>
            </IonToolbar>

            {
                segment === 'default' &&

                <IonRadioGroup value={bodyType} onIonChange={e => setBodyType(e.detail.value)}>
                    <IonRow>
                        {renderListOfBodies(listOfBodies)}
                    </IonRow>
                </IonRadioGroup>
            } 

            {
                segment === 'custom' &&
                <IonRadioGroup value={bodyType} onIonChange={e => setBodyType(e.detail.value)}>
                    <IonRow>
                        <IonItem lines="none">Coming Soon :)</IonItem>
                    </IonRow>
                </IonRadioGroup>
            } 
        </> 
    );
}

export default SelectBodyType;