import { useRef } from 'react';

import { IonContent, IonButton, IonToolbar, IonHeader, IonPage, IonButtons, IonIcon, IonTitle, IonCard, IonCardContent } from '@ionic/react';
import { chevronBack } from 'ionicons/icons';


import * as ROUTES from '../../config';

const TOS: React.FC = () => {
  
  const pageRef = useRef<HTMLElement>(null);

  return (
    <IonPage ref={pageRef} id="tos-page">
      <IonHeader>
        <IonToolbar mode="ios" color="toolbar">
            <IonButtons slot="start">
              <IonButton routerLink={ROUTES.TABS_ACCOUNT_PAGE_ROUTE} routerDirection='back'>
                <IonIcon icon={chevronBack} />
              </IonButton>
            </IonButtons>
            <IonTitle>Terms of App Use</IonTitle>
        </IonToolbar>
      </IonHeader>
      
      <IonContent fullscreen>
      <IonCard className='ion-text-center ion-justify-content' mode="ios">
          <IonCardContent>
            <h3><strong>Hino Truck Body Builders App</strong></h3>
            <h1><strong>TERMS OF APP USE</strong></h1>

            <ol>
              <li className='ion-text-start'>
                <h2 className="ion-padding-bottom"><strong>Introduction</strong></h2>
                <p className="ion-padding-bottom ion-margin-bottom">
                  Welcome to the Hino Truck Body Builders App (&quot;App&quot;), operated by Toyota South Africa Motors (TSAM). The App is designed to connect truck body builders with preferred suppliers and dealers, provide guidelines on body mounting, and help users learn about applicable National Traffic (AARTO) Regulations. By accessing or using the App, you agree to these Terms of Use.
                </p>
              </li>

              <li className='ion-text-start'>
                <h2 className="ion-padding-bottom"><strong>Acceptance of Terms</strong></h2>
                <p className="ion-padding-bottom">
                  By downloading, installing, or using the App, you accept and agree to comply with these Terms of Use, as well as any changes we may make to them.
                </p>
                <p className="ion-padding-bottom">
                The benefits of the App shall only become active once you have downloaded and installed the App and completed any required relevant information, as set out therein.
                </p>
                <p className="ion-padding-bottom">
                Download, installation and use of the App requires internet connectivity and mobile data. TSAM shall
under no circumstances be liable should you not be able to access the App due to lack of data or any
connectivity issues.
                </p>
                <p className="ion-padding-bottom">
                If you do not agree with these terms, please refrain from using the App.
                </p>
              </li>

              <li className='ion-text-start'>
                <h2 className="ion-padding-bottom"><strong>Purpose and Scope of the App</strong></h2>
                <p className="ion-padding-bottom">
                The App provides:
                </p>
                <ul className='ion-text-start'>
                  <li>
                    <p className="ion-padding-bottom">
                    Information about Hino Body Mounting Guidelines and relevant National Traffic (AARTO)
                    Regulations.
                    </p>
                  </li>
                  <li>
                    <p className="ion-padding-bottom">
                    A platform to connect truck body builders with preferred suppliers and dealers.
                    </p>
                  </li>
                  <li>
                    <p className="ion-padding-bottom">
                    Communication channels with Hino Dealership Representatives and Preferred Suppliers.
                    </p>
                  </li>
                  <li>
                    <p className="ion-padding-bottom">
                    Detailed specifications of Hino models for viewing and sharing.
                    </p>
                  </li>
                  <li>
                    <p className="ion-padding-bottom">
                    Tools to configure body specifications for Hino models.
                    </p>
                  </li>
                  <li>
                    <p className="ion-padding-bottom">
                    Features to compute axle weight, bridge formula, and turning circle calculations.
                    </p>
                  </li>
                  <li>
                    <p className="ion-padding-bottom">
                    Validation tools for calculations against AARTO Regulations and Hino Body Mounting Guidelines.
                    </p>
                  </li>
                  <li>
                    <p className="ion-padding-bottom">
                    Access to view and capture jobs on the e-Toyota Portal.
                    </p>
                  </li>
                </ul>
                <p className="ion-padding-bottom">
                All content provided in the App, including body specifications, is for informational and illustration purposes only. TSAM does not guarantee the accuracy or completeness of the content, and you should verify all information before acting on it. </p>
              </li>

              <li className='ion-text-start'>
                <h2 className="ion-padding-bottom"><strong>TSAM&apos;s Responsibilities</strong></h2>
                <p className="ion-padding-bottom">
                While we strive to keep the App functional and up-to-date, the App is provided on an &quot;as-is&quot; and &quot;as-
available&quot; basis. TSAM does not warrant the App&#39;s uninterrupted availability or error-free operation
and shall not be liable for any inaccuracies, errors, or omissions in the content. The calculations and
configurations offered in the App are for guidance only, and TSAM shall not be liable for their use in
real-world applications.
                </p>
              </li>

              <li className='ion-text-start'>
                <h2 className="ion-padding-bottom"><strong>User Responsibilities</strong></h2>
                <p className="ion-padding-bottom">
                You agree to use the App in compliance with all applicable laws and regulations. You are responsible
for ensuring that any information shared via the App is accurate, lawful, and, where required,
consented to by third parties. In using the App, you must not:
                </p>
                <ul className='ion-text-start'>
                  <li>
                    <p className="ion-padding-bottom">
                    Disrupt or interfere with the App&#39;s operation or other users&#39; access.
                    </p>
                  </li>
                  <li>
                    <p className="ion-padding-bottom">
                    Use the App for any unlawful or harmful purposes.
                    </p>
                  </li>
                  <li>
                    <p className="ion-padding-bottom">
                    Distribute any malicious software or code via the App.
                    </p>
                  </li>
                  <li>
                    <p className="ion-padding-bottom">
                    Copy, modify, or distribute the App&#39;s content without TSAM&#39;s prior written consent.
                    </p>
                  </li>
                </ul>
              </li>

              <li className='ion-text-start'>
                <h2 className="ion-padding-bottom"><strong>Personal Information</strong></h2>
                <p className="ion-padding-bottom">
                TSAM, through the App, may collect, store, and use your personal information (as the term is defined
                  in Protection of Personal Information Act 4 of 2013 (&quot;POPIA&quot;)) for the following purposes:
                </p>
                <ul className='ion-text-start'>
                  <li>
                    <p className="ion-padding-bottom">
                    to greet you when you contact us;
                    </p>
                  </li>
                  <li>
                    <p className="ion-padding-bottom">
                    to compile non-personal statistical information for marketing and promotional purposes;
                    </p>
                  </li>
                  <li>
                    <p className="ion-padding-bottom">
                    for research to develop new products, services and offers; and
                    </p>
                  </li>
                  <li>
                    <p className="ion-padding-bottom">
                    to contact you via app notifications, SMS or emails to share marketing communication and promotional offers.
                    </p>
                  </li>
                </ul>
                <p className="ion-padding-bottom">
                By accepting these Terms of Use and/or by using the App, you hereby consent to the processing (as
                  the term is defined in POPIA) of your personal information for the purposes set out above.
                </p>
              </li>

              <li className='ion-text-start'>
                <h2 className="ion-padding-bottom"><strong>Intellectual Property</strong></h2>
                <p className="ion-padding-bottom">
                The App and its content, including but not limited to text, graphics, logos, and software, are the
property of TSAM or its licensors and are protected by applicable copyright and trademark laws. You
are granted a limited, non-exclusive, and revocable license to use the App for its intended purpose.
You may not use any part of the App for commercial purposes without obtaining a license to do so
from us or our licensors.
                </p>
              </li>

              <li className='ion-text-start'>
                <h2 className="ion-padding-bottom"><strong>Third-Party Links and Content</strong></h2>
                <p className="ion-padding-bottom">
                The App may contain links to third-party websites or resources. TSAM is not responsible for the
content, privacy policies, or practices of any third-party websites or resources. You acknowledge and
agree that TSAM shall not be liable for any damage or loss caused or alleged to be caused by or in
connection with your use of or reliance on any third-party content.
                </p>
              </li>

              <li className='ion-text-start'>
                <h2 className="ion-padding-bottom"><strong>Limitation of Liability and Indemnity</strong></h2>
                <p className="ion-padding-bottom">
                To the fullest extent permitted by law, TSAM disclaims all warranties, express or implied, including but
not limited to warranties of merchantability, fitness for a particular purpose, and non-infringement.
TSAM shall not be liable for any direct, indirect, incidental, special, or consequential damages
resulting from your use of the App or any content therein.
                </p>
                <p className='ion-padding-bottom'>
You agree to indemnify and hold TSAM, its affiliates, and its and their directors, officers, employees,
and agents harmless from any claims, damages, losses, liabilities, and expenses (including legal
fees) arising out of or related to your use of the App or your violation of these Terms of Use.
                </p>
              </li>

              <li className='ion-text-start'>
                <h2 className="ion-padding-bottom"><strong>Termination</strong></h2>
                <p className="ion-padding-bottom">
                TSAM reserves the right to suspend and/or terminate your participation in the App in the event of
suspected fraudulent and/or inappropriate conduct, abuse, or misuse of the App. In such an event,
any benefits which may have accrued to you under the App will, at the sole discretion of TSAM, acting
reasonably, and to the extent allowed by law, be automatically suspended or forfeited and you will
have no claim against TSAM for any such loss.
                </p>
              </li>

              <li className='ion-text-start'>
                <h2 className="ion-padding-bottom"><strong>Governing Law</strong></h2>
                <p className="ion-padding-bottom">
                These Terms of Use are governed by the laws of the Republic of South Africa, without regard to its
conflict of laws principles. Any disputes arising out of or relating to these Terms shall be resolved in
accordance with the dispute resolution procedures outlined herein or as otherwise agreed between
the parties.
                </p>
              </li>

              <li className='ion-text-start'>
                <h2 className="ion-padding-bottom"><strong>Dispute Resolution</strong></h2>
                <p className="ion-padding-bottom">
                This clause 12 is a separate, divisible agreement from the rest of these Terms of Use and shall not be
or become void, voidable or unenforceable by reason only of any alleged misrepresentation, mistake,
duress, undue influence, impossibility (initial or supervening), illegality, immorality, absence of
consensus, lack of authority or other cause relating in substance to the rest of the Terms of Use and
not to this clause 12; and remain in effect even if these Terms of Use expire or terminate for any
reason whatsoever.
                </p>

                <p className='ion-padding-bottom'>
                If you have a complaint or dispute relating to the App, please contact us at +2711 809 9111. We
will use reasonable efforts to resolve your complaint promptly. Should you not be satisfied with the
efforts and resolution by TSAM, you may refer the dispute to any of the forms of alternative dispute
resolution under section 70 of the CPA.
                </p>

                <p className='ion-padding-bottom'>
                Should you refer the dispute to mediation under section 70(1)(c) of the CPA, the mediator will be as
agreed by you and TSAM. Should the parties fail to agree on a mediator within 10 business days or if
the dispute cannot be resolved between the parties within 30 business days, the dispute will be
referred to arbitration.
                </p>

                <p className='ion-padding-bottom'>
                All disputes which cannot be settled as set out above shall be referred to arbitration and determined in
accordance with the rules of the Arbitration Foundation of Southern Africa (AFSA).
                </p>

                <p className='ion-padding-bottom'>
                AFSA shall appoint an arbitrator who shall be an attorney or senior advocate (with at least 20 years&#39;
                  experience in commercial legal practice).
                </p>

                <p className='ion-padding-bottom'>
                The arbitration shall take place in Johannesburg, South Africa. The arbitrator&#39;s decision shall be final
and binding and may be made an order of any court of competent jurisdiction.
                </p>

                <p className='ion-padding-bottom'>
                Nothing contained in this clause 12 shall prohibit you or TSAM from approaching any court of
competent jurisdiction for urgent interim relief.
                </p>

                <p className='ion-padding-bottom'>
                You hereby consent to the non-exclusive jurisdiction of the High Court of South Africa (Gauteng Local
                  Division, Johannesburg) for any proceedings arising out of or in connection with this Agreement.
                </p>
              </li>

              <li className='ion-text-start'>
                <h2 className="ion-padding-bottom"><strong>Changes to the App and the Terms</strong></h2>
                <p className="ion-padding-bottom">
                TSAM reserves the right to at any time change the App, including any benefits and information
provided in the App.
                </p>

                <p className='ion-padding-bottom'>
                TSAM may, and reserves the right to, revise these Terms of Use from time to time. The most current
version of the Terms will be available on the App. Your continued use of the App after any changes
become effective constitutes your acceptance of the revised Terms.
                </p>
              </li>

              <li className='ion-text-start'>
                <h2 className="ion-padding-bottom"><strong>Consumer Protection</strong></h2>

                <p className='ion-padding-bottom'>
                This App is or may be subject to the provisions of the Consumer Protection Act 68 of 2008, as
amended from time to time (&quot;CPA&quot;). To the extent permitted by law, you shall have no claims under
the CPA against TSAM, its management, directors, or employees arising out of or in relation to your
participation in and use of the App.
                </p>
              </li>

              <li className='ion-text-start'>
                <h2 className="ion-padding-bottom"><strong>Severability</strong></h2>

                <p className='ion-padding-bottom'>
                If any provision of these Terms is found to be invalid or unenforceable by a court of competent
jurisdiction, the remaining provisions shall continue in full force and effect.
                </p>
              </li>

              <li className='ion-text-start'>
                <h2 className="ion-padding-bottom"><strong>Entire Agreement</strong></h2>

                <p className='ion-padding-bottom'>
                These Terms, along with our Privacy Policy, constitute the entire agreement between you and TSAM
regarding your use of the App and supersede any prior agreements and you may not rely on anything
communicated to you by TSAM (whether or not made innocently, negligently or deliberately) which
allegedly caused you to accept these Terms of Use.
                </p>

                <p className='ion-padding-bottom'>
                You waive the right to rely on any alleged provision not expressly contained in these Terms of Use.
                </p>
              </li>

              <li className='ion-text-start'>
                <h2 className="ion-padding-bottom"><strong>Miscellaneous</strong></h2>
                <ul className='ion-text-start'>
                  <li>
                    <p className="ion-padding-bottom">
                    You warrant that you have the necessary legal capacity to enter into and perform in terms of
the agreement contemplated in these Terms of Use.
                    </p>
                  </li>
                  <li>
                    <p className="ion-padding-bottom">
                    To extent permitted by law, TSAM provides no warranties in respect of the App.
                    </p>
                  </li>
                  <li>
                    <p className="ion-padding-bottom">
                    The grant of any indulgence, extension by TSAM of any time or relaxation of any provision
under these Terms of Use (or under any other agreement or document issued or executed
pursuant to these Terms of Use) shall not constitute a waiver of any right by TSAM or prevent
or adversely affect the exercise by TSAM of any existing or future right of TSAM.
                    </p>
                  </li>
                  <li>
                    <p className="ion-padding-bottom">
                    The rule of construction that an agreement shall be interpreted against or to the disadvantage
of the party responsible for the drafting or preparation of the agreement (ie the contra
proferentem rule), shall not apply to these Terms of Use.
                    </p>
                  </li>
                </ul>
              </li>








              




              





            




              

            </ol>
            
          </IonCardContent>
        </IonCard>

      </IonContent>
    </IonPage>
  );
};

export default TOS;
