import { Store } from 'pullstate';

export interface GuidelineParameters {
    url: string | null;
    title: string | null;
    regulations: Array<any> | null;

}

const GuidelineStore = new Store<GuidelineParameters>({
    title: null,
    url: null,
    regulations: null
});


export default GuidelineStore;

