import React, { useState } from 'react';
import { isPlatform, IonHeader, IonToolbar, IonButtons, IonButton, IonContent, IonList, IonListHeader,
    IonLabel, IonIcon, IonCol, IonCard, IonCardContent, IonCardTitle, IonRow, IonTitle, IonPage } from '@ionic/react';
import { close, options } from 'ionicons/icons';
import { useHistory } from 'react-router';
import { min, max } from 'mathjs';

import { useDefaultTrucks } from '../../services/trucks';
import { Truck, TruckSpecifications } from '../../models/Truck';

import * as ROUTES from '../../config';

import './TruckListFilter.css';
import SearchInput from '../SearchInput';

interface TruckListFilterProps {
    onDismissModal: () => void;
    updateSeries?: (series: string) => void;
    route?: string;
  }

export const listOfModels = (trucks: Truck, series: string) => {
    const c = trucks.series.filter(models => models.name === series)
      .map(s => s.models).flat(1);

    return c;
}

export const applicationArray = (trucks: Truck, series: string) => {
    const c = listOfModels(trucks, series).flat(1);
    const a: Array<String> = series === "200" || series === '300' || series === 'dyna' ?
   
                            ["Dropside, Flatdeck, Tautliner, Van"]  //Convert Body Application for 200 and 300 series from General Use to Dropside, Flatdeck, Van and Tautliner
    
                        : 
    
                            Array.from(new Set(c.map((o: TruckSpecifications) => o.application)));

    const str = a.join(', ');

    localStorage.setItem(`${series}-series-application`, JSON.stringify(str));

    return str;
}

const TruckListFilter: React.FC<TruckListFilterProps> = ({onDismissModal, updateSeries, route}) => {

    const history = useHistory();

    const ios = isPlatform('ios');
    const {defaultTrucks, isLoading: isLoadingTrucks } = useDefaultTrucks(null);

    const [query, setQuery] = useState<string>("");
    const [showFilter, setShowFilter] = useState(false);
    
    const gvmArray = (series: string) => {
        const c = listOfModels(defaultTrucks, series).flat(1);
        const g = Array.from(new Set(c.map((o: TruckSpecifications) => +o.maxGVMRating)));

        return g;
    }

    const seriesList = defaultTrucks.series.flat(1).map(series => series.name);
    
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="toolbar">
                    <IonButtons slot="start">
                        <IonButton onClick={onDismissModal}>
                        <IonIcon icon={close} />
                        &nbsp;</IonButton>
                    </IonButtons>
                    <IonTitle>Hino Trucks</IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent>

            {/*<IonToolbar mode="ios" className="ion-padding-top">
                <SearchInput onChangeSearchQuery={(query) => setQuery(query)}/> 
                <IonButtons slot="end">
                    {
                    !showFilter &&
                    <IonButton color="dark" onClick={() => setShowFilter(true)}>
                    {ios ? 'Filter' : <IonIcon icon={options} slot="icon-only" />}
                    </IonButton>}
                    {
                    showFilter &&
                    <IonButton color="dark" onClick={() => setShowFilter(false)}>
                        {ios ? 'Close' : <IonIcon icon={close} slot="icon-only" />}
                    </IonButton>
                    
                    }
                </IonButtons>
                </IonToolbar>*/}

                <IonList lines={ ios ? 'inset' : 'full'}>


                    {isLoadingTrucks && <IonListHeader>Please wait...</IonListHeader>}
              
                    <IonRow className="ion-justify-content-center"> 
                        
                    {
                        seriesList && seriesList.sort() && seriesList.map((s: string) => {

                            return (
                            <IonCol className="ion-padding" size="12" /*sizeMd="6" sizeLg="6" sizeXl="6"*/ key={s}>

                                <IonCard mode="ios" className="ion-text-center">

                                {
                                isLoadingTrucks && defaultTrucks.series.length === 0 ? 
                                <IonLabel slot="start" className="ion-padding ion-margin-end">
                                    <IonCardTitle mode="ios">Please wait...</IonCardTitle>
                                </IonLabel> 
                                
                                :

                                <IonCardContent>

                                    <IonRow>
                                    <IonCol size="12">
                                        <IonLabel color="primary">
                                        <h2>
                                            <strong>
                                            {s === 'dyna' ? `Toyota ${s.charAt(0).toUpperCase() + s.slice(1)} ` : `Hino ${s.charAt(0).toUpperCase() + s.slice(1)} Series`}
                                            </strong>
                                        </h2>
                                        </IonLabel>
                                    </IonCol>
                                    </IonRow>

                                    <IonRow>

                                    <IonCol size="6">

                                        <br/>

                                        {
                                            s === 'busco' || s === 'busmark' ? 

                                            <></>

                                            :

                                            min(gvmArray(s)) === max(gvmArray(s)) ?
                                            
                                            <p><strong>GVM:</strong> {`${min(gvmArray(s))} kg`}</p>

                                            :

                                            <>
                                                <p><strong>Min GVM: </strong>{`${min(gvmArray(s))} kg`}</p>
                                                <p><strong>Max GVM:</strong> {`${max(gvmArray(s))} kg`}</p>   
                                            </>
                                        }

                                        <br/>

                                        <p><strong><u>Application (s)</u></strong></p>
                                        <p>{applicationArray(defaultTrucks, s)}</p>

                                    </IonCol>

                                    <IonCol size="6">
                                        <img src={`/assets/images/hino${s}.png`} alt={s}/>
                                    </IonCol>
                                    
                                    </IonRow>

                                    <IonRow className="ion-justify-content-center">
                                    <IonButton 
                                        routerLink={`${ROUTES.TRUCK_LIST_PAGE_ROUTE}?series=${s}&from=${route}`}
                                        onClick={() => {
                                            
                                            updateSeries !== undefined ? updateSeries(s)

                                            :
                                            
                                            history.push(`${ROUTES.TRUCK_LIST_PAGE_ROUTE}?series=${s}&from=${route}`);

                                            onDismissModal();
                                        }}
                                        color="primary">
                                        View {`${listOfModels(defaultTrucks, s).length < 2 ? `model` : `all ${listOfModels(defaultTrucks, s).length} models`}`} &rarr;
                                        </IonButton>
                                    </IonRow>

                                </IonCardContent>
                                }
                                </IonCard>

                            </IonCol>
                            );
                        })
                        }

                    </IonRow>

                </IonList>

            </IonContent>

        </IonPage>
    )
}

export default TruckListFilter;